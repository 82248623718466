import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  deepExtend,
  getDeepFromObject,
  nbAuthCreateToken,
  NbAuthIllegalTokenError,
  NbAuthResult,
  NbAuthStrategyOptions,
  NbAuthToken,
} from '@nebular/auth';

/**
 * Must override NbPasswordAuthStrategy in order to include token
 */
export abstract class CustomAuthStrategy {
  protected defaultOptions!: NbAuthStrategyOptions;
  protected options!: NbAuthStrategyOptions;

  public setOptions(options: any): void {
    this.options = deepExtend({}, this.defaultOptions, options);
  }

  public getOption(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  public createToken<T extends NbAuthToken>(value: any, failWhenInvalidToken?: boolean): T {
    const token = nbAuthCreateToken<T>(this.getOption('token.class'), value, this.getName());
    if (failWhenInvalidToken && !token.isValid()) {
      throw new NbAuthIllegalTokenError('Token is empty or invalid.');
    }
    return token;
  }

  public getName(): string {
    return this.getOption('name');
  }

  public abstract authenticate(data?: any): Observable<NbAuthResult>;

  public abstract register(data?: any): Observable<NbAuthResult>;

  public abstract registerWithCode(data?: any, code?: string): Observable<NbAuthResult>;

  public abstract requestPassword(data?: any): Observable<NbAuthResult>;

  public abstract resetPassword(data?: any): Observable<NbAuthResult>;

  // Need to add token here to customize NbAuthStrategy
  public abstract token(data?: any): Observable<NbAuthResult>;

  public abstract logout(): Observable<NbAuthResult>;

  public abstract refreshToken(data?: any): Observable<NbAuthResult>;

  // eslint-disable-next-line
  protected createFailResponse(data?: any): HttpResponse<Object> {
    // eslint-disable-next-line
    return new HttpResponse<Object>({ body: {}, status: 401 });
  }
  // eslint-disable-next-line
  protected createSuccessResponse(data?: any): HttpResponse<Object> {
    // eslint-disable-next-line
    return new HttpResponse<Object>({ body: {}, status: 200 });
  }

  protected getActionEndpoint(action: string): string {
    const actionEndpoint: string = this.getOption(`${action}.endpoint`);
    const baseEndpoint: string = this.getOption('baseEndpoint');
    return actionEndpoint ? baseEndpoint + actionEndpoint : '';
  }
}
