import { Component, Input } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { PesFormControlBaseComponent } from '../form-control-base/form-control-base.component';

@Component({
  selector: 'pes-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss'],
})
export class PesFormDateComponent extends PesFormControlBaseComponent {
  @Input() format = '';
  @Input() placeholder = '';
  @Input() size: NbComponentSize = 'medium';
  @Input() readonly = false;
  @Input() iconSize: NbComponentSize = 'medium';

  public onClear(): void {
    this.control.reset('');
  }

  public get hasValue(): boolean {
    return !!this.control.value;
  }
}
