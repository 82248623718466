/**
 * Configuration state data passed in through the Router. Required to initialize
 * the Survey page
 */
export interface PesPatientQuestionnaireConfig {
  userId: number;
  questionnaireId: number;
  userStudyMilestoneId: number;
  backUrl?: string;
  labels: {
    pageTitle: string;
    backButtonLabel: string;
  };
}
