import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { API_CONFIG, IApiConfig } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepContactUsService {
  private readonly endpoints = {
    contactUs: '/api/v1/contact-us',
  };
  constructor(
    private apiService: PepApiService,
    @Inject(API_CONFIG) private apiConfig: IApiConfig
  ) {}

  /**
   * Post user query
   */
  public raiseQuery(
    contactObj: Partial<{ category: string | null; comment: string | null }>
  ): Observable<string> {
    return this.apiService.add(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.contactUs}`,
      contactObj
    );
  }
}
