import { Injectable } from '@angular/core';
import { NbGlobalLogicalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';

type ErrorType = string | { message: string } | { msg: string } | { error: Error };

/**
 * Manage displaying "toast" notifications.  These appear in the upper-right of the screen.
 */
@Injectable({ providedIn: 'root' })
export class PepToastNotificationService {
  constructor(private toastrService: NbToastrService) {}

  /**
   * show error toaster with error and title
   *
   * @param err string error message or error instance (TODO: need standardized error responses from api)
   * @param title string error title
   */
  public showErrorToast(err: ErrorType, title = 'Error') {
    let errorMessage: string;
    if (!err) {
      errorMessage = 'Unknown Error';
    } else if (typeof err === 'string') {
      errorMessage = err;
    } else if ('message' in err) {
      errorMessage = err.message;
    } else if ('msg' in err) {
      errorMessage = err.msg;
    } else if (err.error instanceof Error) {
      errorMessage = err.error.message;
    } else {
      // no idea what type this is...
      errorMessage = err.toString();
    }
    this.toastrService.show(errorMessage, title, this.errorToastr());
  }

  /**
   * show info toaster with message and title
   *
   * @param message string message
   * @param title string title
   */
  public showInfoToast(message: string, title = 'Info') {
    this.toastrService.show(message, title, this.infoToastr());
  }

  /**
   * show success toaster with message and title
   *
   * @param message string message
   * @param title string title
   */
  public showSuccessToast(message: string, title = 'Success') {
    this.toastrService.show(message, title, this.successToastr());
  }

  // toastr config for error
  private errorToastr() {
    return new NbToastrConfig({
      position: NbGlobalLogicalPosition.TOP_END,
      status: 'danger',
      toastClass: 'toast-message',
      duration: 10000,
    });
  }

  // toastr config for success
  private successToastr() {
    return new NbToastrConfig({
      position: NbGlobalLogicalPosition.TOP_END,
      status: 'success',
      toastClass: 'toast-message',
      duration: 4000,
    });
  }

  // toastr config for success
  private infoToastr() {
    return new NbToastrConfig({
      position: NbGlobalLogicalPosition.TOP_END,
      status: 'info',
      duration: 4000,
    });
  }
}
