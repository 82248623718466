<img class="img-fluid d-block main-img" src="{{ logo }}" alt="Target RWE" />
<h1>{{ 'appTitle' | translate }}</h1>
<h2 id="title" class="title">{{ 'changePassword' | translate }}</h2>
<h3 class="title">{{ userName }}</h3>
<p class="sub-title">{{ 'newPasswordSetTitle' | translate }}</p>
<p class="sub-title">{{ 'newPasswordSet' | translate }}</p>

<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <ul class="alert-message-list">
    <li class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="message && !submitted" outline="success" role="alert">
  <ul class="alert-message-list">
    <li class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form [formGroup]="resetForm" (ngSubmit)="onResetPass()" novalidate>
  <!-- New Password -->
  <div class="mb-4">
    <pes-form-input
      [control]="resetForm.controls.password"
      [size]="'large'"
      [type]="'password'"
      label="{{ 'newPassword' | translate }}"
      [errors]="{
        required: translate('formError_required'),
        pattern: translate('passwordPatternError'),
        minlength: translate('passwordLengthError', { minLength: 8, maxLength: 50 }),
        maxLength: translate('passwordLengthError', { minLength: 8, maxLength: 50 })
      }"
      [showErrors]="submitted"
    >
    </pes-form-input>
  </div>

  <!-- Confirm Password -->
  <div class="mb-4">
    <pes-form-input
      [control]="resetForm.controls.confirmPassword"
      [size]="'large'"
      [type]="'password'"
      label="{{ 'confirmPassword' | translate }}"
    >
    </pes-form-input>
    <div
      *ngIf="submitted && resetForm.errors?.['mustMatch']"
      class="invalid-feedback d-flex"
      id="confirmPassword-error"
    >
      {{ 'passwordMismatchError' | translate }}
    </div>
  </div>

  <button
    nbButton
    status="primary"
    fullWidth
    size="large"
    class="mb-3"
    [class.btn-pulse]="submitted"
  >
    {{ 'changePassword' | translate }}
  </button>
</form>

<section class="sign-in-or-up text-center mb-4" aria-label="Sign in or sign up">
  <p>
    <a class="text-link" routerLink="../login">{{ 'backtoLogIn' | translate }}</a>
  </p>
  <p>
    <a class="text-link" routerLink="../register">{{ 'register' | translate }}</a>
  </p>
</section>
