import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { IUserInfo } from '../../interfaces';
import { PesUserProfileConfig } from '../../interfaces/user-profile-config.interface';
import { PepUserService } from '../../services';
import { RoleType } from '../../types/role.type';
import { pepTranslate } from '../../utilities';
import { NbAuthService } from '@nebular/auth';

@Component({
  selector: 'pes-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PesAppHeaderComponent implements OnInit {
  @Input() user!: IUserInfo;
  @Input() userMenu!: Array<NbMenuItem>;
  @Input() messagePopup!: any;
  @Input() sideExpanded = false;
  @Input() appTargetLink = '';
  @Input() appName = '';
  @Input() currentUserRole = '';
  @Output() private stateChanged = new EventEmitter<boolean>();

  public userPictureOnly = false;

  private readonly router = inject(Router);
  private readonly menuService = inject(NbMenuService);
  private readonly userService = inject(PepUserService);
  private readonly changeDetection = inject(ChangeDetectorRef);
  public readonly translate = pepTranslate();
  private readonly nbAuthService = inject(NbAuthService);
  constructor() {}

  ngOnInit(): void {
    this.menuService.onItemClick().subscribe((menuItem) => {
      if (menuItem.item.parent?.title === 'Switch Roles') {
        if (menuItem.item.data.name === RoleType.StudyManager) {
          this.switchRole(RoleType.StudyManager);
          if (this.appName === 'iep') {
            this.router.navigate(['study-manager/participant-management']);
          } else {
            this.router.navigate(['study-manager/study-list']);
          }
        }
        if (menuItem.item.data.name === RoleType.Hcp) {
          this.router.navigate(['study-manager/study-list']);
          this.switchRole(RoleType.Hcp);
        }
        if (menuItem.item.data.name === RoleType.Patient) {
          this.router.navigate(['engagement/studies']);
          this.switchRole(RoleType.Patient);
        }
        if (menuItem.item.data.name === RoleType.StudySiteAdmin) {
          this.router.navigate(['admin/participant-management']);
          this.switchRole(RoleType.StudySiteAdmin);
        }
      } else if (menuItem.item.title === 'My Profile') {
        const link = menuItem.item.link || menuItem.item.data.link || '';
        const state: PesUserProfileConfig = {
          loggedInUser: menuItem.item.data.loggedInUser,
          userRole: menuItem.item.data.userRole,
        };
        this.router.navigateByUrl(link, { state });
      } else if (menuItem.item?.data?.name === 'privacy-policy') {
        window.open(menuItem.item.data.link, '_blank');
      } else {
        if (menuItem.item?.data?.link) {
          this.router.navigate([menuItem.item.data.link]);
        }
        if (menuItem.item?.link) {
          this.router.navigate([menuItem.item.link]);
        }
      }
    });
  }

  /**
   * Toggles the expand/contract mode of the sidebar
   */
  public onToggleSidebar() {
    this.sideExpanded = !this.sideExpanded;
    this.stateChanged.emit(this.sideExpanded);
  }

  public get userName() {
    return `${this.user.first_name} ${this.user.last_name}`;
  }

  public switchRole(newRole: string): void {
    const crtUser = this.user;
    const activeRoleObj = crtUser.roles?.find(
      (x) => x.name.toLowerCase().replace(/\s/g, '') === newRole.toLowerCase()
    );
    if (activeRoleObj) {
      this.userService.roleHasBeenUpdated(activeRoleObj);
      this.user.title = activeRoleObj.type;
      this.changeDetection.detectChanges();
    }
  }

  public get isStudyManager(): boolean {
    return RoleType.StudyManager === this.currentUserRole.toLowerCase();
  }

  public onApplicationNavigation(): void {
    this.nbAuthService.getToken().subscribe((auth: any) => {
      const targetUrl = new URL(this.appTargetLink);
      targetUrl.searchParams.set('act', auth.token);
      targetUrl.searchParams.set('role', this.currentUserRole.toLowerCase());
      targetUrl.searchParams.set('user', this.user.email.toLowerCase());
      window.open(targetUrl.href, '_self');
    });
  }

  public get appHeader(): string {
    switch (this.currentUserRole.toLowerCase()) {
      case RoleType.StudyManager:
        return this.appName === 'iep'
          ? this.translate('applicationSiteAdminLabel')
          : this.translate('applicationStudyManagerLabel');
      case RoleType.Patient:
        return this.translate('applicationPatientLabel');
      case RoleType.StudySiteAdmin:
        return this.translate('applicationSiteAdminLabel');
      default:
        return '';
    }
  }
}
