import { IElements } from './element.interface';
import { ISubstitutionItem } from './substitution-item.interface';

/**
 * Represents a SurveyJS survey with additional data
 */
export interface IPEPSurvey {
  logo?: string;
  logoHeight?: string;
  logoWidth?: string;
  pages: Array<IElements>;
  name?: string;
  title?: string;
  tag?: string;
  description?: string;
  substitutions?: ISubstitutionItem[];
}
