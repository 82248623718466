import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PepToastNotificationService,
  PepPaymentSummaryService,
  IPaymentAccount,
  IUserPrimaryAccount,
  pepTranslate,
  PepLocalstoragedataService,
} from '@targetrwe/pep-shared';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'trwe-paypal-login',
  templateUrl: './paypal-login.component.html',
  styleUrls: ['./paypal-login.component.scss'],
})
export class TrwePaypalLoginComponent implements OnInit {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly paymentSummaryService = inject(PepPaymentSummaryService);
  private readonly localstorageDataService = inject(PepLocalstoragedataService);
  private readonly translate = pepTranslate();
  public submitted = false;
  public success = false;

  constructor() {}

  ngOnInit(): void {
    this.login();
  }

  private login() {
    const accessCode = this.activatedRoute.snapshot?.queryParamMap.get('code');
    if (accessCode) {
      const payload: IPaymentAccount = {
        code: accessCode,
        processor: 'paypal',
      };
      this.linkAccount(payload);
    }
  }

  public linkAccount(payload: IPaymentAccount): void {
    this.paymentSummaryService
      .linkAccount(payload)
      .pipe(
        catchError((error) => {
          this.submitted = false;
          this.toastService.showErrorToast(this.translate('errorLinkingAccount'));
          const state = {
            success: false,
            errorType: error.error,
            source: 'paypal',
          };
          // if new window is opened push state to localstorage else redirect to profile page
          if (window.opener) {
            this.onSend(state);
          } else {
            this.router.navigateByUrl('user/profile', { state: state });
          }
          return of(undefined);
        })
      )
      .subscribe((response?: IUserPrimaryAccount) => {
        if (response) {
          this.submitted = false;
          this.success = true;
          const state = {
            success: true,
            source: 'paypal',
          };
          if (window.opener) {
            this.onSend(state);
          } else {
            this.router.navigateByUrl('user/profile', { state: state });
          }
        }
      });
  }
  /**
   *
   * @param message set state in localstorage and close the window going back to parent page
   */
  public onSend(message: any) {
    this.localstorageDataService.setPaypalResponse(JSON.stringify(message));
    window.close();
  }
}
