import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMilestone } from '../../interfaces';
import { IPro, IStudySurvey } from '../../interfaces/study-survey.interface';
import { ISurveySubmitRequest } from '../../interfaces/survey-request.interface';
import { IUserSurvey } from '../../interfaces/user-survey.interface';
import { API_CONFIG } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepStudySurveyService {
  private readonly apiService = inject(PepApiService);
  private readonly apiConfig = inject(API_CONFIG);

  private readonly endpoints = {
    userSurvey: '/user_study_survey_by_user',
    surveyMilestone: '/study_survey_milestone',
    viewSurvey: '/api/v1/survey_response',
    answerSurvey: '/api/v1/answer',
    survey: '/api/v1/survey',
    userStudySurvey: '/api/v1/user_study_survey/',
  };

  constructor() {}

  /**
   * Get a survey by user id
   */
  public getMilestoneSurveyById(id: number): Observable<IStudySurvey> {
    return this.apiService.getSingle(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.surveyMilestone,
      id
    );
  }

  /**
   * Get a survey by survey id
   */
  public getSurveyById(id: number): Observable<IPro> {
    return this.apiService.getSingle(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.survey,
      id
    );
  }

  /**
   * Get user surveys by user id and optional study id
   */
  public getUserSurveys(id: number): Observable<Array<IStudySurvey>> {
    const url = `${this.endpoints.userSurvey}/${id}`;
    return this.apiService.getAll(this.apiConfig.surveyServiceBaseUrl, url);
  }

  /**
   * Get view survey by study survey id
   */
  public getViewSurvey(id: number): Observable<IUserSurvey> {
    return this.apiService.getAll(
      this.apiConfig.surveyServiceBaseUrl,
      `${this.endpoints.viewSurvey}/?userstudysurveyid=${id}`
    );
  }

  /**
   * Get a pro linked with study or unlinked PROs, Draft PROs,or Reviewed Pros
   */
  public getAllPro(query: string): Observable<Array<IPro>> {
    return this.apiService.getAll(
      this.apiConfig.surveyServiceBaseUrl,
      `${this.endpoints.survey}/?${query}`
    );
  }

  /**
   * Post survey answers
   */
  public submitSurvey(survey: ISurveySubmitRequest): Observable<any> {
    return this.apiService.add(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.answerSurvey,
      survey
    );
  }

  /**
   * Get user study survey details
   */
  public getUserStudySurvey(filter?: string): Observable<Array<IStudySurvey>> {
    let url = this.endpoints.userStudySurvey;
    if (filter) {
      url += `${filter}`;
    }
    return this.apiService.getAll(this.apiConfig.surveyServiceBaseUrl, url);
  }

  /**
   * Update the PRO
   */
  public updateSurvey(survey: IPro): Observable<IPro> {
    return this.apiService.update(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.survey,
      survey
    );
  }

  /**
   * Create a new PRO
   */
  public createSurvey(survey: IPro): Observable<IPro> {
    return this.apiService.add(this.apiConfig.surveyServiceBaseUrl, this.endpoints.survey, survey);
  }

  /**
   * Delete the PRO
   */
  public deleteSurvey(proId: number): Observable<any> {
    return this.apiService.alternativeDelete(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.survey,
      proId
    );
  }
}
