<nb-alert class="mb-5" *ngIf="error && !submitted" outline="danger" role="alert">
  <ul class="alert-message-list">
    <li class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>
<nb-alert class="mb-5" *ngIf="validConfirmation" outline="success">{{ success }}</nb-alert>

<p *ngIf="!validConfirmation" class="sub-title">
  {{ 'confirmationCodeInstruction' | translate }}
</p>

<form [formGroup]="confirmForm" (ngSubmit)="onConfirmEmail()" novalidate>
  <!-- Email -->
  <div class="mb-4">
    <pes-form-input
      [control]="confirmForm.controls.username"
      [size]="'large'"
      label="{{ 'user_email' | translate }}"
      [readonly]="true"
    >
    </pes-form-input>
  </div>

  <!-- Confirmation Code -->
  <div class="mb-4">
    <pes-form-input
      [control]="confirmForm.controls.confirmation_code"
      [size]="'large'"
      label="{{ 'confirmationCode' | translate }}"
      [errors]="{ required: translate('formError_required') }"
      [showErrors]="submitted"
    >
    </pes-form-input>
  </div>

  <!-- Password -->
  <div class="mb-4">
    <pes-form-input
      [control]="confirmForm.controls.password"
      [size]="'large'"
      [type]="'password'"
      label="{{ 'password' | translate }}"
      [errors]="{
        required: translate('formError_required'),
        pattern: translate('passwordPatternError'),
        minlength: translate('passwordLengthError', { minLength: 8, maxLength: 50 }),
        maxLength: translate('passwordLengthError', { minLength: 8, maxLength: 50 })
      }"
      [showErrors]="submitted"
    >
    </pes-form-input>
  </div>

  <!-- Confirm Password -->
  <div class="mb-4">
    <pes-form-input
      [control]="confirmForm.controls.confirmPassword"
      [size]="'large'"
      [type]="'password'"
      label="{{ 'confirmPassword' | translate }}"
    >
    </pes-form-input>
    <div
      *ngIf="submitted && confirmForm.errors?.['mustMatch']"
      class="invalid-feedback d-flex"
      id="confirmPassword-error"
    >
      {{ 'passwordMismatchError' | translate }}
    </div>
  </div>

  <button
    nbButton
    fullWidth
    status="primary"
    size="large"
    class="mb-3"
    [class.btn-pulse]="submitted"
  >
    {{ 'changePassword' | translate }}
  </button>
</form>

<section class="another-action mb-3" aria-label="Verification" *ngIf="validConfirmation">
  {{ 'passwordChanged' | translate }}
  <a class="text-link" routerLink="/auth/login">{{ 'login' | translate }}</a>
</section>
