import { ISurveyResponse } from './survey-response.interface';

/**
 * API Request for updating User Questionnaire response
 */
export interface IUserQuestionnaireRequest {
  id?: number;
  created_by_id?: number;
  questionnaire_id: number;
  response: ISurveyResponse;
  updated_by_id: number;
  user_id: number;
  user_questionnaire_id?: number;
  user_study_milestone_id?: number;
  version?: number;
}

export type IUserQuestionnaireResponse = IUserQuestionnaireRequest;
