/**
 * Configuration state data passed in through the Router. Required to initialize
 * the Survey page for PRO completion
 */
export interface PesProConfig {
  userId: number;
  userPreferredLanguage: string;
  labels: {
    pageTitle: string;
    backButtonLabel: string;
  };
  backRoute: string;
  studySurveyMilestoneId: number;
  userStudyMilestoneId: number;
  showLanguages: boolean;
  initialLanguage?: string;
  studySiteId?: number;
}
