<div class="container-fluid">
  <div class="row">
    <div class="col-4 text-start">
      <ng-content select="[start]"></ng-content>
    </div>
    <div class="col-4 text-center">
      <ng-content></ng-content>
    </div>
    <div class="col-4 text-end">
      <ng-content select="[end]"></ng-content>
    </div>
  </div>
</div>
