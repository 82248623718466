import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export interface IFileHandle {
  file: File;
  value?: number;
}

@Directive({
  selector: '[pesDrag]',
})
export class PesDragDropDirective {
  @Output() public files: EventEmitter<IFileHandle[]> = new EventEmitter();

  constructor() {}

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    const files: IFileHandle[] = [];
    if (evt.dataTransfer?.files) {
      Array.from(evt.dataTransfer?.files).forEach((file: File) => {
        files.push({ file });
      });
      if (files.length > 0) {
        this.files.emit(files);
      }
    }
  }
}
