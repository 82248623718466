<pes-page-container title="{{ 'userProfile' | translate }}" alignment="left">
  <ng-container pesPageBody>
    <div class="row">
      <nb-card>
        <nb-card-header class="fw-normal border-bottom mb-3">
          <div class="container">
            <div class="row d-flex justify-content-between">
              <div class="col text-start">{{ userName }}</div>
              <div class="col text-end user-email">{{ email }}</div>
            </div>
          </div>
        </nb-card-header>
        <nb-card-body>
          <form [formGroup]="form">
            <div class="row">
              <!-- Title -->
              <div class="col-md-6 col-lg-4 pb-3" *ngIf="!isPatient">
                <pes-form-select [control]="form.controls.title" label="{{ 'title' | translate }}"
                  [options]="userTitles"></pes-form-select>
              </div>

              <!-- First Name -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input [control]="form.controls.first_name" label="{{ 'user_firstName' | translate }}"
                  [errors]="{
                    required: translate('formError_required'),
                    pattern: translate('formError_invalidCharacters')
                  }" [showErrors]="submitted">
                </pes-form-input>
              </div>

              <!-- Middle Name -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input [control]="form.controls.middle_name" label="{{ 'middleName' | translate }}">
                </pes-form-input>
              </div>

              <!-- Last Name* -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input [control]="form.controls.last_name" label="{{ 'user_lastName' | translate }}" [errors]="{
                    required: translate('formError_required'),
                    pattern: translate('formError_invalidCharacters')
                  }" [showErrors]="submitted">
                </pes-form-input>
              </div>

              <!-- Phone Number -->
              <div class="col-md-6 col-lg-4 pb-3">
                <div class="form-group">
                  <label for="inputPhone" class="form-label">{{ 'phoneNo' | translate }}</label>
                  <div class="w-100 d-inline-flex dial-code"
                    [ngClass]="{ 'is-invalid': submitted && form.controls.phone.errors }">
                    <nb-select fullWidth class="border-0 country-code" formControlName="country_code">
                      <nb-option class="code-opt" *ngFor="let code of DialCode" title="{{ code.name }}"
                        value="{{ code.dial_code }}">
                        {{ code.dial_code }} ({{ code.code }})
                      </nb-option>
                    </nb-select>
                    <input type="text" nbInput fullWidth id="inputPhone" #phone formControlName="phone"
                      class="phone form-control border-0 w-75"
                      [ngClass]="{ 'is-invalid': submitted && form.controls.phone.errors }" />
                  </div>
                  <div *ngIf="submitted && form.controls.phone.invalid" class="input-error caption status-danger">
                    <div>{{ 'user_phoneError' | translate }}</div>
                  </div>
                </div>
              </div>

              <!-- Speciality -->
              <div class="col-md-6 col-lg-4 pb-3" *ngIf="!isPatient">
                <pes-form-select [control]="form.controls.specialty" label="{{ 'user_specialty' | translate }}"
                  [options]="specialties"></pes-form-select>
              </div>

              <!-- Email* -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input [control]="form.controls.email" label="{{ 'user_email' | translate }}"
                  [readonly]="true">
                </pes-form-input>
              </div>

              <!-- Gender -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-select [control]="form.controls.sex" label="{{ 'user_gender' | translate }}"
                  [options]="genders"></pes-form-select>
              </div>

              <!-- DOB -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-date [control]="form.controls.dob" label="{{ 'user_dob' | translate }}" format="yyyy-MM-dd"
                  [readonly]="true" placeholder="YYYY-MM-DD" [errors]="{
                    required: translate('formError_required'),
                    invalidDate: 'Please input date in YYYY-MM-DD format'
                  }" [showErrors]="submitted"></pes-form-date>
              </div>

              <!-- Address line 1 -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input label="{{ 'user_addressLine1' | translate }}" [control]="form.controls.address1">
                </pes-form-input>
              </div>

              <!-- Address line 2 -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input label="{{ 'user_addressLine2' | translate }}" [control]="form.controls.address2">
                </pes-form-input>
              </div>

              <!-- City -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input [control]="form.controls.city" label="{{ 'user_city' | translate }}"></pes-form-input>
              </div>

              <!-- State -->
              <div class="col-md-6 col-lg-4 pb-3">
                <ng-container *ngIf="
                    form.value.country === 'United States' || form.value.country === '';
                    then usStates;
                    else nonUsState
                  "></ng-container>

                <ng-template #usStates>
                  <pes-form-select [control]="form.controls.state" label="{{ 'user_state' | translate }}"
                    [options]="stateAbbreviations"></pes-form-select>
                </ng-template>
                <ng-template #nonUsState>
                  <pes-form-input [control]="form.controls.state" label="{{ 'user_state' | translate }}">
                  </pes-form-input>
                </ng-template>
              </div>

              <!-- Zip -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-input label="{{ 'user_zip' | translate }}" [control]="form.controls.postal_code">
                </pes-form-input>
              </div>

              <!-- Country -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-select [control]="form.controls.country" label="{{ 'user_country' | translate }}"
                  [options]="countries"></pes-form-select>
              </div>
            </div>

            <hr />
            <h5>{{ 'user_otherInfo' | translate }}</h5>
            <hr />

            <div class="row">
              <!-- Income Level -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-select [control]="form.controls.income_range" label="{{ 'user_incomeLevel' | translate }}"
                  [options]="incomeRanges"></pes-form-select>
              </div>

              <!-- Education -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-select [control]="form.controls.education_level" label="{{ 'user_education' | translate }}"
                  [options]="educationLevels"></pes-form-select>
              </div>

              <!-- Race -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-select [control]="form.controls.race" label="{{ 'user_race' | translate }}" [options]="races">
                </pes-form-select>
              </div>

              <!-- Preferred Language -->
              <div class="col-md-6 col-lg-4 pb-3">
                <pes-form-select [control]="form.controls.preferred_language_code"
                  label="{{ 'preferredLang' | translate }}" [options]="languages"></pes-form-select>
              </div>
            </div>

            <ng-container *ngIf="isPatient">
              <hr />
              <h5>{{ 'paymentAccountManagement' | translate }}</h5>
              <hr />

              <div class="row margin-bottom">
                <p *ngIf="noLinkedAccount">*{{ 'noPaymentAccountsLinked' | translate }}</p>
                <div class="row" *ngFor="let account of linkedAccounts">
                  <div class="col-md-12 col-lg-4 pb-3">
                    <div class="form-group link-account">
                      <label for="inputPhone" class="form-label">{{
                        account.processor | titlecase
                        }}</label>
                      <input type="text" class="form-control" nbInput fullWidth label="account.processor"
                        [value]="account.account" [readonly]="true" />
                      <ng-container>
                        <span class="icons" (click)="!account.primary && onMakePrimaryAccount(account.id)">
                          <i nbTooltip="{{ getAccountTooltipLabel(account.primary) }}"
                            [ngClass]="{ primary: account.primary }" nbTooltipStatus="hint" nbTooltipPlacement="top"
                            class="fas fa-solid fa-star"></i>
                        </span>
                        <span class="icons" (click)="onRemovePrimaryAccount(account.id, account.primary)">
                          <i nbTooltip="{{ 'tooltip-delete' | translate }}" nbTooltipStatus="hint"
                            nbTooltipPlacement="top" class="fas fa-trash"></i></span>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row margin-bottom">
                <div class="col-md-12 col-lg-4 pb-3">
                  <button type="button" nbButton outline status="basic" (click)="onLinkAccount()">
                    <nb-icon icon="plus-outline"></nb-icon>{{ 'linkNewAccount' | translate }}
                  </button>
                </div>
              </div>
            </ng-container>

            <hr />

            <div class="row my-4">
              <div class="col-12 d-flex justify-content-center">
                <!-- <button nbButton status="primary" size="medium" [class.btn-pulse]="submitted">
                  {{ 'update' | translate }}
                </button> -->

                <button *ngIf="isPatient" nbButton outline type="button" status="danger" (click)="onDeleteAccount()">
                  <nb-icon icon="person"></nb-icon> {{ 'deleteAccount' | translate }}
                </button>
              </div>
            </div>
          </form>
        </nb-card-body>
      </nb-card>
    </div>
  </ng-container>
</pes-page-container>