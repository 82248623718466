import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { TrweAuthGuard } from '@targetrwe/trwe-auth';
/**
 * /study-manager
 *     /study-list
 *     /
 * /engagement
 *    /studies                 - study list for participant        ('documentlist')
 *    /surveys                 - patient reported outcomes list
 *    /consent                 - complete a consent or reconsent
 *    /questionnaire           - complete a questionnaire
 *    /survey                  - complete a survey
 *    /payment-summary         - payment summary list page for patient
 * /user
 *    /profile - edit the logged in user's profile
 *    /messages - view/manage messages fro the user
 * /mobile
 *  /generate-pdf - generating the pdf for mobile
 *  /pdf-result - to the final ststus of the generated pdf
 */

export const routes: Routes = [
  {
    canActivate: [TrweAuthGuard],
    path: '',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('@targetrwe/trwe-auth').then((m) => m.TrweAuthModule),
  },
  {
    loadChildren: () => import('./mobile/mobile.module').then((m) => m.MobileModule),
    path: 'mobile',
  },
  {
    path: 'external-emails',
    loadChildren: () =>
      import('./external-emails/external-emails.module').then((m) => m.ExternalEmailsModule),
  },
  { path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
  enableTracing: false, // <-- debugging purposes only
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
