import Slider from 'bootstrap-slider';
import { svgColors } from '../../constants';
import { defaultSVG } from './default-svg';

export function svginit(survey: any): void {
  let updateDefaultProperties: any[] = [];
  let multiSelectStack: any[] = [];
  const widget = {
    // Use this function to create a new class or add new properties or remove unneeded properties from your widget
    // activatedBy tells how your widget has been activated by: property, type or customType
    // property - it means that it will activated if a property of the existing question type is set to particular value,
    // for example inputType = "date"
    // type - you are changing the behaviour of entire question type.
    activatedByChanged() {
      survey.JsonObject.metaData.addClass('svgmap', [], null, 'empty');
      survey.JsonObject.metaData.addProperty('svgmap', {
        category: 'general',
        default: false,
        name: 'multiSelect:boolean',
        visibleIndex: 6,
      });
      survey.JsonObject.metaData.addProperties('svgmap', [
        {
          category: 'general',
          default: defaultSVG + ' ',
          displayName: 'SVG Content',
          name: 'svg',
          type: 'html',
          visibleIndex: 1,
        },
        {
          category: 'general',
          default: updateDefaultProperties,
          name: 'paths:itemvalues',
          visibleIndex: 2,
        },
        {
          category: 'Pain Indicator',
          categoryIndex: 1,
          default: 1,
          name: 'step:number',
        },
        {
          category: 'Pain Indicator',
          default: 0,
          name: 'rangeMin:number',
        },
        {
          category: 'Pain Indicator',
          default: 10,
          name: 'rangeMax:number',
        },
        {
          category: 'Pain Indicator',
          choices: ['horizontal', 'vertical'],
          default: 'horizontal',
          name: 'orientation',
        },
      ]);

      // DON'T NEED THIS 'config' PROPERTY --- DELETE ME
      survey.JsonObject.metaData.addProperty('svgmap', {
        category: 'general',
        default: null,
        name: 'config',
      });
    },

    // The main function, rendering and two-way binding
    afterRender(question: any, el: any) {
      el.style.paddingTop = '20px';
      el.style.paddingBottom = '17px';
      el.style.paddingLeft = '10px';
      const path = 'path_' + question.name;
      const currentKey = 'currentKey_' + question.name;
      const object: any = { [path]: {}, [currentKey]: '' };

      let maxRange = 1;
      if (question.rangeMax) {
        maxRange = question.rangeMax;
      }

      // CONFIG VALUES FOR BOOTSTRAP-SLIDER
      const config = question.config || {};
      if (config.min === undefined) {
        config.min = 0;
      }

      if (config.max === undefined) {
        config.max = question.rangeMax;
      }

      if (config.step === undefined) {
        config.step = question.step;
      }

      if (config.enabled === undefined) {
        config.enabled = !question.isReadOnly;
      }

      if (config.value === undefined) {
        config.value = question.rangeMin;
      }

      updateDefaultProperties = question.paths.map((x: any) => {
        return {
          text: x.text,
          value: x.value,
        };
      });

      const questionId = `#questionId_${question.name}`;
      const pathContainer = `#pathContainer_${question.name}`;

      // SLIDES - IS ARRAY OF ALL THE TEXT FIELDS TO BE REPLACED BY BOOTSTRAP-SLIDER
      const slides = document.querySelectorAll(`${pathContainer} input.slidertext`);
      for (let i = 0; i < slides.length; i++) {
        const slider = new Slider((slides as any)[i], config);
        slider.on('change', function (valueObj: any) {
          const key = object[currentKey];
          object[path][key] = valueObj.newValue;
          question.value = Object.keys(object[path]).map((d) => {
            return {
              text: d,
              value: object[path][d],
            };
          });
          const tempPath = updateDefaultProperties.find((x: any) => x.text === object[currentKey]);
          if (tempPath) {
            tempPath.value = valueObj.newValue;
          }
          // let value = 0;
          // value = valueObj.newValue;
          $(`${questionId} svg #${object[currentKey]}.selected`).css(
            'fill',
            svgColors[(object[path][key] / maxRange) * 10]
          );
        });

        question.readOnlyChangedCallback = function () {
          if (question.isReadOnly) {
            slider.disable();
          } else {
            slider.enable();
          }
        };

        question.bootstrapSlider = slider;
      }

      question.paths.forEach((x: any) => {
        if (x.value > 0) {
          $(`${questionId} #${x.text}`).toggleClass('selected');
          $(`${questionId} svg path.selected`).css('fill', svgColors[(x.value / maxRange) * 10]);
          object[path][x.text] = x.value;
        }
      });

      if (!question.isReadOnly) {
        $(`${questionId} svg path`).on('click', function (evt) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _self = this;
          $(`${pathContainer} .` + evt.target.id + '_wrap')
            .find('input')
            .trigger('click');
          if (isNaN(object[path][evt.target.id])) {
            object[currentKey] = evt.target.id;
            if (!question.multiSelect) {
              singleSelect(
                _self,
                evt,
                question,
                pathContainer,
                object,
                currentKey,
                path,
                maxRange,
                questionId
              );
            } else {
              multiSelectSVG(
                _self,
                evt.target.id,
                question,
                pathContainer,
                object,
                currentKey,
                path,
                maxRange
              );
            }
          } else {
            $(this).css('fill', '');
            $(this).toggleClass('selected');
            $(`${pathContainer} .path-wrap`)
              .not(`${pathContainer} .` + evt.target.id + '_wrap')
              .hide();
            $(`${pathContainer} .` + evt.target.id + '_wrap').hide();
            delete object[currentKey];
            delete object[path][evt.target.id];

            if (question.multiSelect) {
              const index = multiSelectStack.findIndex((x) => x === evt.target.id);
              if (index > -1) {
                multiSelectStack.splice(index, 1);
              }
              if (multiSelectStack.length > 0) {
                const key = multiSelectStack[multiSelectStack.length - 1];
                object[currentKey] = key;
                multiSelectSVG(
                  key,
                  key,
                  question,
                  pathContainer,
                  object,
                  currentKey,
                  path,
                  maxRange
                );
              }
            }
          }
        });
      }
    },

    // THE input[type=text].slidertext IN THE HTMLTEMPLATE BELOW, IS WHAT GETS TRANSFORMED TO BOOTSTRAP-SLIDER, STARTING AT line: 279
    htmlTemplate: `<div data-bind="html:question.svg,attr: {id:'questionId_'+question.name, class:'svg'}"></div>
    <div data-bind="attr: {id:'pathContainer_'+question.name}">
    <!-- ko foreach: { data: question.paths, as: 'item', afterRender: question.koAfterRender}  -->
    <ul  data-bind="if: question.isReadOnly"><li><span style="font-weight: bold;text-transform: capitalize;" data-bind="text: item.text"></span> - <span data-bind="text: item.value"></span></li></ul>
    <!-- ko if: !question.isReadOnly -->
    <div style="display: none" data-bind="attr:{class:'path-wrap '+item.text+'_wrap'}">
    <label style="display: block"><span data-bind="text: item.text"></span>
    <input style="display: none" data-bind="attr: {type: question.multiSelect ? 'checkbox' : 'radio', name: question.name + '_' + question.id, 'data-path': item.text, value: item.text, id: question.inputId + '_' + item.text, 'aria-required': question.ariaRequired, 'aria-label': question.ariaLabel, 'aria-invalid': question.ariaInvalid, 'aria-describedby': question.ariaDescribedBy}, checked: question.koValue, enable: !question.isInputReadOnly && item.isEnabled, css: question.koCss().itemControl"
    /></label>
    <input data-bind="attr: {tooltip: 'always', type: 'text', class:'slidertext', name: question.name + '_' + question.id + '_' + item.text + '_pain', id: item.text + '_pain', 'aria-required': question.ariaRequired, 'aria-label': question.ariaLabel, 'aria-invalid': question.ariaInvalid, 'aria-describedby': question.ariaDescribedBy, 'data-slider-id': item.text + '_painSlider','data-slider-min': question.rangeMin,'data-slider-max': question.rangeMax,'data-slider-step': question.step,'data-slider-value': item.value || question.rangeMin}, checked: question.koValue, enable: !question.isInputReadOnly && item.isEnabled, css: question.koCss().itemControl"
    />
    </div>
    <!-- /ko -->
    <!-- /ko -->
    </div>
    <style>
    .slider .tooltip.bs-tooltip-top {
      transition: opacity 250ms ease-in-out;
      position: absolute;
    }
      .slider .tooltip.bs-tooltip-top.show {
        opacity: 1;
      }
      .svg svg {
        max-width: 400px;
        width: 100%;
        height: auto;
      }
      .svg svg path {
        cursor: pointer;
      }
      .svg svg path:hover {
        fill: #78C73D;
      }
      .svg svg path.selected {
        fill: #78C73D;
      }

      .svg svg path.selected.glow {

        fill: #78C73D;
      }
      .path-wrap + path-wrap {
        margin-top: 1rem;
      }
    </style>`,

    // the name of the icon on the toolbox. We will leave it empty to use the standard one
    iconName: '',

    // SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
    isFit: (question: any) => {
      // we return true if the type of question is svgmap
      return question.getType() === 'svgmap';
      // the following code will activate the widget for a text question with inputType equals to date
      // return question.getType() === 'text' && question.inputType === "date";
    },

    // the widget name. It should be unique and written in lowcase.
    name: 'svgmap',

    // the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
    title: 'SVG Image Map',

    // If the widgets depends on third-party library(s) then here you may check if this library(s) is loaded
    widgetIsLoaded: (e: any) => {
      // return typeof $ == "function" && !!$.fn.select2; //return true if jQuery and select2 widget are loaded on the page
      return true; // we do not require anything so we just return true.
    },

    // Use it to destroy the widget. It is typically needed by jQuery widgets
    willUnmount: (question: any, el: any) => {
      question.bootstrapSlider && question.bootstrapSlider.destroy();
      question.bootstrapSlider = null;
      question.readOnlyChangedCallback = null;
      // currentKey = null;
      updateDefaultProperties = [];
      multiSelectStack = [];
      // path = {};
    },
  };

  function singleSelect(
    self: any,
    evt: any,
    question: any,
    pathContainer: any,
    object: any,
    currentKey: any,
    path: any,
    maxRange: any,
    questionId: any
  ) {
    const parts = $(`${questionId} svg path`).not(self);
    parts.removeClass('selected');
    parts.css('fill', '');
    // $('#svg svg path').not(this).removeClass('selected');
    $(`${pathContainer} .path-wrap`)
      .not(`${pathContainer} .` + evt.target.id + '_wrap')
      .hide();
    object[currentKey] = evt.target.id;
    const elem: any = $(`${pathContainer} .` + evt.target.id + '_wrap');
    for (const elm of elem) {
      elm.value = 0;
    }
    Object.keys(object[path]).map((k) => {
      delete object[path][k];
    });
    $(self).toggleClass('selected');
    const tempPath: any = updateDefaultProperties.find((x: any) => x.text === object[currentKey]);
    if (tempPath) {
      $(`${questionId} svg path.selected`).css('fill', svgColors[(tempPath.value / maxRange) * 10]);
      object[path][evt.target.id] = tempPath.value;
      question.value = [
        {
          text: evt.target.id,
          value: tempPath.value,
        },
      ];
    } else {
      $(`${questionId} svg path.selected`).css('fill', '');
      object[path][evt.target.id] = 0;
    }
    $(`${pathContainer} .` + evt.target.id + '_wrap').toggle();
  }

  function multiSelectSVG(
    _self: any,
    key: any,
    question: any,
    pathContainer: any,
    object: any,
    currentKey: any,
    path: any,
    maxRange: any
  ) {
    if (!$(_self).hasClass('selected')) {
      $(_self).toggleClass('selected');
    }
    const tempPath: any = updateDefaultProperties.find((x: any) => x.text === object[currentKey]);
    if (tempPath) {
      $(_self).css('fill', svgColors[(tempPath.value / maxRange) * 10]);
      object[path][key] = tempPath.value;
      question.value = Object.keys(object[path]).map((d) => {
        return {
          text: d,
          value: object[path][d],
        };
      });
    } else {
      $(_self).css('fill', '');
      object[path][key] = 0;
    }
    if (multiSelectStack.findIndex((d) => d === key) === -1) {
      multiSelectStack.push(key);
    }
    $(`${pathContainer} .path-wrap`)
      .not(`${pathContainer} .` + key + '_wrap')
      .hide();
    $(`${pathContainer} .` + key + '_wrap').toggle();
  }

  // Register our widget in singleton custom widget collection
  survey.CustomWidgetCollection.Instance.addCustomWidget(widget, 'customtype');
}
