import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IRegionLanguage } from '../../interfaces/language-region.interface';
import { API_CONFIG, IApiConfig } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepLanguageService {
  private readonly endpoints = {
    language: '/api/v1/lang-region/',
  };

  constructor(
    private apiService: PepApiService,
    @Inject(API_CONFIG) private apiConfig: IApiConfig
  ) {}

  /**
   * Get all languages
   */
  public getLanguages(): Observable<IRegionLanguage[]> {
    return this.apiService.getAll(this.apiConfig.languageServiceUrl, this.endpoints.language);
  }
}
