import { ISurveyResponse } from './survey-response.interface';
import { SurveyStatusEnum } from '../types/survey-status.enum';

/**
 * API structure used to submit survey responses
 */
export interface ISurveySubmitRequest {
  user_id: number;
  study_survey_milestone_id: number;
  questions: ISurveyResponse;
  status: SurveyStatusEnum;
  language_region_code: string;
}

/**
 * API response object after submiting a survey request
 */
export type ISurveySubmitResponse = ISurveySubmitRequest;
