import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getDeepFromObject, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { LOGO_URLS, PepFormValidatorsService, pepTranslate } from '@targetrwe/pep-shared';

@Component({
  selector: 'trwe-reset-password',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
})
export class TrweResetPasswordComponent implements OnInit {
  public redirectDelay = 0;
  public strategy = '';
  public submitted = false;
  public error = '';
  public message = '';
  public userName = '';
  private session = '';
  public resetForm = this.createForm();
  public readonly logo: string = LOGO_URLS.engageLogoHorizontal;

  public readonly translate = pepTranslate();
  private readonly cd = inject(ChangeDetectorRef);
  private readonly nbAuthService = inject(NbAuthService);
  private readonly router = inject(Router);

  // configuration data provided by Router on navigation
  private readonly state = this.router.getCurrentNavigation()?.extras?.state;

  private createForm() {
    const fb = inject(NonNullableFormBuilder);
    const formValidators = inject(PepFormValidatorsService);

    return fb.group(
      {
        username: '',
        email: '',
        session: '',
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(50),
            Validators.pattern(formValidators.passwordPattern),
          ],
        ],
        confirmPassword: '',
      },
      { validators: formValidators.passwordMatchingValidation }
    );
  }

  constructor() {
    const options = inject(NB_AUTH_OPTIONS);
    this.redirectDelay = getDeepFromObject(options, 'forms.resetPassword.redirectDelay');
    this.strategy = getDeepFromObject(options, 'forms.resetPassword.strategy');
  }

  public ngOnInit(): void {
    if (this.state) {
      this.userName = (this.state['email'] || '').toLowerCase();
      this.session = this.state['session'];
    }
    this.resetForm.controls.username.setValue(this.userName);
    this.resetForm.controls.email.setValue(this.userName);
    this.resetForm.controls.session.setValue(this.session);
  }

  public onResetPass(): void {
    this.error = this.message = '';
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    } else {
      this.nbAuthService.resetPassword(this.strategy, this.resetForm.value).subscribe((result) => {
        this.submitted = false;
        if (result.isSuccess()) {
          this.message = this.translate('passwordResetSuccess');
        } else {
          this.error = result.getErrors()[0];
        }

        const redirect = result.getRedirect();

        if (redirect) {
          setTimeout(() => this.router.navigateByUrl(redirect), this.redirectDelay);
        }
        this.cd.detectChanges();
      });
    }
  }
}
