export interface IEnrollmentLog {
  created_at: string;
  created_by: string;
  enrollment_approved_by: string;
  enrollment_approved_comments: string;
  enrollment_approved_on: string;
  enrollment_denial_comments: string;
  enrollment_denied_by: string;
  enrollment_denied_on: string;
  pending_withdrawal_approved_by: string;
  pending_withdrawal_approved_comments: string;
  pending_withdrawal_approved_on: string;
  pending_withdrawal_denied_by: string;
  pending_withdrawal_denied_comments: string;
  pending_withdrawal_denied_on: string;
  reaffirmed_on: string;
  reconsent_approved_by: string;
  reconsent_approved_comments: string;
  reconsent_approved_on: string;
  reconsent_denied_by: string;
  reconsent_denied_comments: string;
  reconsent_denied_on: string;
  user_study_consent_staus: string;
  withdrawal_reason: string;
  withdrawn_consent_by: string;
  withdrawn_consent_on: string;
  end_of_study_comment: string;
  end_of_study_by: string;
  end_of_study_created_at: string;
}
