import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NbAuthIllegalTokenError,
  NbAuthResult,
  NbAuthStrategy,
  NbAuthStrategyClass,
} from '@nebular/auth';
import { Observable, catchError, map, of, throwError, take, switchMap } from 'rxjs';
import { TrweAuthStore } from '../auth-store.service';
import {
  CustomPasswordAuthStrategyOptions,
  defaultPasswordStrategyOptions,
} from './custom-auth-strategy-options';

/**
 * Must override NbPasswordAuthStrategy in order to include token
 */
@Injectable({ providedIn: 'root' })
export class CustomPasswordAuthStrategy extends NbAuthStrategy {
  protected defaultOptions: CustomPasswordAuthStrategyOptions = defaultPasswordStrategyOptions;

  private readonly authStore = inject(TrweAuthStore);
  protected readonly http = inject(HttpClient);
  private readonly route = inject(ActivatedRoute);

  constructor() {
    super();
  }

  public static setup(
    options: CustomPasswordAuthStrategyOptions
  ): [NbAuthStrategyClass, CustomPasswordAuthStrategyOptions] {
    return [CustomPasswordAuthStrategy, options];
  }

  public authenticate(data?: any): Observable<NbAuthResult> {
    const module = 'login';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);
    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map((res) => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse(data);
        }
        return res;
      }),
      map(
        (res) =>
          new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            this.getOption('messages.getter')(module, res, this.options),
            this.createToken(
              this.getOption('token.getter')(module, res, this.options),
              requireValidToken
            )
          )
      ),
      catchError((res) => this.handleResponseError(res, module))
    );
  }

  public register(data?: any): Observable<NbAuthResult> {
    const module = 'register';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);
    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map((res) => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse(data);
        }
        return res;
      }),
      map(
        (res) =>
          new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            this.getOption('messages.getter')(module, res, this.options),
            this.createToken(
              this.getOption('token.getter')('login', res, this.options),
              requireValidToken
            )
          )
      ),
      catchError((res) => this.handleResponseError(res, module))
    );
  }

  public requestPassword(data?: any): Observable<NbAuthResult> {
    const module = 'requestPass';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map((res) => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse();
        }
        return res;
      }),
      map(
        (res) =>
          new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            this.getOption('messages.getter')(module, res, this.options)
          )
      ),
      catchError((res) => this.handleResponseError(res, module))
    );
  }

  public resetPassword(data: any = {}): Observable<NbAuthResult> {
    const module = 'resetPass';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const tokenKey = this.getOption(`${module}.resetPasswordTokenKey`);
    data[tokenKey] = this.route.snapshot.queryParams[tokenKey];
    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map((res) => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse();
        }
        return res;
      }),
      map(
        (res) =>
          new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            this.getOption('messages.getter')(module, res, this.options)
          )
      ),
      catchError((res) => this.handleResponseError(res, module))
    );
  }

  public logout(): Observable<NbAuthResult> {
    throw new Error('Method not implemented.');
  }

  public refreshToken(data?: any): Observable<NbAuthResult> {
    const module = 'refreshToken';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);

    return this.authStore.loggedInUser$.pipe(
      take(1),
      switchMap((currentUser) => {
        if (!currentUser) {
          return throwError(() => new Error('No Current User cached.  Require Log in'));
        }

        const tokenModel = {
          username: currentUser.email,
          refresh_token: currentUser.refresh_token,
          refresh: 'true',
        };

        return this.http.request(method, url, { body: tokenModel, observe: 'response' }).pipe(
          map((res) => {
            if (this.getOption(`${module}.alwaysFail`)) {
              throw this.createFailResponse(data);
            }
            return res;
          }),
          map(
            (res) =>
              new NbAuthResult(
                true,
                res,
                this.getOption(`${module}.redirect.success`),
                [],
                this.getOption('messages.getter')(module, res, this.options),
                this.createToken(
                  this.getOption('token.getter')(module, res, this.options),
                  requireValidToken
                )
              )
          ),
          catchError((res) => this.handleResponseError(res, module))
        );
      })
    );
  }

  public token(data?: any): Observable<NbAuthResult> {
    const module = 'validateExternalUser';
    const method = this.getOption(`${module}.method`);
    const url = this.getActionEndpoint(module);
    const requireValidToken = this.getOption(`${module}.requireValidToken`);

    return this.http.request(method, url, { body: data, observe: 'response' }).pipe(
      map((res) => {
        if (this.getOption(`${module}.alwaysFail`)) {
          throw this.createFailResponse(data);
        }
        return res;
      }),
      map(
        (res) =>
          new NbAuthResult(
            true,
            res,
            this.getOption(`${module}.redirect.success`),
            [],
            this.getOption('messages.getter')(module, res, this.options),
            this.createToken(
              this.getOption('token.getter')('login', res, this.options),
              requireValidToken
            )
          )
      ),
      catchError((res) => this.handleResponseError(res, module))
    );
  }

  protected handleResponseError(res: any, module: string): Observable<NbAuthResult> {
    let errors = [];
    if (res instanceof HttpErrorResponse) {
      errors = this.getOption('errors.getter')(module, res, this.options);
    } else if (res instanceof NbAuthIllegalTokenError) {
      errors.push(res.message);
    } else {
      errors.push('Something went wrong.');
    }
    return of(new NbAuthResult(false, res, this.getOption(`${module}.redirect.failure`), errors));
  }
}
