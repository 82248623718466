import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NbAuthModule } from '@nebular/auth';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { enUS } from 'date-fns/locale';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSelectModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { PepSharedModule, PesSpinnerComponent } from '@targetrwe/pep-shared';
import { TrweConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { TrweLoginComponent } from './components/login/login.component';
import { TrweLogoutComponent } from './components/logout/logout.component';
import { TrweRegisterComponent } from './components/register/register.component';
import { TrweRequestPasswordComponent } from './components/request-password/request-password.component';
import { TrweResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TrweSocialLoginComponent } from './components/social-login/social-login.component';
import { TrweVerificationComponent } from './components/verification/verification.component';
import { CustomPasswordAuthStrategy } from './services/auth-strategies/custom-password-auth-strategy';
import { TrweAuthComponent } from './trwe-auth.component';
import { TrweAuthRoutingModule } from './trwe-auth.routing.module';
import { TrweHelpComponent } from './components/help/help.component';

const components = [
  TrweLoginComponent,
  TrweSocialLoginComponent,
  TrweRegisterComponent,
  TrweVerificationComponent,
  TrweLogoutComponent,
  TrweRequestPasswordComponent,
  TrweConfirmEmailComponent,
  TrweResetPasswordComponent,
  TrweAuthComponent,
  TrweHelpComponent,
];

const nebular = [
  NbButtonModule,
  NbAlertModule,
  NbIconModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbInputModule,
  NbDatepickerModule.forRoot(),
  NbDateFnsDateModule.forRoot({
    parseOptions: { locale: enUS },
    formatOptions: { locale: enUS },
  }),
  NbSelectModule,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    PepSharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NbAuthModule,
    PesSpinnerComponent,
    TranslateModule.forChild({
      isolate: false,
    }),
    ...nebular,
    TrweAuthRoutingModule,
  ],
})
export class TrweAuthModule {
  static forRoot(): ModuleWithProviders<TrweAuthModule> {
    return {
      ngModule: TrweAuthModule,
      providers: [CustomPasswordAuthStrategy],
    };
  }
}
