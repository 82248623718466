import { Component, Input } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { PesFormControlBaseComponent } from '../form-control-base/form-control-base.component';
import { PesFormSelectOption } from './form-select.types';

/**
 * Styled Form Select input
 */
@Component({
  selector: 'pes-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
export class PesFormSelectComponent extends PesFormControlBaseComponent {
  public overlayOffset = 0;
  /**
   * Text displayed above the input
   */
  @Input() options: PesFormSelectOption[] = [];

  /**
   * The Nebular input size
   */
  @Input() size: NbComponentSize = 'medium';

  /**
   * Value if default selected is needed
   */
  @Input() defaultSelectedValue = '';

  public get postIcon(): string {
    return (
      this.options?.find((op) => !!op.postIcon && op.value === +this.control.value)?.postIcon || ''
    );
  }
}
