import { HttpErrorResponse } from '@angular/common/http';
import { NbAuthJWTToken } from '@nebular/auth';
import { CustomPasswordAuthStrategy } from '@targetrwe/trwe-auth';
import { environment } from '../environments/environment';

/**
 * Definitions for using external SSO logins
 */
const socialLinks = [
  {
    icon: 'logo-sm',
    pack: 'target',
    target: '_self',
    url:
      environment.socialLogin.siteUrl +
      '?' +
      'identity_provider=' +
      environment.socialLogin.targetRWEIdentity +
      '&' +
      'redirect_uri=' +
      environment.socialLogin.redirectUri +
      '&' +
      'response_type=' +
      environment.socialLogin.responseType +
      '&' +
      'client_id=' +
      environment.socialLogin.clientId +
      '&' +
      'scope=' +
      environment.socialLogin.scope,
  },
  // TODO - We do not currently support logging in through Google
  // {
  //   icon: 'google',
  //   pack: 'eva',
  //   target: '_self',
  //   url:
  //     environment.socialLogin.siteUrl +
  //     '?' +
  //     'identity_provider=' +
  //     environment.socialLogin.googleIdentity +
  //     '&' +
  //     'redirect_uri=' +
  //     environment.socialLogin.redirectUri +
  //     '&' +
  //     'response_type=' +
  //     environment.socialLogin.responseType +
  //     '&' +
  //     'client_id=' +
  //     environment.socialLogin.clientId +
  //     '&' +
  //     'scope=' +
  //     environment.socialLogin.scope,
  // },
];

/**
 * Configure strategies to define
 */
const customPasswordAuthStrategyConfig = {
  baseEndpoint: environment.authApiUrl,
  name: 'pepAuthStrategy',
  token: {
    class: NbAuthJWTToken,
    key: 'user.token',
  },
  login: {
    endpoint: '/user/login',
    method: 'post',
    redirect: {
      failure: null,
      success: 'engagement/studies',
    },
    requireValidToken: false,
  },
  logout: {
    redirect: {
      failure: null,
      success: 'auth/login',
    },
  },
  refreshToken: {
    endpoint: '/user/login',
    method: 'post',
    requireValidToken: true,
    redirect: {
      success: null,
      failure: 'auth/login',
    },
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['Your token has been successfully refreshed.'],
  },
  register: {
    endpoint: '/user',
    method: 'post',
    redirect: {
      failure: null,
      success: 'auth/verification',
    },
    requireValidToken: false,
  },
  resetPass: {
    endpoint: '/password/reset',
    method: 'post',
    redirect: {
      failure: null,
      success: 'engagement/studies',
    },
    requireValidToken: false,
  },
  requestPass: {
    endpoint: '/password/forgot',
    method: 'post',
    redirect: {
      failure: null,
      success: 'auth/passwordconfirmemail',
    },
    requireValidToken: false,
  },
  validateExternalUser: {
    endpoint: '/token',
    method: 'post',
    redirect: {
      failure: null,
      success: 'engagement/studies',
    },
    requireValidToken: false,
  },
  errors: {
    // This overrides the getter of errors functions
    // res: It is the HttpResponse coming from backend
    getter: (module: string, res: HttpErrorResponse) => {
      if (res.error instanceof ProgressEvent) {
        return res.statusText;
      } else {
        // eslint-disable-next-line no-underscore-dangle
        return res.error.msg || res.error.error || res.error.error.Message_;
      }
    },
  },
};

/**
 * Configuration for IEP NbAuthModule with support for SSO and login from Portal
 */
const authModuleConfig = {
  strategies: [
    // basic nbAuth strategy
    CustomPasswordAuthStrategy.setup(customPasswordAuthStrategyConfig),
  ],
  forms: {
    login: {
      redirectDelay: 1000,
      strategy: 'pepAuthStrategy',
      rememberMe: false,
      socialLinks: socialLinks,
    },
    register: {
      strategy: 'pepAuthStrategy',
      socialLinks: null,
    },
    resetPassword: {
      strategy: 'pepAuthStrategy',
    },
    requestPassword: {
      strategy: 'pepAuthStrategy',
    },
    refreshToken: {
      strategy: 'pepAuthStrategy',
    },
    validateExternalUser: {
      redirectDelay: 500,
      showMessages: {
        error: true,
        success: true,
      },
      strategy: 'pepAuthStrategy',
    },
  },
};

export default authModuleConfig;
