import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import {
  IAdminDocument,
  IArtifact,
  IArtifactType,
  IMilestone,
  ISimpleMilestone,
  IStudySiteUserStudyMilestone,
} from '../../interfaces';
import { PepToastNotificationService } from '../helper-services/toast-notification.service';
import { API_CONFIG, IApiConfig } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepMilestoneService {
  private readonly endpoints = {
    userStudyMilestones: '/api/v2/user_study_milestone',
    userStudyMilestonesV1: '/api/v1/user_study_milestone',
    studyMilestones: '/api/v1/study_milestone',
    milestoneTypes: '/api/v1/milestone_type/',
    paperMilestoneUpload: '/api/v1/user_study_consent',
    paperReconsentUpload: '/api/v1/user_study_consent/{id}/paper-reconsent',
    completeMilestone: '/api/v1/user_study_milestone/{id}/complete',
    artifactTypes: '/api/v1/artifact_type/',
  };

  private readonly idReg = /{id}/;

  constructor(
    private apiService: PepApiService,
    private toastService: PepToastNotificationService,
    @Inject(API_CONFIG) private apiConfig: IApiConfig
  ) {}

  /**
   * Get all study site milestones
   */
  public getUserStudyMilestones(
    studySiteId: number,
    filter?: string
  ): Observable<Array<IStudySiteUserStudyMilestone>> {
    let query = `?studysiteid=${studySiteId}`;
    query += filter ? filter : '';

    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestones}/${query}`
    );
  }

  /**
   * Get all study site milestones
   */
  public getUserStudyMilestonesV1(
    studySiteId: number,
    filter?: string
  ): Observable<Array<IStudySiteUserStudyMilestone>> {
    let query = `?studysiteid=${studySiteId}`;
    query += filter ? filter : '';

    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestonesV1}/${query}`
    );
  }

  /**
   * Get user study milestone artifacts
   */
  public getMilestoneArtifacts(milestoneId: number): Observable<Array<IArtifact>> {
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestonesV1}/${milestoneId}/artifact/`
    );
  }

  /**
   * Get a milestone based on the milesone id
   */
  public getMilestone(milestoneId: number): Observable<IMilestone> {
    return this.apiService.getSingle(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.studyMilestones,
      milestoneId
    );
  }

  /**
   * Get Admin Documents
   */
  public getAdminDocuments(
    studyId: number,
    studySiteId: number
  ): Observable<Array<IAdminDocument>> {
    const query = `?studyid=${studyId}&studysiteid=${studySiteId}&ismilestone=true`;

    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studyMilestones}/${query}`
    );
  }

  /**
   * Get Study Milestones by study id
   * TODO: Both this and getAdminDocuments are both calling the same endpoint but returning different things
   */
  public getStudyMilestones(studyId: number): Observable<Array<IMilestone>> {
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studyMilestones}/?studyid=${studyId}`
    );
  }

  /**
   * Get upcoming user study milestones
   */
  public getUpcomingUserStudyMilestones(
    userId: number,
    studyId: number
  ): Observable<Array<IStudySiteUserStudyMilestone>> {
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestonesV1}/?userid=${userId}&studyid=${studyId}&isupcoming=true`
    );
  }

  /**
   * Get the artifact file
   */
  public downloadArtifacts(milestoneId: number, id: number) {
    return this.apiService.getFile(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestonesV1}/${milestoneId}/artifact/${id}`
    );
  }

  /**
   * Put updated milestone
   */
  public updateMilestone(milestone: ISimpleMilestone): Observable<IMilestone> {
    return this.apiService.update(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.studyMilestones,
      milestone
    );
  }

  /**
   * Upload paper consent artifact
   */
  public uploadPaperMilestone(
    data: FormData,
    userStudyConsentId: number
  ): Observable<string | { status: string; progress?: number }> {
    return this.apiService.addWithObserve(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.paperMilestoneUpload}/${userStudyConsentId}/paper`,
      data
    );
  }

  /**
   * Upload paper consent artifact
   */
  public uploadPaperReconsent(
    data: FormData,
    userStudyConsentId: number
  ): Observable<string | { status: string; progress?: number }> {
    const endpoint = this.endpoints.paperReconsentUpload.replace(
      this.idReg,
      `${userStudyConsentId}`
    );

    return this.apiService.addWithObserve(this.apiConfig.studyManagementServiceUrl, endpoint, data);
  }

  /**
   * Upload milestone artifact
   */
  public uploadArtifactsWithProgress(
    data: FormData,
    milestoneId: number
  ): Observable<string | { status: string; progress?: number; response?: unknown }> {
    return this.apiService.addWithObserve(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestonesV1}/${milestoneId}/artifact`,
      data
    );
  }

  /**
   * Create a new milestone
   */
  public postStudyMilestone(milestone: ISimpleMilestone): Observable<IMilestone> {
    return this.apiService.add(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.studyMilestones,
      milestone
    );
  }

  /**
   * Upload milestone artifact
   */
  public uploadArtifacts(data: FormData, milestoneId: number): Observable<FormData> {
    return this.apiService.add(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestonesV1}/${milestoneId}/artifact`,
      data
    );
  }

  /**
   * Update the user study milestone
   */
  public updateUserStudyMilestone(
    userStudyMilestone: IStudySiteUserStudyMilestone
  ): Observable<any> {
    return this.apiService.update(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.userStudyMilestonesV1,
      userStudyMilestone
    );
  }

  /**
   * Complete the milestone
   */
  public completeMilestone(userStudyMilestoneId: number): Observable<undefined> {
    const endpoint = this.endpoints.completeMilestone.replace(
      this.idReg,
      `${userStudyMilestoneId}`
    );
    return this.apiService
      .update(this.apiConfig.studyManagementServiceUrl, endpoint, '')
      .pipe(map(() => undefined));
  }

  /**
   * Update milestone status to complete
   */
  public updateCompletionDate(milestone: any): Observable<any> {
    return this.apiService.update(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.userStudyMilestonesV1,
      milestone
    );
  }

  /**
   * on clicking view artifacts, it will be downloaded
   */
  public downloadArtifact(artifact: IArtifact): void {
    this.downloadArtifacts(artifact.user_study_milestone_id, artifact.id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error, 'Error downloading artifact');
          return of(undefined);
        })
      )
      .subscribe((data: any) => {
        if (data) {
          const file = new Blob([data.body], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          // if you want to open PDF in new tab
          window.open(fileURL);
        }
      });
  }

  /**
   * Delete a study milestone
   */
  public deleteMilestoneFromStudy(milestoneId: number): Observable<any> {
    return this.apiService.alternativeDelete(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.studyMilestones,
      milestoneId
    );
  }

  /**
   * get artifact types
   */
  public getArtifactTypes(): Observable<IArtifactType[]> {
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.artifactTypes
    );
  }
}
