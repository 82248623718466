import { IPEPSurvey } from './pep-survey.interface';
import { ISurveyResponse } from './survey-response.interface';

/**
 * Configuration data for a Survey
 */
export interface ISurveyPlayerConfig {
  survey: IPEPSurvey;
  prefillResponses: ISurveyResponse | undefined /* data used to prefill survey questions */;
  readonly: boolean;
  preferredLanguage?: string;
}
