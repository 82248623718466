<div class="container">
  <div class="row mb-3 d-flex justify-content-between align-items-center">
    <div class="col-9">
      <div class="text-start">
        <header class="page-header">
          <h1 class="page-title">{{ pageTitle }}</h1>
        </header>
      </div>
    </div>
    <div class="col-3" *ngIf="showLanguages">
      <div class="form-control-group">
        <nb-select
          class="bg-white"
          fullWidth
          [optionsOverlayOffset]="overlayOffset"
          fieldSize="medium"
          id="preferredLang"
          name="preferredLang"
          [selected]="language"
          (selectedChange)="onGetNewLanguagePRO($event)"
        >
          <nb-option *ngFor="let lang of languageList" [value]="lang.code">{{
            lang.description
          }}</nb-option>
        </nb-select>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="surveyConfig">
    <div class="col">
      <ng-container *ngIf="(completionState.get$ | async) !== 'continue'; else proCompleted">
        <ng-container *ngIf="(completionState.get$ | async) !== 'compensated'; else lastCompleted">
          <pes-survey-player
            style="
              --question-header-align: left;
              --question-margin-bottom: 0;
              --survey-header-align: left;
            "
            [surveyConfig]="surveyConfig"
            (surveyCompleted)="onSubmitSurvey($event)"
          ></pes-survey-player>
        </ng-container>
        <ng-template #lastCompleted>
          <div class="container container-background">
            <div class="row">
              <div class="col-12 ps-4 pb-3 pt-3">
                <h4 size="large">
                  {{ 'completed-surveys' | translate }}
                  <b>({{ totalCompletedSurveys.completed }}/{{ totalCompletedSurveys.total }})</b>
                </h4>
                <p>
                  {{ 'completed-surveys-info' | translate }}
                </p>
                <p>
                  {{ 'completed-surveys-sub-info' | translate }}
                </p>
              </div>
              <div class="col-12 ps-4 pb-3">
                <div class="col d-flex justify-content-start">
                  <button
                    class="me-2"
                    nbButton
                    title="{{ 'done' | translate }}"
                    data-test="done"
                    (click)="onDone()"
                  >
                    {{ 'done' | translate }}
                  </button>
                  <button
                    nbButton
                    status="primary"
                    (click)="onGetPaid()"
                    [disabled]="totalCompletedSurveys.amountPaid === 0"
                  >
                    {{ 'getPaid' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #proCompleted>
        <div class="container container-background">
          <div class="row">
            <div class="col-12 ps-4 pb-3 pt-3">
              <h4 size="large">
                {{ 'completed-surveys' | translate }}
                <b>({{ totalCompletedSurveys.completed }}/{{ totalCompletedSurveys.total }})</b>
              </h4>
            </div>
            <div class="col-12 ps-4 pb-3">
              <div class="col d-flex justify-content-start">
                <button
                  class="me-2"
                  nbButton
                  title="{{ 'save-progress' | translate }}"
                  (click)="onBack()"
                  data-test="save-progress"
                >
                  {{ 'save-progress' | translate }}
                </button>
                <button nbButton status="primary" (click)="onContinueToNext()">
                  {{ 'continue-to-next' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
