import { ILatestConsent } from './consent.interface';
import { IMilestoneType } from './milestone-type.interface';
import { IStudy } from './study.interface';

export interface IAdminDocument {
  active_on: number;
  consents: Array<ILatestConsent>;
  created_by_id: number;
  depends_on: number | string;
  duration: number;
  id: number;
  interval: number;
  milestone_description: string;
  milestone_name: string;
  milestone_type: IMilestoneType;
  milestone_type_id: number;
  study: IStudy;
  study_id: number;
  timepoint_id: number;
  timepoint?: string;
  open?: boolean;
  study_milestone_questionnaire?: IDocumentSurveyMilestone;
  study_survey_milestone?: IDocumentQuestionnaireMilestone;
  documentFilter?: string;
}

export interface IDocumentSurveyMilestone {
  id: number;
  study_milestone_id: number;
  study_survey_milestone_title: string;
  survey_id: number;
}
export interface IDocumentQuestionnaireMilestone {
  id: number;
  questionnaire_id: number;
  study_milestone_id: number;
  study_milestone_questionnaire_title: string;
}
