import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  getDeepFromObject,
  NbAuthJWTToken,
  NbAuthStrategyOptions,
  NbPasswordStrategyMessage,
  NbPasswordStrategyModule,
  NbPasswordStrategyReset,
  NbPasswordStrategyToken,
} from '@nebular/auth';

/**
 * Must override NbPasswordAuthStrategyOptions in order to include validateExternalUser
 */
export class CustomPasswordAuthStrategyOptions extends NbAuthStrategyOptions {
  public baseEndpoint? = '/api/auth/';

  public login?: boolean | NbPasswordStrategyModule = {
    alwaysFail: false,
    defaultErrors: ['Login/Email combination is not correct, please try again.'],
    defaultMessages: ['You have been successfully logged in.'],
    endpoint: 'login',
    method: 'post',
    redirect: {
      failure: null,
      success: '/',
    },
    requireValidToken: true,
  };

  public register?: boolean | NbPasswordStrategyModule = {
    alwaysFail: false,
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['You have been successfully registered.'],
    endpoint: 'register',
    method: 'post',
    redirect: {
      failure: null,
      success: '/',
    },
    requireValidToken: true,
  };
  public requestPass?: boolean | NbPasswordStrategyModule = {
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['Reset password instructions have been sent to your email.'],
    endpoint: 'request-pass',
    method: 'post',
    redirect: {
      failure: null,
      success: '/',
    },
  };
  public resetPass?: boolean | NbPasswordStrategyReset = {
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['Your password has been successfully changed.'],
    endpoint: 'reset-pass',
    method: 'put',
    redirect: {
      failure: null,
      success: '/',
    },
    resetPasswordTokenKey: 'reset_password_token',
  };
  public logout?: boolean | NbPasswordStrategyReset = {
    alwaysFail: false,
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['You have been successfully logged out.'],
    endpoint: 'logout',
    method: 'delete',
    redirect: {
      failure: null,
      success: '/',
    },
  };
  public refreshToken?: boolean | NbPasswordStrategyModule = {
    alwaysFail: false,
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['Your token has been successfully refreshed.'],
    endpoint: 'user/login',
    method: 'post',
    redirect: {
      failure: null,
      success: null,
    },
    requireValidToken: true,
  };

  public validateExternalUser?: boolean | NbPasswordStrategyModule = {
    alwaysFail: false,
    defaultErrors: ['Something went wrong, please try again.'],
    defaultMessages: ['User authenticated successfully.'],
    endpoint: 'token',
    method: 'post',
    redirect: {
      failure: null,
      success: '/',
    },
    requireValidToken: true,
  };

  public token?: NbPasswordStrategyToken = {
    class: NbAuthJWTToken,
    key: 'data.token',
    getter: (module: string, res: HttpResponse<any>, options: CustomPasswordAuthStrategyOptions) =>
      getDeepFromObject(res.body, options.token?.key || '', ''),
  };

  public errors?: NbPasswordStrategyMessage = {
    key: 'data.errors',

    getter: (module: string, res: HttpErrorResponse, options: CustomPasswordAuthStrategyOptions) =>
      getDeepFromObject(res.error, options.errors?.key || '', ''),
  };

  public messages?: NbPasswordStrategyMessage = {
    key: 'data.messages',
    // eslint-disable-next-line
    getter: (module: string, res: HttpResponse<any>, options: CustomPasswordAuthStrategyOptions) =>
      getDeepFromObject(res.body, options.messages?.key || '', ''),
  };
}

export const defaultPasswordStrategyOptions = new CustomPasswordAuthStrategyOptions();
