import { BehaviorSubject } from 'rxjs';

/**
 * Wrap a value in a BehaviorSubject and provides access for setting and getting it
 * @param initialValue initial value
 * @returns object that represents the value being observed
 */
export const useObserver = <T>(initialValue: T) => {
  const subject = new BehaviorSubject(initialValue);

  /**
   * Sets the value of this observed object
   * @param value
   */
  const setter = (value: T) => {
    subject.next(value);
  };

  /**
   * Async Observable that emits when the value changes
   * @returns Observable that emits when value changes
   */
  const getter$ = subject.asObservable();

  /**
   * Syncrounous access to the value
   * @note Avoid using this!
   * @returns the value
   */
  const getter = () => subject.value;

  return { get$: getter$, set: setter, _get: getter };
};
