import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TrweConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { TrweLoginComponent } from './components/login/login.component';
import { TrweLogoutComponent } from './components/logout/logout.component';
import { TrwePaypalLoginComponent } from './components/paypal-login/paypal-login.component';
import { TrweRegisterComponent } from './components/register/register.component';
import { TrweRequestPasswordComponent } from './components/request-password/request-password.component';
import { TrweResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TrweSocialLoginComponent } from './components/social-login/social-login.component';
import { TrweVerificationComponent } from './components/verification/verification.component';
import { TrweAuthComponent } from './trwe-auth.component';
import { TrweHelpComponent } from './components/help/help.component';

export const routes: Routes = [
  {
    path: '',
    component: TrweAuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: TrweLoginComponent,
      },
      {
        path: 'register',
        component: TrweRegisterComponent,
      },
      {
        path: 'logout',
        component: TrweLogoutComponent,
      },
      {
        path: 'request-password',
        component: TrweRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: TrweResetPasswordComponent,
      },
      {
        path: 'verification',
        component: TrweVerificationComponent,
      },
      {
        component: TrweConfirmEmailComponent,
        path: 'passwordconfirmemail',
      },
      {
        component: TrweSocialLoginComponent,
        path: 'sociallogin',
      },
      {
        component: TrwePaypalLoginComponent,
        path: 'link-account',
      },
      {
        component: TrweHelpComponent,
        path: 'help',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TrweAuthRoutingModule {}
