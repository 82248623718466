import { Component, Input } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { PesFormControlBaseComponent } from '../form-control-base/form-control-base.component';

/**
 * Styled Form Text input
 */
@Component({
  selector: 'pes-form-text-area',
  templateUrl: './form-text-area.component.html',
})
export class PesFormTextAreaComponent extends PesFormControlBaseComponent {
  /**
   * The Nebular input size
   */
  @Input() size: NbComponentSize = 'medium';

  /**
   * The number of rows
   */
  @Input() rows = 4;

  /**
   * When true, input is readonly
   */
  @Input() readonly = false;
}
