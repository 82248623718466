import { ISurveyOptions } from './survey-options.interface';

/**
 * set of SurveyPDF Elements
 */
export interface IElements {
  elements: Array<IElement>;
}

/**
 * SurveyPDF Element
 */
export interface IElement {
  name?: string;
  title: string | { [key: string]: string };
  type: string;
  visible?: boolean;
  paths?: any; // Array<any>; // TODO Array<{ text: string; value: number }> | string;
  defaultValue?: any;
  choices?: Array<ISurveyOptions>;
  emotions?: Array<ISurveyOptions>;
  columns?: Array<ISurveyOptions>;
  rows?: Array<ISurveyOptions>;
  svg?: string;
  tag?: string;
  snomed?: string;
  field_name?: string;
  locale?: string;
  readOnly?: boolean;
  imageLink?: string;
  imageWidth?: string;
  imageHeight?: string;
  rangeMax?: number;
  rangeMin?: number;
  html?: string;
  height?: number;
  width?: number;
  role?: string;
  visibleIf?: string;
  isRequired?: boolean;
  penColor?: string;
  inputType?: any;
  defaultValueExpression?: any;
}
