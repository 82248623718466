import { Subject } from 'rxjs';

/**
 * Automate completion of Subscriptions using takeUntil
 * Ex:
 * class SomeComponent: OnDestroy {
 *   private readonly unsubscriber = new Unsubscriber();
 *
 *   contructor(private someService: SomeService) {
 *     this.someService.event$
 *        .pipe(
 *          // will unsubscribe when done$ is triggered
 *          takeUnitl(this.unsubscriber.done$)
 *        )
 *        .subscribe( () => console.log('EVENT!') )
 *   }
 *
 *   ngOnDestroy() {
 *     // triggers done$ to emit
 *     this.unsubscriber.complete();
 *   }
 * }
 *
 */
export class Unsubscriber {
  private _done = new Subject<void>();

  /**
   * Emits when complete() is called.  Use this with takeUntil()
   * to automatically unsubscribe from Observables
   */
  public readonly done$ = this._done.asObservable();

  /**
   * Trigger completion of the done$ Observable.  In general, this
   * should occur in the ngOnDestroy() Angular method, or whenever
   * the owner of the Unsubscriber is destroyed.
   */
  public complete() {
    this._done.next();
    this._done.complete();
  }
}
