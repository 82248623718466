<div class="form-group">
  <label *ngIf="label" for="{{ inputId }}" class="form-label">
    <span *ngIf="isRequired" class="required">*</span>
    {{ label }}
  </label>
  <input
    #picker
    id="{{ inputId }}"
    nbInput
    fullWidth
    [fieldSize]="size"
    class="form-control"
    [nbDatepicker]="pesDatePicker"
    [formControl]="control"
    [readonly]="readonly"
    [ngClass]="{ 'is-invalid': showErrors && control.errors }"
    placeholder="{{ placeholder }}"
  />
  <nb-icon
    *ngIf="hasValue"
    class="cancel-icon"
    [ngClass]="{ large: size === 'large', tiny: iconSize === 'tiny' }"
    icon="close-outline"
    (click)="onClear()"
  ></nb-icon>
  <nb-icon
    class="calendar-icon"
    [ngClass]="{ large: size === 'large', 'tiny-cal': iconSize === 'tiny' }"
    icon="calendar-outline"
    (click)="picker.click()"
  ></nb-icon>
  <nb-datepicker #pesDatePicker [format]="format"></nb-datepicker>
  <pes-form-error
    *ngIf="showErrors && control.errors"
    [control]="control"
    [errors]="errors"
  ></pes-form-error>
</div>
