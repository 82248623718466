import { Component, OnInit, inject } from '@angular/core';
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms';
import { pepTranslate } from '../../utilities';
import { Location } from '@angular/common';
import { PepContactUsService, PepToastNotificationService } from '../../services';
import { catchError, interval, of, take } from 'rxjs';

@Component({
  selector: 'pes-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class PesContactUsComponent implements OnInit {
  public isSuccess = false;
  public selectedCategory: string | null = '';
  public categoryList: Array<{ label: string; value: string }> = [
    { label: 'PRO Issue', value: 'PRO Issue' },
    { label: 'Questionnaire Issue', value: 'Questionnaire Issue' },
    { label: 'Biosample Issue', value: 'Biosample Issue' },
    { label: 'General Technical Question', value: 'General Technical Question' },
    { label: 'Provide App Feedback', value: 'Provide App Feedback' },
    { label: 'Study Sign Up Issue', value: 'Study Sign Up Issue' },
    { label: 'Account Issues', value: 'Account Issues' },
    { label: 'Payment Issues', value: 'Payment Issues' },
  ];
  public contactUsForm = this.initializeForm();
  public readonly translate = pepTranslate();
  private readonly location = inject(Location);
  private readonly contactUsService = inject(PepContactUsService);
  private readonly toastService = inject(PepToastNotificationService);

  constructor() {}

  ngOnInit(): void {
    this.contactUsForm.statusChanges.subscribe(() => (this.isSuccess = false));
    // Track changes to the category control
    this.contactUsForm.controls.category.valueChanges.subscribe((value) => {
      this.selectedCategory = value;
    });
  }

  /**
   * Initializes the reactive form
   */
  private initializeForm() {
    const fb = inject(NonNullableFormBuilder);
    return fb.group({
      category: new FormControl<string>('', Validators.required),
      comment: new FormControl<string>('', Validators.required),
    });
  }

  /**
   * Method used to go back based on the last active tab
   */
  public onBack(): void {
    this.location.back();
  }
  public onClear(): void {
    this.contactUsForm.reset();
  }

  public get isFormInvalid(): boolean {
    return this.contactUsForm.invalid;
  }

  public onSubmit(): void {
    this.contactUsService
      .raiseQuery(this.contactUsForm.value)
      .pipe(
        catchError((error) => {
          this.toastService.showErrorToast(error, 'Contact Us Form Submitted');
          return of(undefined);
        })
      )
      .subscribe((response: string | undefined) => {
        if (response) {
          this.contactUsForm.reset();
          this.toastService.showSuccessToast(
            this.translate('contact-us-success-message'),
            'Contact Us Form Submitted'
          );
          this.isSuccess = true;
          // To show the success note at the bottom
          interval(100)
            .pipe(take(1))
            .subscribe(() => window.scrollTo(0, document.body.scrollHeight));
        }
      });
  }
}
