import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IQuestion } from '../../interfaces';
import { API_CONFIG } from './api-config';
import { PepApiService } from './api.service';

/**
 * API access to Questions
 */
@Injectable({ providedIn: 'root' })
export class PepQuestionService {
  private readonly apiService = inject(PepApiService);
  private readonly apiConfig = inject(API_CONFIG);

  private readonly endpoints = {
    question: '/api/v1/question',
  };

  constructor() {}

  /**
   * Get all questions
   */
  public getAllQuestions(filter?: string): Observable<Array<IQuestion>> {
    let url = this.endpoints.question;
    if (filter) {
      url += `?${filter}`;
    }
    return this.apiService.getAll(this.apiConfig.surveyServiceBaseUrl, url);
  }

  /**
   * Create a question
   */
  public createQuestion(question: IQuestion) {
    return this.apiService.add<IQuestion, IQuestion>(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.question,
      question
    );
  }

  /**
   * Update a question
   */
  public updateQuestion(question: IQuestion, id: number) {
    return this.apiService.update<IQuestion, IQuestion>(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.question,
      question,
      id
    );
  }

  /**
   * Delete question
   */
  public deleteQuestion(id: number) {
    return this.apiService.alternativeDelete(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.question,
      id
    );
  }
}
