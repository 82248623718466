/**
 * Roles assignable to a User
 */
export enum RoleType {
  User = 'user', // guest
  // Admin       = 'admin', // pep_admin
  Patient = 'patient', // pep_patient
  Hcp = 'hcp', // pep_investigator
  StudyManager = 'studymanager', // pep_site_admin
  StudySiteAdmin = 'studysiteadmin', // pep_study_site_admin
}
