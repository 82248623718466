import { ConsentStatusEnum } from '../types/consent-status.enum';
import { IConsentLanguage } from './consent-language.interface';
import { IPEPSurvey } from './pep-survey.interface';
import { UserStudyConsent } from './user-study-consent.interface';
import { ISurveyResponse } from './survey-response.interface';

export interface IConsent {
  artifact_id: number;
  consent_corrected_date: string;
  consent_id: number;
  consent_language_code: string;
  consent_language_id: number;
  consent_name: string;
  consent_protocol_no: string;
  consent_response?: { [key: string]: string };
  consent_response_id: number;
  consent_title: string;
  consent_version_no: string;
  content: string;
  participant_id?: string;
  study_id: number;
  study_name: string;
  study_site_id: number;
  title: string;
  user_study_consent_id: number;
  user_study_consent_status: ConsentStatusEnum;
  user_study_milestone_id: number;
  version_date?: Date;

  isLoading?: boolean;
}

export interface ILatestConsent {
  consent_id: number;
  consent_response: { [key: string]: unknown };
  consent_title: string;
  created_by_id: number;
  id: number;
  site_id: number;
  study_id: number;
  study_site_id: number;
  user_study_consents: Array<UserStudyConsent>;
  version_no: string;
  commit_branch?: string;
  consent_date_time?: string;
  consent_json?: IPEPSurvey;
  consent_languages?: Array<IConsentLanguage>;
  content?: string;
  corrected_date?: string;
  document_name?: string;
  document_title?: string;
  document_url?: string;
  language_region_code?: string;
  location?: string;
  message?: string;
  protocol_no?: string;
  status?: string;
  study_site_ids?: string;
  version_date?: string;
}

/**
 * API response object from /api/v1/user_study_consent/:user_study_consent_id/response?languageregioncode={languageCd}
 */
export interface IConsentResponse {
  consent_details: IConsent;
  user_study_consent_id: string;
}

/**
 * Data required to cancel a participant's consent
 */
export interface IConsentRequest {
  id?: number; // consent_response_id,
  user_study_consent_id: number;
  response: ISurveyResponse; // JSON blob representing the survey response
  consent_language_id?: number;

  user_id?: number;
  invite_code?: string;
  site_admin_id?: number;

  status?: ConsentStatusEnum; // I don't think this is uesed?
}

/**
 * Data required to cancel a participant's consent
 */
export interface ICancelConsentRequest {
  comment: string;
  id: number; // user_study_consent_id
  reviewer_id: number;
  status: string;
  study_consent_id: number;
  user_id: number;
  user_study_milestone_id: number;
  site_admin_id?: number;
}

export interface IOtpRequest {
  response: unknown;
  user_study_consent_id: number;
}

export interface IPatientConsent {
  consent_details: IConsent;
  user_study_consent_id: number;
}

export interface IEOSRequest {
  dmSubjID: string;
  userStudyID: number;
  trial: string;
  protocolViolation: string;
  contactDt: string;
  deathDt: string;
  other: string;
}
