/**
 * Returns the Date portion of the passed CORRECTLY FORMATTED Date
 * For example:
 * ```typescript
 *  toDate(Sun Dec 31 1995 18:00:00 GMT-0600) => '12/31/1995'
 * ```
 */
export const toDateStr = (dt: Date | string) =>
  dt && Date.parse(dt.toString()) > 0 ? new Date(dt).toLocaleDateString('en-US') : '';

/**
 * Return date string as mm/dd/yyyy when date is coming from api with INCORRECT timestamp
 * so that it can be converted to a date correctly for display
 * For example:
 *  defaultDateStrFormat('2022-08-31T00:00:00.000Z') => '08/31/2022'
 */
export const toDefaultDateStr = (dt: string): string => {
  if (dt && Date.parse(dt) > 0) {
    const dateArr = dt.split('T')[0].split('-');
    return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}` || '';
  }
  return '';
};

/**
 * Return date string as yyyy-mm-dd (surveyjs datepicker requirement only)
 * when date is coming from api with INCORRECT timestamp
 * so that it can be converted to a date correctly for display in surveyjs
 * For example:
 *  defaultDateStrFormat('2022-08-31T00:00:00.000Z') => '2022-08-31'
 */
export const toDefaultSurveyJsDateStr = (dt: string): string => {
  if (dt && Date.parse(dt) > 0) {
    const dateArr = dt.split('T')[0].split('-');
    return `${dateArr[0]}-${dateArr[1]}-${dateArr[2]}` || '';
  }
  return '';
};
