<nb-layout>
  <nb-layout-column class="p-0">
    <nb-card class="m-0">
      <nb-card-body class="d-flex flex-column">
        <nb-auth-block class="m-auto">
          <router-outlet></router-outlet>
        </nb-auth-block>
        <img class="target-logo" src="{{ logo }}" alt="Target RWE" />
      </nb-card-body>
      <pes-spinner></pes-spinner>
    </nb-card>
  </nb-layout-column>
</nb-layout>
