/**
 * Describes a Timepoint for a study.
 */
export interface ITimepoint {
  id: number;
  visit_num: number;
  visit: string;
}

/**
 * Timepoints within a Study
 */
export interface IStudyTimepoints {
  study_id: number;
  timepoints_ids: number[];
}
