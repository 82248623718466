import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PepFormValidatorsService, pepTranslate } from '@targetrwe/pep-shared';
import { catchError, of } from 'rxjs';
import { IUpdateUser, TrweAuthService } from '../../services';

@Component({
  selector: 'trwe-confirm-email',
  styleUrls: ['./confirm-email.component.scss'],
  templateUrl: './confirm-email.component.html',
})
export class TrweConfirmEmailComponent implements OnInit {
  public confirmForm = this.createForm();
  public submitted = false;
  public validConfirmation = false;
  private email = '';
  public error = '';
  public success = '';

  public readonly translate = pepTranslate();
  private readonly router = inject(Router);
  private readonly authService = inject(TrweAuthService);

  // configuration data provided by Router on navigation
  private readonly state = this.router.getCurrentNavigation()?.extras?.state;

  private createForm() {
    const fb = inject(NonNullableFormBuilder);
    const formValidators = inject(PepFormValidatorsService);

    return fb.group(
      {
        username: ['', [Validators.required, Validators.pattern(formValidators.emailPattern)]],
        confirmation_code: ['', Validators.required],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(50),
            Validators.pattern(formValidators.passwordPattern),
          ],
        ],
        confirmPassword: ['', Validators.required],
      },
      { validators: formValidators.passwordMatchingValidation }
    );
  }

  constructor() {}

  public ngOnInit(): void {
    if (this.state) {
      this.email = this.state['email']?.toLowerCase();
    }
    this.confirmForm.controls.username.setValue((this.email || '').toLowerCase());
  }

  public onConfirmEmail() {
    this.submitted = true;
    if (this.confirmForm.invalid) {
      return;
    } else {
      this.authService
        .confirmPassword(this.confirmForm.value as IUpdateUser)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.submitted = false;
            if (error.error instanceof ProgressEvent) {
              this.error = error.statusText;
            } else {
              /* eslint-disable no-underscore-dangle */
              this.error = error.error.Message_ ? error.error.Message_ : error.error;
            }
            return of(undefined);
          })
        )
        .subscribe((confirmResponse) => {
          if (confirmResponse) {
            this.validConfirmation = true;
            this.success = this.translate('passwordResetSuccess');
          }
        });
    }
  }
}
