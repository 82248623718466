import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PepApiService {
  constructor(private http: HttpClient) {}

  /**
   * HTTP GET call to return Array of objects
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @returns array of objects
   */
  public getAll<T>(baseUrl: string, path: string) {
    return this.http.get<T>(`${baseUrl}${path}`);
  }

  /**
   * HTTP GET a single object based on id
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param id number value of the object to return
   * @returns single object
   */
  public getSingle<T>(baseUrl: string, path: string, id?: number) {
    const url = id ? `${baseUrl}${path}/${id}` : `${baseUrl}${path}`;
    return this.http.get<T>(url);
  }

  /**
   * HTTP GET file (pdf download)
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @returns downloaded file
   */
  public getFile(baseUrl: string, path: string) {
    return this.http.get<Blob>(`${baseUrl}${path}`, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  /**
   * HTTP POST file (pdf upload)
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   */
  public uploadFile<R = unknown>(
    baseUrl: string,
    path: string,
    data: FormData,
    reportProgress?: boolean
  ) {
    return this.http.post<R>(`${baseUrl}${path}`, data, {
      reportProgress,
    });
  }

  /**
   * HTTP POST call
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param item object to be posted
   */
  public add<T, R = unknown>(baseUrl: string, path: string, item: T) {
    return this.http.post<R>(`${baseUrl}${path}`, item);
  }

  /**
   * HTTP POST call with observe to monitor upload progress
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param item object to be posted
   * @param reportProgress true to report progress back to caller
   * @returns created file
   *
   * TODO - refactor this! Should return a consistent type!
   */
  public addWithObserve<T, R = unknown>(
    baseUrl: string,
    path: string,
    item: T,
    reportProgress = true
  ): Observable<string | { status: string; progress?: number; response?: R }> {
    return this.http
      .post<T>(`${baseUrl}${path}`, item, {
        observe: 'events',
        reportProgress,
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress: {
              const progress = Math.round((100 * event.loaded) / (event.total || 1));
              return { status: 'progress', progress };
            }
            case HttpEventType.Response:
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return { status: 'success', response: event } as any; // TODO - do not use any type here! fix typings
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }
  /**
   * HTTP PUT call
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param id number value for the object to be updated
   * @param item object to be updated
   * @returns upodated object
   */
  public update<T, R = unknown>(baseUrl: string, path: string, item: T, id?: number) {
    if (id) {
      return this.http.put<R>(`${baseUrl}${path}/${id}`, item);
    } else {
      return this.http.put<R>(`${baseUrl}${path}`, item);
    }
  }

  /**
   * HTTP DELETE call
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param id number value for the object to delete
   * @returns observable 200 OK or error
   */
  public delete<R>(baseUrl: string, path: string, id?: number) {
    if (id) {
      path = `${baseUrl}${path}/${id}`;
    }
    return this.http.delete<R>(`${baseUrl}${path}`);
  }

  /**
   * HTTP POST call with string response instead of standard 200 OK or error
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param id number value for the object to delete
   * @returns improper string response
   */
  public alternativeAdd<T, R = unknown>(baseUrl: string, path: string, item: T) {
    return this.http.post<R>(`${baseUrl}${path}`, item, {
      responseType: 'text' as 'json',
      observe: 'response',
    });
  }

  /**
   * HTTP Delete call with string response instead of standard 200 OK or error
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param id number value for the object to delete
   * @returns improper string response
   */
  public alternativeDelete<R = unknown>(baseUrl: string, path: string, id: number) {
    return this.http.delete<R>(`${baseUrl}${path}/${id}`, {
      responseType: 'text' as 'json',
      observe: 'response',
    });
  }

  /**
   * HTTP POST call with string response instead of standard 200 OK or error
   *
   * @param baseUrl string value for the base url
   * @param path string value for the endpoint path
   * @param id number value for the object to delete
   * @returns improper string response
   */
  public alternativeGet<R = unknown>(baseUrl: string, path: string) {
    return this.http.get<R>(`${baseUrl}${path}`, {
      responseType: 'text' as 'json',
      observe: 'response',
    });
  }
}
