<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <ul class="alert-message-list">
    <li class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<section
  class="verification-another-action mb-4"
  aria-label="Verification"
  *ngIf="validConfirmation; else pendingValidationSection"
>
  <p class="mb-5">{{ 'accountActiveMessage' | translate }}</p>
  <button nbButton fullWidth status="primary" size="large" (click)="onSignIn()">
    {{ 'signIn' | translate }}
  </button>
</section>
<ng-template #pendingValidationSection>
  <p class="sub-title">{{ 'registrationCodeLabel' | translate }}</p>
  <form [formGroup]="verifyForm" (ngSubmit)="onVerifyUser()" novalidate>
    <div class="form-control-group">
      <pes-form-input
        [control]="verifyForm.controls.confirmation_code"
        [size]="'large'"
        label="{{ 'emailVerification' | translate }}"
        [errors]="{ required: translate('formError_required') }"
        [showErrors]="submitted"
      >
      </pes-form-input>
    </div>

    <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted">
      {{ 'validate' | translate }}
    </button>
  </form>
</ng-template>
