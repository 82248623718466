import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getDeepFromObject, NbAuthResult, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import {
  IRegionLanguage,
  LOGO_URLS,
  PepFormValidatorsService,
  PepLanguageService,
  PepToastNotificationService,
  pepTranslate,
  PepUserService,
  RoleType,
} from '@targetrwe/pep-shared';
import { catchError, map, of, take } from 'rxjs';
import { INbAuthSocialLink } from '../../interfaces/social-link.interface';
import { IUserRole } from '../../interfaces/social-login-response.interface';
import { TrweAuthService, TrweAuthStore } from '../../services';
import { TRWE_AUTH_MODULE_CONFIG } from '../../trwe-auth-module.config';

@Component({
  selector: 'trwe-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class TrweLoginComponent implements OnInit {
  public loginForm = this.createForm();
  public redirectDelay = 0;
  public strategy = '';
  public error = '';
  public message = '';
  public submitted = false;
  public socialLinks: INbAuthSocialLink[] = [];
  public readonly brandlogo: string = LOGO_URLS.engageLogo;
  public readonly brandmark: string = LOGO_URLS.brandmarkColor;
  public showLanguageAlert = false;
  public language = '';
  public languageCode = '';
  public isValid = true;
  public isPep = true;

  public readonly translate = pepTranslate();
  private readonly authService = inject(TrweAuthService);
  protected readonly cd = inject(ChangeDetectorRef);
  private readonly languageService = inject(PepLanguageService);
  private readonly nbAuthService = inject(NbAuthService);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly userService = inject(PepUserService);
  private readonly authStore = inject(TrweAuthStore);
  private readonly authModuleSettings = inject(TRWE_AUTH_MODULE_CONFIG);

  // configuration data provided by Router on navigation
  private readonly state = this.router.getCurrentNavigation()?.extras?.state;

  private createForm() {
    const fb = inject(NonNullableFormBuilder);
    const formValidators = inject(PepFormValidatorsService);

    return fb.group({
      username: ['', [Validators.required, Validators.pattern(formValidators.emailPattern)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
    });
  }

  constructor() {
    const nbAuthOptions = inject(NB_AUTH_OPTIONS);
    this.redirectDelay = getDeepFromObject(nbAuthOptions, 'forms.login.redirectDelay');
    this.strategy = getDeepFromObject(nbAuthOptions, 'forms.login.strategy');
    this.socialLinks = getDeepFromObject(nbAuthOptions, 'forms.login.socialLinks');
  }

  public ngOnInit(): void {
    if (this.authModuleSettings?.appName == 'iep') {
      this.isPep = false;
    }

    if (this.state) {
      this.message = this.state['message'];
    }
    // if directed to this page, then make sure no auth_token is set in localstorage
    this.authService.logout();

    // TODO - fix language support here!
    this.authStore.preferredLanguage$.subscribe((language) => {
      if (
        !language &&
        navigator.language.toLowerCase() !== 'en-us' &&
        navigator.language.toLowerCase() !== 'en'
      ) {
        this.getLanguage();
      }
    });
  }

  private getLanguage(): void {
    this.languageService
      .getLanguages()
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast(this.translate('errorGetLanguages'));
          return of([]);
        }),
        map((response: Array<IRegionLanguage>) =>
          response.find((language) => language.code === navigator.language.toLowerCase())
        )
      )
      .subscribe((matchingLanguage) => {
        if (matchingLanguage) {
          this.showLanguageAlert = true;
          this.language = matchingLanguage.description;
          this.languageCode = matchingLanguage.code;
        }
        this.cd.detectChanges();
      });
  }

  public onLogin(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.loginForm.controls.username.setValue(
        (this.loginForm.controls.username.value || '').toLowerCase()
      );

      this.error = this.message = '';

      this.nbAuthService
        .authenticate(this.strategy, this.loginForm.value)
        .subscribe((result: NbAuthResult) => {
          this.submitted = false;
          if (
            this.authModuleSettings.appName === 'iep' &&
            result.isSuccess() &&
            !result
              .getResponse()
              .body.user.roles.find(
                (role: IUserRole) =>
                  role.name.toLowerCase() === RoleType.StudySiteAdmin ||
                  role.name.toLowerCase() === RoleType.StudyManager
              )
          ) {
            this.error = 'Incorrect username or password.';
            return;
          }
          if (
            result.isSuccess() &&
            result.getResponse().body.user.user_status === 'NEW_PASSWORD_REQUIRED'
          ) {
            setTimeout(
              () =>
                this.router.navigate(['auth/reset-password'], {
                  skipLocationChange: true,
                  state: {
                    email: result.getResponse().body.user.email,
                    session: result.getResponse().body.user.session,
                  },
                }),
              this.redirectDelay
            );
          } else if (
            result.isSuccess() &&
            result.getResponse().body.user.user_status !== 'NEW_PASSWORD_REQUIRED'
          ) {
            this.message = result.getMessages()[0];
            const userEmail = result.getResponse().body.user.email;
            this.userService.getUserByEmailId(userEmail).subscribe((userProfile) => {
              if (userProfile) {
                this.authStore.setAppName(this.authModuleSettings.appName);
                this.authStore.setLoggedInUserFromUserProfile(userProfile);

                // get the condensed user since we want the RoleTypes from it
                this.authStore.loggedInUser$.pipe(take(1)).subscribe((user) => {
                  const activeRole = this.authService.getValidRole(
                    this.authModuleSettings.defaultRole,
                    user?.roles || []
                  );
                  // TODO - if activeRole is undefined, then the user is not authorized to use this app at all!
                  this.authStore.setActiveRole(activeRole);
                  // if user has only study manager  role.
                  if (activeRole?.name.toLowerCase() === RoleType.StudyManager) {
                    this.router.navigateByUrl('/study-manager/study-list');
                  } else {
                    this.router.navigateByUrl(result.getRedirect());
                  }
                });
              } else {
                // failed to get the user profile, so can't log in
                this.toastService.showErrorToast(
                  new Error('No User Found'),
                  `Failed to load user profile for ${userEmail}.`
                );
                this.authService.logout();
              }
            });
          } else {
            if (result.getResponse().error?.error.includes('UserNotConfirmedException')) {
              setTimeout(
                () =>
                  this.router.navigate(['auth/verification'], {
                    skipLocationChange: true,
                    state: { email: this.loginForm.controls.username.value },
                  }),
                this.redirectDelay
              );
            } else {
              this.error = result.getResponse().error?.error;
            }
          }
          this.cd.detectChanges();
        });
    }
  }

  public onOk(): void {
    this.showLanguageAlert = false;
    this.authStore.setLanguage(this.languageCode);
  }

  public onCancel(): void {
    this.showLanguageAlert = false;
    this.authStore.setLanguage('cancelled');
  }
}
