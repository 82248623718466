import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, forkJoin, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import {
  IPEPSurvey,
  ISurveyFile,
  ISurveyPlayerConfig,
  ISurveyResponse,
  ISurveyResponseValue,
  ISurveyResult,
  IUserQuestionnaireRequest,
  PesSiteQuestionnaireConfig,
} from '../../interfaces';
import { DiagnosisService } from '../../services/api-services/study-management/diagnosis.service';
import {
  InputCreatePatientDm,
  InputUpdatePatientDm,
  PatientDm,
} from '../../services/api-services/ods/api/ods-api.types';
import { ParticipantDemographicsService } from '../../services/api-services/ods/participant-demographics.service';
import { PepQuestionnaireService } from '../../services/api-services/questionnaire.service';
import { toDateStr, toDefaultSurveyJsDateStr, valueExtractor } from '../../utilities';
import {
  PatientDiagnosis,
  StudyDiagClassification,
} from '../../services/api-services/study-management/api/study-management-api.types';
import { PepToastNotificationService } from '../../services/helper-services/toast-notification.service';
import { PepUserService } from '../../services/api-services/user.service';
import { PepPdfExportService } from '../../services/helper-services/pdf-export.service';
import { PepMilestoneService } from '../../services/api-services/milestone.service';
import { PepSiteService } from '../../services/api-services/site.service';
import { ParticipantConsentService } from '../../services';

// specifies if this questionnaire should also update participant demographics in ODS
export type QuestionnaireTemplateType = undefined | 'ods';

export type StudyDiagnosisUI = StudyDiagClassification & { conditionCode: string };
export type PatientDiagnosisUI = PatientDiagnosis & { conditionCode: string };

@Component({
  selector: 'pes-site-questionnaire',
  templateUrl: './site-questionnaire.component.html',
  styleUrls: ['./site-questionnaire.component.scss'],
})
export class PesSiteQuestionnaireComponent implements OnInit {
  private studyDiagClassifications: StudyDiagnosisUI[] = [];
  private existingDiagnoses: PatientDiagnosisUI[] = [];
  private knownData!: [string, string | number | (string | undefined)[] | undefined][];
  private updateDemographics = false;
  private existingDemographics: PatientDm | undefined;
  private userQuestionnaireVersion = 0;

  private readonly diagnosisService = inject(DiagnosisService);
  private readonly questionnaireService = inject(PepQuestionnaireService);
  private readonly participantDemographicsService = inject(ParticipantDemographicsService);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly userService = inject(PepUserService);
  private readonly siteService = inject(PepSiteService);
  private readonly pdfExportService = inject(PepPdfExportService);
  private readonly milestoneService = inject(PepMilestoneService);
  private readonly router = inject(Router);
  private readonly participantConsentService = inject(ParticipantConsentService);

  public questionnaire: IPEPSurvey | undefined;

  /**
   * configuration data provided by Router on navigation
   */
  private readonly configData = this.router.getCurrentNavigation()?.extras
    ?.state as PesSiteQuestionnaireConfig;

  /**
   * translated labels used in the UI
   */
  public get labels() {
    return this.configData?.labels;
  }

  public surveyConfig: ISurveyPlayerConfig | undefined;

  /**
   * Initialize the component
   */
  public ngOnInit(): void {
    if (!this.configData) {
      // no config data, so go to home page
      this.router.navigateByUrl('/');
      return;
    }

    this.initializeSurvey();
  }

  /**
   * Load all required data for the survey and display it
   */
  private initializeSurvey() {
    // load the Survey and the prefill data for the quetionnaire
    this.loadSurvey()
      .pipe(
        switchMap((survey) => forkJoin([of(survey), this.loadSurveyData(survey)])),
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error, this.labels.errorGettingQuestionnaire);
          return of([]);
        })
      )
      .subscribe(([survey, prefillResponses]) => {
        if (!survey || !prefillResponses) {
          this.router.navigateByUrl(this.configData.backUrl);
          return;
        }

        const readOnlyAfterSet = ['ageDiag', 'yearDiag', 'race', 'dmSubjID', 'timepoint'];
        const existingResponses = Object.keys(prefillResponses).filter(
          (key) => !!prefillResponses[key]
        ); // list of existing responses

        // set question to read-only if there is an existing response AND the question is in the readOnlyAfterSet list
        survey.pages[0].elements.forEach((element) => {
          element.readOnly =
            !!element.name &&
            existingResponses.includes(element.name) &&
            readOnlyAfterSet.includes(element.name);
        });

        // set the configured Survey
        this.surveyConfig = {
          readonly: false,
          survey,
          prefillResponses,
        };

        // save the original questionnaire so we can use ot to generate a PDF for upload later
        this.questionnaire = survey;
      });
  }

  /**
   * Load the questionnaire survey and configure with options
   */
  private loadSurvey() {
    return forkJoin([
      this.questionnaireService.getQuestionnaireById(this.configData.questionnaireId),
      this.questionnaireService.getUserQuestionnaireLastVersion(
        this.configData.userQuestionnaireId,
        this.configData.questionnaireId
      ),
      this.diagnosisService.getAllDiagnosesForStudyAndUser(
        this.configData.studyId,
        this.configData.participantUserId
      ),
    ]).pipe(
      switchMap(
        ([
          questionnaire,
          userQuestionnaire,
          [diagClassification, studyDiagClassification, patientDiagnosis],
        ]) => {
          if (!questionnaire) {
            return throwError(
              () =>
                new Error(
                  `${this.labels.errorGettingQuestionnaire} ${this.configData.questionnaireId}`
                )
            );
          }

          this.studyDiagClassifications =
            studyDiagClassification?.map(
              (sdc) =>
                ({
                  ...sdc,
                  conditionCode: diagClassification?.find((c) => c.id === sdc.diagClassificationID)
                    ?.conditionCode,
                } as StudyDiagnosisUI)
            ) || [];

          // save the existing diagnoses here since we get them along with the diagnoses definitions.
          // otherwise, we should be getting this in the loadSurveyData call.
          this.existingDiagnoses =
            patientDiagnosis?.map(
              (ed) =>
                ({
                  ...ed,
                  conditionCode: this.studyDiagClassifications?.find(
                    (c) => c.id === ed.studyDiagClassificationID
                  )?.conditionCode,
                } as PatientDiagnosisUI)
            ) || [];

          // get the survey from the consent
          const questions = questionnaire.json_content;

          // if template type is 'ods', then we need to update user demographics
          this.updateDemographics = questionnaire?.template_type === 'ods';

          this.userQuestionnaireVersion = userQuestionnaire?.version
            ? userQuestionnaire?.version
            : 0;

          const survey = {
            pages: [questions],
          } as IPEPSurvey;

          return of(survey);
        }
      )
    );
  }

  /**
   * Load data required to prefill survey responses
   */
  private loadSurveyData(survey: IPEPSurvey) {
    return forkJoin([
      this.userService.getUserByEmailId(this.configData.participantEmail),
      this.participantDemographicsService.fetchWithSubjId(this.configData.dmSubjId),
      this.siteService.getStudySite(this.configData.studySiteId),
      this.participantConsentService.fetchWithSubjId(this.configData.dmSubjId),
    ]).pipe(
      map(([userProfile, participantDm, studySite, participantConsentDm]) => {
        // save original demographic data.
        this.existingDemographics = { ...participantDm };
        const pdm = valueExtractor(participantDm);
        const pcdm = {} as { first_name: string; last_name: string; dob: string } | any;
        try {
          participantConsentDm?.forEach((pc) => {
            pcdm[pc.fldName] = pc.fldValue;
          });
          if (pcdm.dob) {
            const month = pcdm.dob.slice(0, 2);
            const date = pcdm.dob.slice(2, 4);
            const year = pcdm.dob.slice(4, 8);
            pcdm.dob = `${year}-${month}-${date}`;
          }
        } catch (e) {
          pcdm.dob = undefined;
          console.log(e);
        }

        const dob = pcdm.dob || toDefaultSurveyJsDateStr(userProfile?.person.dob || '');
        // gather known data to be used for prefilling the questionnaire
        this.knownData = Object.entries({
          studyName: studySite.study.study_name,
          siteName: studySite.site.name,
          studySiteID: this.configData.studySiteId,
          timepoint: this.configData.timepoint || 'None',

          // demographic data
          dmSubjID: pdm.asString('dmSubjID', this.configData.dmSubjId),
          ageDiag: pdm.asNumber('ageDiag'),
          birthYr: pdm.asNumber(
            'birthYr',
            dob && !dob.startsWith('0001')
              ? new Date(dob || userProfile?.person.dob || '').getFullYear()
              : 0
          ),
          dmSex: pdm.asString('dmSex', userProfile?.person.sex),
          dob: dob,
          email: pdm.asString('email', userProfile?.email),
          employmentStat: pdm.asString('employmentStat'),
          firstName: pdm.asString('firstName', pcdm.first_name || userProfile.person.first_name),
          id: pdm.asNumber('id'),
          lastName: pdm.asString('lasttName', pcdm.last_name || userProfile.person.last_name),
          maritalStat: pdm.asString('maritalStat'),
          race: pdm.asString('race'),
          raceOther: pdm.asString('raceOther'),
          ssn: pdm.asString('ssn'),
          updatedAt: toDateStr(new Date()),
          yearDiag: pdm.asNumber('yearDiag'),
          zipCode: pdm.asString('zipCode'),

          // diagnostic data
          updateDiagnosticClassification: this.existingDiagnoses.length > 0 ? 'yes' : 'no',
          diagnosticClassifications: this.existingDiagnoses
            .map(
              (ed) =>
                this.studyDiagClassifications.find((sdc) => sdc.id === ed.studyDiagClassificationID)
                  ?.conditionCode
            )
            .filter((c) => !!c),
        });

        // Only prefill data for questions that are actually IN the survey.  Dumping
        // everything in here will cause problems
        const prefillResponses = {} as ISurveyResponse;

        survey.pages.forEach((page) => {
          page.elements.forEach((element) => {
            if (element.name) {
              // If this is defining onsetDate for a diagnosis, extract out the correct date
              if (element.name.startsWith('onsetDate_')) {
                // get the condition code
                const code = element.name.split('_')[1];
                // get the date this diagnosis occured
                const studyDiagId = this.studyDiagClassifications.find(
                  (sc) => sc.conditionCode === code
                )?.id;

                const date = this.existingDiagnoses.find(
                  (ed) => ed.studyDiagClassificationID === studyDiagId
                )?.diagDate;

                prefillResponses[element.name] = toDateStr(date);
              } else {
                // find
                const data = this.getKnownValue(element.name);
                if (data) {
                  prefillResponses[element.name] = data[1] as ISurveyResponseValue;
                }
              }
            }
          });
        });

        return prefillResponses;
      })
    );
  }

  /**
   * Submit all Questionnaire answers to the backend
   */
  public onSubmitSurvey(surveyResult: ISurveyResult) {
    const { response, files } = this.extractFilesFromResult(surveyResult.responses);

    const request: IUserQuestionnaireRequest = {
      questionnaire_id: this.configData.questionnaireId,
      updated_by_id: this.configData.participantUserId,
      user_id: this.configData.participantUserId,
      user_questionnaire_id: this.configData.userQuestionnaireId,
      user_study_milestone_id: this.configData.userStudyMilestoneId,
      version: this.userQuestionnaireVersion + 1,
      response,
    };

    this.questionnaireService
      .submitQuestionnaire(request)
      .pipe(
        switchMap(() =>
          this.questionnaire
            ? this.pdfExportService.toBlob(this.questionnaire, surveyResult)
            : of(undefined)
        ),
        switchMap((blobUrl) => {
          if (blobUrl) {
            files.push({
              content: blobUrl,
              name: `filled-form-${this.configData.userQuestionnaireId}-${this.configData.questionnaireId}`,
            });
          }
          return forkJoin(files.map((file) => this.uploadArtifact(file)));
        }),
        switchMap(() => {
          if (this.updateDemographics) {
            // update ODS data
            return forkJoin([
              this.saveUpdateDiagnoses(response),
              this.saveUpdatedDemographics(response),
            ]).pipe(map(() => true));
          } else {
            return of(true);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error, this.labels.errorSaving);
          return of(false);
        })
      )
      .subscribe((success) => {
        if (success) {
          // Successfully saved all data about this survey
          this.toastService.showSuccessToast(this.labels.questionnaireSaved);
        }
      });
  }

  /**
   * Extract Diagnoses responses and save them to ODS
   * @returns emits true if any updates made, else emits false.
   */
  private saveUpdateDiagnoses(response: ISurveyResponse) {
    const updateDiagnostics = response['updateDiagnosticClassification'] === 'yes';

    if (!updateDiagnostics) {
      // survey not updating diagnoses
      return of(false);
    }

    const selectedDiagnoses = response['diagnosticClassifications'] as string[];
    const selectedOnsetDates = Object.entries(response).filter(
      (entry) => entry[0].includes('onsetDate_') && !!entry[1]
    );

    const byOnsetDateCode = (conditionCode: string) => (od: ISurveyResponseValue[]) =>
      od[0] === `onsetDate_${conditionCode}`;

    // a diagnosis is deleted when it is NOT selected in the list OR the onsetDate has changed
    const diagnosesToDelete = this.existingDiagnoses
      .filter((ed) => {
        if (!selectedDiagnoses.includes(ed.conditionCode)) {
          // diagnoses no longer selected
          return true;
        }

        const prevOnsetDate = toDateStr(ed.diagDate);
        const newOnsetDate = this.reformatDate(
          selectedOnsetDates.find(byOnsetDateCode(ed.conditionCode))?.[1] as string
        );

        if (prevOnsetDate !== newOnsetDate) {
          // onset dates are different
          return true;
        }

        // diagnosis still selected and has the same onset date
        return false;
      })
      .map((ed) => ed.studyDiagClassificationID);

    // it is added when it was not previously added OR the onsetDate has changed
    const diagnosesToAdd = this.studyDiagClassifications
      .filter((sdc) => {
        if (!selectedDiagnoses.includes(sdc.conditionCode)) {
          // diagnosis not selected so dont add it
          return false;
        }

        const prevOnsetDate = toDateStr(
          this.existingDiagnoses.find((ed) => ed.conditionCode === sdc.conditionCode)?.diagDate
        );
        const newOnsetDate = this.reformatDate(
          selectedOnsetDates.find(byOnsetDateCode(sdc.conditionCode))?.[1] as string
        );

        if (prevOnsetDate === newOnsetDate) {
          // onset dates are the same so don't add diagnosis
          return false;
        }

        // add the diagnosis
        return true;
      })
      .map((sdc) => {
        const newOnsetDate = this.reformatDate(
          selectedOnsetDates.find(byOnsetDateCode(sdc.conditionCode))?.[1] as string
        );

        const result = {
          userID: this.configData.participantUserId,
          studyDiagClassificationID: +sdc.id, // this is the study-specific ID for the classification
          diagDate: new Date(newOnsetDate),
        };

        return result;
      });

    if (diagnosesToAdd.length === 0 && diagnosesToDelete.length === 0) {
      return of(false);
    }

    return this.diagnosisService.deletePatientDiagnoses(diagnosesToDelete).pipe(
      switchMap(() => this.diagnosisService.createPatientDiagnoses(diagnosesToAdd)),
      map(() => true)
    );
  }

  /**
   * Save the updated Demographic data.
   */
  private saveUpdatedDemographics(response: ISurveyResponse): Observable<PatientDm | undefined> {
    // combine demographic data from the results with original data
    const rs = valueExtractor(response, this.existingDemographics);

    const dob = rs.asDate('dob');

    const updatedDemographics = {
      id: this.existingDemographics?.id as number,
      studySiteID: this.configData.studySiteId,
      ageDiag: rs.asNumber('ageDiag'),
      dmSex: rs.asString('dmSex'),
      dmSubjID:
        rs.asString('dmSubjID') || this.getKnownValue('dmSubjID')
          ? this.getKnownValue('dmSubjID')[1]
          : '',
      dob,
      birthYr: rs.asNumber('birthYr', dob?.getFullYear()),
      email: rs.asString('email') || this.configData.participantEmail,
      employmentStat: rs.asString('employmentStat'),
      firstName: rs.asString('firstName'),
      lastName:
        rs.asString('lastName') || this.getKnownValue('lastName')
          ? this.getKnownValue('lastName')[1]
          : '',
      maritalStat: rs.asString('maritalStat'),
      race: rs.asString('race'),
      raceOther: rs.asString('raceOther'),
      ssn: rs.asString('ssn'),
      timepoint: rs.asString('timepoint'),
      yearDiag: rs.asNumber('yearDiag'),
      zipCode: rs.asString('zipCode'),
    };

    // create or update the demographics data
    const mutate$ = updatedDemographics.id
      ? this.participantDemographicsService.update(updatedDemographics as InputUpdatePatientDm)
      : this.participantDemographicsService.create(updatedDemographics as InputCreatePatientDm);

    return mutate$.pipe(
      tap((participantDM) => {
        if (participantDM) {
          this.existingDemographics = participantDM;
        }
      })
    );
  }

  /**
   * extract any File uploads from the survey results and return them in separete lists
   */
  private extractFilesFromResult(results: ISurveyResponse): {
    response: ISurveyResponse;
    files: ISurveyFile[];
  } {
    const newResult: { response: ISurveyResponse; files: ISurveyFile[] } = {
      response: {},
      files: [],
    };

    for (const [key, value] of Object.entries(results)) {
      // if the value is an array of objects, it is an array of Files
      if (Array.isArray(value) && typeof value[0] === 'object') {
        value.forEach((item) => {
          if (typeof item === 'object' && 'content' in item) {
            newResult.files.push(item);
          }
        });
      } else {
        /* Commented out: without onsetDates updating diagnosis does not work
         if (!key.includes('onsetDate_')) */
        // standard response type (exclude onsetDate_ keys since they are not part of the survey response)
        newResult.response[key] = value;
      }
    }

    return newResult;
  }

  /**
   * Upload the passed File/Blob as an artifact for this questionnaire
   * @param filename name to give the uploaded file
   * @param content DataURL to a File, or Blobk data
   */
  private uploadArtifact(file: ISurveyFile) {
    const dataURLtoFile = (dataUrl: string, filename: string) => {
      const arr = dataUrl.split(',');
      const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    };

    const uploadForm = new FormData();
    uploadForm.append('description', `${file.name}`);
    uploadForm.append('created_by_id', this.configData.adminUserId.toString());

    if (typeof file.content === 'string') {
      // content is a path to a file
      uploadForm.append('file', dataURLtoFile(file.content, file.name));
    } else {
      // content is a Blob of data
      uploadForm.append(
        'file',
        file.content,
        `filled-form-${this.configData.userStudyMilestoneId}-${this.configData.questionnaireId}`
      );
    }

    return this.milestoneService.uploadArtifacts(uploadForm, this.configData.userStudyMilestoneId);
  }

  /**
   * Navigate to previous page
   */
  public onBack() {
    this.router.navigateByUrl(this.configData.backUrl);
  }

  /**
   * format back to mm/dd/yyyy before submit
   * 2022-12-01 returns 12/01/2022
   */
  private reformatDate(dateStr: string): string {
    const splitDt = dateStr.split('-');
    return `${splitDt[1]}/${splitDt[2]}/${splitDt[0]}`;
  }

  private getKnownValue(name: string): any | undefined {
    return this.knownData.find((d) => d[0] === name);
  }
}
