<div class="container">
  <div class="row mb-3">
    <h2>{{ 'messages' | translate }}</h2>
  </div>

  <div class="row">
    <div class="col-12 mb-3">
      <button nbButton size="small" status="primary" class="align-button" (click)="navigateBack()">
        {{ 'backToInbox' | translate }}
      </button>
    </div>

    <nb-alert *ngIf="error" outline="danger" role="alert">
      <div class="alert-message-list">
        <span class="alert-message">{{ error }}</span>
      </div>
    </nb-alert>

    <div class="col-12 mb-3" *ngIf="!error">
      <nb-card class="border-start-0">
        <nb-card-body>
          <!-- From -->
          <div class="row mb-3">
            <div class="col-1">
              <div class="message-label">{{ 'lblFrom' | translate }}</div>
            </div>
            <div class="col-11 message-content">
              {{ message?.send_user_email }}
            </div>
          </div>

          <!-- To -->
          <div class="row mb-3">
            <div class="col-1">
              <div class="message-label">{{ 'lblTo' | translate }}</div>
            </div>
            <div class="col-11 message-content">
              {{ message?.recipient_contact }}
            </div>
          </div>

          <!-- Date -->
          <div class="row mb-3">
            <div class="col-1">
              <div class="message-label">{{ 'lblDate' | translate }}</div>
            </div>
            <div class="col-11 message-content">
              {{ message?.created_at | date: 'longDate' }}
            </div>
          </div>

          <!-- Message -->
          <div class="row mb-3">
            <div class="col-1"></div>
            <div class="col-11 message-content">
              {{ message?.message }}
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>
