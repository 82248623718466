import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IQuestionnaire } from '../../interfaces/questionnaire.interface';
import {
  IUserQuestionnaireRequest,
  IUserQuestionnaireResponse,
} from '../../interfaces/user-questionnaire.interface';
import { API_CONFIG } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepQuestionnaireService {
  private readonly endpoints = {
    questionnaire: '/api/v1/questionnaire',
    viewQuestionnaire: '/api/v1/questionnaire_response',
    userQuestionnaire: '/api/v1/user_questionnaire',
  };

  private readonly apiService = inject(PepApiService);
  private readonly apiConfig = inject(API_CONFIG);

  /**
   * Get questionnaire by id
   */
  public getQuestionnaireById(id: number) {
    return this.apiService.getSingle<IQuestionnaire>(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.questionnaire,
      id
    );
  }

  /**
   * Get all questionnaire based on the status
   */
  public getAllQuestionnaires(query: string): Observable<Array<IQuestionnaire>> {
    return this.apiService.getAll(
      this.apiConfig.surveyServiceBaseUrl,
      `${this.endpoints.questionnaire}/?${query}`
    );
  }

  /**
   * Get view questionnaire
   */
  public getViewQuestionnaire(id: number): Observable<IUserQuestionnaireResponse> {
    return this.apiService.getAll(
      this.apiConfig.surveyServiceBaseUrl,
      `${this.endpoints.viewQuestionnaire}/?userquestionnaireid=${id}`
    );
  }

  /**
   * Post questionnaire
   */
  public submitQuestionnaire(request: IUserQuestionnaireRequest) {
    return this.apiService.add(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.viewQuestionnaire,
      request
    );
  }

  public deleteQuestionnaire(questionnaireId: number): Observable<any> {
    return this.apiService.alternativeDelete(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.questionnaire,
      questionnaireId
    );
  }

  /**
   * Update the questionnaire
   */
  public updateQuestionnaire(questionnaire: IQuestionnaire): Observable<IQuestionnaire> {
    return this.apiService.update(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.questionnaire,
      questionnaire
    );
  }

  /**
   * create questionnaire
   */
  public createQuestionniare(questionnaire: IQuestionnaire): Observable<IQuestionnaire> {
    return this.apiService.add(
      this.apiConfig.surveyServiceBaseUrl,
      this.endpoints.questionnaire,
      questionnaire
    );
  }

    /***
   * get user questionnaire previous version
   */
    public getUserQuestionnaireLastVersion(userquestionnaireid: number, questionnaireId: number) {
      return this.apiService.getSingle<any>(
        this.apiConfig.surveyServiceBaseUrl,
        `${this.endpoints.viewQuestionnaire}/?userquestionnaireid=${userquestionnaireid}&questionnaireid=${questionnaireId}`
      );
    }
}
