import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OdsApiService } from './api/ods-api.service';
import { InputCreatePatientDm, InputUpdatePatientDm, PatientDm } from './api/ods-api.types';

/**
 * Adapter service to provide access to the ODS GraphQL API
 */
@Injectable({
  providedIn: 'root',
})
export class ParticipantDemographicsService {
  constructor(private odsApiService: OdsApiService) {}

  /**
   * Creates a new participant record
   *
   * @returns the newly created record
   */
  public create(participantDM: InputCreatePatientDm): Observable<PatientDm> {
    return this.odsApiService
      .createPatientDm({ input: participantDM })
      .pipe(map((result) => result?.data?.createPatientDM as PatientDm));
  }

  /**
   * Updates the participant record
   *
   * @returns the newly updated record
   */
  public update(participantDM: InputUpdatePatientDm): Observable<PatientDm> {
    return this.odsApiService
      .updatePatientDm({ input: participantDM })
      .pipe(map((result) => result?.data?.updatePatientDM as PatientDm));
  }

  /**
   * Fetch the Demographic data for the participant with database ID
   *
   * @param id database Id
   */
  public fetch(id: number): Observable<PatientDm> {
    return this.odsApiService.patientDm({ id }).pipe(map((result) => result?.data?.patientDM));
  }

  /**
   * Fetch the Demographic data for the participant with matching dmSubjID
   *
   * @param dmSubjID SubjectId of the participant to fetch
   */
  public fetchWithSubjId(dmSubjID: string): Observable<PatientDm> {
    return this.odsApiService
      .patientDMs({ queryParams: { dmSubjID } })
      .pipe(map((result) => result?.data?.patientDMs?.[0] as PatientDm));
  }

  /**
   * Fetch the Demographic data for all participant
   */
  public fetchAll(): Observable<PatientDm[]> {
    return this.odsApiService
      .patientDMs({ queryParams: {} })
      .pipe(map((result) => (result?.data?.patientDMs as PatientDm[]) || []));
  }
}
