import { InjectionToken } from '@angular/core';

/**
 * Token used by the Apps for injecting the ApiConfig
 */
export const API_CONFIG = new InjectionToken<IApiConfig>('ApiConfig');

/**
 * Required API endpoint definitions provided by the Application through the API_CONFIG injection token
 */
export interface IApiConfig {
  authApiUrl: string;
  consentServiceBaseUrl: string;
  languageServiceUrl: string;
  messageServiceUrl: string;
  studyManagementServiceUrl: string;
  studyTimepointsServiceUrl: string;
  surveyServiceBaseUrl: string;
  timepointsServiceUrl: string;
  userPreferenceServiceUrl: string;
  milestoneRulesServiceUrl: string;
  odsGraphQLUrl: string;
  studyManagementGraphQLUrl: string;
  paymentServiceUrl: string;
  paypalLoginConfig: any;
  paypalLoginScript: string;
}
