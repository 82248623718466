import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { catchError, of, Subscription, take } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getDeepFromObject, NbAuthResult, NB_AUTH_OPTIONS } from '@nebular/auth';
import { NbIconLibraries } from '@nebular/theme';
import {
  LOGO_URLS,
  PepToastNotificationService,
  PepUserService,
  RoleType,
  SVG_ICONS,
} from '@targetrwe/pep-shared';
import { TrweAuthService, TrweAuthStore } from './services';
import { TRWE_AUTH_MODULE_CONFIG } from './trwe-auth-module.config';

@Component({
  selector: 'trwe-auth',
  templateUrl: './trwe-auth.component.html',
  styleUrls: ['./trwe-auth.component.scss'],
})
export class TrweAuthComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly logo: string = LOGO_URLS.noTaglineColor;
  public subscription!: Subscription;
  public authenticated = false;
  public token = '';
  public redirectDelay = 0;
  public strategy = '';
  public submitted = false;
  public loginOrigin = '';

  private readonly authStore = inject(TrweAuthStore);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly authService = inject(TrweAuthService);
  private readonly iconLibraries = inject(NbIconLibraries);
  private readonly location = inject(Location);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly userService = inject(PepUserService);
  private paramsSubscription!: Subscription;
  private readonly authModuleSettings = inject(TRWE_AUTH_MODULE_CONFIG);

  constructor() {
    this.iconLibraries.registerSvgPack('target', SVG_ICONS);
    const nbAuthOptions = inject(NB_AUTH_OPTIONS);
    this.strategy = getDeepFromObject(nbAuthOptions, 'forms.validateExternalUser.strategy');

    window.addEventListener('message', this.handleMessage.bind(this), false);
  }

  ngAfterViewInit() {
    // Send "ready" message to portal when tab is loaded
    if (window.opener) {
      window.opener.parent.postMessage('ready', '*');
    }
  }

  ngOnDestroy() {
    window.opener = null;
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.paramsSubscription = this.activatedRoute.queryParams.subscribe((params) => {
      const e = params['act'];
      if (e) {
        this.validateToken(params);
      } else {
        /* if (!params['invite_code']) {
          const url = `/auth/login`;
          this.router.navigate([url]);
        } */
      }
    });
  }

  // Code for postMessage()
  public handleMessage(event: any) {
    if (event.data.act) {
      this.loginOrigin = event.origin;
      this.validateToken(event.data);
    }
  }

  public back() {
    this.location.back();
  }

  /**
   * Authenticate the passed token passed through params:
   * ```
   * {
   *  act: string; // token string to verify
   *  user: string; // user email
   * }
   * ```
   * @param params
   */
  public validateToken(params: Params): void {
    this.submitted = true;
    const accessToken = params['act'];

    this.authService
      .validateToken(this.strategy, accessToken)
      .subscribe((response: NbAuthResult): void => {
        this.submitted = false;

        if (response.isSuccess()) {
          this.toastService.showSuccessToast(response.getMessages()[0]);
        } else {
          // error logging in, so require another login
          this.toastService.showErrorToast(response.getErrors()[0]);
          this.router.navigateByUrl('/auth/login');
        }

        const redirect = response.getRedirect();
        if (redirect) {
          const emailToken =
            params['user'] || (response.getResponse().body.user.username || '').toLowerCase();

          // fetch the user profile for the logged in user using the passed emailToken
          this.userService
            .getUserByEmailId(emailToken)
            .pipe(
              catchError(() => {
                this.toastService.showErrorToast('Error getting user by email');
                return of(undefined);
              })
            )
            .subscribe((profile) => {
              if (profile) {
                this.authStore.setLoggedInUserFromUserProfile(profile);
                if (params['role']) {
                  const userRole = params['role'].replace(/_/g, '').toLowerCase();
                  this.authStore.loggedInUser$.pipe(take(1)).subscribe((user) => {
                    const activeRole = this.authService.getValidRole([userRole], user?.roles || []);

                    this.authStore.setActiveRole(activeRole);
                    if (userRole === 'studysiteadmin') {
                      setTimeout(
                        () => this.router.navigateByUrl('/admin/participant-management'),
                        this.redirectDelay
                      );
                    } else if (
                      this.authModuleSettings.appName === 'pep' &&
                      userRole === 'studymanager'
                    ) {
                      this.switchRole(RoleType.StudyManager);
                      setTimeout(
                        () => this.router.navigateByUrl('/study-manager/study-list'),
                        this.redirectDelay
                      );
                    } else if (
                      this.authModuleSettings.appName === 'iep' &&
                      userRole === 'studymanager'
                    ) {
                      this.switchRole(RoleType.StudyManager);
                      setTimeout(
                        () => this.router.navigateByUrl('/study-manager/participant-management'),
                        this.redirectDelay
                      );
                    } else if (userRole === 'hcp') {
                      this.switchRole(RoleType.Hcp);
                      setTimeout(
                        () => this.router.navigateByUrl('/study-manager/study-list'),
                        this.redirectDelay
                      );
                    } else if (userRole === 'patient') {
                      this.switchRole(RoleType.Patient);
                      setTimeout(
                        () => this.router.navigateByUrl('/engagement/studies'),
                        this.redirectDelay
                      );
                    } else {
                      setTimeout(
                        () => this.router.navigateByUrl('/unauthorized'),
                        this.redirectDelay
                      );
                    }
                  });
                } else {
                  setTimeout(() => this.router.navigateByUrl('/unauthorized'), this.redirectDelay);
                }
              }
            });
        }
      });
  }

  /**
   * set the currently active role to the one named if the user has that role. Otherwise, does nothing.
   * @param newRoleName name of the new role to make active
   */
  public switchRole(newRoleName: string): void {
    this.authStore.loggedInUser$.pipe(take(1)).subscribe((user) => {
      const role = user?.roles?.find(
        (role) => role.name.toLowerCase().replace(/\s/g, '') === newRoleName.toLowerCase()
      );

      if (role) {
        this.authStore.setActiveRole(role);
      }
    });
  }
}
