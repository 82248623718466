import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { catchError, map, of } from 'rxjs';
import {
  IArtifact,
  IPEPSurvey,
  IRegionLanguage,
  IStudySurvey,
  ISurveyPlayerConfig,
  ISurveyResponse,
  ISurveyResult,
  ISurveySubmitRequest,
  PesProConfig,
} from '../../interfaces';
import { PepMilestoneService } from '../../services';
import { PepArtifactsService } from '../../services/api-services/artifacts.service';
import { PepLanguageService } from '../../services/api-services/language.service';

import { PepStudySurveyService } from '../../services/api-services/study-survey.service';
import { PepPdfExportService } from '../../services/helper-services/pdf-export.service';
import { PepToastNotificationService } from '../../services/helper-services/toast-notification.service';
import { SurveyStatusEnum } from '../../types/survey-status.enum';
import { currencytoNumber, useObserver } from '../../utilities';
import { pepTranslate } from '../../utilities/translate';
import { PesCompensationModeComponent } from '../public-api';

/**
 * The completion status for the consent. used to display results
 */
type CompletionState = 'pending' | 'saving' | 'completed' | 'continue' | 'compensated';

@Component({
  selector: 'pes-pro-survey',
  templateUrl: './pro-survey.component.html',
  styleUrls: ['./pro-survey.component.scss'],
})
export class PesProSurveyComponent implements OnInit {
  public surveyData!: IPEPSurvey | undefined;
  public json!: IStudySurvey;
  public id!: number;
  public overlayOffset = 0;
  public languageList: IRegionLanguage[] = [];
  public language = '';
  public preferredLanguage = '';
  public totalCompletedSurveys = {
    completed: 0,
    total: 0,
    userStudyMilestoneId: 0,
    studySurveyMilestoneId: 0,
    isCompensated: false,
    amountPaid: 0,
    siteName: '',
    studyName: '',
    studySiteId: 0,
    totalDue: 0,
  };
  private readonly router = inject(Router);
  private readonly studySurveyService = inject(PepStudySurveyService);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly pdfExportService = inject(PepPdfExportService);
  private readonly artifactService = inject(PepArtifactsService);
  private readonly translate = pepTranslate();
  private readonly languageService = inject(PepLanguageService);
  private readonly milestoneService = inject(PepMilestoneService);
  protected readonly dialogService = inject(NbDialogService);

  // configuration data provided by Router on navigation
  private readonly configData = this.router.getCurrentNavigation()?.extras?.state as PesProConfig;
  public readonly completionState = useObserver<CompletionState>('pending');

  public get pageTitle() {
    return this.configData?.labels.pageTitle;
  }

  public get backButtonLabel() {
    return this.configData?.labels.backButtonLabel;
  }

  public get showLanguages() {
    return this.configData?.showLanguages;
  }

  private readonly proConfig = {
    userId: this.configData?.userId,
    userPreferredLanguage: this.configData?.userPreferredLanguage,
    userStudyMilestoneId: this.configData?.userStudyMilestoneId,
    studySurveyMilestoneId: this.configData?.studySurveyMilestoneId,
    pageTitle: this.configData?.labels.pageTitle,
    backButtonLabel: this.configData?.labels.backButtonLabel,
    backRoute: this.configData?.backRoute,
    showLanguages: this.configData?.showLanguages,
    studySiteId: this.configData?.studySiteId || 0,
  };

  public surveyConfig: ISurveyPlayerConfig | undefined;

  constructor() {}

  /**
   * Initialize the component
   */
  public ngOnInit(): void {
    if (!this.configData) {
      // no config data, so go to home page
      this.router.navigateByUrl('/');
      return;
    }
    this.initializeSurvey();
  }

  /**
   * Load all required data for the survey and display it
   */
  private initializeSurvey() {
    if (this.configData?.showLanguages) {
      this.languageService
        .getLanguages()
        .pipe(
          catchError(() => {
            // TODO standardize error
            this.toastService.showErrorToast('Error getting languages');
            return of([]);
          })
        )
        .subscribe((languageResponse: Array<IRegionLanguage>) => {
          if (languageResponse.length > 0) {
            this.languageList = languageResponse;
            this.language = languageResponse.find((language) => language.is_default)?.code || '';
            this.onGetNewLanguagePRO(this.language);
          }
        });
    } else {
      this.onGetNewLanguagePRO(this.configData.userPreferredLanguage);
    }
  }

  /**
   * Load the PRO for the newly selected language
   */
  public onGetNewLanguagePRO(language: string) {
    this.surveyConfig = undefined;
    this.studySurveyService
      .getMilestoneSurveyById(this.proConfig.studySurveyMilestoneId)
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast('Error getting survey');
          return of(undefined);
        })
      )
      .subscribe((response?: IStudySurvey) => {
        if (response) {
          this.json = response;
          this.surveyData =
            this.json.survey?.pro && Array.isArray(this.json.survey.pro)
              ? { pages: this.json.survey.pro }
              : this.json.survey?.pro;
          if (this.surveyData) {
            this.surveyConfig = {
              survey: this.surveyData,
              prefillResponses: {} as ISurveyResponse,
              readonly: false,
              preferredLanguage: language,
            };
          }
        }
      });
  }

  // data: { response: ISurveyResponse; status: SurveyStatusEnum }): void {
  public onSubmitSurvey(surveyResult: ISurveyResult): void {
    this.completionState.set('saving');
    const responses = surveyResult.responses;
    const request: ISurveySubmitRequest = {
      user_id: this.proConfig?.userId,
      study_survey_milestone_id: this.proConfig?.studySurveyMilestoneId,
      questions: responses,
      status: SurveyStatusEnum.COMPLETE,
      language_region_code: this.proConfig?.userPreferredLanguage?.split('-')[0],
    };
    this.studySurveyService
      .submitSurvey(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(
            error.error instanceof ProgressEvent ? error.statusText : error.error
          );
          return of(undefined);
        })
      )
      .subscribe((response: ISurveySubmitRequest) => {
        if (response) {
          // Successfully uploaded survey response.  Build PDF from survey
          // and upload it as an artifact
          this.completionState.set('completed');
          this.surveyData?.pages.forEach((page) => {
            page.elements.forEach((element) => {
              const userResponse = responses[element.name || ''];
              if (userResponse) {
                if (element.type === 'svgmap') {
                  element.paths = userResponse;
                } else {
                  element.defaultValue = userResponse;
                }
              }
            });
          });
          this.prepareBlob();
        }
      });
  }

  private getUserStudyMilestones(): void {
    this.milestoneService
      .getUserStudyMilestonesV1(this.proConfig.studySiteId, `&userid=${this.proConfig.userId}`)
      .pipe(
        map((milestones) => {
          return {
            completed:
              milestones.filter(
                (mile) => mile.status === 'COMPLETE' && mile.milestone_type_name === 'PRO'
              )?.length || 0,
            total:
              milestones.filter(
                (mile) => mile.status !== 'MISSED' && mile.milestone_type_name === 'PRO'
              )?.length || 0,
            nextMilestone: milestones.find(
              (mile) => mile.status === 'INCOMPLETE' && mile.milestone_type_name === 'PRO'
            ),
            isCompensated:
              milestones.find((mile) => mile.milestone_type_name === 'PRO')?.is_compensated ||
              false,
            siteName:
              milestones.find((mile) => mile.milestone_type_name === 'PRO')?.site_name || '',
            studySiteId:
              milestones.find((mile) => mile.milestone_type_name === 'PRO')?.study_site_id || 0,
            studyName:
              milestones.find((mile) => mile.milestone_type_name === 'PRO')?.study_name || '',
            amountPaid: milestones
              .filter((mile) => mile.milestone_type_name === 'PRO')
              .reduce(
                (previousValue, currentValue) =>
                  +previousValue +
                  +currencytoNumber(currentValue?.compensation_amount) -
                  +currencytoNumber(currentValue?.amount_paid),
                0
              ),
            totalDue: milestones
              .filter((mile) => mile.status === 'COMPLETE' && mile.is_compensated)
              .reduce(
                (previousValue, currentValue) =>
                  +previousValue +
                  +currencytoNumber(currentValue?.compensation_amount) -
                  +currencytoNumber(currentValue?.amount_paid),
                0
              ),
          };
        }),
        catchError(() => {
          this.toastService.showErrorToast('Error getting user study milestones');
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if (response) {
          this.totalCompletedSurveys = {
            completed: response.completed,
            total: response.total,
            userStudyMilestoneId: response.nextMilestone?.user_study_milestone_id || 0,
            studySurveyMilestoneId: response.nextMilestone?.study_survey_milestone_id || 0,
            isCompensated: response.isCompensated,
            amountPaid: response.amountPaid,
            siteName: response.siteName,
            studySiteId: response.studySiteId,
            studyName: response.studyName,
            totalDue: response.totalDue,
          };
          this.completionState.set(
            response.completed === response.total
              ? response.isCompensated
                ? 'compensated'
                : 'completed'
              : 'continue'
          );
        }
      });
  }

  /**
   * create the blob data from JSON
   */
  private prepareBlob(): void {
    if (this.surveyData) {
      this.pdfExportService
        .getSurveyBlob(
          this.surveyData,
          '',
          '',
          this.proConfig?.userPreferredLanguage?.split('-')[0]
        )
        .then((blobfile: string) => {
          this.uploadArtifact(blobfile);
        });
    }
  }

  /**
   * upload file/artifact into the S3 bucket
   *
   * @param blobfile: Blob data of the survey
   */
  public uploadArtifact(blobfile: string): void {
    const formData = new FormData();
    const filename = `${this.json.study_name}.pdf`;
    formData.append('description', `${filename}`);
    formData.append('file', blobfile, filename);
    this.artifactService
      .uploadArtifacts(formData, this.proConfig.userStudyMilestoneId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(
            error.error instanceof ProgressEvent ? error.statusText : error.error
          );
          return of(undefined);
        })
      )
      .subscribe((response?: IArtifact) => {
        if (response) {
          this.toastService.showSuccessToast(this.translate('surveySubmitted'));
          if (this.proConfig.studySiteId) {
            this.getUserStudyMilestones();
          } else {
            this.completionState.set('completed');
          }
        }
      });
  }

  /**
   * Navigate to previous page
   */
  public onBack(): void {
    this.router.navigateByUrl(this.proConfig.backRoute);
  }

  public onContinueToNext(): void {
    this.completionState.set('pending');
    this.proConfig.userStudyMilestoneId = this.totalCompletedSurveys.userStudyMilestoneId;
    this.proConfig.studySurveyMilestoneId = this.totalCompletedSurveys.studySurveyMilestoneId;
    this.onGetNewLanguagePRO(this.configData.userPreferredLanguage);
  }

  public onGetPaid(): void {
    this.dialogService
      .open(PesCompensationModeComponent, {
        context: {
          userId: this.configData?.userId,
          transferAmount: this.totalCompletedSurveys.amountPaid,
          isLinkedPro: true,
          paymentSites: [
            {
              studySiteId: this.totalCompletedSurveys.studySiteId,
              studyName: '',
              studySiteIds: [this.totalCompletedSurveys.studySiteId],
              siteName: this.totalCompletedSurveys.siteName,
              amount: this.totalCompletedSurveys.amountPaid,
              isForSurvey: true,
            },
          ],
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((paid: boolean) => {
        if (paid) {
          this.onDone();
        }
      });
  }

  public onDone(): void {
    this.router.navigateByUrl('/engagement/payment-summary');
  }
}
