<div class="form-group">
  <label *ngIf="label" for="{{ inputId }}" class="form-label">
    <span *ngIf="isRequired" class="required">*</span>
    <span>{{ label }}</span>
  </label>
  <textarea
    [rows]="rows"
    id="{{ inputId }}"
    nbInput
    fullWidth
    [fieldSize]="size"
    [readonly]="readonly"
    [formControl]="control"
    class="form-control"
    [class.is-invalid]="isInvalid"
  ></textarea>
  <pes-form-error
    data-testid="pes-form-error"
    *ngIf="isInvalid"
    [control]="control"
    [errors]="errors"
  ></pes-form-error>
</div>
