<pes-page-container title="{{ 'messagesInbox' | translate }}" alignment="left">
  <ng-container pesPageBody>

    <div class="row">
      <div class="col-md-12">
        <nb-alert *ngIf="error" outline="danger" role="alert">
          <ul class="alert-message-list">
            <li>{{ error }}</li>
          </ul>
        </nb-alert>
      </div>
    </div>

    <div *ngIf="!error">
      <button
        nbButton
        [disabled]="markedMessages.length === 0"
        size="tiny"
        status="primary"
        class="align-button"
        (click)="markAsUnread()"
      >
        <nb-icon icon="email"></nb-icon>Mark as unread
      </button>

      <div class="row mt-3">
        <div class="col">
          <table class="table table-hover bg-white w-100">
            <tbody>
              <tr class="align-middle" *ngFor="let message of messages; let i = index;"
              (mouseover)="isHover = i" [ngClass]="{'active' : isHover === i}" (mouseout)="isHover = null">
                <td class="border-bottom px-3">
                    <nb-checkbox
                      [(ngModel)]="message.checked"
                      (checkedChange)="toggleChecked($event, message)"
                    ></nb-checkbox>
                </td>
                <td class="message-text border-bottom py-2 pe-3" (click)="openMessage(message)">
                  <span class="text-font" [ngClass]="{ 'fw-bold': message.status === 'UNREAD' }">
                    {{ message.send_user_email }}
                  </span>
                  <div class="date-font pt-0">
                    {{ message.created_at | date: 'longDate' }}
                  </div>
                </td>
                <td (click)="openMessage(message)"
                  class="message-text border-bottom py-2 pe-3"
                  [ngClass]="{ 'fw-bold': message.status === 'UNREAD' }"
                >
                  {{ message.message }}
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>

  </ng-container>
</pes-page-container>
<div class="container">

