import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { catchError, of } from 'rxjs';
import { IOtpRequest } from '../../../interfaces/consent.interface';
import { PepConsentService } from '../../../services/api-services/consent.service';
import { PepToastNotificationService } from '../../../services/helper-services/toast-notification.service';
import { pepTranslate } from '../../../utilities';

@Component({
  selector: 'pes-consent-verification',
  templateUrl: './consent-verification.component.html',
  styleUrls: ['./consent-verification.component.scss'],
})
export class PepConsentVerificationModalComponent implements OnInit {
  @Input() public id!: number;
  @Input() public siteName!: string;
  @Input() public studyName!: string;
  public validConfirmation = false;
  public otp = new FormControl<string>('', { nonNullable: true, validators: Validators.required });
  public isValidated = false;
  public submitted = false;

  public readonly translate = pepTranslate();
  private readonly consentService = inject(PepConsentService);
  public readonly dialogRef = inject(NbDialogRef<PepConsentVerificationModalComponent>);
  private readonly toastService = inject(PepToastNotificationService);

  constructor() {}

  public ngOnInit(): void {
    this.validConfirmation = false;
  }

  /**
   * Validate consent using the One Time Password (OTP)
   * TODO - this code should be refactored.  return type doesn't make sense. Seems odd to use a GET to validate code
   */
  public onValidateOTP() {
    this.submitted = true;
    if (!this.otp.valid) {
      return;
    }

    this.validConfirmation = false;
    this.submitted = false;
    this.consentService
      .validateConsent(this.otp.value, this.id)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.validConfirmation = false;
          this.isValidated = false;
          const errorMsg =
            error.status === 401
              ? this.translate('consent_verification--invalid-code')
              : error.error;
          this.toastService.showErrorToast(errorMsg);
          return of(undefined);
        })
      )
      .subscribe((consentResponse) => {
        if (consentResponse) {
          this.validConfirmation = true;
          this.toastService.showSuccessToast(this.translate('consent_verification--success'));
          this.isValidated = true;
          setTimeout(() => {
            this.close();
          }, 1000);
        }
      });
  }

  public onResend(): void {
    this.isValidated = false;
    this.validConfirmation = false;
    const request: IOtpRequest = {
      response: {},
      user_study_consent_id: this.id,
    };
    this.getOTP(request);
  }

  private getOTP(request: IOtpRequest): void {
    this.consentService
      .resendVerificationCode(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.validConfirmation = false;
          this.toastService.showErrorToast(error.error);
          return of(undefined);
        })
      )
      .subscribe((verifyResponse) => {
        if (verifyResponse) {
          this.validConfirmation = true;
          this.toastService.showSuccessToast(this.translate('consent_verification--code-resent'));
        }
      });
  }

  public close(): void {
    this.dialogRef.close(this.isValidated);
  }
}
