import { IStudySiteUserStudyMilestone } from './milestone.interface';

export interface IParticipantStudyConsent {
  artifact_id: number;
  consent_corrected_date: string;
  consent_id: number;
  consent_language_code: string;
  consent_language_id: number;
  consent_language_status: string;
  consent_name: string;
  consent_protocol_no: string;
  consent_response: null;
  consent_response_id: number;
  consent_title: string;
  consent_version_no: string;
  content: string;
  participant_id: null;
  study_id: number;
  study_name: string;
  study_site_id: number;
  title: string;
  user_study_consent_id: number;
  user_study_consent_status: string;
  user_study_milestone_id: number;
  version_date: string;

  isLoading?: boolean;
  milestones?: IStudySiteUserStudyMilestone[];
  milestonesV1?: IStudySiteUserStudyMilestone[];
  opened?: boolean;

  missedMilestoneOpened?: boolean;
}
