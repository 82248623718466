import { DocumentStatusType } from '../types/app.types';
import { SurveyStatusEnum } from '../types/survey-status.enum';
import { IUserStudyMilestone } from './milestone.interface';
import { IPEPSurvey } from './pep-survey.interface';

export interface IStudySurvey {
  study_name: string;
  study_survey_milestone_id: number;
  id: number;
  status: SurveyStatusEnum;
  title?: string;
  user_id: number;
  user_study_milestone?: IUserStudyMilestone;
  user_study_milestone_id: number;
  survey?: IPro;
}

export interface IPro {
  id?: number;
  name?: string;
  pro?: IPEPSurvey;
  version?: string;
  duplicate?: boolean;
  study_id?: number;
  study_name?: string;
  status?: DocumentStatusType;
  language_region_code?: string;
}
