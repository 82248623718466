import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PepSpinnerService {
  private readonly loadingEventSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.loadingEventSubject.asObservable();

  constructor() {}

  public show() {
    this.loadingEventSubject.next(true);
  }

  public hide() {
    this.loadingEventSubject.next(false);
  }
}
