import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, of, tap } from 'rxjs';
import { IMilestoneType } from '../../interfaces';
import { API_CONFIG } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepDropdownsService {
  private milestoneStats!: string[];
  private enrollmentStats!: string[];
  private milestoneTypes!: IMilestoneType[];

  private readonly endpoints = {
    userStudyMilestones: '/api/v2/user_study_milestone',
    userConsent: '/api/v1/user_study_consent',
    milestoneTypes: '/api/v1/milestone_type/',
  };

  private readonly idReg = /{id}/;
  private readonly apiService = inject(PepApiService);
  private readonly apiConfig = inject(API_CONFIG);

  constructor() {}

  /**
   * Get milestone statuses
   */
  public getMilestoneStatus() {
    if (this.milestoneStats) {
      return of(this.milestoneStats);
    } else {
      return this.apiService
        .getAll<string[]>(
          this.apiConfig.studyManagementServiceUrl,
          `${this.endpoints.userStudyMilestones}/?status=true`
        )
        .pipe(
          tap({
            next: (response: string[]) => (this.milestoneStats = response),
            error: (err: HttpErrorResponse) => of(new Error(err.message)),
          }),
          map((response) => response)
        );
    }
  }

  /**
   * Get all enrollment statuses
   */
  public getEnrollmentStatus() {
    if (this.enrollmentStats) {
      return of(this.enrollmentStats);
    } else {
      return this.apiService
        .getAll<string[]>(
          this.apiConfig.studyManagementServiceUrl,
          `${this.endpoints.userConsent}/?status=true`
        )
        .pipe(
          tap({
            next: (response: string[]) => (this.enrollmentStats = response),
            error: (err: HttpErrorResponse) => of(new Error(err.message)),
          }),
          map((response) => response)
        );
    }
  }

  /**
   * Get all milestone types
   */
  public getMilestoneTypes() {
    if (this.milestoneTypes) {
      return of(this.milestoneTypes);
    } else {
      return this.apiService
        .getAll<IMilestoneType[]>(
          this.apiConfig.studyManagementServiceUrl,
          this.endpoints.milestoneTypes
        )
        .pipe(
          tap({
            next: (response: IMilestoneType[]) => (this.milestoneTypes = response),
            error: (err: HttpErrorResponse) => of(new Error(err.message)),
          }),
          map((response) => response)
        );
    }
  }
}
