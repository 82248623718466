<div class="header-row">
  <div
    class="me-4"
    [class.header-logo-collapsed]="!sideExpanded"
    [class.header-logo-expanded]="sideExpanded"
  ></div>
  <a class="toggle" (click)="onToggleSidebar()">
    <nb-icon [icon]="!sideExpanded ? 'menu-outline' : 'close-outline'"></nb-icon>
  </a>
  <span class="header-text">
    {{ appHeader }}
  </span>
  <div class="spacer"></div>
  <div>
    <nb-actions size="small">
      <nb-action *ngIf="isStudyManager" class="control-item">
        <span (click)="onApplicationNavigation()" class="navigate-app"
          ><img />{{ 'applicationNavigateLabel' | translate }}</span
        >
      </nb-action>

      <nb-action
        data-test="open-messages"
        class="control-item"
        icon="email-outline"
        [nbPopover]="messagePopup"
        [nbPopoverContext]="{ loggedInUser: user }"
        nbPopoverPlacement="bottom"
      >
      </nb-action>
      <nb-action data-test="user-actions" class="user-action">
        <nb-user
          [nbContextMenu]="userMenu"
          nbContextMenuTag="userMenu-context"
          [onlyPicture]="userPictureOnly"
          [name]="userName"
          [title]="user.title"
        >
        </nb-user>
      </nb-action>
    </nb-actions>
  </div>
</div>
