import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { pepTranslate } from '@targetrwe/pep-shared';
import { catchError, of } from 'rxjs';
import { TrweAuthService } from '../../services';

@Component({
  selector: 'trwe-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class TrweVerificationComponent implements OnInit {
  public verifyForm = this.createForm();
  public error = '';
  public submitted = false;
  private email = '';
  public validConfirmation = false;

  public readonly translate = pepTranslate();
  private readonly authService = inject(TrweAuthService);
  private readonly router = inject(Router);

  // configuration data provided by Router on navigation
  private readonly state = this.router.getCurrentNavigation()?.extras?.state;

  private createForm() {
    const fb = inject(NonNullableFormBuilder);
    return fb.group({
      confirmation_code: ['', Validators.required],
      username: '',
    });
  }

  constructor() {}

  public ngOnInit(): void {
    if (this.state) {
      this.email = this.state['email'];
    }
  }

  public onVerifyUser(): void {
    this.verifyForm.controls.username.setValue(this.email.toLowerCase());
    this.submitted = true;
    if (this.verifyForm.invalid) {
      return;
    } else {
      // continue to post validate User
      this.authService
        .signupConfirmation(
          this.verifyForm.value as { confirmation_code: string; username: string }
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.submitted = false;
            if (error.error instanceof ProgressEvent) {
              this.error = error.statusText;
            } else {
              this.error = error.error.error;
            }
            return of(undefined);
          })
        )
        .subscribe((confirmationResponse) => {
          if (confirmationResponse) {
            this.validConfirmation = true;
          }
        });
    }
  }

  public onSignIn(): void {
    this.router.navigateByUrl('/auth/login');
  }
}
