<pes-page-container title=" {{ 'contactUs' | translate }}">
  <ng-container pesHeaderButtons>
    <div class="col d-flex justify-content-end">
      <button nbButton (click)="onBack()" class="me-2">
        <i class="fa-solid fa-arrow-left me-1 mt-1"></i>{{ 'back' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container pesPageBody>
    <p class="mb-5 col-8">
      {{ 'contact-us-need' | translate }}
    </p>
    <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-4">
          <pes-form-select
            placeholder="{{ 'selectCategory' | translate }}"
            [control]="contactUsForm.controls.category"
            label="{{ 'category' | translate }}"
            [options]="categoryList"
          ></pes-form-select>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-8">
          <pes-form-text-area
            [control]="contactUsForm.controls.comment"
            label="{{ 'comment' | translate }}"
          ></pes-form-text-area>
        </div>
      </div>
      <div class="row mt-3">
        <div
          class="col-8 color-red"
          [innerHTML]="'contact-us-only-technical-issues' | translate"
        ></div>
      </div>

      <div class="row my-4">
        <div class="col-12">
          <button
            class="me-2"
            type="button"
            nbButton
            outline
            status="basic"
            (click)="onClear()"
            data-test="cancel"
          >
            <i class="fas fa-times me-1 mt-1"></i> {{ 'clear' | translate }}
          </button>

          <button type="submit" status="primary" nbButton [disabled]="isFormInvalid">
            <i class="fa-regular fa-circle-check me-1 mt-1"></i> {{ 'submit' | translate }}
          </button>
        </div>
      </div>
      <div class="row my-4" *ngIf="isSuccess">
        <div class="col-8 d-flex justify-content-center align-items-center contact-us-success-note">
          <nb-icon class="me-3" pack="target" icon="enrolled"></nb-icon>
          <span>{{ 'contact-us-success-note' | translate }}</span>
        </div>
      </div>
    </form>
  </ng-container>
</pes-page-container>
