export interface UserStudyConsent {
  artifact_id: number;
  comment: string;
  consent_language_id: number;
  id: number;
  reviewer_id: number;
  site_admin_id: number;
  status: string;
  study_consent_id: number;
  user_id: number;
  user_study_milestone_id: number;
}
