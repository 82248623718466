import { Component, Input, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';

@Component({
  providers: [],
  selector: 'pes-app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
})
export class PesAppSidebarComponent implements OnInit {
  @Input() menu!: Array<NbMenuItem>;
  private readonly availabeRoutesSubject = new BehaviorSubject<NbMenuItem[]>([]);
  public readonly availableRoutes$ = this.availabeRoutesSubject;

  constructor() {}

  public ngOnInit(): void {
    this.availabeRoutesSubject.next(this.menu);
  }
}
