import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';

import { catchError, forkJoin, from, map, of, switchMap, throwError } from 'rxjs';
import { PepConsentVerificationModalComponent } from '../../components';
import {
  IConsent,
  IConsentRequest,
  IPEPSurvey,
  IStudySite,
  ISurveyPlayerConfig,
  ISurveyResponse,
  ISurveyResult,
  PesPatientConsentConfig,
} from '../../interfaces';
import {
  InputConsentFld,
  InputCreateConsent,
  ParticipantConsentService,
  PatientSubject,
  PatientSubjectService,
  PepConsentService,
  PepLanguageService,
  PepMilestoneService,
  PepPdfExportService,
  PepSiteService,
  PepToastNotificationService,
} from '../../services';
import { ConsentStatusEnum, RoleType } from '../../types';
import { useObserver } from '../../utilities';

// Type of Consent associated with the ODS field changes
// TODO - are these correct? What is ODS expecting?
// eslint-disable-next-line no-shadow
enum OdsConsentType {
  INITIAL = 'initial',
  RECONSENT = 'reconsent',
}

/**
 * Used to define a selectable Consent by language
 */
interface UIConsentLanguage {
  consent_language_id: number;
  language_code: string;
  language_description: string;
  is_default: boolean;
}

/**
 * The completion status for the consent. used to display results
 */
type CompletionState =
  | 'pending'
  | 'saving'
  | 'userCompleted'
  | 'userVerified'
  | 'adminApproved'
  | 'userConsentFailed'
  | 'adminApprovalFailed';

@Component({
  selector: 'pes-patient-consent',
  templateUrl: './patient-consent.component.html',
  styleUrls: ['./patient-consent.component.scss'],
})
export class PesPatientConsentComponent implements OnInit {
  public overlayOffset = 0;

  private studySite: IStudySite | undefined;

  public consentLanguages: UIConsentLanguage[] = [];
  public selectedConsentLanguage: UIConsentLanguage | undefined;
  private consentDetails: IConsent | undefined;
  private signaturekey: string[] = [];

  public location = ''; // description of where the consent was completed by participant
  public dateTime = ''; // date/time when the consent was completed by participant

  // JSON to PDF Code Changes
  public survey: IPEPSurvey | undefined;
  public existingResponseData: ISurveyResponse = {};

  public surveyConfig: ISurveyPlayerConfig | undefined;

  private readonly router = inject(Router);
  private readonly consentService = inject(PepConsentService);
  private readonly languageService = inject(PepLanguageService);
  private readonly milestoneService = inject(PepMilestoneService);
  private readonly participantConsentService = inject(ParticipantConsentService);
  private readonly patientSubject = inject(PatientSubjectService);
  private readonly pdfExportService = inject(PepPdfExportService);
  private readonly siteService = inject(PepSiteService);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly dialogService = inject(NbDialogService);
  private readonly configData = this.router.getCurrentNavigation()?.extras
    ?.state as PesPatientConsentConfig;

  public readonly completionState = useObserver<CompletionState>('pending');

  /**
   * translated labels used in the UI
   */
  public get labels() {
    return this.configData?.labels || {};
  }

  /**
   * Returns the Action to perform with this Consent ('view' | 'approve' | 'reaffirm' | 'complete')
   */
  public get action() {
    return this.configData.action;
  }

  constructor() {}

  ngOnInit() {
    if (!this.configData) {
      // no config data, so go to home page
      this.router.navigateByUrl('/');
      return;
    }

    this.initializeSurvey();
  }

  /**
   * Load all required data for the survey and display it
   */
  private initializeSurvey() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location = `Latitude: ${position.coords.latitude} Longitude: ${position.coords.longitude}`;
      });
    }

    const byCode = (code: string) => (lang: { code: string }) => lang.code === code;
    const byLanguageCode = (code: string) => (lang: { language_code: string }) =>
      lang.language_code === code;

    // get list of languages
    forkJoin([
      this.languageService.getLanguages(),
      this.consentService.getConsentLanguages(this.configData.studyConsentId),
    ])
      .pipe(
        map(([availableLanguages, consentLanguages]) => {
          const uiLanguages = consentLanguages
            .filter((lang) => availableLanguages.find(byCode(lang.language_region_code)))
            .map((lang) => {
              const al = availableLanguages.find(byCode(lang.language_region_code));
              return {
                consent_language_id: lang.id,
                language_code: lang.language_region_code,
                language_description: al?.description || lang.language_region_code,
                is_default: !!al?.is_default,
              } as UIConsentLanguage;
            });

          return uiLanguages;
        }),
        catchError((error) => {
          this.toastService.showErrorToast(error, this.labels?.failedToLoad);
          return of([] as UIConsentLanguage[]);
        })
      )
      .subscribe((consentLanguages) => {
        this.consentLanguages = consentLanguages;

        // select the initial language
        this.selectedConsentLanguage =
          this.consentLanguages.find(byLanguageCode(this.configData.initialLanguage)) ||
          this.consentLanguages.find((lang) => lang.is_default) ||
          this.consentLanguages[0];

        this.onLanguageChanged(this.selectedConsentLanguage);
      });
  }

  /**
   * Load the Consent for the newly selected language
   */
  public onLanguageChanged(selection: UIConsentLanguage) {
    this.loadConsentForLanguage(this.configData.userStudyConsentId, selection.language_code)
      .pipe(
        catchError((error) => {
          this.toastService.showErrorToast(
            error,
            `consent_id: ${selection.consent_language_id} - language_code: ${selection.language_code}`
          );
          return of([] as UIConsentLanguage[]);
        })
      )
      .subscribe();
  }

  /**
   * Load the Consent for the specified language and update the UI with it
   */
  private loadConsentForLanguage(studyConsentId: number, language: string) {
    this.survey = undefined;
    this.surveyConfig = undefined;

    return this.consentService.getConsentById(studyConsentId, language).pipe(
      // TODO - this type casting is incorrect!!!
      map((response) => response?.consent_details),
      switchMap((consentDetails) => {
        if (!consentDetails) {
          return throwError(() => this.labels?.failedToLoad);
        }
        this.consentDetails = consentDetails;
        return this.siteService.getStudySite(consentDetails?.study_site_id);
      }),
      map((studySite) => {
        this.studySite = studySite;
        this.survey = this.initSurvey(this.consentDetails);
        if (this.survey) {
          // is there an existing response to the survey? If so, save it
          this.existingResponseData = this.consentDetails?.consent_response || {};
          this.dateTime = this.existingResponseData['consent_date_time']?.toString() || '';
          this.location = this.existingResponseData['location']?.toString() || '';

          // for mobile signature, Web need to append data:image/png;base64
          if (Object.keys(this.existingResponseData).length) {
            this.signaturekey.forEach((key) => {
              const signaturepad = this.existingResponseData[key] as string;
              if (signaturepad && !signaturepad.includes('data:image/png;base64')) {
                this.existingResponseData[key] =
                  'data:image/png;base64,' + this.existingResponseData[key];
              }
            });
          }
          this.surveyConfig = {
            survey: this.survey,
            readonly: this.configData.action == 'view',
            prefillResponses:
              Object.keys(this.existingResponseData).length > 0
                ? this.existingResponseData
                : undefined,
          };
        }
      })
    );
  }

  /**
   * Handle the user completing the Consent form
   * @param surveyResult key/values for all questions in the consent
   */
  public onSurveyCompleted(surveyResult: ISurveyResult) {
    this.completionState.set('saving');

    this.saveConsent(surveyResult)
      .pipe(
        catchError((error) => {
          this.toastService.showErrorToast(error, 'Error Saving Consent');
          this.completionState.set(
            this.action === 'approve' ? 'adminApprovalFailed' : 'userConsentFailed'
          );

          return of(false);
        })
      )
      .subscribe((success) => {
        if (success) {
          this.toastService.showSuccessToast(this.labels?.consentSaved);
          if (this.configData.action === 'approve') {
            // after approving, navigate back
            this.completionState.set('adminApproved');
            this.router.navigateByUrl(this.configData.backUrl);
          } else {
            this.completionState.set('userCompleted');
          }
        }
      });
  }

  /**
   * Ask user to enter verification code for the Consent
   */
  public onVerifyConsent(): void {
    this.dialogService
      .open(PepConsentVerificationModalComponent, {
        context: {
          id: this.configData.userStudyConsentId,
          studyName: this.labels?.studyName,
          siteName: this.labels?.siteName,
        },
      })
      .onClose.subscribe((data) => {
        if (data) {
          this.completionState.set('userVerified');
        }
      });
  }

  /**
   * Initialize the Survey from the consent
   */
  private initSurvey(consentDetails: IConsent | undefined) {
    if (!consentDetails) {
      // Must have a consentDetails to initialize with
      return undefined;
    }

    let survey: IPEPSurvey | undefined = undefined;
    try {
      /* 'XHAE=' +  */
      survey = JSON.parse(consentDetails?.content) as IPEPSurvey;
    } catch (err) {
      // error parsing the consent survey, so can't do anything with it
      this.toastService.showErrorToast(`${err}`, this.labels.failedToLoad);
      return undefined;
    }

    // if approving the survey, then set all elements to read-ony except the StudySiteAdmin signatur
    const isAdminAproval = this.configData.action === 'approve';
    survey?.pages?.forEach((page) => {
      page?.elements?.forEach((element, index) => {
        element.readOnly = isAdminAproval;

        // special processing for admin name field
        if (element.name === 'admin_name') {
          element.visible = isAdminAproval;
          element.readOnly = !isAdminAproval;
        }

        // special processing for signature pad elements
        if (element.type === 'signaturepad') {
          // TODO - role is set in the JSON, not in the Survey Editor! This is bad! We need to
          // improve this.  It is also not set for the Patient
          if (element.role === RoleType.StudySiteAdmin) {
            element.visible = isAdminAproval;
            element.readOnly = !isAdminAproval;
          }
          this.signaturekey[index] = element.name || '';
        }
      });
    });

    return survey;
  }

  /**
   * Approve/Submit the consent
   */
  private saveConsent(result: ISurveyResult) {
    this.existingResponseData = result.responses;
    const action$ =
      this.configData.action === 'approve'
        ? this.approveConsent(result, this.configData.adminUserId)
        : this.submitConsent(result, this.configData.adminUserId);

    return action$;
  }

  /**
   * Save the current consent as a PDF
   */
  public onDownloadPDF() {
    if (this.survey) {
      const title = 'consent_pdf_' + this.labels?.studyName + '.pdf';
      this.pdfExportService.savePDF(this.survey, title, this.existingResponseData);
    }
  }

  /**
   * Navigate back to the previous page
   */
  public onBack() {
    this.router.navigateByUrl(this.configData.backUrl);
  }

  /**
   * Submits the Consent as a participant
   * @returns Observable that emits true if successfully saved, else emits false
   */
  private submitConsent(result: ISurveyResult, adminUserId: number) {
    const request: IConsentRequest = {
      consent_language_id: this.selectedConsentLanguage?.consent_language_id, // selected language code
      response: {
        ...result?.responses,
        location: this.location,
        consent_date_time: result?.completedAt,
      },
      user_study_consent_id: this.configData?.userStudyConsentId,
      site_admin_id: adminUserId,
      user_id: this.configData.userId,
    };
    return this.consentService.submitConsentResponse(request).pipe(map((result) => !!result));
  }

  /**
   * Approve and save the consent
   * @returns Observable that emits true if successfully saved, else emits false
   */
  private approveConsent(result: ISurveyResult, adminUserId: number) {
    // Approving the Consent
    const request: IConsentRequest = {
      id: this.consentDetails?.consent_response_id,
      response: {
        ...result.responses,
      },
      status: ConsentStatusEnum.ENROLLED,
      user_id: this.configData.userId,
      user_study_consent_id: this.configData?.userStudyConsentId,
      invite_code: this.studySite?.invite_code,
      site_admin_id: adminUserId,
    };

    // update ODS Consent data
    return this.consentService.approveConsent(request).pipe(
      switchMap((approvalResponse) => {
        // Generate a PDF version of the consent using the responses used to approve it
        // This is done by MODIFYING the origin survey data that was loaded and ensuring
        // it contains the filled in data.
        const approvedData = result.responses;
        this.survey?.pages.forEach((page) => {
          page?.elements?.forEach((element) => {
            element.readOnly = true;
            let data = element.name ? approvedData[element.name] : undefined;
            if (data) {
              if (
                element.type === 'signaturepad' &&
                !(data as string).includes('data:image/png;base64')
              ) {
                data = 'data:image/png;base64,' + data;
              }
              if (element.type === 'svgmap') {
                element.paths = data;
              } else {
                element.defaultValue = data;
              }
            }
          });
        });
        const filename = `${this.consentDetails?.consent_title}.pdf`;
        return forkJoin([
          of(approvalResponse),
          this.uploadArtifact(
            this.survey as IPEPSurvey,
            filename,
            adminUserId,
            this.dateTime,
            this.location
          ),
        ]);
      }),
      switchMap(([approvalResponse, _artifactResult]) => {
        if (!approvalResponse) {
          return of(false);
        }
        return this.saveOdsData(approvalResponse);
      })
    );
  }

  /**
   * Upload the survey as a PDF artifact
   */
  private uploadArtifact(
    survey: IPEPSurvey,
    filename: string,
    adminUserId: number,
    dateTime?: string,
    location?: string
  ) {
    return from(this.pdfExportService.getSurveyBlob(survey, dateTime, location)).pipe(
      switchMap((blobfile: string) => {
        const formData = new FormData();
        formData.append('description', `${filename}`);
        formData.append('created_by_id', adminUserId.toString() || '');
        formData.append('file', blobfile, filename);
        return this.milestoneService.uploadArtifacts(
          formData,
          this.consentDetails?.user_study_milestone_id || 0
        );
      }),
      catchError((err) => {
        this.toastService.showErrorToast(err, 'Error uploading artifact');
        return of(undefined);
      })
    );
  }

  /**
   * Write the response data to ODS
   */
  private saveOdsData(approvalResponse: IConsentRequest) {
    // Keys to ignore from Consent Data when updating ODS Consent data
    const ignoreKeys = [
      'patientSignature',
      'patientPrintedName',
      'siteAdminSignature',
      'consent_date_time',
      'location',
    ];

    const fields: InputConsentFld[] = Object.entries(approvalResponse.response)
      .filter(([key, _]) => {
        const validKey = !ignoreKeys.includes(key) && !key.startsWith('question');
        return validKey;
      })
      .map(([key, value]) => ({ fldName: key, fldValue: `${value}` }));

    const startDt = this.consentDetails?.consent_corrected_date
      ? new Date(this.consentDetails.consent_corrected_date)
      : undefined;
    const versionDt = this.consentDetails?.version_date
      ? new Date(this.consentDetails.version_date)
      : undefined;

    // call api to fetch the dmSubjId/participant_id
    return this.patientSubject
      .getPatientSubject(approvalResponse.user_id || 0, this.studySite?.site_reference || '')
      .pipe(
        map((subject: PatientSubject) => subject?.participantID),
        switchMap((dmSubjID) => {
          if (!dmSubjID) {
            // failed to get a dmSubjId for the user, so can't continue.
            return throwError(() => new Error('Could not get participant_id/dmSubjId'));
          }

          // Update ODS with consent data
          const consentInput: InputCreateConsent = {
            consentType: `${
              this.consentDetails?.user_study_consent_status ===
              ConsentStatusEnum.RECONSENT_PENDING_APPROVAL
                ? OdsConsentType.RECONSENT
                : OdsConsentType.INITIAL
            }`,
            dmSubjID,
            flds: fields,
            protocol: this.consentDetails?.consent_protocol_no || '',
            startDt,
            studySiteID: this.consentDetails?.study_site_id,
            version: this.consentDetails?.consent_version_no || '',
            versionDt,
            studyStartDate: this.studySite?.study.study_start || '',
            studyEndDate: this.studySite?.study.study_end || '',
          };
          return this.participantConsentService.create(consentInput);
        })
      );
  }
}
