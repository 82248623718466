export const svgColors = [
  '#78C73D',
  '#FCED21',
  '#F5D822',
  '#EFC324',
  '#E9AE25',
  '#E39926',
  '#DD8427',
  '#D66F29',
  '#D05A2A',
  '#CA452B',
  '#C4302C',
];
