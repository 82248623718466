<div class="container">
  <div class="row mb-3">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <div class="text-start">
        <header class="page-header">
          <h1 class="page-title">{{ pageTitle }}</h1>
        </header>
      </div>
      <div>
        <button class="float-right" nbButton status="primary" (click)="back()">
          {{ backButtonLabel }}
        </button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="surveyConfig">
    <div class="col">
      <pes-survey-player
        style="--question-header-align: left; --question-margin-bottom: 0"
        [surveyConfig]="surveyConfig"
        (surveyCompleted)="onSubmitSurvey($event)"
      ></pes-survey-player>
    </div>
  </div>
</div>
