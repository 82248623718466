import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Factory function that returns a function which instantly translate the `key` in
 * current Translation language
 * @param key translation key
 * @param interpolateParams extra parameters to use for interpolation
 * @returns translated string or `key` if no translation available
 * @note can only be called in constructor/property initializers in an injection context
 */
export const pepTranslate = () => {
  const translateService = inject(TranslateService);
  return (key: string, interpolateParams?: Record<string, unknown>): string => {
    return translateService?.instant(key, interpolateParams) || key;
  };
};
