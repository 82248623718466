export interface IArtifact {
  created_at: string;
  created_by_id: number;
  deleted_at: string;
  description: string;
  external_filename: string;
  id: number;
  storage_key: string;
  user_study_milestone_id: number;
  comment?: string;
  activity_date?: string;
  artifact_type_id?: number;
  artifact_type?: string;
}

export interface IArtifactType {
  created_at: string;
  deleted_at: string;
  type: string;
  id: number;
}
