import { toDateStr } from './toDateStr';

/**
 * Wrap an an Object dictionary to make extracting key values as specific types easier
 *
 * You may provide an `altData` object that will be used if the requested key does
 * not exist in the primary `data` object.  Likewise, each function can accept
 * a `_default` parameter to use if the key is not found in either the `data` or
 * `altData` objects
 *
 * @param data object dictionary to wrap
 * @param altData a secondary object to use if key not found in data
 */
export const valueExtractor = (
  data: Record<string, unknown>,
  altData?: Record<string, unknown>
) => {
  const _data = data;
  const _altData = altData;

  const asString = (key: string, _default?: string) => {
    const d = _data?.[key] || _altData?.[key];
    return d ? (d as string) : _default;
  };

  const asDate = (key: string, _default?: Date) => {
    const d = _data?.[key] || _altData?.[key];
    return d ? new Date(d as Date | string) : _default ? new Date(_default) : undefined;
  };

  const asNumber = (key: string, _default?: number) => {
    const d = _data?.[key] || _altData?.[key];
    return d && !Number.isNaN(d) ? Number(d) : _default;
  };

  const asDateStr = (key: string, _default?: string) => {
    const d = _data?.[key] || _altData?.[key];
    return d ? toDateStr(d as Date | string) : _default;
  };

  return {
    get: (key: string) => _data?.[key] || _altData?.[key],
    asDateStr,
    asString,
    asDate,
    asNumber,
  };
};
