import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { PepSpinnerService } from '../../services';

@Component({
  selector: 'pes-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss'],
})
export class PesSpinnerComponent {
  public isLoading$ = new Observable<boolean>();
  private readonly spinnerService = inject(PepSpinnerService);

  constructor() {
    this.isLoading$ = this.spinnerService.isLoading$;
  }
}
