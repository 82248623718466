/**
 * Configuration state data passed in through the Router. Required to initialize
 * the Survey page
 */
export interface PesSiteQuestionnaireConfig {
  adminUserId: number;
  participantUserId: number;
  participantEmail: string;
  dmSubjId: string;
  questionnaireId: number;
  userStudyMilestoneId: number;
  timepoint: string;
  studyId: number;
  studySiteId: number;
  userQuestionnaireId: number;
  backUrl: string;
  form_type: string;

  labels: {
    pageTitle: string;
    questionnaireSaved: string;
    errorGettingQuestionnaire: string;
    errorSaving: string;
    errorSavingQuestionnaire: string;
    errorSavingDemographics: string;
    errorSavingDiagnoses: string;

    backBtn: string;
    completeBtn: string;
  };
}
