import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISort } from '../../interfaces';

@Component({
  selector: 'pes-app-sorting',
  templateUrl: './app-sorting.component.html',
  styleUrls: ['./app-sorting.component.scss'],
})
export class PesAppSortingComponent {
  // sorting object
  @Input() public sorting!: ISort;
  // Key where to apply the sorting
  @Input() public orderBy!: string;
  @Output() public sortingChange = new EventEmitter();

  constructor() {}

  public onSorting() {
    try {
      if (this.sorting) {
        const order = this.sorting.order === 'desc' ? 'asc' : 'desc';
        if (this.sorting.orderBy === this.orderBy) {
          this.sorting.order = order;
        } else {
          this.sorting.orderBy = this.orderBy;
          this.sorting.order = 'asc';
        }
      } else {
        this.sorting = {
          order: 'asc',
          orderBy: this.orderBy,
        };
      }
      this.sortingChange.emit(Object.assign({}, this.sorting));
    } catch (e) {
      console.log(e);
    }
  }

  public get showAscOrder(): boolean {
    return this.sorting?.order === 'asc' && this.sorting?.orderBy === this.orderBy;
  }

  public get showDescOrder(): boolean {
    return this.sorting?.order === 'desc' && this.sorting?.orderBy === this.orderBy;
  }

  public get showNoOrder(): boolean {
    return this.sorting?.orderBy !== this.orderBy;
  }
}
