import { Inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import {
  ICancelConsentRequest,
  IConsent,
  IConsentAction,
  IConsentLanguage,
  IConsentRequest,
  IConsentResponse,
  IEnrollmentLog,
  ILatestConsent,
  IOtpRequest,
  StudyConsent,
} from '../../interfaces';
import { IParticipantStudyConsent } from '../../interfaces/participant-study.interface';
import { API_CONFIG, IApiConfig } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepConsentService {
  private readonly endpoints = {
    consent: '/api/v1/consent',
    userConsent: '/api/v1/user_study_consent',
    consentById: '/api/v1/user_study_consent/{id}/response',
    consentByLanguages: '/api/v1/consent_language',
    consentResponse: '/consentresponse',
    validate: '/validate',
    resend: '/api/v1/otp',
    consentByUser: '/user_study_consent_by_user',
    latestUserConsent: '/api/v1/reaffirm',
    consentLogs: '/api/v1/enrollment_logs',
    reconsent: '/api/v1/reconsent/{id}',
  };

  private readonly idReg = /{id}/;

  constructor(
    @Inject(API_CONFIG) private apiConfig: IApiConfig,
    private apiService: PepApiService
  ) {}

  /**
   * Get all user consents by user id
   */
  public getUserStudiesWithStatus(userId: number): Observable<Array<IParticipantStudyConsent>> {
    return this.apiService.getAll(
      this.apiConfig.consentServiceBaseUrl,
      `${this.endpoints.consentByUser}/${userId}`
    );
  }

  /**
   * Get consent by user_study_consent_id and language region code
   */
  public getConsentById(id: number, language: string): Observable<IConsentResponse> {
    // replace id placeholders in config

    const urlReplaced = this.endpoints.consentById.replace(this.idReg, `${id}`);

    return this.apiService.getAll(
      this.apiConfig.consentServiceBaseUrl,
      `${urlReplaced}?languageregioncode=${language}`
    );
  }

  /**
   * Get all consent languages by consent id
   */
  public getConsentLanguages(id: number): Observable<Array<IConsentLanguage>> {
    return this.apiService.getAll(
      this.apiConfig.consentServiceBaseUrl,
      `${this.endpoints.consentByLanguages}/?consentid=${id}`
    );
  }

  /**
   * Emits true if the OTP (one-time password) matches the passed milestone_id
   */
  public validateConsent(otp: string, milestoneId: number): Observable<boolean> {
    return this.apiService.getSingle(
      this.apiConfig.consentServiceBaseUrl,
      `${this.endpoints.validate}/${otp}`,
      milestoneId
    );
  }

  /**
   * Get latest user consent by consentId
   * don't see this being called anywhere
   */
  /* public getLatestUserConsent(consentId: number): Observable<ILatestConsent> {
    return this.apiService.getSingle(
      this.apiConfig.consentServiceBaseUrl,
      this.endpoints.latestUserConsent,
      consentId
    );
  } */

  /**
   * Get latest consent by study id and language code
   */
  public getLatestConsentByStudyId(
    studyId: number,
    languageCode: string,
    substitutions: boolean = true
  ): Observable<ILatestConsent> {
    return this.apiService.getAll(
      this.apiConfig.consentServiceBaseUrl,
      `${this.endpoints.consent}/?studysiteid=${studyId}&languageregioncode=${languageCode}&substitutions=${substitutions}`
    );
  }

  /**
   * Get consent by consent id and language code.
   * if substitutions is true, performs text substitutions on server. else, returns unsubstituted text
   */
  public getConsentByConsentId(
    consentId: number,
    languageCode: string,
    substitutions: boolean = true
  ): Observable<ILatestConsent> {
    return this.apiService.getAll(
      this.apiConfig.consentServiceBaseUrl,
      `${this.endpoints.consent}/${consentId}/${languageCode}?substitutions=${substitutions}`
    );
  }

  /**
   * Cancel the participants Consent, withdrawing them from study
   */
  public cancelConsent(request: ICancelConsentRequest): Observable<ICancelConsentRequest> {
    return this.apiService.update(
      this.apiConfig.consentServiceBaseUrl,
      this.endpoints.userConsent,
      request
    );
  }

  /**
   * Update the consent applying approval
   */
  public approveConsent(request: IConsentRequest): Observable<IConsentRequest> {
    return this.apiService.update(
      this.apiConfig.consentServiceBaseUrl,
      `${this.endpoints.consentResponse}?issiteadmincompletingconsent=true`,
      request
    );
  }

  /**
   * Put updated consent
   */
  public updateConsent(consent: ILatestConsent): Observable<IConsent> {
    return this.apiService.update(
      this.apiConfig.consentServiceBaseUrl,
      this.endpoints.consent,
      consent
    );
  }

  /**
   * Upload a consent
   */
  public uploadConsent(consent: StudyConsent): Observable<StudyConsent> {
    return this.apiService.add(
      this.apiConfig.consentServiceBaseUrl,
      this.endpoints.consent,
      consent
    );
  }

  /**
   * Post a consent
   */
  public submitConsentResponse(consentresponse: IConsentRequest): Observable<IConsentRequest> {
    return this.apiService.add(
      this.apiConfig.consentServiceBaseUrl,
      this.endpoints.consentResponse,
      consentresponse
    );
  }

  /**
   * Post to resend verification code
   */
  public resendVerificationCode(request: IOtpRequest): Observable<IOtpRequest> {
    return this.apiService.add(
      this.apiConfig.consentServiceBaseUrl,
      this.endpoints.resend,
      request
    );
  }

  /**
   * Get consent logs for consent
   */
  public getConsentLogs(consentId: number): Observable<IEnrollmentLog> {
    const query = `?summary=true&userstudyid=${consentId}`;

    return this.apiService.getAll(
      this.apiConfig.consentServiceBaseUrl,
      `${this.endpoints.consentLogs}/${query}`
    );
  }

  /**
   * Update the status of a consent
   */
  public updateConsentStatus(consent: IConsentAction): Observable<IConsentAction> {
    return this.apiService.update(
      this.apiConfig.consentServiceBaseUrl,
      this.endpoints.userConsent,
      consent
    );
  }

  /**
   * Require participant to reconsent the consent matching passed user_study_consent_id
   *
   * @param user_study_consent_id id of the User Consent to require participant to reconsent to
   * @returns true on success, else throws an error.
   */
  public requireReconsent(user_study_consent_id: number) {
    return (
      this.apiService
        .add(
          this.apiConfig.consentServiceBaseUrl,
          this.endpoints.reconsent.replace(this.idReg, `${user_study_consent_id}`),
          undefined
        )
        // on success, return true
        .pipe(map(() => true))
    );
  }
}
