export interface IUserAccounts {
  id: number;
  created_at: string;
  updated_at: string;
  subject_user_id: number;
  processor: string;
  account: string;
  name: string;
  primary?: boolean;
}

export interface IUserPrimaryAccount {
  usrer_id: number;
  account_id: number;
}
