import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filter a list of items by matching values in the passed key/value object
 *
 * TODO - this needs documented and cleaned up!  It is not generic as it is!
 */

@Pipe({
  name: 'pesMultiFilter',
  pure: true,
})
export class PesMultiFilterPipe implements PipeTransform {
  public transform(array: Array<any>, searchObject: any): Array<any> {
    let result = array;
    if (!searchObject) {
      return array;
    }

    searchObject.forEach((obj: any) => {
      const key = Object.keys(obj)[0];
      let searchText = '';
      if (obj.type === 'date') {
        searchText = obj[key] ? obj[key] : '';
      } else {
        searchText = obj[key] ? obj[key].toLowerCase() : '';
      }
      if (searchText) {
        result = result.filter((data: any) => {
          let val = '';
          if (obj.type === 'date') {
            val = data[key] ? data[key].split('T')[0] : '';
            return val.toLowerCase().includes(searchText);
          } else if (obj.type === 'dropdown') {
            val = data[key] ? data[key].toString() : '';
            return val.toLowerCase() === searchText;
          } else {
            val = data[key] ? data[key].toString() : '';
            return val.toLowerCase().includes(searchText);
          }
        });
      }
    });
    return result;
  }
}
