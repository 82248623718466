<div class="container">
  <div class="row p-2">
    <div class="col-md-12">
      <nb-alert *ngIf="error" outline="danger" role="alert">
        <ul class="alert-message-list">
          <li>{{ error }}</li>
        </ul>
      </nb-alert>
    </div>
  </div>
  <div class="row message-container p-2">
    <div class="col" *ngIf="!error">
      <table class="table table-striped">
        <tr class="align-top" *ngFor="let message of topMessages">
          <td class="message-text border-bottom py-2">
            <span class="text-font" [ngClass]="{ 'fw-bold': message.status === 'UNREAD' }">
              {{ message.send_user_email.substring(0, 23)
              }}<span *ngIf="message.send_user_email.length > 23">...</span>
            </span>
            <div class="date-font pt-0">
              {{ message.created_at | date: 'longDate' }}
            </div>
          </td>
          <td
            class="message-text border-bottom py-2"
            [ngClass]="{ 'fw-bold': message.status === 'UNREAD' }"
          >
            {{ message.message.substring(0, 40)
            }}<span *ngIf="message.message.length > 40">...</span>
          </td>
        </tr>
      </table>
      <div class="text-center">
        <p class="to-see mb-1">{{ instructions }}</p>
        <button nbButton size="small" status="primary" class="mb-3" (click)="onOpenInbox()">
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
