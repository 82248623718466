import { DatePipe } from '@angular/common';
import { HttpClient, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbAuthModule, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { enUS } from 'date-fns/locale';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbRadioModule,
  NbSidebarModule,
  NbThemeModule,
  NbToastrModule,
} from '@nebular/theme';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  API_CONFIG,
  IApiConfig,
  PepHttpErrorInterceptor,
  PepSharedModule,
  PesSpinnerComponent,
  RoleType,
} from '@targetrwe/pep-shared';
import {
  TrweAuthInterceptor,
  TrweAuthJWTInterceptor,
  TRWE_AUTH_MODULE_CONFIG,
} from '@targetrwe/trwe-auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import authModuleConfig from './auth-module.config';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.arb');
}

const nebularModules = [
  NbLayoutModule,
  NbCardModule,
  NbEvaIconsModule,
  NbIconModule,
  NbAlertModule,
  NbRadioModule,
  NbButtonModule,
  NbSidebarModule.forRoot(),
  NbThemeModule.forRoot({ name: 'pep' }),
  NbToastrModule.forRoot(),
  NbMenuModule.forRoot(),
  NbDatepickerModule.forRoot(),
];

// set the baseUrls based on the current environment
const apiConfig: IApiConfig = {
  authApiUrl: environment.authApiUrl,
  consentServiceBaseUrl: environment.consentServiceBaseUrl,
  languageServiceUrl: environment.languageServiceUrl,
  messageServiceUrl: environment.messageServiceUrl,
  studyManagementServiceUrl: environment.studyManagementServiceUrl,
  studyTimepointsServiceUrl: environment.studyTimepointsServiceUrl,
  surveyServiceBaseUrl: environment.surveyServiceBaseUrl,
  timepointsServiceUrl: environment.timepointsServiceUrl,
  userPreferenceServiceUrl: environment.userPreferenceServiceUrl,
  milestoneRulesServiceUrl: environment.milestoneRulesServiceUrl,
  odsGraphQLUrl: environment.odsGraphQLUrl,
  studyManagementGraphQLUrl: environment.studyManagementGraphQLUrl,
  paymentServiceUrl: environment.paymentServiceUrl,
  paypalLoginConfig: environment.paypalLoginConfig,
  paypalLoginScript: environment.paypalLoginApiScriptUrl,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ...nebularModules,
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: enUS },
      formatOptions: { locale: enUS },
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'app_en_us',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    PesSpinnerComponent,
    NbAuthModule.forRoot(authModuleConfig),
    NbDialogModule.forRoot({ hasBackdrop: true }),
    AppRoutingModule,
    PepSharedModule,
  ],
  providers: [
    // {
    //   provide: ErrorHandler,
    //   useClass: PepGlobalErrorHandler,
    // },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<{ refresh_token: string }>) {
        return req.url.endsWith('/user/login') && req.body?.refresh_token;
      },
    },
    { provide: API_CONFIG, useValue: apiConfig },
    { provide: HTTP_INTERCEPTORS, useClass: PepHttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TrweAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TrweAuthJWTInterceptor, multi: true },
    {
      provide: TRWE_AUTH_MODULE_CONFIG,
      useValue: {
        appName: 'pep',
        inviteCodeOnRegister: true,
        defaultRole: [RoleType.Patient, RoleType.StudyManager],
        socialLogin: environment.socialLogin,
      },
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
