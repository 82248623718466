import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { IStudyTimepoints, ITimepoint } from '../../interfaces/timepoint.interface';
import { API_CONFIG } from './api-config';
import { PepApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PepTimepointService {
  private timepoints!: ITimepoint[];

  private readonly endpoints = {
    timepoints: '/api/v1/timepoints',
    studyTimepoints: '/api/v1/study-timepoints',
  };

  private readonly apiService = inject(PepApiService);
  private readonly apiConfig = inject(API_CONFIG);

  /**
   * Get list of all available Timepoints
   */
  public getAllTimepoints(): Observable<ITimepoint[]> {
    if (this.timepoints) {
      return of(this.timepoints);
    } else {
      return this.apiService
        .getAll<ITimepoint[]>(this.apiConfig.timepointsServiceUrl, this.endpoints.timepoints)
        .pipe(
          tap({
            next: (response: ITimepoint[]) => (this.timepoints = response),
            error: (err: HttpErrorResponse) => of(new Error(err.message)),
          }),
          map((response: any) => response)
        );
    }
  }

  /**
   * Get specific timepoint
   */
  public getTimepoint(id: number): Observable<ITimepoint> {
    if (this.timepoints) {
      const foundTimepoint = this.timepoints.find((timepoint: ITimepoint) => timepoint.id === id);
      if (foundTimepoint) {
        return of(foundTimepoint);
      } else {
        return this.retrieveTimepoint(id);
      }
    } else {
      return this.retrieveTimepoint(id);
    }
  }

  /**
   * Get list of all timepoints associated with the study
   */
  public getTimepointsForStudy(studyId: number): Observable<ITimepoint[]> {
    return this.apiService.getAll(
      this.apiConfig.studyTimepointsServiceUrl,
      this.endpoints.timepoints + `?studyid=${studyId}`
    );
  }

  private retrieveTimepoint(id: number): Observable<ITimepoint> {
    return this.apiService.getSingle(
      this.apiConfig.timepointsServiceUrl,
      this.endpoints.timepoints,
      id
    );
  }

  /**
   * Set the list of Timepoints available in a Study
   */
  public setTimepointsForStudy(studyTimepoints: IStudyTimepoints): Observable<IStudyTimepoints> {
    return this.apiService.add(
      this.apiConfig.studyTimepointsServiceUrl,
      this.endpoints.studyTimepoints,
      studyTimepoints
    );
  }

  /**
   * Update the list of Timepoints available in a Study
   */
  public updateTimepointsForStudy(
    studyId: number,
    timepoints: IStudyTimepoints
  ): Observable<IStudyTimepoints> {
    return this.apiService.update(
      this.apiConfig.studyTimepointsServiceUrl,
      this.endpoints.studyTimepoints,
      timepoints,
      studyId
    );
  }
}
