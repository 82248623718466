export const LOGO_URLS = {
  taglineBlack: 'https://tgt-cdn.trwe.net/logos/black/targetrwe_logo_tagline_black.svg',
  noTaglineBlack: 'https://tgt-cdn.trwe.net/logos/black/targetrwe_logo_black.svg',
  engageLogo: 'https://tgt-cdn.trwe.net/logos/apps/engage/engage-logo.svg',
  fevicon: 'https://tgt-cdn.trwe.net/logos/apps/favicon.ico',
  brandmarkColor: 'https://tgt-cdn.trwe.net/logos/brandmark/targetrwe_brandmark_color.svg',
  engageLogoHorizontal: 'https://tgt-cdn.trwe.net/logos/apps/engage/engage-logo_horizontal.svg',
  engageLogoSmall: 'https://tgt-cdn.trwe.net/logos/apps/engage/engage-logo_small.svg',
  noTaglineColor: 'https://tgt-cdn.trwe.net/logos/color/targetrwe_logo_colors.svg',
};
