/**
 * Arguments passed to the PesFormError label function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PesFormErrorArgs = any;

/**
 * Type for defining validation error codes and the label (string or function) to display for that error
 * If label is a function, it is passed the result of the Validator that failed and must return a string.
 *
 * Example:
 * ```
 * const errorCodes: PesFormErrors = {
 *  required: 'Required',
 *  pattern: (err: { requiredPattern: string }) =>`Invalid Characters: Must match '${err.requiredPattern}'`,
 * }
 * ```
 */
export type PesFormErrors = { [key: string]: string | ((err: PesFormErrorArgs) => string) };
