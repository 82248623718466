<img class="img-fluid d-block main-img" src="{{ brandlogo }}" alt="Target RWE" />
<h3 id="title" class="title">{{ 'register' | translate }}</h3>
<div id="top"></div>

<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <ul class="alert-message-list">
    <li outline="danger" class="alert-message d-flex justify-content-center">Error: {{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="message && !submitted" outline="success" role="alert">
  <p class="alert-title">
    <b>{{ 'success' | translate }}</b>
  </p>
  <ul class="alert-message-list">
    <li class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form [formGroup]="registerForm" (ngSubmit)="onRegister()" novalidate>
  <!-- Title -->
  <div class="mb-4">
    <pes-form-select [control]="registerForm.controls.title" label="{{ 'title' | translate }}" [options]="userTitles"
      [size]="'large'">
    </pes-form-select>
  </div>

  <!-- First Name -->
  <div class="mb-4">
    <pes-form-input [control]="registerForm.controls.first_name" [size]="'large'"
      label="{{ 'user_firstName' | translate }}"
      [errors]="{ required: translate('formError_required'), pattern: translate('spaces-error') }"
      [showErrors]="submitted">
    </pes-form-input>
  </div>

  <!-- Last Name -->
  <div class="mb-4">
    <pes-form-input [control]="registerForm.controls.last_name" [size]="'large'"
      label="{{ 'user_lastName' | translate }}"
      [errors]="{ required: translate('formError_required'), pattern: translate('spaces-error') }"
      [showErrors]="submitted">
    </pes-form-input>
  </div>

  <!-- Email address -->
  <div class="mb-4">
    <pes-form-input [control]="registerForm.controls.email" [size]="'large'" label="{{ 'user_email' | translate }}"
      [errors]="{
        required: translate('formError_required'),
        pattern: translate('invalidEmailAddress')
      }" [showErrors]="submitted">
    </pes-form-input>
  </div>

  <!-- Country Code Phone -->
  <div class="mb-4 form-group">
    <label class="form-label" for="input-phone">{{ 'phoneNo' | translate }}:</label>
    <div class="w-100 d-inline-flex dial-code"
      [ngClass]="{ 'is-invalid': submitted && registerForm.controls.phone.errors }">
      <nb-select fullWidthfullWidth [optionsOverlayOffset]="overlayOffset" class="border-0 w-25 country-code"
        size="large" formControlName="country_code">
        <nb-option *ngFor="let code of DialCode" value="{{ code.dial_code }}">
          {{ code.dial_code }} ({{ code.code }})
        </nb-option>
      </nb-select>
      <input nbInput id="input-phone" class="phone form-control border-0 w-75" type="tel" fullWidth fieldSize="large"
        formControlName="phone" />
    </div>
    <div *ngIf="submitted && registerForm.controls.phone.invalid" class="invalid-feedback">
      <div *ngIf="registerForm.controls.phone.errors?.['pattern']">
        {{ 'invalidPhoneFormat' | translate }}
      </div>
    </div>
  </div>

  <!-- Password -->
  <div class="mb-4">
    <pes-form-input [control]="registerForm.controls.password" [size]="'large'" [type]="'password'"
      label="{{ 'password' | translate }}" [errors]="{
        required: translate('formError_required'),
        pattern: translate('passwordPatternError'),
        minlength: translate('passwordLengthError', { minLength: 8, maxLength: 50 }),
        maxLength: translate('passwordLengthError', { minLength: 8, maxLength: 50 })
      }" [showErrors]="submitted">
    </pes-form-input>
  </div>

  <!-- Confirm Password -->
  <div class="mb-4">
    <pes-form-input [control]="confirmPassword" [size]="'large'" [type]="'password'" [errors]="{
        required: translate('formError_required')
      }" label="{{ 'confirmPassword' | translate }}" [showErrors]="submitted">
    </pes-form-input>
    <div *ngIf="confirmPassword.value && submitted && registerForm.errors?.['mustMatch']"
      class="invalid-feedback d-flex" id="confirmPassword-error">
      {{ 'passwordMismatchError' | translate }}
    </div>
  </div>

  <!-- Preferred Language -->
  <div class="mb-4" *ngIf="langList.length > 0">
    <pes-form-select [control]="registerForm.controls.preferred_language_code" label="{{ 'preferredLang' | translate }}"
      [options]="langList" [size]="'large'">
    </pes-form-select>
  </div>

  <!-- Show Invitation Code if supported by app -->
  <div class="mb-4" *ngIf="allowInviteCode">
    <pes-form-input [control]="invitationCode" [size]="'large'" label="{{ 'studyInviteCode' | translate }}">
    </pes-form-input>
  </div>

  <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted">
    {{ 'register' | translate }}
  </button>
</form>

<section class="another-action mb-4" aria-label="Sign in">
  <p>{{ 'haveAnAccountQuestionLabel' | translate }}
    <a class="text-link" routerLink="../login">{{ 'login' | translate }}</a>
  </p>
  <p><button nbButton outline status="basic" type="button" (click)="onClick()">
      {{ 'privacyPolicy' | translate }}
    </button></p>
</section>