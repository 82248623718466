import { RoleType } from '../types';

/**
 * Identifies a user role
 */
export interface IUserRole {
  ID: number;
  name: string;
  type: string;
}

/**
 * Convert the passed role name to proper label role name.
 * If can't convert, returns RoleType.Patient
 */
export const roleNameToRoleType = (roleName: string): string => {
  const role = roleName?.toLowerCase().replace(/\s/g, '');
  switch (role) {
    case RoleType.User:
      return 'User';
    case RoleType.Hcp:
      return 'HCP';
    case RoleType.StudyManager:
      return 'Study Manager';
    case RoleType.StudySiteAdmin:
      return 'Site Admin';
    case RoleType.Patient:
    default:
      return 'Patient';
  }
};
