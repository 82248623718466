import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPaymentClaim, IPaymentSummary, IPaymentConfig, IPaymentAccount } from '../../interfaces';
import { API_CONFIG } from './api-config';
import { PepApiService } from './api.service';
import { IUserAccounts, IUserPrimaryAccount } from '../../interfaces/user-accounts.interface';

@Injectable({ providedIn: 'root' })
export class PepPaymentSummaryService {
  private readonly apiService = inject(PepApiService);
  private readonly apiConfig = inject(API_CONFIG);

  private readonly endpoints = {
    paymentSummary: '/api/v1/user_milestone_compensation/ledger',
    paymentClaim: '/api/v1/compensation/claim',
    paymentSiteClaim: '/api/v1/compensation_by_site/claim',
    userAccounts: '/api/v1/accounts',
    userPrimaryAccount: '/api/v1/accounts/primary',
    linkAccount: '/api/v1/accounts/link',
  };

  constructor() {}

  /**
   * load payment details for paitent
   */
  public getPaymentSummary(): Observable<IPaymentSummary[]> {
    const url = `${this.endpoints.paymentSummary}`;
    return this.apiService.getAll<IPaymentSummary[]>(this.apiConfig.studyManagementServiceUrl, url);
  }
  /**
   * Claim compensation
   */
  public claimCompensation(compensationClaim: {
    account_id: number | null;
    user_id: number;
    study_site_id?: number | undefined;
    is_for_survey?: boolean | undefined;
  }): Observable<IPaymentClaim> {
    return this.apiService.add(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.paymentClaim,
      compensationClaim
    );
  }

  /**
   * Claim compensation
   */
  public claimSiteCompensation(compensationClaim: {
    study_site_id: number | null;
    is_for_survey: boolean;
  }): Observable<IPaymentClaim> {
    return this.apiService.add(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.paymentSiteClaim,
      compensationClaim
    );
  }

  /**
   * user's linked Accounts
   */
  public getUserAccounts(): Observable<IUserAccounts[]> {
    return this.apiService.getAll(
      this.apiConfig.paymentServiceUrl,
      `${this.endpoints.userAccounts}`
    );
  }

  /**
   * user's linked Accounts
   */
  public getUserPrimaryAccount(): Observable<IUserPrimaryAccount> {
    return this.apiService.getSingle(
      this.apiConfig.paymentServiceUrl,
      `${this.endpoints.userPrimaryAccount}`
    );
  }
  /**
   *
   * get paypal script url
   */
  public getPaypalScriptUrl(): string {
    const url = this.apiConfig.paypalLoginScript;
    return url;
  }
  /**
   * Get paypal configurations
   */
  public getPaypalConfig(): IPaymentConfig {
    return this.apiConfig.paypalLoginConfig;
  }
  /**
   * Link Account for Paypal
   */
  public linkAccount(accountInfo: IPaymentAccount): Observable<IUserPrimaryAccount> {
    return this.apiService.add(
      this.apiConfig.paymentServiceUrl,
      this.endpoints.linkAccount,
      accountInfo
    );
  }
  /**
   * Delete primary account
   */
  public removeAccount(accountId: number): Observable<boolean> {
    return this.apiService.delete(
      this.apiConfig.paymentServiceUrl,
      `${this.endpoints.userAccounts}/${accountId}`
    );
  }
  /**
   * Update Primary Account
   */
  public updatePrimaryAccount(accountId: number): Observable<any> {
    return this.apiService.update(
      this.apiConfig.paymentServiceUrl,
      this.endpoints.userPrimaryAccount,
      undefined,
      accountId
    );
  }
}
