import { Component, Input } from '@angular/core';

const PesPageHeaderAlignment = {
  left: 'left',
  center: 'center',
  right: 'right',
} as const;
export type PesPageHeaderAlignment =
  typeof PesPageHeaderAlignment[keyof typeof PesPageHeaderAlignment];

@Component({
  selector: 'pes-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PesPageContainerComponent {
  /**
   * Title of the Page
   */
  @Input() title = '';

  /**
   * Smaller subtitle displayed under the main title
   */
  @Input() subTitle = '';

  /**
   * Specifies if the title is left, center, or right aligned in the header
   */
  @Input() alignment: PesPageHeaderAlignment = PesPageHeaderAlignment.left;
}
