import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getDeepFromObject, NbAuthResult, NB_AUTH_OPTIONS } from '@nebular/auth';
import {
  PepToastNotificationService,
  pepTranslate,
  PepUserService,
  Unsubscriber,
} from '@targetrwe/pep-shared';
import { catchError, of, take } from 'rxjs';
import { ISocialLoginResponse } from '../../interfaces/social-login-response.interface';
import { TrweAuthStore } from '../../services';
import { TrweAuthService } from '../../services/auth.service';
import { TRWE_AUTH_MODULE_CONFIG } from '../../trwe-auth-module.config';

@Component({
  selector: 'trwe-social-login',
  templateUrl: './social-login.component.html',
})
export class TrweSocialLoginComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  public strategyName = '';
  public submitted = false;
  public redirectDelay = 0;

  private readonly authStore = inject(TrweAuthStore);
  private readonly authModuleSettings = inject(TRWE_AUTH_MODULE_CONFIG);
  private readonly authService = inject(TrweAuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly translate = pepTranslate();
  private readonly userService = inject(PepUserService);

  constructor() {
    const nbAuthOptions = inject(NB_AUTH_OPTIONS);
    this.strategyName = getDeepFromObject(nbAuthOptions, 'forms.login.strategy', null);
  }

  ngOnInit(): void {
    this.login();
  }

  public ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private login() {
    const accessCode = this.activatedRoute.snapshot?.queryParamMap.get('code');
    if (this.authModuleSettings?.socialLogin?.clientId) {
      this.authService
        .socialLogin(
          accessCode || '',
          this.authModuleSettings.socialLogin?.redirectUri,
          this.authModuleSettings.socialLogin?.clientId
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.error.includes('invalid_grant')) {
              this.toastService.showErrorToast(this.translate('invalidGrant'));
            }
            return of(undefined);
          })
        )
        .subscribe((data?: ISocialLoginResponse) => {
          if (data) {
            this.validateToken(data);
          } else {
            this.router.navigateByUrl('auth/login');
          }
        });
    }
  }

  public validateToken(params: ISocialLoginResponse): void {
    this.submitted = true;
    const accessToken = params.user.token;

    this.authService
      .validateToken(this.strategyName, accessToken)
      .subscribe((response: NbAuthResult): void => {
        this.submitted = false;
        if (!response.isSuccess()) {
          this.authService.logout();
          this.toastService.showErrorToast(this.translate('errorLoggingIn'));
          this.router.navigateByUrl('/auth/login');
        }
        const redirect = response.getRedirect();
        if (redirect) {
          const emailToken = (
            params.user.email ||
            response.getResponse().body.user.username ||
            ''
          ).toLowerCase();

          this.userService.getUserByEmailId(emailToken).subscribe((userProfile) => {
            if (userProfile) {
              this.authStore.setAppName(this.authModuleSettings.appName);
              this.authStore.setLoggedInUserFromUserProfile(userProfile);

              // get the condensed user since we want the RoleTypes from it
              this.authStore.loggedInUser$.pipe(take(1)).subscribe((user) => {
                const activeRole = this.authService.getValidRole(
                  this.authModuleSettings.defaultRole,
                  user?.roles || []
                );

                // TODO - if activeRole is undefined, then the user is not authorized to use this app at all!
                this.authStore.setActiveRole(activeRole);
                this.router.navigateByUrl(redirect);
              });
            } else {
              // failed to get the user profile, so can't log in
              this.toastService.showErrorToast(
                new Error('No User Found'),
                `Failed to load user profile for ${emailToken}.`
              );
              this.authService.logout();
            }
          });
        }
      });
  }
}
