import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbIconLibraries } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import {
  PepToastNotificationService,
  PesConfirmationModalComponent,
  SVG_ICONS,
} from '@targetrwe/pep-shared';
import { StorageVariables, TrweAuthStore } from '@targetrwe/trwe-auth';
import { catchError, map, of } from 'rxjs';

@Component({
  selector: 'pep-root',
  template: `<router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  private readonly authStore = inject(TrweAuthStore);
  private readonly document = inject(DOCUMENT);
  private readonly dialogService = inject(NbDialogService);
  private readonly iconLibraries = inject(NbIconLibraries);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly translate = inject(TranslateService);

  constructor() {
    this.iconLibraries.registerSvgPack('target', SVG_ICONS);

    // if the currentUser then we need to log out the user from this tab
    const reAuthHandler = (event: StorageEvent) => {
      if (
        !this.router.url.includes('/auth/login') &&
        (event.key === null || event.key === StorageVariables.currentUser)
      ) {
        this.document.defaultView?.removeEventListener('storage', reAuthHandler);
        this.dialogService
          .open(PesConfirmationModalComponent, {
            context: {
              title: this.translate.instant('authRequired'),
              body: this.translate.instant('authReload'),
              okButtonTitle: this.translate.instant('authReloadBtn'),
              showCancel: false,
            },
          })
          .onClose.subscribe(() => {
            // route to login page
            const url = `/auth/login`;
            this.router.navigate([url]);
          });
      }
    };
    this.document.defaultView?.addEventListener('storage', reAuthHandler);
  }

  ngOnInit(): void {
    let language = 'app_en_us';
    this.translate.setDefaultLang(language);

    // Get preferred language
    this.authStore.preferredLanguage$.subscribe((preferredLanguage) => {
      if (preferredLanguage && !preferredLanguage.includes('cancelled')) {
        if (!preferredLanguage.includes('app')) {
          preferredLanguage = 'app_' + preferredLanguage.replace('-', '_');
        }
        language = preferredLanguage;
      }
      this.translate.use(language);
      this.setTranslateLanguage(language);
    });
  }

  private setTranslateLanguage(selectedLanguage: string): void {
    this.authStore.setLanguage(selectedLanguage);
    this.translate
      .getTranslation(selectedLanguage)
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast('Error getting translations');
          return of(undefined);
        }),
        map((response) =>
          Object.keys(response)
            .filter((trans) => !trans.includes('@'))
            .reduce((cur, key) => Object.assign(cur, { [key]: response[key] }), {})
        )
      )
      .subscribe((translations) => {
        if (translations) {
          this.translate.setTranslation(selectedLanguage, translations, false);
        }
      });
  }
}
