import { Component, OnInit, inject } from '@angular/core';
import {
  ICancelConsentRequest,
  IEOSRequest,
  ISurveyPlayerConfig,
  ISurveyResponse,
} from '../../interfaces';
import { Router } from '@angular/router';
import * as surveyData from '../../assets/end-of-study-en-us.json';
import {
  ParticipantConsentService,
  PatientSubjectService,
  PepConsentService,
  PepToastNotificationService,
} from '../../services';
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { pepTranslate } from '../../utilities';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'pes-end-of-study-survey',
  templateUrl: './end-of-study-survey.component.html',
  styleUrls: ['./end-of-study-survey.component.scss'],
})
export class PesEndOfStudySurveyComponent implements OnInit {
  public readonly translate = pepTranslate();
  private readonly consentService = inject(PepConsentService);
  private readonly patientConsentService = inject(ParticipantConsentService);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly router = inject(Router);
  private readonly datePipe = inject(DatePipe);
  public surveyConfig: ISurveyPlayerConfig | undefined;
  private readonly configData = this.router.getCurrentNavigation()?.extras?.state as any;
  public source = 'eos';

  constructor() {}

  public get pageTitle() {
    return this.configData?.labels.pageTitle;
  }

  ngOnInit(): void {
    if (!this.configData) {
      // no config data, so go to home page
      this.router.navigateByUrl('/');
      return;
    }

    this.surveyConfig = {
      survey: surveyData,
      prefillResponses: {} as ISurveyResponse,
      readonly: false,
      preferredLanguage: this.configData.userPreferredLanguage,
    };
  }

  public onSubmitSurvey(surveyResult: any): void {
    const responses = surveyResult.responses;
    const question_keys = Object.keys(responses);
    const comment = responses[question_keys[0]];

    const request: ICancelConsentRequest = {
      comment: comment.toString(),
      id: this.configData?.id,
      reviewer_id: this.configData?.reviewer_id,
      status: this.configData?.status,
      study_consent_id: this.configData?.study_consent_id,
      user_id: this.configData?.userId,
      user_study_milestone_id: this.configData?.user_study_milestone_id,
      site_admin_id: this.configData.site_admin_id,
    };

    this.consentService
      .cancelConsent(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error, this.translate('errorEndOfStudy'));
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if (response) {
          this.toastService.showSuccessToast(
            this.translate('endOfStudyMessage'),
            this.translate('endOfStudy')
          );
          if (
            comment != 'Completed Study' &&
            comment != 'Withdrawn Consent' &&
            comment != 'Withdrawn by Investigator'
          ) {
            this.saveToODS(responses);
          }
        }
      });
  }

  public saveToODS(responses: any): void {
    const eosods = {
      userStudyID: this.configData.user_study_id,
      dmSubjID: this.configData.participant_id,
      trial: responses?.specify_trial,
      protocolViolation: responses?.protocol_violation,
      contactDt: responses?.final_contact_date_with_patient
        ? `${this.datePipe.transform(
            responses?.final_contact_date_with_patient,
            'yyyy-MM-dd'
          )}T00:00:00Z`
        : undefined,
      deathDt: responses?.date_of_death
        ? `${this.datePipe.transform(responses?.date_of_death, 'yyyy-MM-dd')}T00:00:00Z`
        : undefined,
      other: responses?.other,
    } as IEOSRequest;

    this.patientConsentService
      .getCreatePatientStudy(eosods)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error, this.translate('errorEndOfStudy'));
          return of(undefined);
        })
      )
      .subscribe((response) => {
        console.log(response);
      });
  }
}
