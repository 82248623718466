import { Component, inject } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getDeepFromObject, NbAuthResult, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { LOGO_URLS, PepFormValidatorsService, pepTranslate } from '@targetrwe/pep-shared';

@Component({
  selector: 'trwe-request-password',
  styleUrls: ['./request-password.component.scss'],
  templateUrl: './request-password.component.html',
})
export class TrweRequestPasswordComponent {
  public redirectDelay = 0;
  public strategy = '';
  public submitted = false;
  public error = '';
  public message = '';
  public requestForm = this.createForm();
  public readonly brandlogo: string = LOGO_URLS.engageLogo;

  public readonly translate = pepTranslate();
  private readonly nbAuthService = inject(NbAuthService);
  private readonly router = inject(Router);

  private createForm() {
    const fb = inject(NonNullableFormBuilder);
    const formValidators = inject(PepFormValidatorsService);

    return fb.group({
      email: ['', [Validators.required, Validators.pattern(formValidators.emailPattern)]],
      username: '',
    });
  }
  constructor() {
    const options = inject(NB_AUTH_OPTIONS);
    this.redirectDelay = getDeepFromObject(options, 'forms.requestPassword.redirectDelay');
    this.strategy = getDeepFromObject(options, 'forms.requestPassword.strategy');
  }

  public onRequestPassword(): void {
    this.submitted = true;
    this.error = this.message = '';
    if (this.requestForm.invalid) {
      return;
    } else {
      // continue to post request
      this.requestForm.controls.email.setValue(this.requestForm.controls.email.value.toLowerCase());
      this.requestForm.controls.username.setValue(this.requestForm.controls.email.value);

      this.nbAuthService
        .requestPassword(this.strategy, this.requestForm.value)
        .subscribe((result: NbAuthResult) => {
          this.submitted = false;
          if (result.isSuccess()) {
            this.message = result.getMessages()[0];
          } else {
            this.error = result.getErrors()[0];
          }

          const redirect = result.getRedirect();
          if (redirect) {
            setTimeout(
              () =>
                this.router.navigate([redirect], {
                  skipLocationChange: true,
                  state: { email: this.requestForm.controls.email.value },
                }),
              this.redirectDelay
            );
          }
        });
    }
  }
}
