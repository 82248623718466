import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAdminUser } from '../../interfaces/user.interface';
import { API_CONFIG, PepApiService } from './public-api';

@Injectable({ providedIn: 'root' })
export class PepSiteAdminService {
  private readonly endpoints = {
    studySiteAdmins: '/api/v1/study_site_role',
  };

  private readonly apiConfig = inject(API_CONFIG);
  private readonly apiService = inject(PepApiService);

  /**
   * Create a new admin user on study
   */
  public addSiteAdmin(admin: any): Observable<IAdminUser> {
    return this.apiService.add(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.studySiteAdmins,
      admin
    );
  }

  /**
   * Get Admin Users by Study Id
   */
  public getAdminListByStudyId(studyId: number): Observable<Array<IAdminUser>> {
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studySiteAdmins}/?studysiteid=${studyId}&isstudysiteadmins=true`
    );
  }

  /**
   * Delete site admin from the study
   */
  public deleteSiteAdminFromStudy(adminId: number) {
    return this.apiService.alternativeDelete(
      this.apiConfig.studyManagementServiceUrl,
      this.endpoints.studySiteAdmins,
      adminId
    );
  }
}
