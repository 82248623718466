import { catchError, of } from 'rxjs';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DialCode } from '../../../constants';
import { IAccountLinkStatus, IUserPrimaryAccount, IPaymentAccount } from '../../../interfaces';
import {
  PepToastNotificationService,
  PepPaymentSummaryService,
  PepFormValidatorsService,
  PepLocalstoragedataService,
} from '../../../services';
import { pepTranslate } from '../../../utilities';
import { NonNullableFormBuilder, Validators } from '@angular/forms';

type PaymentModeType = 'PAYPAL' | 'VENMO';
declare const paypal: any;

@Component({
  selector: 'pes-link-payment-account',
  templateUrl: './link-payment-account.component.html',
  styleUrls: ['./link-payment-account.component.scss'],
})
export class PesLinkPaymentAccountComponent implements AfterViewInit {
  constructor() {}

  public _state!: IAccountLinkStatus;
  @Input() public userId!: number;
  @Input() public phone!: string;
  @Input() set state(value: IAccountLinkStatus) {
    if (value && value.success) {
      this._state = value;
    }
    if (value && value.errorType) {
      this.toastService.showErrorToast(
        this.translate('errorLinkingAccount'),
        value.errorType?.error
      );
    }
  }
  public paymentVenmoForm = this.createVenmoForm();
  private readonly toastService = inject(PepToastNotificationService);
  private readonly paymentSummaryService = inject(PepPaymentSummaryService);
  private readonly localstorageDataService = inject(PepLocalstoragedataService);
  public readonly dialogRef = inject(NbDialogRef<PesLinkPaymentAccountComponent>);
  public readonly translate = pepTranslate();
  public readonly DialCode = DialCode;
  public submitted = false;
  public paymentMode!: PaymentModeType;
  public success = false;
  @ViewChild('paypal') paypalElement!: ElementRef;
  /**
   * Listens to window's storage event and fetch data and set it to current state
   *  if its triggered from paypal-login new window
   */
  @HostListener('window:storage')
  onStorageChange() {
    const message: any = this.localstorageDataService.getPaypalResponse();
    this.state = message ? JSON.parse(message) : undefined;
    setTimeout(() => {
      this.localstorageDataService.removePaypalResponse();
    }, 2000);
  }

  ngAfterViewInit(): void {
    this.paymentVenmoForm.patchValue({
      phone: this.phone,
    });
    this.loadExternalScript(this.paymentSummaryService.getPaypalScriptUrl()).then(() => {
      paypal.use(['login'], (login: any) => {
        login.render(this.paymentSummaryService.getPaypalConfig());
      });
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onModeSelect(mode: PaymentModeType): void {
    this.paymentMode = mode;
    if (this.paymentMode === 'PAYPAL') {
      const e: any = document.querySelector('#lippButton a');
      e?.click();
    }
  }

  public onVenmoSubmit(mode: string): void {
    this.submitted = true;
    const payload: IPaymentAccount = {
      processor: mode.toLowerCase(),
      code: this.paymentVenmoForm.value.phone,
    };
    this.sendRequest(payload);
  }

  public sendRequest(payload: IPaymentAccount): void {
    this.paymentSummaryService
      .linkAccount(payload)
      .pipe(
        catchError((errorResponse) => {
          this.submitted = false;
          this.toastService.showErrorToast(
            this.translate('errorLinkingAccount'),
            errorResponse?.error?.error
          );
          return of(undefined);
        })
      )
      .subscribe((response?: IUserPrimaryAccount) => {
        if (response) {
          this.submitted = false;
          this.success = true;
        }
      });
  }

  public onDone(accountLinked?: boolean): void {
    this.success = false;
    this.dialogRef.close(accountLinked);
  }

  private loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  private createVenmoForm() {
    const fb = inject(NonNullableFormBuilder);
    const formValidators = inject(PepFormValidatorsService);
    return fb.group(
      {
        country_code: [{ value: '+1', disabled: true }, [Validators.required]],
        phone: ['', [Validators.required, Validators.pattern(formValidators.phonePattern)]],
        verify_country_code: [{ value: '+1', disabled: true }, [Validators.required]],
        verifyPhone: ['', [Validators.required, Validators.pattern(formValidators.phonePattern)]],
      },
      { validators: formValidators.phoneMatchingValidation }
    );
  }

  public isDefaultEnabled(): boolean {
    return !this._state && (!this.paymentMode || this.paymentMode === 'PAYPAL');
  }

  public isSuccessEnabled(): boolean {
    return this._state && this._state.success ? true : false;
  }

  public isVenmoEnabled(): boolean {
    return !this._state && this.paymentMode === 'VENMO' ? true : false;
  }
}
