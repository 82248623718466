import { IAdminDocument } from './admin-document.interface';
import { IParticipant } from './milestone.interface';
import { IStudy } from './study.interface';
import { UserStudyConsent } from './user-study-consent.interface';

export interface IStudyDocuments {
  id: number;
  study_id: number;
  documents: Array<IAdminDocument>;
  site_id: number;
  study: IStudy;
  admin_topic_rn?: string;
  all_patients_topic_rn?: string;
  study_manager_topic_rn?: string;
  site_reference?: string;
  invite_code?: string;
  invite_link?: string;
  addedParticipant?: Array<IParticipant>;
  user_study_milestone_consents?: UserStudyConsent; // TODO -this is probably the wrong type
  site: ISite;
  milestoneNameFilter?: string;
  open?: boolean;
  totalUserStudyMilestones?: number;
}
interface ISite {
  name: string;
  address: string;
  study_sites: IStudyDocuments;
}
