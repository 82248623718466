import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StudyManagementApiService } from './api/study-management-api.service';
import {
  DiagClassification,
  InputCreatePatientDiagnosis,
  InputCreateStudyDiagClassification,
  PatientDiagnosis,
  StudyDiagClassification,
} from './api/study-management-api.types';

@Injectable({
  providedIn: 'root',
})
export class DiagnosisService {
  constructor(private studyManagementApiService: StudyManagementApiService) {}

  // #region Patient Diagnosis

  /**
   * Save a diagnosis for a Patient
   */
  public createPatientDiagnoses(
    diagnoses: InputCreatePatientDiagnosis[]
  ): Observable<PatientDiagnosis[]> {
    return this.studyManagementApiService
      .createPatientDiagnosis({ input: diagnoses })
      .pipe(map((result) => (result?.data?.createPatientDiagnosis as PatientDiagnosis[]) || []));
  }

  /**
   * Save a diagnosis for a Patient
   */
  public deletePatientDiagnoses(recordIds: number[]): Observable<number[]> {
    return this.studyManagementApiService
      .deletePatientDiagnosis({ input: recordIds })
      .pipe(map((result) => result?.data?.deletePatientDiagnosis || []));
  }

  /**
   * Return list of all recorded PatientDiagnosis for the specified user
   */
  public getPatientDiagnoses(userID: number): Observable<PatientDiagnosis[]> {
    return this.studyManagementApiService
      .patientDiagnoses({
        queryParams: {
          userID,
        },
      })
      .pipe(map((result) => (result?.data?.patientDiagnoses as PatientDiagnosis[]) || []));
  }

  // #endregion

  // #region Diagnosis Classifications

  /**
   * Return list of all known Diagnosis Classifications
   * If a studyId is passed, only returns classifications assigned to that study
   */
  public getDiagnosisClassifications(studyID?: number): Observable<DiagClassification[]> {
    return this.studyManagementApiService
      .diagClassifications({ query: { studyID } })
      .pipe(map((result) => (result?.data?.diagClassifications as DiagClassification[]) || []));
  }

  // #endregion

  // #region Study Diagnoses

  /**
   * Return list of all Diagnosis Classifications assigned to a Study
   */
  public getStudyClassifications(studyID: number): Observable<StudyDiagClassification[]> {
    return this.studyManagementApiService
      .studyDiagClassifications({ queryParams: { studyID } })
      .pipe(
        map((result) => (result?.data?.studyDiagClassifications as StudyDiagClassification[]) || [])
      );
  }

  /**
   * Return object containing list of all diagnoses and studyDiagnoses, and patient diagnoses for a study.
   */
  public getAllDiagnosesForStudyAndUser(
    studyId: number,
    userId: number
  ): Observable<[DiagClassification[], StudyDiagClassification[], PatientDiagnosis[]]> {
    return this.studyManagementApiService
      .allDiagnosesForStudyAndUser({ studyId, userId })
      .pipe(
        map((result) => [
          result.data.diagClassifications as DiagClassification[],
          result.data.studyDiagClassifications as StudyDiagClassification[],
          result.data.patientDiagnoses as PatientDiagnosis[],
        ])
      );
  }

  /**
   * Save a diagnosis for a Patient
   */
  public createStudyDiagnosis(
    diagnosis: InputCreateStudyDiagClassification
  ): Observable<StudyDiagClassification> {
    return this.studyManagementApiService
      .createStudyDiagClassification({
        input: diagnosis,
      })
      .pipe(
        map((result) => result?.data?.createStudyDiagClassification as StudyDiagClassification)
      );
  }

  // #endregion
}
