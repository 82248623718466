import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';

import { catchError, debounceTime, delay, map, of, take, tap } from 'rxjs';
import { PesConfirmationModalComponent } from '../../components';
import {
  DialCode,
  EducationLevel,
  IncomeRange,
  Race,
  Speciality,
  StateAbbreviation,
  Title,
} from '../../constants/dropdowns';
import {
  IUserRole,
  IUserProfile,
  IUserProfileUpdate,
  IUserAccounts,
  IUserPrimaryAccount,
  IAccountLinkStatus,
} from '../../interfaces';
import { PesUserProfileConfig } from '../../interfaces/user-profile-config.interface';
import { PepToastNotificationService } from '../../services/helper-services/toast-notification.service';
import { PepLanguageService } from '../../services/api-services/language.service';
import { PepUserService } from '../../services/api-services/user.service';
import { RoleType } from '../../types';
import { toDefaultDateStr, Unsubscriber } from '../../utilities';
import { pepTranslate } from '../../utilities/translate';
import { PepFormValidatorsService } from '../../services/helper-services/form-validators.service';
import { PesLinkPaymentAccountComponent } from '../modals/link-payment-account/link-payment-account.component';
import { PepPaymentSummaryService } from '../../services';

@Component({
  selector: 'pes-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class PesProfileComponent implements OnInit, OnDestroy {
  public readonly minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 110));
  public readonly maxDate = new Date();

  public readonly DialCode = DialCode;
  public readonly translate = pepTranslate();

  public readonly incomeRanges = [
    {
      label: this.translate('prefer-not-to-answer'),
      value: 0,
    },
    ...IncomeRange.map((t) => ({ label: t.name, value: t.value })),
  ];

  public readonly educationLevels = [
    {
      label: this.translate('prefer-not-to-answer'),
      value: 0,
    },
    ...EducationLevel.map((t) => ({ label: t.name, value: t.value })),
  ];

  public readonly userTitles = [
    {
      label: this.translate('prefer-not-to-answer'),
      value: '',
    },
    ...Title.map((t) => ({ label: t.name, value: t.value })),
  ];

  public readonly specialties = Speciality.map((t) => ({ label: t.name, value: t.value }));
  public readonly stateAbbreviations = StateAbbreviation.map((t) => ({
    label: t.name,
    value: t.value,
  }));
  public readonly countries = [
    { label: this.translate('user_unknown'), value: '' },
    ...DialCode.map((t) => ({ label: t.name, value: t.name })),
  ];

  public readonly races = [
    {
      label: this.translate('prefer-not-to-answer'),
      value: 0,
    },
    ...Race.map((t) => ({ label: t.name, value: t.value })),
  ];

  public readonly genders = [
    { label: 'Male', value: 'M' },
    { label: 'Female', value: 'F' },
    { label: 'Other', value: 'O' },
  ];

  public languages: Array<{ label: string; value: string }> = [];
  public form = this.createForm();
  public userName = '';
  public email = '';
  public userId!: number;
  public phone = '';
  // true once the form has been submitted for the first time
  public submitted = false;
  public userRoles: IUserRole[] = [];
  public isPatient = false;
  private unsubscriber = new Unsubscriber();
  public noLinkedAccount = false;
  public linkedAccounts!: Array<IUserAccounts>;
  public accountLinkState!: IAccountLinkStatus | null;

  private readonly userService = inject(PepUserService);
  private readonly languageService = inject(PepLanguageService);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly dialogService = inject(NbDialogService);
  private readonly router = inject(Router);
  private readonly paymentSummaryService = inject(PepPaymentSummaryService);
  private readonly activatedRouter = inject(ActivatedRoute);
  private readonly userKey = 'currentUser';
  private readonly roleKey = 'activeRole';
  // configuration data provided by Router on navigation
  private readonly configData = this.router.getCurrentNavigation()?.extras
    ?.state as PesUserProfileConfig;

  public routerState = this.router.getCurrentNavigation()?.extras?.state as
    | IAccountLinkStatus
    | undefined;

  public get user() {
    const key = Object.keys(localStorage).find((key) => key.includes(this.userKey)) || '';
    if (this.configData?.loggedInUser || key) {
      return (
        this.configData?.loggedInUser || JSON.parse(localStorage.getItem(key) || '') || undefined
      );
    }
    return undefined;
  }

  public get userRole() {
    const key = Object.keys(localStorage).find((key) => key.includes(this.roleKey)) || '';
    if (this.configData?.userRole || key) {
      return this.configData?.userRole || JSON.parse(localStorage.getItem(key) || '')?.name;
    }
    return '';
  }

  /**
   * Return a newly initialized UserProfile form
   */
  private createForm() {
    const fb = inject(NonNullableFormBuilder);
    const formValidators = inject(PepFormValidatorsService);

    return fb.group({
      title: new FormControl<string>(''),
      first_name: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(formValidators.emptySpacesPattern),
      ]),
      middle_name: new FormControl<string>(''),
      last_name: new FormControl<string>('', [
        Validators.required,
        Validators.pattern(formValidators.emptySpacesPattern),
      ]),
      country_code: new FormControl<string>(''), // part of the phone number (ie, +1 for US)
      phone: new FormControl<string>('', Validators.pattern(formValidators.phonePattern)),
      specialty: new FormControl<string>(''),
      email: new FormControl<string>('', Validators.required),
      sex: new FormControl<string>(''),
      dob: new FormControl<Date | null>(null),

      address1: new FormControl<string>(''),
      address2: new FormControl<string>(''),
      city: new FormControl<string>(''),
      state: new FormControl<string>(''),
      postal_code: new FormControl<string>(''),
      country: new FormControl<string>(''),
      income_range: new FormControl<string>(''),
      education_level: new FormControl<string>(''),
      race: new FormControl<string>(''),
      preferred_language_code: new FormControl<string>(''),
      username: new FormControl<string>(''),
    });
  }

  constructor() {}

  public ngOnInit() {
    if (!this.user && !this.userRole) {
      // no config data, so go to home page
      this.router.navigateByUrl('/');
      return;
    }
    this.isPatient = this.userRole.toLowerCase() === RoleType.Patient;
    this.setUpProfile();

    this.activatedRouter.queryParams.pipe(take(1), delay(500)).subscribe((params) => {
      if (params['linktoaccount']) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    });

    this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => this.updateProfile());
  }

  private setUpProfile() {
    this.userService
      .getUserByEmailId(this.user?.email || '')
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error.message);
          return of(undefined);
        })
      )
      .subscribe((userResponse?) => {
        if (userResponse) {
          this.userName = `${userResponse.person.first_name} ${userResponse.person.last_name}`;
          this.email = userResponse.email || '';
          this.userId = userResponse.id;
          this.phone = userResponse.phone;
          this.setFormData(userResponse);
          this.getLanguages();
          this.getLinkedAndPrimaryAccounts();
          if (this.routerState && this.routerState?.source) {
            this.handleRouterState();
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private getLanguages(): void {
    this.languageService
      .getLanguages()
      .pipe(
        catchError(() => {
          // TODO standardize error
          this.toastService.showErrorToast('Error getting languages');
          return of([]);
        }),
        map((response) =>
          response.map((language) => ({ label: language.description, value: language.code }))
        )
      )
      .subscribe((languagesResp) => {
        this.languages = languagesResp;
        this.languages.unshift({ label: this.translate('user_unknown'), value: '' });
      });
  }

  /**
   * Populate the form with the passed User data
   */
  private setFormData(user: IUserProfile) {
    this.form.setValue(
      {
        title: user.person.title,
        first_name: user.person.first_name,
        middle_name: user.person.middle_name || '',
        last_name: user.person.last_name,
        country_code: user.country_code,
        phone: user.phone,
        specialty: `${user.person.specialty}`,
        email: user.email,
        sex: user.person.sex || '',
        dob:
          user.person.dob && !user.person.dob.startsWith('0001')
            ? new Date(toDefaultDateStr(user.person.dob.toString()))
            : null,
        address1: user.person.address.address1 || '',
        address2: user.person.address.address2 || '',
        city: user.person.address.city || '',
        state: user.person.address.state || '',
        postal_code: user.person.address.postal_code || '',
        country: user.person.address.country || '',

        income_range: `${user.person.income_range}`,
        education_level: `${user.person.education_level}`,
        race: `${user.person.race}`,
        preferred_language_code: user.preferred_language_code,
        username: user.username,
      },
      { emitEvent: false }
    );

    this.submitted = false;
  }

  // return 'yyyy-mm-dd' for save (only way it will successfully save)
  private reorderDateForSave(dt: string): string {
    const dtArr = dt.split('/');
    return `${dtArr[2]}-${dtArr[0].padStart(2, '0')}-${dtArr[1].padStart(2, '0')}`;
  }

  /**
   * Read data out of the form and update user on server
   */
  public updateProfile(): void {
    this.submitted = true;
    if (this.form.invalid) {
      // There are errors in the form, so we can't submit it
      return;
    } else {
      // map the values from the form into the API datatype
      const userToSave: IUserProfileUpdate = {
        address1: this.form.value.address1 || '',
        address2: this.form.value.address2 || '',
        city: this.form.value.city || '',
        country: this.form.value.country || '',
        country_code: this.form.value.phone ? this.form.value.country_code || '' : '',
        dob: this.form.value.dob
          ? this.reorderDateForSave(this.form.value.dob.toLocaleDateString())
          : '',
        education_level: Number(this.form.value.education_level) || 0,
        email: this.form.value.email || '',
        first_name: this.form.value.first_name || '',
        income_range: Number(this.form.value.income_range) || 0,
        last_name: this.form.value.last_name || '',
        middle_name: this.form.value.middle_name || '',
        phone: this.form.value.phone || '',
        postal_code: this.form.value.postal_code || '',
        preferred_language_code: this.form.value.preferred_language_code || '',
        race: Number(this.form.value.race) || 0,
        sex: this.form.value.sex || '',
        specialty: Number(this.form.value.specialty) || 0,
        state: this.form.value.state || '',
        title: this.form.value.title || '',
        username: this.form.value.email || '',
      };

      this.userService
        .updateUserProfile(userToSave)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.toastService.showErrorToast(error);
            return of(undefined);
          })
        )
        .subscribe((profileResponse) => {
          if (profileResponse) {
            this.toastService.showSuccessToast(this.translate('profileInfoUpdateSuccessMsg'));
            this.userName = `${this.form.value.first_name} ${this.form.value.last_name}`;
            this.email = this.form.value.email || '';
            this.submitted = false;
            this.updateAuthStore();
          }
        });
    }
  }

  public onDeleteAccount() {
    this.dialogService
      .open(PesConfirmationModalComponent, {
        context: {
          icon: 'person',
          title: this.translate('deleteAccount'),
          body: this.translate('deleteAccount_confirmation'),
          okButtonTitle: this.translate('deleteAccount'),
          showCancel: true,
          buttonStatus: 'danger',
          okButtonIcon: 'person',
        },
      })
      .onClose.subscribe((confirmed: boolean) => {
        if (confirmed && this.user?.id) {
          this.userService
            .deleteUserAccount(this.user.id)
            .pipe(
              catchError((error: HttpErrorResponse) => {
                this.toastService.showErrorToast(error);
                return of(undefined);
              })
            )
            .subscribe((response) => {
              if (response) {
                this.toastService.showSuccessToast(this.translate('deleteAccount_message'));
                setTimeout(() => {
                  this.router.navigate(['auth/logout']);
                }, 4000);
              }
            });
        }
      });
  }

  /**
   * Update the auth store with the updated user and preferred language
   */
  private updateAuthStore() {
    if (this.form.value.email) {
      this.userService.getUserByEmailId(this.form.value.email).subscribe((user: IUserProfile) => {
        this.userService.userHasBeenUpdated(user);
      });
    }
  }
  /**
   * Link payment Account (PAYPAL/VENMO) to profile
   */
  public onLinkAccount() {
    this.dialogService
      .open(PesLinkPaymentAccountComponent, {
        context: {
          userId: this.user?.id,
          state: this.routerState,
          phone: this.phone,
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((linked: boolean) => {
        if (linked) {
          //recall getAccounts and primary account endpoints
          this.routerState = undefined;
          this.getLinkedAndPrimaryAccounts();
        }
      });
  }
  /**
   * Get linked accounts and primary account
   */
  private getLinkedAndPrimaryAccounts() {
    this.paymentSummaryService
      .getUserAccounts()
      .pipe(
        catchError(() => {
          this.submitted = false;
          this.toastService.showErrorToast('Error getting linked accounts');
          return of([]);
        }),
        tap(() => {
          this.noLinkedAccount = true;
        })
      )
      .subscribe((response: IUserAccounts[]) => {
        if (response) {
          if (response && response.length > 0) {
            this.noLinkedAccount = false;
            this.linkedAccounts = response;
          }
          if (response?.length === 0) {
            this.noLinkedAccount = true;
            this.linkedAccounts = [];
          }
        }
      });
  }

  public onRemovePrimaryAccount(account_id: number, isPrimary: boolean | undefined): void {
    let body;
    if (isPrimary && this.linkedAccounts.length > 1) {
      body = `<p>${this.translate('removePrimaryAccountInfo')}</p>
        <p>${this.translate('removePrimaryAccountNote')}</p>`;
    }
    if (isPrimary && this.linkedAccounts.length === 1) {
      body = `<p>${this.translate('removeOnlyPrimaryAccountInfo')}</p>
        <p>${this.translate('removePrimaryAccountNote')}</p>`;
    }
    if (!isPrimary) {
      body = `<p>${this.translate('removePrimaryAccountNote')}</p>`;
    }
    this.dialogService
      .open(PesConfirmationModalComponent, {
        context: {
          title: this.translate('removePrimaryAccountTitle'),
          body: body,
          okButtonTitle: this.translate('remove'),
        },
        hasBackdrop: true,
      })
      .onClose.subscribe((confirmation: boolean) => {
        if (confirmation) {
          this.removeAccount(account_id);
        }
      });
  }

  public onMakePrimaryAccount(account_id: number): void {
    this.paymentSummaryService
      .updatePrimaryAccount(account_id)
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast(this.translate('errorLinkingAccount'));
          return of(undefined);
        })
      )
      .subscribe((response?: IUserPrimaryAccount) => {
        if (response) {
          this.getLinkedAndPrimaryAccounts();
        }
      });
  }

  private handleRouterState() {
    this.onLinkAccount();
  }

  private removeAccount(account_id: number) {
    this.paymentSummaryService
      .removeAccount(account_id)
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast(this.translate('errorRemovingAccount'));
          return of(undefined);
        })
      )
      .subscribe((response?: boolean) => {
        if (response) {
          this.toastService.showSuccessToast(this.translate('accountRemovedSuccessfully'));
          this.getLinkedAndPrimaryAccounts();
        }
      });
  }

  public getAccountTooltipLabel(isPrimary: boolean | undefined): string {
    return isPrimary
      ? this.translate('payment-account-label')
      : this.translate('make-primary-account-label');
  }
}
