import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { enUS } from 'date-fns/locale';
import {
  NbActionsModule,
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbPopoverModule,
  NbSelectModule,
  NbSidebarModule,
  NbTooltipModule,
  NbUserModule,
  NbTabsetModule,
  NbAccordionModule,
  NbTagModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { PesAppFooterComponent } from './components/app-footer/app-footer.component';
import { PesAppHeaderComponent } from './components/app-header/app-header.component';
import { PesConfirmationModalComponent } from './components/app-modals/confirmation-modal/confirmation-modal.component';
import { PepConsentVerificationModalComponent } from './components/app-modals/consent-verification/consent-verification.component';
import { PesAppPaginationComponent } from './components/app-pagination/app-pagination.component';
import { PesAppSidebarComponent } from './components/app-sidebar/app-sidebar.component';
import { PesFormDateComponent } from './components/form-date/form-date.component';
import { PesFormDebugComponent } from './components/form-debug/form-debug.component';
import { PesFormErrorComponent } from './components/form-error/form-error.component';
import { PesFormInputComponent } from './components/form-input/form-input.component';
import { PesFormSelectComponent } from './components/form-select/form-select.component';
import { PesFormTextAreaComponent } from './components/form-text-area/form-text-area.component';
import { PesPageContainerComponent } from './components/page-container/page-container.component';
import { PesDragDropDirective } from './directives/drag-drop.directive';
import {
  PesContactUsComponent,
  PesPatientConsentComponent,
  PesPatientQuestionnaireComponent,
  PesProSurveyComponent,
  PesSiteQuestionnaireComponent,
} from './pages';
import { PesMessagesInboxComponent } from './pages/user/messages/inbox/messages-inbox.component';
import { PesMessagesComponent } from './pages/user/messages/messages.component';
import { PesMessagesPopoutComponent } from './pages/user/messages/popout/messages-popout.component';
import { PesViewMessageComponent } from './pages/user/messages/view-message/view-message.component';
import { PesProfileComponent } from './pages/user/profile.component';
import { PepPipesModule } from './pipes/pipes.module';
import { ApiServicesModule } from './services/api-services/api-services.module';
import { PesSurveyPlayerComponent } from './survey';
import { PesAppSortingComponent } from './components/app-sorting/app-sorting.component';
import { PesSpinnerComponent } from './components/app-spinner/app-spinner.component';
import { PesCompensationModeComponent } from './pages/modals/compensation-mode/compensation-mode.component';
import { PesLinkPaymentAccountComponent } from './pages/modals/link-payment-account/link-payment-account.component';

const components = [
  PesPageContainerComponent,
  PepConsentVerificationModalComponent,
  PesAppFooterComponent,
  PesAppHeaderComponent,
  PesAppSidebarComponent,
  PesConfirmationModalComponent,
  PesFormDateComponent,
  PesFormDebugComponent,
  PesFormErrorComponent,
  PesFormInputComponent,
  PesFormSelectComponent,
  PesFormTextAreaComponent,
  PesMessagesComponent,
  PesMessagesInboxComponent,
  PesMessagesPopoutComponent,
  PesPatientConsentComponent,
  PesPatientQuestionnaireComponent,
  PesProSurveyComponent,
  PesProfileComponent,
  PesSiteQuestionnaireComponent,
  PesViewMessageComponent,
  PesAppPaginationComponent,
  PesAppSortingComponent,
  PesCompensationModeComponent,
  PesLinkPaymentAccountComponent,
  PesContactUsComponent,
];

const directives = [PesDragDropDirective];

const nebular = [
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbPopoverModule,
  NbSelectModule,
  NbSidebarModule,
  NbTabsetModule,
  NbTagModule,
  NbTooltipModule,
  NbUserModule,
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiServicesModule,
    RouterModule,
    PesSpinnerComponent,
    ...nebular,
    NbDatepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({
      parseOptions: { locale: enUS },
      formatOptions: { locale: enUS },
    }),
    PesSurveyPlayerComponent,
    PepPipesModule,
    TranslateModule,
  ],
  declarations: [...components, ...directives],
  exports: [
    ...components,
    ...directives,
    ...nebular,
    NbDatepickerModule,
    NbDateFnsDateModule,
    FormsModule,
    ReactiveFormsModule,
    PesSurveyPlayerComponent,
    PepPipesModule,
  ],
})
export class PepSharedModule {}
