import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class surveyPlayerService {
  getCustomCss() {
    return {
      root: 'pes-survey__root',
      page: {
        title: 'pes-survey__title',
      },
      progress: 'pes-survey__progress',
      progressBar: 'pes-survey__progress-bar',
      progressText: 'pes-survey__progress-text',
      row: 'pes-survey__row',
      actionBar: {
        root: 'pes-survey__action-bar',
      },
      navigationButton: 'pes-survey__nav-button',
      radiogroup: {
        itemControl: 'pes-survey__radio-item',
        label: 'pes-survey__radio-label',
      },
      error: {
        root: 'pes-survey__error-box',
      },
      question: {
        mainRoot: 'pes-survey__question-main-root',
        content: 'sd-question__content pes-survey__question-content',
        header: 'pes-survey__question-header',
        description: 'pes-survey__question-description',
      },
      paneldynamic: {
        title: 'pes-survey__d-title',
      },
      signaturepad: {
        mainRoot: 'pes-survey__sign-main-root',
        controls: 'sjs_sp_controls pes-survey__sign-controls',
        placeholder: 'pes-survey__sign-placeholder',
        clearButton: 'pes-survey__sign-clear-btn',
      },
      dropdown: {
        root: 'pes-survey__dropdown-base',
        selectWrapper: 'pes-survey__dropdown-wrapper',
        control: 'pes-survey__dropdown-control',
        controlValue: 'pes-survey__dropdown-control-value',
        cleanButton: 'pes-survey__dropdown-clean-btn',
        filterStringInput: 'pes-survey__dropdown-filter-string-input',
      },
      checkbox: {
        itemControl: 'pes-survey__checkbox-control',
        label: 'pes-survey__checkbox-label',
        root: 'pes-survey__checkbox-base',
        item: 'pes-survey__checkbox-item',
        itemDecorator: 'pes-survey__checkbox-svg',
        materialDecorator: 'pes-survey__checkbox-decorator',
      },
      file: {
        fileInput: 'sd-visuallyhidden pes-survey__file-input',
        fileDecorator: 'sd-file__decorator pes-survey__file-decorator',
        chooseFile: 'sd-file__choose-btn pes-survey__file-choose-btn',
        removeButton:
          'sd-context-btn sd-context-btn--negative sd-file__btn sd-file__clean-btn pes-survey__file-remove-btn',
        noFileChosen: 'pes-survey__file-none-chosen-lbl',
      },
      rating: {
        root: 'sd-rating pes-survey__rating-root',
        item: 'pes-survey__rating-item',
        itemText: 'pes-survey__rating-item-text',
      },
      imagepicker: {
        item: 'pes-survey__imgpicker-item',
        itemControl: 'pes-survey__imgpicker-control',
        itemChecked: 'pes-survey__imgpicker-item--checked',
      },
      matrix: {
        root: 'pes-survey__table-root',
        itemValue: 'pes-survey__table-control',
      },
    };
  }
}
