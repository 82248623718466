<div class="form-group">
  <label *ngIf="label" for="{{ inputId }}" class="form-label">
    <span *ngIf="isRequired" class="required">*</span>
    <span> {{ label }}</span>
  </label>
  <input
    [type]="type"
    id="{{ inputId }}"
    nbInput
    fullWidth
    [fieldSize]="size"
    [readonly]="readonly"
    [formControl]="control"
    [placeholder]="placeholder"
    class="form-control"
    [class.is-invalid]="isInvalid"
  />
  <pes-form-error
    data-testid="pes-form-error"
    *ngIf="isInvalid"
    [control]="control"
    [errors]="errors"
  ></pes-form-error>
</div>
