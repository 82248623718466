import { IElements } from './element.interface';

/**
 * Defines a Question that can be used in a Questionnaire
 * As returned by /api/v1/question?withsurvey=true
 */
export interface IQuestion {
  name: number;
  title: string;
  question: IElements;
  survey_questions?: IQuestionSurveyQuestion[];
  snomed?: string;
  field_name?: string;
  associatedPRO?: string;
}

/**
 * Survey usage of this Question
 */
export interface IQuestionSurveyQuestion {
  id: number;
  survey_id: number;
  survey: IQuestionSurvey;
  question_id: number;

  // used by the UI to indicated this question exists in
  // multiple versions of a Survey
  duplicate?: boolean;
}

/**
 * Describes a Survey containing Questions
 */
export interface IQuestionSurvey {
  id?: number;
  version: string;
  name: string;
  status: string;
  question_ids: number[] | null;
}
