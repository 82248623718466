import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IMessageNotification } from '../../../../interfaces/message-notification.interface';
import { PepMessageService } from '../../../../services/api-services/message.service';
import { PepToastNotificationService } from '../../../../services/helper-services/toast-notification.service';
import { pepTranslate } from '../../../../utilities/translate';
import { catchError, of } from 'rxjs';
import { IUserInfo } from '../../../../interfaces/user-info.interface';

@Component({
  selector: 'pes-messages-inbox',
  templateUrl: './messages-inbox.component.html',
  styleUrls: ['./messages-inbox.component.scss'],
})
export class PesMessagesInboxComponent implements OnInit {
  public isHover!: number | null;
  public messages: IMessageNotification[] = [];
  public errors: string[] = [];
  public error = '';
  public markedMessages: IMessageNotification[] = [];

  public readonly translate = pepTranslate();
  private readonly messageService = inject(PepMessageService);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly user: IUserInfo = this.router.getCurrentNavigation()?.extras?.state as IUserInfo;

  public get userId() {
    const userKey = Object.keys(localStorage).find((key) => key.includes('currentUser')) || '';
    if (this.user?.user_id || userKey) {
      return this.user?.user_id || JSON.parse(localStorage.getItem(userKey) || '')?.id;
    }
    return undefined;
  }

  constructor() {}

  public ngOnInit(): void {
    if (this.userId) {
      this.getMessages();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  private getMessages() {
    this.messageService
      .getMessageDataByRecipientId(this.userId)
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast(this.translate('badRequest'));
          return of([]);
        })
      )
      .subscribe((messageResponse: Array<IMessageNotification>) => {
        messageResponse.forEach((item: IMessageNotification) => {
          if (item.notification_type === 'msgt-email-html') {
            item.message = this.messageService.extractContent(item.message);
            item.checked = false;
            this.messages.push(item);
          }
        });
        this.messages = [...this.messages];
      });
  }

  public markAsUnread(): void {
    if (this.markedMessages) {
      this.markedMessages.forEach((element: IMessageNotification) => {
        element.status = 'UNREAD';
      });
      this.messageService
        .updateStatus(this.markedMessages)
        .pipe(
          catchError(() => {
            this.toastService.showErrorToast('Error updating message status');
            return of([]);
          })
        )
        .subscribe((messageResponse: Array<IMessageNotification>) => {
          if (messageResponse) {
            this.toastService.showSuccessToast('Message status updated to unread');
            messageResponse.forEach((item) => {
              if (item.notification_type === 'msgt-email-html') {
                item.message = this.messageService.extractContent(item.message);
                item.checked = false;
                this.messages.forEach((val) => {
                  val.checked = false;
                  if (val.id === item.id) {
                    val = item;
                  }
                });
              }
            });
            this.messages = [...this.messages];
            this.markedMessages = [];
          }
        });
    }
  }

  public openMessage(row: IMessageNotification): void {
    row.status = 'READ';
    this.messageService
      .updateStatus([row])
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast('Error updating message status');
          return of([]);
        })
      )
      .subscribe((messagesResponse: Array<IMessageNotification>) => {
        if (messagesResponse.length > 0) {
          this.toastService.showSuccessToast('Message status updated to read');
          if (messagesResponse.length > 0) {
            messagesResponse.forEach((item: IMessageNotification) => {
              if (item.notification_type === 'msgt-email-html') {
                item.message = this.messageService.extractContent(item.message);
                this.messages.push(item);
              }
            });
          }
          this.messages = [...this.messages];
        }
      });

    this.router.navigate(['user/messages/view-message', row.id]);
  }

  public toggleChecked(checkedStatus: unknown | boolean, message: IMessageNotification) {
    if (checkedStatus) {
      message.checked = true;
      this.markedMessages.push(message);
    } else {
      const index = this.markedMessages.findIndex((m) => m.id === message.id);
      if (index > -1) {
        message.checked = false;
        this.markedMessages.splice(index, 1);
      }
    }
  }
}
