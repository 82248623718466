import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LOGO_URLS } from '@targetrwe/pep-shared';
import { TrweAuthService } from '../../services';

@Component({
  selector: 'trwe-logout',
  template: `<h1 id="title" class="title">Logout</h1>`,
})
export class TrweLogoutComponent implements OnInit {
  public readonly brandlogo: string = LOGO_URLS.engageLogo;
  private readonly authService = inject(TrweAuthService);
  private readonly router = inject(Router);
  constructor() {}

  ngOnInit(): void {
    this.logout();
  }

  public logout(): void {
    this.authService.logout();
    setTimeout(() => this.router.navigateByUrl('/auth/login'), 1);
  }
}
