import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

import { catchError, forkJoin, Observable, of, tap } from 'rxjs';
import { IPaymentClaim, ISitePaymentMethod } from '../../../interfaces';
import { PepToastNotificationService, PepPaymentSummaryService } from '../../../services';
import { pepTranslate } from '../../../utilities';
import { Router } from '@angular/router';
import { PesFormSelectOption } from '../../../components';
import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { PaymentProcessor } from '../../../types';

type CompensationPaymentStep = 'select_site' | 'confirm' | 'success' | undefined;

@Component({
  selector: 'pes-compensation-mode-component',
  templateUrl: './compensation-mode.component.html',
  styleUrls: ['./compensation-mode.component.scss'],
  providers: [CurrencyPipe, TitleCasePipe],
})
export class PesCompensationModeComponent implements OnInit {
  @Input() public userId!: number;
  @Input() public transferAmount!: number;
  @Input() public paymentSites!: ISitePaymentMethod[];
  @Input() public isLinkedPro?: boolean;
  public linkedAccounts!: PesFormSelectOption[];
  public linkedSiteAccounts!: PesFormSelectOption[];

  public noLinkedAccount = false;
  public showSiteLinkPage = false;
  public paymentAccount = new FormControl('', Validators.required);

  private readonly toastService = inject(PepToastNotificationService);
  private readonly paymentSummaryService = inject(PepPaymentSummaryService);
  public readonly dialogRef = inject(NbDialogRef<PesCompensationModeComponent>);
  public readonly translate = pepTranslate();
  public readonly router = inject(Router);
  public readonly pipe = inject(CurrencyPipe);
  public titleCasePipe = inject(TitleCasePipe);
  public submitted = false;
  public showSitePaymentConfirmation = false;
  public compensationPaymentStep: CompensationPaymentStep;

  constructor() {}

  ngOnInit(): void {
    this.linkedSiteAccounts = this.getLinkedSiteAccounts();
    this.paymentSummaryService
      .getUserAccounts()
      .pipe(
        catchError(() => {
          this.submitted = false;
          this.toastService.showErrorToast('Error getting linked accounts');
          return of([]);
        }),
        tap(() => {
          this.noLinkedAccount = true;
          this.compensationPaymentStep = 'select_site';
        })
      )
      .subscribe((response) => {
        if (response && response.length > 0) {
          this.noLinkedAccount = false;
          this.linkedAccounts = response.map((account) => {
            if (account.primary) {
              this.paymentAccount.patchValue(account.id + '');
            }
            return {
              label: `${account.processor}: ${account.account}`,
              postIcon: account.primary ? 'fas fa-solid fa-star' : undefined,
              value: account.id,
            };
          });
          this.linkedAccounts.push(...this.linkedSiteAccounts);
        }
      });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onUpdateSitePaymentStep(): void {
    if (!this.compensationPaymentStep || this.compensationPaymentStep === 'select_site') {
      this.compensationPaymentStep = 'confirm';
    } else if (this.compensationPaymentStep === 'confirm') {
      this.compensationPaymentStep = 'success';
    }
  }

  public onValidatePaymentTypeRequest() {
    if (this.siteDetail) {
      this.onUpdateSitePaymentStep();
    } else {
      this.onClaimCompensation();
    }
  }

  public onClaimCompensation(): void {
    if (this.paymentAccount.invalid) {
      return;
    }

    this.getCompensationAPI()
      .pipe(
        catchError(() => {
          this.submitted = false;
          this.toastService.showErrorToast('Error claiming compensation');
          return of(undefined);
        })
      )
      .subscribe((response?: IPaymentClaim[]) => {
        if (response) {
          this.submitted = false;
          this.compensationPaymentStep = 'success';

          if (!this.siteDetail) {
            const message = this.translate('get-paid-your-transfer-request', {
              processor: this.transformProcessorText(),
              transferAmount: this.pipe.transform(this.transferAmount),
            });
            this.toastService.showSuccessToast(message, 'Transfer Request Recieved');
          } else {
            const message = this.translate('paid_by_site_success', {
              siteName: this.siteName,
              transferAmount: this.pipe.transform(this.showTranferAmount),
            });
            this.toastService.showSuccessToast(message, 'Success');
            if (this.paymentSites.length === 1) {
              this.showSiteLinkPage = true;
            }
          }
        }
      });
  }

  private getCompensationAPI(): Observable<IPaymentClaim[]> {
    // from summary page and claim through paymentgateway
    if (!this.siteDetail) {
      return forkJoin([
        this.paymentSummaryService.claimCompensation({
          account_id: +(this.paymentAccount.value || 0),
          user_id: this.userId,
          // from survey linked
          study_site_id: this.isLinkedPro ? this.paymentSites[0].studySiteId : undefined,
          is_for_survey: this.isLinkedPro,
        }),
      ]);
    } else {
      return forkJoin(
        this.removeDuplicates(this.siteDetail.studySiteIds).map((ssId) => {
          return this.paymentSummaryService.claimSiteCompensation({
            study_site_id: ssId,
            is_for_survey: this.siteDetail?.isForSurvey || false,
          });
        })
      );
    }
  }

  private removeDuplicates(ssIds: number[]): number[] {
    return ssIds.filter(
      (studysiteid, index, array) => array.findIndex((ssid) => studysiteid === ssid) === index
    );
  }

  public onDone(): void {
    this.dialogRef.close(this.success);
  }

  public onGotoProfile(): void {
    this.close();
    this.router.navigateByUrl('/user/profile?linktoaccount=true');
  }

  public onGotoSiteLinked(): void {
    if (this.paymentSites.length > 1) {
      this.showSiteLinkPage = true;
    } else {
      this.paymentAccount.patchValue(this.paymentSites[0].studySiteId + '');
      this.onUpdateSitePaymentStep();
    }
  }

  public transformProcessorText(): string {
    return this.processor.toLowerCase() === PaymentProcessor.PAYPAL.toLowerCase()
      ? PaymentProcessor.PAYPAL
      : this.titleCasePipe.transform(this.processor);
  }

  public get processor(): string {
    return (
      this.linkedAccounts
        ?.find(
          (la) =>
            la.value ===
            +(this.paymentAccount?.value ? this.paymentAccount.value.toLowerCase() : '')
        )
        ?.label.split(':')[0] || ''
    );
  }

  public get siteDetail(): ISitePaymentMethod | undefined {
    return this.paymentSites.find(
      (x) => x.studySiteId === parseInt(this.paymentAccount.value || '', 0)
    );
  }

  public get showTranferAmount(): number {
    return this.siteDetail ? this.siteDetail.amount : this.transferAmount;
  }

  public get siteNameWithOr(): string {
    return [...new Set(this.paymentSites.map((x) => x.siteName))].join(' or ');
  }

  public get siteNameWithAnd(): string {
    return [...new Set(this.paymentSites.map((x) => x.siteName))].join(' and ');
  }

  public get siteName(): string {
    return this.siteDetail?.siteName || '';
  }

  private getLinkedSiteAccounts(): PesFormSelectOption[] {
    return this.paymentSites?.map((x) => {
      return {
        label: `Get Paid By ${x.siteName} ${x.studySiteIds.length > 1 ? '' : x.studyName}`,
        value: x.studySiteId,
      } as PesFormSelectOption;
    });
  }

  public get success() {
    return this.compensationPaymentStep === 'success';
  }

  public get isSitePaymentConfirmation() {
    return this.compensationPaymentStep === 'confirm';
  }
}
