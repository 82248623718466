import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IMessageNotification } from '../../../../interfaces/message-notification.interface';
import { PepMessageService } from '../../../../services/api-services/message.service';
import { PepToastNotificationService } from '../../../../services/helper-services/toast-notification.service';

@Component({
  selector: 'pes-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.scss'],
})
export class PesViewMessageComponent implements OnInit {
  public message!: IMessageNotification | undefined;
  public messageId!: number;
  public error!: string;
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly messageService = inject(PepMessageService);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);

  constructor() {}

  ngOnInit(): void {
    this.messageId = +this.activatedRoute.snapshot.params['id'] || 0;
    if (this.messageId > 0) {
      this.getMessage();
    } else {
      this.navigateBack();
    }
  }

  private getMessage() {
    this.messageService
      .getMessageById(this.messageId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error);
          return of(undefined);
        })
      )
      .subscribe((selectedMessage?: IMessageNotification) => {
        this.message = selectedMessage;
      });
  }

  navigateBack() {
    const userKey = Object.keys(localStorage).find((key) => key.includes('currentUser')) || '';
    this.router.navigateByUrl('user/messages/inbox', {
      state: { loggedInUser: JSON.parse(localStorage.getItem(userKey) || '') },
    });
  }
}
