<ng-container *ngIf="totalRecords; else noRecordFoundRef">
  <div
    class="pagination-container d-flex"
    [class.justify-content-end]="!showPagination"
    [class.justify-content-between]="showPagination"
  >
    <!-- Left side display results -->
    <div class="d-flex justify-content-start align-items-center">
      <span class="me-1">Displaying </span>
      <span *ngIf="!isLastPage"
        >{{ (currentPageIndex - 1) * itemsPerPage + 1 }} to
        {{ currentPageIndex * itemsPerPage }}</span
      >
      <span *ngIf="isLastPage"
        >{{ (currentPageIndex - 1) * itemsPerPage + 1 }} to {{ totalRecords }}</span
      >
      <span class="ms-1"> of {{ totalRecords }} results</span>
    </div>

    <!-- Right side- pagination -->
    <span *ngIf="showPagination">
      <span class="page-dropdown-section">
        <span>
          <a title="Previous" (click)="!isFirstPage && selectPreviousPage()">
            <b>
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </b>
          </a>
          <a (click)="selectPage(1)" [ngClass]="{ selected: 1 === currentPageIndex }">1</a>
          <a *ngIf="isPreviousElipseVisible">...</a>
          <a
            *ngFor="let page of pageRange"
            (click)="selectPage(page)"
            [ngClass]="{ selected: page === currentPageIndex }"
            >{{ page }}</a
          >
          <a *ngIf="isLastElipseVisible">...</a>
          <a
            *ngIf="1 < totalPages"
            (click)="selectPage(totalPages)"
            [ngClass]="{ selected: totalPages === currentPageIndex }"
            >{{ totalPages }}</a
          >

          <a title="Next" class="next pageNumber" (click)="!isLastPage && selectNextPage()">
            <b>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </b>
          </a>
        </span>
      </span>
    </span>
  </div>
</ng-container>
<ng-template #noRecordFoundRef>
  <b class="d-flex justify-content-center">{{ noDataFound }}</b>
</ng-template>
