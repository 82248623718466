import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { getDeepFromObject, NbAuthResult, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import {
  DialCode,
  IRegionLanguage,
  IUserRole,
  LOGO_URLS,
  PepFormValidatorsService,
  PepLanguageService,
  PepToastNotificationService,
  pepTranslate,
  PesFormSelectOption,
  RoleType,
  Title,
} from '@targetrwe/pep-shared';
import { catchError, interval, map, of, take, withLatestFrom } from 'rxjs';
import { TrweAuthService, TrweAuthStore } from '../../services';
import { TRWE_AUTH_MODULE_CONFIG } from '../../trwe-auth-module.config';

@Component({
  selector: 'trwe-register',
  styleUrls: ['./register.component.scss'],
  templateUrl: './register.component.html',
})
export class TrweRegisterComponent implements OnInit {
  public redirectDelay = 0;
  public strategy = '';
  public registerForm = this.createForm();
  public submitted = false;
  public error = '';
  public message = '';
  public readonly brandlogo: string = LOGO_URLS.engageLogo;
  public readonly DialCode = DialCode;
  public readonly userTitles: PesFormSelectOption[] = Title.map((t) => ({
    label: t.name,
    value: t.value,
  }));
  public langList: PesFormSelectOption[] = [];
  public tempPreferredLanguage = '';
  public overlayOffset = 0;
  public invitationCode = new FormControl('');
  public allowInviteCode = false;
  private authStore = inject(TrweAuthStore);

  public readonly translate = pepTranslate();
  private readonly authService = inject(TrweAuthService);
  private readonly nbAuthService = inject(NbAuthService);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly languageService = inject(PepLanguageService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly toastService = inject(PepToastNotificationService);

  private createForm() {
    const fb = inject(NonNullableFormBuilder);
    const formValidators = inject(PepFormValidatorsService);

    return fb.group(
      {
        title: '',
        first_name: [
          '',
          [Validators.required, Validators.pattern(formValidators.emptySpacesPattern)],
        ],
        last_name: [
          '',
          [Validators.required, Validators.pattern(formValidators.emptySpacesPattern)],
        ],
        email: ['', [Validators.required, Validators.pattern(formValidators.emailPattern)]],
        username: '',
        country_code: '+1',
        roles: [[-1]],
        phone: ['', Validators.pattern(formValidators.phonePattern)],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(50),
            Validators.pattern(formValidators.passwordPattern),
          ],
        ],
        confirmPassword: ['', Validators.required],
        preferred_language_code: navigator.language.toLowerCase(),
      },
      { validators: formValidators.passwordMatchingValidation }
    );
  }

  constructor() {
    const nbAuthOptions = inject(NB_AUTH_OPTIONS);
    this.redirectDelay = getDeepFromObject(nbAuthOptions, 'forms.register.redirectDelay');
    this.strategy = getDeepFromObject(nbAuthOptions, 'forms.register.strategy');

    const authConfig = inject(TRWE_AUTH_MODULE_CONFIG);
    this.allowInviteCode = authConfig.inviteCodeOnRegister;
  }

  public onClick(): void {
    const url = 'https://targetrwe.com/privacy-policy';
    window.open(url, '_blank');
  }

  public ngOnInit(): void {
    this.getLanguage();
    interval(0)
      .pipe(take(1))
      .subscribe(() => this.getAllRoles());

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['invite_code']) {
        this.invitationCode.setValue(params['invite_code']);
      }
    });
  }

  private getAllRoles(): void {
    this.authService
      .getAllRoles()
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast(this.translate('errorGetAllRoles'));
          return of(undefined);
        }),
        map(
          (response: { roles: Array<IUserRole> } | undefined) =>
            response?.roles.find((role) => role?.name?.toLocaleLowerCase() === RoleType.Patient)?.ID
        )
      )
      .subscribe((roleId: number | undefined) => {
        if (roleId) {
          this.registerForm.controls.roles.patchValue([roleId]);
        }
      });
  }

  private getLanguage(): void {
    this.languageService
      .getLanguages()
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast(this.translate('errorGetLanguages'));
          return of([]);
        }),
        map((response: Array<IRegionLanguage>) =>
          response.map((language) => ({ label: language.description, value: language.code }))
        ),
        withLatestFrom(this.authStore.preferredLanguage$),
        take(1)
      )
      .subscribe(
        ([mappedLanguages, savedLanguage]: [PesFormSelectOption[], string | undefined]) => {
          this.langList.push(...mappedLanguages);

          if (savedLanguage) {
            if (!savedLanguage.includes('cancelled')) {
              this.tempPreferredLanguage = savedLanguage.replace('app_', '').replace('_', '-');
            } else {
              this.tempPreferredLanguage = 'en-us';
            }
          } else {
            this.tempPreferredLanguage = navigator.language.toLowerCase();
          }
          //https://trwe.atlassian.net/browse/PEP-957
          // backlogged story
          // if (this.invitationCode) {
          //   this.openConentPreview(lang.find((l) => l.is_default).code);
          // }
          this.cd.detectChanges();
        }
      );
  }

  public onRegister(): void {
    this.error = '';
    this.message = '';
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    } else if (this.roleId === -1) {
      this.toastService.showErrorToast(this.translate('errorRegisteringUser'));
    } else {
      // continue to post register
      this.registerForm.controls.phone.setValue(this.registerForm.controls.phone.value.trim());
      // if phone is not populated, clear country_code
      this.registerForm.controls.country_code.setValue(
        this.registerForm.controls.phone.value ? this.registerForm.controls.country_code.value : ''
      );
      this.registerForm.controls.email.setValue(this.registerForm.controls.email?.value.trim());
      this.registerForm.controls.username.setValue(
        (this.registerForm.controls.email.value || '').toLowerCase()
      );

      if (this.invitationCode.value) {
        this.authService
          .registerWithCode(this.registerForm.value, this.invitationCode.value.trim())
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.submitted = false;
              if (error.error.includes('duplicate')) {
                this.error = this.translate('alreadyAssignedStudy');
              } else {
                this.error = this.translate('inviteCodeNotValid');
              }
              const top = document.getElementsByTagName('nb-card-body')[0];
              if (top !== null) {
                top.scrollTo(0, 0);
              }
              this.cd.detectChanges();
              return of(undefined);
            })
          )
          .subscribe((response) => {
            if (response) {
              this.submitted = false;
              this.cd.detectChanges();
              let redirect = '';
              let redirectTimer = 0;
              let state = {};
              if (response.body.includes('registered user')) {
                redirect = 'auth/login';
                state = {
                  message: this.translate('duplicateRegistrationWithCode'),
                };
              } else {
                redirect = 'auth/verification';
                this.message = this.translate('successFullRegistration');
                state = { email: this.registerForm.controls.email.value };

                this.authStore.setLanguage(this.tempPreferredLanguage);
                redirectTimer = 2000;
              }

              if (redirect) {
                setTimeout(() => {
                  this.router.navigate([redirect], {
                    skipLocationChange: true,
                    state,
                  });
                }, redirectTimer);
              }
              // using this to take user to top of page with errors
              const top = document.getElementById('top');
              if (top !== null) {
                top.scrollTo(0, 0);
              }
              this.cd.detectChanges();
            }
          });
      } else {
        this.nbAuthService
          .register(this.strategy, this.registerForm.value)
          .pipe(
            catchError(() => {
              this.toastService.showErrorToast(this.translate('errorRegisteringUser'));
              return of(undefined);
            })
          )
          .subscribe((registerResponse?: NbAuthResult) => {
            if (registerResponse) {
              this.submitted = false;
              if (registerResponse.isSuccess()) {
                this.authStore.setLanguage(this.tempPreferredLanguage);
                this.message = registerResponse.getMessages()[0];
              } else {
                this.error =
                  registerResponse.getResponse()?.error?.error || registerResponse.getErrors()[0];
              }

              const redirect = registerResponse.getRedirect();
              if (redirect) {
                setTimeout(
                  () =>
                    this.router.navigate([redirect], {
                      skipLocationChange: true,
                      state: { email: this.registerForm.controls.email.value },
                    }),
                  this.redirectDelay
                );
              }
              this.cd.detectChanges();

              if (this.error) {
                // using this to take user to top of page with errors
                let top = document.getElementById('top');
                if (top !== null) {
                  top.scrollIntoView();
                  top = null;
                }
              }
            }
          });
      }
    }
  }

  public get confirmPassword() {
    return this.registerForm.controls.confirmPassword;
  }

  public get roleId(): number {
    return this.registerForm.controls.roles.value[0];
  }
}
