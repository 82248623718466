import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PepLocalstoragedataService {
  private paypalInterpreter!: string | null;

  setPaypalResponse(val: string) {
    try {
      localStorage.setItem('paypalInterpreter', val);
    } catch (e) {
      this.paypalInterpreter = val;
    }
  }

  getPaypalResponse(): string | null {
    try {
      return localStorage.getItem('paypalInterpreter');
    } catch (e) {
      return this.paypalInterpreter;
    }
  }

  removePaypalResponse() {
    try {
      localStorage.removeItem('paypalInterpreter');
    } catch (e) {
      this.paypalInterpreter = null;
    }
  }
}
