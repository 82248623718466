export interface IMessageNotification {
  application_id: number;
  created_at: string;
  id: number;
  message: string;
  notification_type: string;
  recipient_contact: string;
  recipient_user_id: number;
  send_user_email: string;
  send_user_id: number;
  status: string;
  updated_at: string;
  checked?: boolean;
}
