export interface IConsentLanguage {
  id?: number;
  document_url?: string;
  language_region_code: string;
  consent_id: number;
  status?: string;
  content?: string;
  document_name?: string;
  document_title: string;
  protocol_no: string;
  corrected_date?: string;
  message?: string;
  commit_branch?: string;
  created_by_id?: number;
}
