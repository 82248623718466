import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class PepGlobalErrorHandler implements ErrorHandler {
  // private readonly logService = inject(PepLoggingService);

  public handleError(error: Error | HttpErrorResponse): void {
    // this.logService.error('Global error handler: ', { Error: error.message });
    if (error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') === -1) {
      console.error('PEP Global Error Handler: ', { Error: error.message });
    }
    // alert(`Global error handler\n${error.message}`);
  }
}
