/**
 * Interface returned by the /user/{email} API call
 */

import { IStudySite } from './study.interface';
import { IUserRole } from './user-role.interface';

/**
 * Address for a user
 */
export interface IUserProfileAddress {
  ID: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  person_id: number;
}

/**
 * Demographic information about a User
 */
export interface IUserProfilePerson {
  ID: number;
  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  sex: string;
  dob: string | null;
  address: IUserProfileAddress;
  specialty: number;
  education_level: number;
  race: number;
  income_range: number;
  user_id: number;
}

/**
 * Represents a User's Profile. Returned as part of IUserProfileRoot
 */
export interface IUserProfile {
  id: number;
  username: string;
  email: string;
  country_code: string;
  phone: string;
  roles: IUserRole[];
  refresh_token: string;
  person: IUserProfilePerson;
  preferred_language_code: string;
}

/**
 * Interface returned from getting a User Profile from /user/:email
 */
export interface IUserProfileRoot {
  user: IUserProfile;
}

/**
 * Interface used to update a User Profile to /user
 */
export interface IUserProfileUpdate {
  address1: string;
  address2: string;
  city: string;
  country: string;
  country_code: string;
  dob: string;
  education_level: number;
  email: string;
  first_name: string;
  income_range: number;
  last_name: string;
  middle_name: string;
  phone: string;
  postal_code: string;
  race: number;
  sex: string;
  specialty: number;
  state: string;
  title: string;
  username: string;
  preferred_language_code?: string;
  // needed but not used in User Profile PUT
  roles?: IUserRole[];
  refresh_token?: string;
  password?: string;
}

/**
 * Minimal Interface used to describe a user
 */
export interface ICondensedUser {
  id: number;
  username: string;
  email: string;
  roles: IUserRole[];
  refresh_token: string;
  preferred_language_code: string;
  first_name: string;
  last_name: string;
}

/**
 * add admin to study
 */

export interface IAdminUser {
  created_by_id: number;
  id: number;
  role_id: number;
  study_site: IStudySite;
  study_site_id: number;
  user: IAdminUserUser;
  user_id: number;
  user_info: IUserInfo;
}

interface IAdminUserUser {
  email: string;
  country_code: string;
  phone: string;
  first_name: string;
  last_name: string;
}
interface IUserInfo {
  country_code: string;
  email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone_number: string;
  title: string;
  user_id: number;
}
