<div class="container">
  <div class="row">
    <header
      class="col-12 d-flex mb-4 align-items-center justify-content-between"
      [ngClass]="{
        'justify-content-start': alignment === 'left',
        'justify-content-center': alignment === 'center',
        'justify-content-end': alignment === 'right'
      }"
    >
      <div
        class="d-flex flex-column"
        [ngClass]="{
          'align-items-start': alignment === 'left',
          'align-items-center': alignment === 'center',
          'align-items-end': alignment === 'right'
        }"
      >
        <h1 *ngIf="title">{{ title }}</h1>
        <h4 *ngIf="subTitle">{{ subTitle }}</h4>
      </div>

      <div class="d-flex flex-fill">
        <ng-content select="[pesHeaderButtons]"></ng-content>
      </div>
    </header>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-content select="[pesPageBody]"></ng-content>
    </div>
  </div>
</div>
