<nb-card class="dialog-card p-3">
  <nb-card-header>
    {{ (isSitePaymentConfirmation ? 'get_paid_by_site' : 'getPaid') | translate }}
    <div class="float-end">
      <nb-icon icon="close-outline" (click)="close()"></nb-icon>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form #form="ngForm" aria-labelledby="comment">
      <div class="d-flex flex-column align-items-center">
        <ng-container
          *ngIf="!showSiteLinkPage; then showAccountLinkedPageSection; else showSiteLinkPageSection"></ng-container>
        <!-- Account linked Section , in this accounts are linked as well as paid by sites are shown-->
        <ng-template #showAccountLinkedPageSection>
          <ng-container *ngIf="noLinkedAccount; then nolinkedAccount; else linkedAccount"></ng-container>

          <!-- No account is associated, here we can add accounts or navigate to the paid by site page -->
          <ng-template #nolinkedAccount>
            <ng-container [ngSwitch]="compensationPaymentStep">
              <ng-container *ngSwitchCase="'select_site'">
                <label class="d-flex text-center">
                  {{ 'get-paid-no-account-linked' | translate }}
                </label>
                <div class="mt-4">
                  <button nbButton status="primary" type="button" (click)="onGotoProfile()">
                    <nb-icon icon="plus-outline"></nb-icon>{{ 'linkNewAccount' | translate }}
                  </button>
                </div>
                <label class="d-flex text-center mt-5">
                  {{
                  'if_you_prefer_to_be_paid_directly'
                  | translate: { siteNameWithOr: siteNameWithOr }
                  }}
                </label>
                <div class="mt-4">
                  <button nbButton status="primary" type="button" (click)="onGotoSiteLinked()">
                    {{ 'get_paid_by_site' | translate }}
                  </button>
                </div>
              </ng-container>

              <!--Step 2: Confirmation page -->
              <ng-container *ngSwitchCase="'confirm'">
                <ng-container *ngTemplateOutlet="
                    siteLinkedConfirmationTemplateRef;
                    context: {
                      $implicit: siteName
                    }
                  ">
                </ng-container>
              </ng-container>

              <!-- Success page -->
              <ng-container *ngSwitchCase="'success'">
                <ng-container *ngTemplateOutlet="
                    siteLinkedSuccessTemplateRef;
                    context: {
                      $implicit: siteName,
                      showTranferAmount: showTranferAmount
                    }
                  ">
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>

          <!-- Do the transfer here & show the success message as per the payment method selected  -->
          <ng-template #linkedAccount>
            <ng-container [ngSwitch]="compensationPaymentStep">
              <ng-container *ngSwitchCase="'select_site'">
                <p class="mb-5">
                  <b>{{
                    'get-paid-transfer-amount-note'
                    | translate: { transferAmount: showTranferAmount | currency }
                    }}</b>
                </p>
                <div class="row">
                  <div class="col-12">
                    <pes-form-select [control]="paymentAccount" label="{{ 'payment-method-label' | translate }}"
                      [options]="linkedAccounts" [errors]="{ required: translate('formError_required') }"
                      [showErrors]="submitted"></pes-form-select>
                  </div>
                </div>
                <div class="mt-4">
                  <button *ngIf="!success" nbButton status="primary" type="button" [disabled]="!showTranferAmount"
                    (click)="onValidatePaymentTypeRequest()">
                    {{ 'transfer' | translate }}
                  </button>
                  <button *ngIf="success" nbButton status="primary" type="button" (click)="onDone()">
                    {{ 'done' | translate }}
                  </button>
                </div>
              </ng-container>

              <!-- Confirmation page -->
              <ng-container *ngSwitchCase="'confirm'">
                <ng-container *ngTemplateOutlet="
                    siteLinkedConfirmationTemplateRef;
                    context: {
                      $implicit: siteName
                    }
                  ">
                </ng-container>
              </ng-container>

              <!-- Success page -->
              <ng-container *ngSwitchCase="'success'">
                <ng-container *ngIf="!siteDetail; else showSiteLinkedSuccessSection">
                  <p class="mb-5">
                    <b class="success">
                      <i class="fas fa-check-circle me-1"></i>{{
                      'get-paid-transfer-request'
                      | translate
                      : {
                      processor: transformProcessorText(),
                      transferAmount: showTranferAmount | currency
                      }
                      }}</b>
                  </p>
                  <div class="mt-4">
                    <button nbButton status="primary" type="button" (click)="onDone()">
                      {{ 'done' | translate }}
                    </button>
                  </div>
                </ng-container>
                <ng-template #showSiteLinkedSuccessSection>
                  <ng-container *ngTemplateOutlet="
                      siteLinkedSuccessTemplateRef;
                      context: {
                        $implicit: siteName,
                        showTranferAmount: showTranferAmount
                      }
                    ">
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-template>
        </ng-template>

        <!-- Paid by site section , in this account are not linked, only paid by sites are shown  -->
        <ng-template #showSiteLinkPageSection>
          <ng-container [ngSwitch]="compensationPaymentStep">
            <ng-container *ngSwitchCase="'select_site'">
              <label class="d-flex text-center">
                {{
                'you_have_outstanding_compensation'
                | translate: { siteNameWithAnd: siteNameWithAnd }
                }}
              </label>
              <div class="row mt-5">
                <div class="col-12">
                  <pes-form-select [placeholder]="'Select Site'" [control]="paymentAccount"
                    label="{{ 'payment-method-label' | translate }}" [options]="linkedSiteAccounts"
                    [errors]="{ required: translate('formError_required') }" [showErrors]="submitted"></pes-form-select>
                </div>
              </div>
              <div class="mt-4">
                <button nbButton status="primary" type="button" [disabled]="!showTranferAmount"
                  (click)="onUpdateSitePaymentStep()">
                  {{ 'done' | translate }}
                </button>
              </div>
            </ng-container>

            <!-- Confirmation page -->
            <ng-container *ngSwitchCase="'confirm'">
              <ng-container *ngTemplateOutlet="
                  siteLinkedConfirmationTemplateRef;
                  context: {
                    $implicit: siteName
                  }
                ">
              </ng-container>
            </ng-container>

            <!-- Success page -->
            <ng-container *ngSwitchCase="'success'">
              <ng-container *ngTemplateOutlet="
                  siteLinkedSuccessTemplateRef;
                  context: {
                    $implicit: siteName,
                    showTranferAmount: showTranferAmount
                  }
                ">
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
    </form>
  </nb-card-body>
</nb-card>

<!-- Success block for paid by site method -->
<ng-template #siteLinkedSuccessTemplateRef let-siteName let-showTranferAmount="showTranferAmount">
  <nb-icon class="success" icon="checkmark-circle-2-outline"></nb-icon>
  <label class="col-8 d-flex text-center success">
    <b>
      {{
      'paid_by_site_success'
      | translate: { siteName: siteName, transferAmount: showTranferAmount | currency }
      }}
    </b>
  </label>
  <div class="col-8 d-flex text-center mt-5">
    <p>
      {{ 'paid_by_site_for_more_information' | translate: { siteName: siteName } }}
    </p>
  </div>
  <div class="mt-4">
    <button nbButton status="primary" type="button" (click)="onDone()">
      {{ 'done' | translate }}
    </button>
  </div>
</ng-template>

<!-- Confirmation block for paid by site method -->
<ng-template #siteLinkedConfirmationTemplateRef let-siteName>
  <label class="col-9 d-flex text-center">
    <strong>{{
      'confirmSiteCompensation'
      | translate: { siteName: siteName }
      }}</strong>
  </label>
  <div class="col-10 d-flex text-center mt-3">
    <p>{{ 'confirmSiteModalText' | translate }}</p>
  </div>
  <div class="mt-4">
    <button class="outline mr-1" nbButton status="basic" type="button" (click)="onDone()">
      <nb-icon icon="close-outline"></nb-icon>{{ 'cancel' | translate }}
    </button>
    <button nbButton status="primary" type="button" (click)="onClaimCompensation()">
      {{ 'confirmPayment' | translate }}
    </button>
  </div>
</ng-template>