import { IStudy, IParticipantCount, IStudySite } from '../interfaces/study.interface';

/**
 * Possible status for milestones
 */
export type UserStudyMilestoneStatusType = 'MISSED' | 'COMPLETE' | 'INCOMPLETE';

/**
 * Possible status for PRO or Questionnaire
 */
export type DocumentStatusType = 'DRAFT' | 'REVIEWED';

/**
 * Possible status for a Study
 */
export type StudyStatusType = 'ACTIVE' | 'DRAFT';

/**
 * Possible actions when creating, cloning or editing PRO or Questionnaire
 */
export type ActionModeType = 'new' | 'edit' | 'clone';

/**
 * Called from Study class, StudySiteSetupComponent component ,StudysetupComponent component
 */
export type UIStudyType = IStudy & {
  show_active: boolean;
  site_ids: Array<number>;
  participantCounts?: Array<IParticipantCount>;
};

// extend base StudySite for the UI
export type UIStudySiteType = IStudySite & { isDirty: boolean };

export type MilestoneType =
  | 'Enrollment'
  | 'Biosample Collection'
  | 'Patient Questionnaire'
  | 'PRO'
  | 'Site Questionnaire'
  | 'Supplemental File Upload'
  | 'Office Visit'
  | 'Reconsent'
  | 'Other';

export type SortOrder = 'asc' | 'desc';

export type LoadConsentActionsType = 'print' | 'download' | 'view' | 'edit';

export enum NotificationEventNameEnum {
  INCOMPLETE_MILESTONE_PATIENT = 'INCOMPLETE_MILESTONE_PATIENT',
  COMPLETE_MILESTONE_SITEADMIN = 'COMPLETE_MILESTONE_SITEADMIN',
  MISSED_MILESTONE_SITEADMIN = 'MISSED_MILESTONE_SITEADMIN',
  INCOMPLETE_MILESTONE_SITEADMIN = 'INCOMPLETE_MILESTONE_SITEADMIN',
}

// eslint-disable-next-line no-shadow
export enum TabTitle {
  LINKED = 'Active Linked',
  UNLINKED = 'Active Unlinked',
  DRAFT = 'Draft',
}
