<div class="container">
  <div class="row">
    <header>
      <!-- Dropdown to select Consent language -->
      <ng-template #approveTitle>
        <h2 class="row d-flex flex-row mb-3">
          <ng-container [ngSwitch]="completionState.get$ | async">
            <ng-container *ngSwitchCase="'userCompleted'">
              {{ labels.completeTitle }}
            </ng-container>
            <ng-container *ngSwitchCase="'userVerified'">
              {{ labels.verifiedTitle }}
            </ng-container>
            <ng-container *ngSwitchCase="'adminApproved'">
              {{ labels.approveTitle }}
            </ng-container>
          </ng-container>
        </h2>
      </ng-template>
      <div
        class="row d-flex flex-row-reverse mb-3"
        *ngIf="
          consentLanguages.length > 0 &&
            (completionState.get$ | async) === 'pending' &&
            action !== 'approve';
          else approveTitle
        "
      >
        <div class="col-3">
          <div class="form-control-group">
            <nb-select
              class="bg-white"
              fullWidth
              [optionsOverlayOffset]="overlayOffset"
              fieldSize="medium"
              id="preferredLang"
              name="preferredLang"
              [selected]="selectedConsentLanguage"
              (selectedChange)="onLanguageChanged($event)"
            >
              <nb-option *ngFor="let lang of consentLanguages" [value]="lang">{{
                lang.language_description
              }}</nb-option>
            </nb-select>
          </div>
        </div>
      </div>
    </header>
  </div>
  <div class="row conatiner-color">
    <div class="col" *ngIf="surveyConfig">
      <ng-container *ngIf="(completionState.get$ | async) === 'pending'; else consentCompleted">
        <pes-survey-player
          *ngIf="surveyConfig"
          [surveyConfig]="surveyConfig"
          (surveyCompleted)="onSurveyCompleted($event)"
        >
        </pes-survey-player>
      </ng-container>

      <ng-template #consentCompleted>
        <div class="container">
          <!-- timestamp and Back button -->
          <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
              <div class="text-start">
                <div class="signed" *ngIf="dateTime">
                  {{ labels.submittedTimeLabel }} {{ dateTime | date }}
                  {{ dateTime | date: 'shortTime' }}
                </div>
                <div class="signed" *ngIf="location">{{ location }}</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 p-5 text-center">
              <!-- user has completed the consent and verification is pending -->
              <ng-template #userCompleted>
                <div class="col-12">
                  <h3>{{ labels.consentSubmitted }}</h3>
                </div>
                <div class="col-12">
                  <button nbButton status="primary" (click)="onVerifyConsent()" class="mt-3">
                    {{ labels.verifyConsentBtn }}
                  </button>
                </div>
                <div class="col-12 mt-3">
                  <a class="go-back" (click)="onBack()">{{ labels.goToMyHomeBtn }}</a>
                </div>
              </ng-template>

              <!-- user has verified the consent and is now waiting for admin approval -->
              <ng-template #userVerified>
                <div class="col-12">
                  <h3>{{ labels.approvedThankYou }}</h3>
                </div>
                <div class="col-12 pt-3">
                  <button nbButton (click)="onDownloadPDF()" status="primary">
                    {{ labels.saveToPdfBtn }}
                  </button>
                </div>
                <div class="col-12 pt-3">
                  <h4>{{ labels.pendingApprovalNote }}</h4>
                </div>
                <div class="col-12 pt-3">
                  <a class="go-back" (click)="onBack()">{{ labels.goToMyHomeBtn }}</a>
                </div>
              </ng-template>

              <!-- saving the consent -->
              <ng-template #saving>
                <h3>{{ labels.saving }}</h3>
              </ng-template>

              <!-- there was an error in completing the consent -->
              <ng-template #userConsentFailed>
                <h3>{{ labels.consentFailed }}</h3>
              </ng-template>

              <!-- admin has successfully approved the consent -->
              <ng-template #adminApproved>
                <h3>{{ labels.genericThankYou }}</h3>
              </ng-template>

              <!-- there was an error in approving the consent -->
              <ng-template #adminApprovalFailed>
                <h3>{{ labels.approvalFailed }}</h3>
              </ng-template>

              <ng-container [ngSwitch]="completionState.get$ | async">
                <ng-container *ngSwitchCase="'saving'" [ngTemplateOutlet]="saving"></ng-container>
                <ng-container
                  *ngSwitchCase="'userCompleted'"
                  [ngTemplateOutlet]="userCompleted"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="'userVerified'"
                  [ngTemplateOutlet]="userVerified"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="'userConsentFailed'"
                  [ngTemplateOutlet]="userConsentFailed"
                >
                </ng-container>
                <ng-container
                  *ngSwitchCase="'adminApproved'"
                  [ngTemplateOutlet]="adminApproved"
                ></ng-container>
                <ng-container
                  *ngSwitchCase="'adminApprovalFailed'"
                  [ngTemplateOutlet]="adminApprovalFailed"
                >
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
