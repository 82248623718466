import { Pipe, PipeTransform } from '@angular/core';

/**
 * Mask the name string so that it can't even be seen in the HTML
 */
@Pipe({ name: 'pesMaskText' })
export class PesMaskTextPipe implements PipeTransform {
  public transform(value: string, visibleDigits: number = 1): string {
    // const visibleDigits = 1;
    const visibleSection = value.slice(0, visibleDigits);
    const maskedSection = value.slice(0, -visibleDigits);
    return visibleSection + maskedSection.replace(/./g, '*');
  }
}
