/**
 * State of the Milestone
 */

import { MilestoneType, UserStudyMilestoneStatusType } from '../types/app.types';

/**
 * Milestone definition for a Study
 */
export interface IMilestone {
  id: number;
  milestone_name: string;
  milestone_description: string;
  created_by_id: number;
  study_id: number;
  active_on: number;
  depends_on: number;
  duration?: number;
  milestone_type_id: number;
  user_study_milestones?: Array<IUserStudyMilestone>;
  interval: number;
  timepoint_id: number;
  timepoint_visit?: string;
  depends_on_name?: string;
  questionnaire_id?: number;
  survey_id?: number;
  self_report?: boolean;
  can_complete_milestone?: boolean;
  active?: boolean;
  milestone_type_name?: MilestoneType;
  status?: UserStudyMilestoneStatusType;
  user_study_milestone_id?: number;
  completion_date?: string;
  completed_by_id?: number;
  timeline_date?: string;
  study_survey_milestone_id?: number;
  timeline_year?: string;
  active_date?: string;
  start_date?: string;
  open?: boolean;
  user_id?: number;
  user_study_consent_status?: string;
  user_study_consent_id: number;
  user_questionnaire_id: number;
  loadMore?: boolean;
  participant_id?: number;
  study_site_id?: number;
  study_consent_id?: number;
  rule_name?: string;
  is_compensated?: boolean;
}

/**
 * Milestone for a Participant in a Study
 */
export interface IUserStudyMilestone {
  id: number;
  created_at: string;
  updated_at: string;
  user_study_id: number;
  status: UserStudyMilestoneStatusType;
  study_milestone_id: number;
  start_date: string;
  active_date: string;
  end_date: string;
  completion_date: string;
  completed_by_id: number;
  study_milestone?: IMilestone;
}

/**
 * User's Milestone for a Study Site
 */
export interface IStudySiteUserStudyMilestone {
  active_date: string;
  active_on: number;
  artifact_id: number;
  can_complete_milestone: boolean;
  completed_by_id: number;
  completion_date?: string;
  consent_language_id: number;
  email?: string;
  end_date: string;
  first_name?: string;
  invite_code?: string;
  invite_link?: string;
  language_region_code?: string;
  last_name?: string;
  milestone_date?: string;
  milestone_name: string;
  milestone_type_id: number;
  milestone_type_name: string;
  participant_id: string;
  patient_id: number;
  questionnaire_id: number;
  self_report: boolean;
  site_name: string;
  start_date: string;
  status: string;
  study_consent_id: number;
  study_id: number;
  study_milestone_id: number;
  study_name: string;
  study_site_id: number;
  study_status: string;
  study_survey_milestone_id: number;
  survey_id: number;
  user_id: number;
  user_questionnaire_id: number;
  user_study_consent_id: number;
  user_study_consent_status?: string;
  user_study_id: number;
  user_study_milestone_id: number;
  user_study_survey_id: number;
  timepoint_id: number;
  timepoint_visit: string;
  enrollment_date: string;

  timeline_date?: string; // date to show (will be completion_date or)
  id?: number; // in order to PUT, the api needs this field to be populated
  open?: boolean; // used for knowing if participant-detail accordion is open or not

  is_compensated: boolean;
  compensation_amount: number | string;
  compensation_currency: string;
  amount_paid: number | string;

  total_incomplete_milestones: number;
  total_complete_milestones: number;
}

/**
 * IParticipant provides data used by the UI for displaying Participants
 */
export interface IParticipant {
  email: string;
  first_name: string;
  language_region_code: string;
  last_name: string;
  milestone_name: string;
  milestone_type_name: string;
  participant_id: string;
  study_consent_id: number;
  study_name: string;
  user_id: number;
  user_study_consent_id: number;
  user_study_consent_status: string;
  user_study_milestone_id: number;
  user_study_id: number;

  // unique to IParticipant
  completionFilter?: string;
  milestoneNameFilter?: string;
  milestones: Array<IStudySiteUserStudyMilestone>;
  open: boolean;
  phi: boolean;
  statusFilter: string;
  timepointFilter?: string;
  enrollment_date: string;
}

/** Post a new study milestone */
export interface ISimpleMilestone {
  active_on: number | null;
  depends_on: number | null;
  duration?: number | null;
  interval: number | null;
  isConditional?: number | null;
  milestone_description: string;
  milestone_name: string;
  milestone_type_id: number | null;
  questionnaire_id?: number | null;
  rule_name?: string;
  study_id: number;
  timepoint_id: number;
  is_compensated?: boolean;
}
