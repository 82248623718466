/**
 * A value/text pair used for substituting text in a consent form
 *
 * `value` is the substitution key
 *
 * `text` is the string used to replace the key in the document
 */
export interface ISubstitutionItem {
  value: string;
  text: string;
}
