import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as SurveyNg from 'survey-angular';
import { SurveyPDF } from 'survey-pdf';
import { StudyConsent } from '../../interfaces';
import { IConsentLanguage } from '../../interfaces/consent-language.interface';
import { IConsent, ILatestConsent } from '../../interfaces/consent.interface';
import { ISubstitutionItem } from '../../interfaces/substitution-item.interface';
import { StudyStatusType } from '../../types/app.types';
import { pepTranslate } from '../../utilities/translate';
import { PepPdfExportService } from '../helper-services/pdf-export.service';
import { PepToastNotificationService } from '../helper-services/toast-notification.service';
import { PepConsentService } from './consent.service';
import { Converter } from 'showdown';

/// used to select a language
export interface UILanguage {
  value: string;
  label: string;
}

/// Study data needed by editor
export interface UIStudy {
  id: number;
  study_name: string;
}

/// used to select a study site
export interface UIStudySites {
  id: number;
  site_name: string;
  selected: boolean;
}

// information about the currently logged in user
export interface UIUserData {
  id: number;
  email: string;
  preferred_language_code: string;
}

export interface UIActiveConsent {
  id: number;
  version: string;
  studySiteId: number | null;
  createdById: number;
}

/**
 * A Consent document for a specific Language
 */
export interface UIConsentDocument {
  document_title: string; // language-specific title of document
  id: number | null; // consent_languages id
  language_region_code: string; // the region code for this consent
  protocol_no: string; // language-specific Protocol number
  status: StudyStatusType; // ACTIVE or DRAFT.
  study_site_ids: number[]; // list of StudySiteIds to associate with this consent. may be more then 1 for new consents
  content: SurveyNg.ISurvey | undefined; // the SurveyJS survey data for this language
  version_date?: string; // date of new version
}

/**
 * Private data about the Site and Consent
 */
interface DataStore {
  study: UIStudy | undefined;
  studySites: UIStudySites[] | undefined;
  languages: UILanguage[] | undefined;
  user: UIUserData | undefined;
  activeConsent?: UIActiveConsent | undefined;
}
/**
 * Manage interaction with a study Consent form
 */
@Injectable({
  providedIn: 'root',
})
export class PepStudyConsentService {
  // #region Datastore for active Site
  private datastore: DataStore = {
    study: undefined,
    studySites: undefined,
    languages: undefined,
    user: undefined,
    activeConsent: undefined,
  };

  public readonly pdfExportService = inject(PepPdfExportService);
  public readonly httpClient = inject(HttpClient);
  public readonly translate = pepTranslate();
  private readonly consentService = inject(PepConsentService);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly datePipe = inject(DatePipe);

  public get study(): UIStudy | undefined {
    return this.datastore.study;
  }
  public get studySites(): UIStudySites[] | undefined {
    return this.datastore.studySites;
  }
  public get languages(): UILanguage[] | undefined {
    return this.datastore.languages;
  }
  public get user(): UIUserData | undefined {
    return this.datastore.user;
  }
  public get activeConsent(): UIActiveConsent | undefined {
    return this.datastore?.activeConsent;
  }
  public setDataStore(data: DataStore) {
    this.datastore.study = data.study;
    this.datastore.studySites = data.studySites;
    this.datastore.languages = data.languages;
    this.datastore.user = data.user;
  }

  // to clear out the previous active consent
  public clearActiveConsent() {
    if (this.datastore) {
      this.datastore.activeConsent = undefined;
    }
  }
  // #region

  constructor() {}

  /**
   * Modify the content by replacing all placeholders with the specified values.
   * Placeholders are defined using mustache notation (ie, `{{ .` and `}}`)
   * Example: `{{ .FirstName }}, {{ .LastName }}`
   *
   * @param original to modify
   * @returns updated content string
   */
  public applySubstitutions(original: string, substitutions?: Record<string, string>): string {
    const regex = /{{\s*[\s.\][\w\s]*}}/g;
    const placeholders = original.match(regex);
    let newContent = original;
    placeholders?.forEach((element) => {
      const key = element.replace(/{{|}}/g, '').trim().replace(/^\./g, ''); // get the key
      const newValue = substitutions?.[key] ?? '';
      newContent = newContent.replace(element, newValue);
    });
    return newContent;
  }

  /**
   * Create a new Language Consent using the passed consent data
   * When createing a new consent, you MAY pass multiple study_site_ids so that the form is used for each specified site
   * HOWEVER, if any of the other sites already have an existing form at this version, it will cause an error.
   */
  public createConsent(
    consent: UIConsentDocument,
    versionId: string
  ): Observable<UIConsentDocument | undefined> {
    const consentToSave = this.convertToStudyConsent(consent);
    consentToSave.message = `Consent created for ${this.study?.study_name}`;
    consentToSave.study_site_id = 0;
    consentToSave.study_site_ids = consent.study_site_ids;
    consentToSave.version_no = versionId; // use new version number provided

    // create a new Consent for language
    return this.consentService.uploadConsent(consentToSave).pipe(
      // after creating a new consent, we need to fetch it for the primary site ID, which should be the first
      // in the study_site_ids list
      switchMap(() =>
        this.getConsentByStudySiteId(consent.study_site_ids[0], consent.language_region_code)
      )
    );
  }

  /**
   * Update an existing language consent with the passed consent data
   */
  public updateConsent(consent: UIConsentDocument): Observable<UIConsentDocument> {
    const consentToSave = this.convertToStudyConsent(consent);
    consentToSave.message = `Consent updated for ${this.study?.study_name}`;
    consentToSave.study_site_id = consent.study_site_ids[0];
    consentToSave.study_site_ids = [];

    return this.consentService.updateConsent(consentToSave as unknown as ILatestConsent).pipe(
      map((result: any) => {
        const newConsent: UIConsentDocument = {
          document_title: result.document_title,
          id: result.id, // consent_languages id
          language_region_code: result.language_region_code,
          protocol_no: result.protocol_no,
          status: result.status as StudyStatusType,
          study_site_ids: [result.study_site_id], // a consent language will only ever have one study_site_id
          content: this.parseConsent(result.content),
        };

        return newConsent;
      })
    );
  }

  /**
   * Convert list of substitution items into Record object with key/values
   */
  public convertSubstitutions(substitutionItems: ISubstitutionItem[]): Record<string, string> {
    const substitutions: Record<string, string> = {};
    substitutionItems?.forEach((item: ISubstitutionItem | string) => {
      if (typeof item === 'string') {
        // no data set for this item
        substitutions[item] = '';
      } else {
        // item defines both the key(value) and the data(text)
        substitutions[item.value] = item.text;
      }
    });
    return substitutions;
  }

  /**
   * Save a new DRAFT version of the passed LatestConsent
   */
  public saveNewDraftVersionConsent(consent: ILatestConsent, userId: number): void {
    const consentData = { ...consent };

    consentData.status = 'DRAFT';
    consentData.corrected_date = this.datePipe.transform(Date.now(), 'yyyy-MM-dd') || undefined;
    consentData.commit_branch = 'master'; // TODO - Need to handle from backend
    consentData.message = 'Consent updated for ' + consent.study_id; // TODO - NEED TO DISCUSS
    consentData.created_by_id = userId; // user that modified the consent

    consentData.language_region_code = consentData.consent_languages
      ? consentData.consent_languages[0].language_region_code
      : '';
    consentData.document_title = consentData.consent_languages
      ? consentData.consent_languages[0].document_title
      : '';
    consentData.protocol_no = consentData.consent_languages
      ? consentData.consent_languages[0].protocol_no
      : '';
    delete consentData.consent_languages;

    this.consentService
      .updateConsent(consentData as unknown as ILatestConsent)
      .pipe(
        catchError(() => {
          this.toastService.showErrorToast('Error saving consent document');
          return of(undefined);
        })
      )
      .subscribe((consentResponse) => {
        if (consentResponse) {
          this.toastService.showSuccessToast('Consent document saved');
        }
      });
  }

  /**
   * Gets the specified Consent in the specified language, if it exists. else creates new consent
   */
  public getConsentFor(
    consentId: number,
    languageCode: string
  ): Observable<UIConsentDocument | undefined> {
    // load the specified Consent for language. If it doesn't exist, then we need to init a new one
    return this.consentService.getConsentByConsentId(consentId, languageCode, false).pipe(
      switchMap((result: ILatestConsent) => {
        if (!result) {
          // Consent for this language doesn't exist.  Create it.
          return this.createNewConsentForLanguage(languageCode);
        } else {
          // extract the info we need from the result
          const consent = this.extractConsentFromResult(result);
          return of(consent);
        }
      }),
      catchError(() => {
        const message = this.translate('errorGetConsentFor', { consentId, languageCode });
        return throwError(() => new Error(message));
      })
    );
  }

  /**
   * Get the Consent info for passed studySiteId
   */
  public getConsentByStudySiteId(
    studySiteId: number,
    languageCode: string
  ): Observable<UIConsentDocument | undefined> {
    return this.consentService.getLatestConsentByStudyId(studySiteId, languageCode, false).pipe(
      tap((result) => {
        if (result) {
          const firstStudySiteId =
            result.study_site_ids && result.study_site_ids.length > 0
              ? +result.study_site_ids[0]
              : null;
          this.datastore.activeConsent = {
            id: result.id,
            version: result.version_no,
            studySiteId: result?.study_site_id || firstStudySiteId,
            createdById: result.created_by_id,
          };
        }
      }),
      switchMap((result) => {
        if (!result) {
          // Consent does not exist. Need to create one
          return this.createNewConsentForLanguage(languageCode);
        } else {
          // extract the info we need from the result
          const consent = this.extractConsentFromResult(result);
          return of(consent);
        }
      }),
      catchError((error) => {
        const message = `Error getting latest consent for StudyId ${studySiteId}: ${error}`;
        console.error(message);
        return throwError(() => new Error(message));
      })
    );
  }

  /**
   * Extract the Consent survey from the passed string
   */
  private parseConsent(content: string | undefined): SurveyNg.ISurvey | undefined {
    if (!content) {
      return undefined;
    }
    try {
      // attempt to extract SurveyJS data from consent data
      const consent = JSON.parse(content) as SurveyNg.ISurvey;
      return consent;
    } catch (error) {
      // failed to parse the Survey content
      console.warn(`CreateConsentService: Failed to parse Consent: "${content}"`, error);
      return undefined;
    }
  }

  private extractConsentFromResult(original: ILatestConsent): UIConsentDocument | undefined {
    const language = original?.consent_languages
      ? (original?.consent_languages[0] as IConsentLanguage & { version_no: string })
      : undefined;
    if (!language) {
      return undefined;
    }

    const consent: UIConsentDocument = {
      document_title: language.document_title || original.document_title || '',
      id: language.id || original.id, // consent_languages DB id
      language_region_code: language.language_region_code || original.language_region_code || '',
      protocol_no: language.protocol_no || original.protocol_no || '',
      status: (language.status || original.status) as StudyStatusType,
      study_site_ids: [original.study_site_id], // a consent language will only ever have one study_site_id
      content: this.parseConsent(language.content) || this.parseConsent(original.content),
      version_date: this.datePipe.transform(original?.version_date, 'yyyy-MM-dd', '+0000') || '',
    };

    return consent;
  }
  /**
   * Save the Consent form as a PDF file
   *
   * @param consent
   */
  public saveConsentAsPdf(consent: UIConsentDocument) {
    const options = this.pdfExportService.pdfOptions;
    const consentJson = JSON.stringify(consent.content);
    const surveyPDF = new SurveyPDF(consentJson, options);
    const converter = new Converter();
    surveyPDF.onTextMarkdown.add((survey: any, options: any) => {
      let str = converter.makeHtml(options.text);
      str = str.replace('<p>', '');
      str = str.replace('</p>', '');
      options.html = str;
    });

    surveyPDF.haveCommercialLicense = true;
    surveyPDF.save(
      'review_consent_pdf_' + consent.document_title + '_' + consent.study_site_ids[0]
    );
  }

  /**
   * Return the default Consent for the specified language
   */
  private defaultConsentForLanguage(languageCode: string): Observable<SurveyNg.ISurvey> {
    const urlForLanguage = (lc: string): string => `assets/surveys/consent-${lc}.json`;
    return this.httpClient.get<SurveyNg.ISurvey>(urlForLanguage(languageCode)).pipe(
      catchError(() =>
        // if there was an error fetching the language, then fetch default language
        this.httpClient.get<SurveyNg.ISurvey>(urlForLanguage('en-us'))
      )
    );
  }

  /**
   * Creates a new instance of a Consent for a specific Language
   */
  private createNewConsentForLanguage(languageCode: string): Observable<UIConsentDocument> {
    return this.defaultConsentForLanguage(languageCode).pipe(
      map((consentForm) => {
        const newConsentLanguage: UIConsentDocument = {
          content: consentForm,
          document_title: '',
          id: null,
          language_region_code: languageCode,
          protocol_no: '',
          status: 'DRAFT',
          study_site_ids: [],
        };
        return newConsentLanguage;
      })
    );
  }

  private convertToStudyConsent(consent: UIConsentDocument): StudyConsent {
    const now = new Date();

    const consentToSave: StudyConsent = {
      commit_branch: 'master',
      content: JSON.stringify(consent.content),
      corrected_date: this.datePipe.transform(now, 'yyyy-MM-dd'),
      document_title: consent.document_title,
      id: consent.id,
      language_region_code: consent.language_region_code,
      message: undefined,
      protocol_no: consent.protocol_no,
      status: 'DRAFT', // this ALWAYS saves as a draft
      study_id: this.study?.id,
      study_site_id: undefined,
      version_no: this.activeConsent?.version,
    } as unknown as StudyConsent;

    return consentToSave;
  }
}
