import { Pipe, PipeTransform } from '@angular/core';

/**
 * Mask the input email string so that it can't even be seen in the HTML
 */
@Pipe({ name: 'pesMaskEmail' })
export class PesMaskEmailPipe implements PipeTransform {
  public transform(value: string): string {
    return value
      ? value.replace(
          /\B.+@/g,
          (c) =>
            c
              .split('')
              .slice(0, -1)
              .map(() => '*')
              .join('') + '@'
        )
      : value;
  }
}
