import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, retry, throwError } from 'rxjs';
import { IUserProfile, IUserProfileRoot, IUserProfileUpdate, IUserRole } from '../../interfaces';
import { API_CONFIG } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepUserService {
  private readonly endpoints = {
    user: '/user',
    userPreference: '/userpref',
    deleteAccount: '/api/v1/patient-subject/{id}/unsubscribe',
  };

  private readonly apiService = inject(PepApiService);
  private readonly apiConfig = inject(API_CONFIG);

  private readonly userUpdatedEventSubject = new BehaviorSubject<IUserProfile | undefined>(
    undefined
  );
  public userUpdated$ = this.userUpdatedEventSubject.asObservable();

  private readonly roleUpdatedEventSubject = new BehaviorSubject<IUserRole | undefined>(undefined);
  public roleUpdated$ = this.roleUpdatedEventSubject.asObservable();

  public userHasBeenUpdated(updatedUser: IUserProfile) {
    this.userUpdatedEventSubject.next(updatedUser);
  }
  public roleHasBeenUpdated(role: IUserRole) {
    this.roleUpdatedEventSubject.next(role);
  }

  /**
   * Get the UserProfile for using the passed User ID
   *
   * @param userId id of the user profile to fetch
   */
  public getUserById(userId: number): Observable<IUserProfileUpdate> {
    return this.apiService
      .getSingle<IUserProfileUpdate>(
        `${this.apiConfig.authApiUrl}/api/v1`,
        this.endpoints.user,
        userId
      )
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => throwError(() => new Error(error.message)))
      );
  }

  /**
   * Get user by email for profile
   */
  public getUserByEmailId(email: string): Observable<IUserProfile> {
    return this.apiService
      .getAll<IUserProfileRoot>(this.apiConfig.authApiUrl, `${this.endpoints.user}/${email}`)
      .pipe(map((profileRoot) => profileRoot.user));
  }

  /**
   * Update the user profile
   */
  public updateUserProfile(user: IUserProfileUpdate): Observable<any> {
    return this.apiService.update(this.apiConfig.authApiUrl, this.endpoints.user, user);
  }

  /**
   * delete user account
   */
  public deleteUserAccount(userId: number): Observable<string> {
    const idReg = /{id}/;
    const urlReplaced = this.endpoints.deleteAccount.replace(idReg, `${userId}`);

    return this.apiService.update(this.apiConfig.consentServiceBaseUrl, urlReplaced, undefined);
  }
}
