import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NbAuthResult, NbTokenService, NB_AUTH_STRATEGIES } from '@nebular/auth';
import { API_CONFIG, IUserRole, PepApiService, RoleType } from '@targetrwe/pep-shared';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';
import { TrweAuthStore } from './auth-store.service';
import { CustomAuthStrategy } from './auth-strategies/custom-auth-strategy';

export interface IUpdateUser {
  username: string;
  confirmation_code: string;
  password: string;
  confirmPassword?: string;
}

export const CURRENT_USER_KEY = 'pep-auth-current-user';

/**
 * Provides Authentication services for PEP apps
 */
@Injectable({ providedIn: 'root' })
export class TrweAuthService {
  private readonly endpoints = {
    resetPassword: '/password/reset',
    confirmSignup: '/user/confirm',
    confirmPassword: '/password/confirm',
    authenticate: '/auth',
    user: '/user',
    studyInvite: '/api/v1/study_invite',
    roles: '/roles',
  };

  // Code for postMessage()
  private loginOrigin = '';

  private readonly apiService = inject(PepApiService);
  private readonly authStore = inject(TrweAuthStore);
  private readonly http = inject(HttpClient);
  private readonly tokenService = inject(NbTokenService);
  private readonly apiConfig = inject(API_CONFIG);
  private readonly strategies = inject(NB_AUTH_STRATEGIES);

  /**
   * Validates that the passed access token for the specified strategy is valid
   * @param strategyName nbAuthStrategy to use
   * @param accessToken token to validate
   * @returns result of validating the token
   */
  public validateToken(strategyName: string, accessToken: string): Observable<NbAuthResult> {
    return this.getStrategy(strategyName)
      .token({
        access_token: accessToken,
      })
      .pipe(switchMap((result: NbAuthResult) => this.processResultToken(result)));
  }

  /**
   * Handle social (SSO) login
   */
  public socialLogin(
    code: string,
    redirectUri: string,
    clientId: string
  ): Observable<{ message: string; user: any }> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const params = new HttpParams()
      .set('code', code)
      .set('redirectUri', redirectUri)
      .set('clientId', clientId);

    const url = `${this.apiConfig.authApiUrl}${this.endpoints.authenticate}`;

    return this.http.get<any>(url, { params, headers }).pipe(
      map((data) => data),
      retry(1),
      catchError((error: HttpErrorResponse) => throwError(() => new Error(error.error)))
    );
  }

  /**
   * Sign outs with the selected strategy
   * Removes token from the token storage
   */
  public logout(): void {
    try {
      this.tokenService.clear();
      this.authStore.reset();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Register the participant using the passed study code
   * TODO: Clean up this registering user object with an interface
   */
  public registerWithCode(participant: any, code?: string): Observable<any> {
    let url = this.endpoints.studyInvite;
    if (code) {
      url += `/code/${code}`;
    }
    return this.apiService.alternativeAdd(
      this.apiConfig.studyManagementServiceUrl,
      url,
      participant
    );
  }

  /**
   * Confirm user signup
   */
  public signupConfirmation(verifyForm: {
    confirmation_code: string;
    username: string;
  }): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const url = `${this.apiConfig.authApiUrl}${this.endpoints.confirmSignup}`;

    return this.http.post<any>(url, verifyForm, { headers }).pipe(
      map((data) => data),
      retry(1),
      catchError((error: HttpErrorResponse) => throwError(() => new Error(error.error)))
    );
  }

  /**
   * Confirm password change
   */
  public confirmPassword(updatedUserInfo: IUpdateUser): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const body = {
      confirmation_code: updatedUserInfo.confirmation_code,
      password: updatedUserInfo.password,
      username: updatedUserInfo.username,
    };

    const url = `${this.apiConfig.authApiUrl}${this.endpoints.confirmPassword}`;

    return this.http.post<any>(url, body, { headers }).pipe(
      map((data) => data),
      retry(1),
      catchError((error: HttpErrorResponse) => throwError(() => new Error(error.error)))
    );
  }

  /**
   * Get registered Authentication strategy by the name
   */
  protected getStrategy(strategyName: string): CustomAuthStrategy {
    const found = this.strategies.find(
      (strategy) => (strategy as unknown as CustomAuthStrategy).getName() === strategyName
    );
    if (!found) {
      throw new TypeError(`There is no Auth Strategy registered under '${strategyName}' name`);
    }
    return found as unknown as CustomAuthStrategy;
  }

  /**
   * Set auth token after social login
   */
  private processResultToken(result: NbAuthResult): Observable<NbAuthResult> {
    if (result.isSuccess() && result.getToken()) {
      return this.tokenService.set(result.getToken()).pipe(map(() => result));
    }

    return of(result);
  }

  /**
   * Returns the default configured IUserRole for the current user. Returns undefined if user
   * does not have on of the valid roles for the app.
   */
  public getValidRole(validRoles: RoleType[], userRoles: IUserRole[]) {
    const foundValidRole = validRoles?.find(
      (validRole) => !!userRoles?.find((userRole) => userRole.name.toLowerCase() === validRole)
    );
    return userRoles?.find((userRole) => userRole.name.toLowerCase() === foundValidRole);
  }

  /**
   * Get all Saved Roles
   */
  public getAllRoles(): Observable<{ roles: Array<IUserRole> }> {
    return this.apiService.getAll(this.apiConfig.authApiUrl, this.endpoints.roles);
  }
}
