<div class="container">
  <div class="row mb-3 d-flex justify-content-between align-items-center">
    <div class="col-9">
      <div class="text-start">
        <header class="page-header">
          <h1 class="page-title">{{ pageTitle }}</h1>
        </header>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="surveyConfig">
    <div class="col">
      <pes-survey-player
        style="
          --question-header-align: left;
          --question-margin-bottom: 0;
          --survey-header-align: left;
        "
        [surveyConfig]="surveyConfig"
        (surveyCompleted)="onSubmitSurvey($event)"
        [source]="source"
      ></pes-survey-player>
    </div>
  </div>
</div>
