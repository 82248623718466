import { RoleType } from '@targetrwe/pep-shared';

/**
 * Configuration data used for initializing TRWE Auth Module
 */
export interface ITRWEAuthModuleConfig {
  appName: string; // name of the app
  inviteCodeOnRegister: boolean; // true to allow registering a new user with an Invite Code
  socialLogin?: ISocialLogin; // Social login information needed for NbAuth
  defaultRole: RoleType[]; // list of default roles when logging in. The default role is selected from first to last
}

/**
 * Social login configuration data. Used by NbAuth
 */
export interface ISocialLogin {
  clientId: string;
  facebookIdentity: string;
  googleIdentity: string;
  redirectUri: string;
  responseType: string;
  scope: string;
  siteUrl: string;
  targetRWEIdentity: string;
}
