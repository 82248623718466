<div class="row">
  <div class="col d-flex justify-content-start">
    <button nbButton (click)="onBack()" class="me-2 margin-n">
      <i class="fa-solid fa-arrow-left me-1 mt-1"></i>{{ 'back' | translate }}
    </button>
  </div>
</div>

<section class="links">
  <img class="img-fluid d-block main-img" src="{{ brandlogo }}" alt="Engage - Target RWE" />
  <p><i class="fa fa-lg fa-question-circle fa-xl" aria-hidden="true"></i></p>
</section>

<section class="links" aria-label="help">
  <p>
    <span
      ><b>{{ 'helpTextSupport' | translate }}</b></span
    >
  </p>
  <p>
    <span
      ><b
        ><a href="mailto:{{ supportLink }}">{{ supportLink }}</a></b
      ></span
    >
  </p>
</section>
<br />
<div class="separator">
  <b>{{ 'or' | translate }}</b>
</div>
<br />
<section class="links" aria-label="help">
  <p>
    <span
      ><b>{{ 'helpTextGuidance' | translate }}</b></span
    >
  </p>
  <p>
    <span
      ><b
        ><a (click)="onLinkClick()" href="javascript:void(0)">{{ portalUrl }}</a></b
      ></span
    >
  </p>
</section>
<br />
<br />
