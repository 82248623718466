import { Component, inject } from '@angular/core';
import { LOGO_URLS } from '@targetrwe/pep-shared';
import { Location } from '@angular/common';
import { TRWE_AUTH_MODULE_CONFIG } from '../../trwe-auth-module.config';

@Component({
  selector: 'trwe-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class TrweHelpComponent {
  public readonly brandlogo: string = LOGO_URLS.engageLogo;
  public readonly brandmark: string = LOGO_URLS.brandmarkColor;
  private readonly location = inject(Location);
  public supportLink = 'support@targetrwe.com';
  private readonly authModuleSettings = inject(TRWE_AUTH_MODULE_CONFIG);
  public portalUrl =
    this.authModuleSettings?.appName === 'iep'
      ? 'https://targetrwe.com/engage/help/site-config'
      : 'https://targetrwe.com/engage/help/web';

  constructor() {}

  public onLinkClick(): void {
    window.open(this.portalUrl);
  }

  public onBack(): void {
    this.location.back();
  }
}
