/**
 * Possible status for Consent
 */
export enum ConsentStatusEnum {
  ENROLL = 'ENROLL',
  ENROLLED = 'ENROLLED',
  NOT_ENROLLED = 'NOT ENROLLED',
  PENDING_VALIDATION = 'PENDING VALIDATION',
  PENDING_REAFFIRMATION = 'PENDING REAFFIRMATION',
  PENDING_APPROVAL = 'PENDING APPROVAL',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  WITHDRAWN = 'WITHDRAWN',
  PENDING_WITHDRAWAL = 'PENDING WITHDRAWAL',
  PENDING_RECONSENT = 'PENDING RECONSENT',
  RECONSENT_PENDING_VALIDATION = 'RECONSENT PENDING VALIDATION',
  RECONSENT_PENDING_APPROVAL = 'RECONSENT PENDING APPROVAL',
  RECONSENTED = 'RECONSENTED',
  UNKNOWN = 'UNKNOWN',
  WITHDRAWAL_DENIED = 'WITHDRAWAL DENIED',
  INACTIVE = 'INACTIVE',
  PENDING_ENROLLMENT = 'PENDING ENROLLMENT',
  END_OF_STUDY = 'END OF STUDY',
}

/**
 * Action the user is performing with a Consent document
 */
export declare type ConsentAction = 'view' | 'approve' | 'reaffirm' | 'complete';

export enum PaymentProcessor {
  PAYPAL = 'PayPal',
  VENMO = 'Venmo',
}

/**
 * Possible status for END OF STUDY Labels
 */
export enum ConsentStatusEOSLabelEnum {
  Completed_Study = 'Completed Study',
  Withdrawn_Consent = 'Withdrawn Consent',
  Enrollment_in_Clinical_Trial = 'Enrollment in Clinical Trial',
  Protocol_Violation = 'Protocol Violation',
  Withdrawn_by_Investigator = 'Withdrawn by Investigator',
  Lost_to_Follow_Up = 'Lost to Follow Up',
  Death = 'Death',
  Other = 'Withdrawn',
}
