import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IArtifact } from '../../interfaces';
import { API_CONFIG, IApiConfig } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepArtifactsService {
  private readonly endpoints = {
    userStudyMilestones: '/api/v1/user_study_milestone',
  };

  constructor(
    private apiService: PepApiService,
    @Inject(API_CONFIG) private apiConfig: IApiConfig
  ) {}

  /**
   * Upload an artifact
   */
  public uploadArtifacts(data: FormData, id: number): Observable<IArtifact> {
    const uploadURL = `${this.endpoints.userStudyMilestones}/${id}/artifact`;

    return this.apiService.uploadFile<IArtifact>(
      this.apiConfig.studyManagementServiceUrl,
      uploadURL,
      data,
      false
    );
  }

  /**
   * Download the artifact file
   */
  public downloadArtifacts(
    milestoneId: number,
    artifactId: number
  ): Observable<HttpResponse<Blob>> {
    return this.apiService.getFile(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.userStudyMilestones}/${milestoneId}/artifact/${artifactId}`
    );
  }
}
