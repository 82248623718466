import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ICondensedUser, IUserProfile, IUserRole, roleNameToRoleType } from '@targetrwe/pep-shared';

const DEFAULT_PREFERRED_LANGUAGE = 'en_us';

export enum StorageVariables {
  currentUser = 'targetrwe-pep.currentUser',
  activeRole = 'targetrwe-pep.activeRole',
  activeAppName = 'targetrwe-pep.activeAppName',
  language = 'targetrwe-pep.language',
}
/**
 * State for the TrweStore
 */
export interface TrweAuthState {
  condensedUser: ICondensedUser | undefined; // profile of the currently logged-in user
  activeRole: IUserRole | undefined;
  appName: string; // name of the current application (`iep` or `pep`)
  language: string;
}

const initialState: TrweAuthState = {
  condensedUser: undefined,
  activeRole: undefined,
  appName: '',
  language: DEFAULT_PREFERRED_LANGUAGE,
};

/**
 * Provide NgRx Component Store for managing Authentication data
 */
@Injectable({
  providedIn: 'root',
})
export class TrweAuthStore extends ComponentStore<TrweAuthState> {
  constructor() {
    const getObject = (key: string) => {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : undefined;
    };

    // initialize store
    const initState: TrweAuthState = {
      condensedUser: getObject(StorageVariables.currentUser) || initialState.condensedUser,
      activeRole: getObject(StorageVariables.activeRole) || initialState.activeRole,
      appName: localStorage.getItem(StorageVariables.activeAppName) || initialState.appName,
      language: localStorage.getItem(StorageVariables.language) || initialState.language,
    };
    super(initState);
  }

  /**
   * Selects the preferred Language Code for the logged in user.
   */
  readonly preferredLanguage$ = this.select((state) => state.language);

  /**
   * Set the preferred language
   */
  setLanguage = this.updater((state, language: string) => {
    localStorage.setItem(StorageVariables.language, language);
    return {
      ...state,
      language,
    };
  });

  /**
   * Selects the currently logged in User's Id, or 0 if no user logged in.
   */
  readonly loggedInUserId$ = this.select((state) => state.condensedUser?.id || 0);

  /**
   * Selects the currently logged in User
   */
  readonly loggedInUser$ = this.select((state) => state.condensedUser);

  /**
   * set the logged in user data from passed user profile
   */
  setLoggedInUserFromUserProfile = this.updater((state, loggedInUser: IUserProfile | undefined) => {
    const condensedUser = this.condensedUser(loggedInUser);
    localStorage.setItem(StorageVariables.currentUser, JSON.stringify(condensedUser));

    return {
      ...state,
      condensedUser,
    };
  });

  /**
   * The currently active User role
   */
  readonly activeRole$ = this.select((state) => state.activeRole);
  setActiveRole = this.updater((state, activeRole: IUserRole | undefined) => {
    localStorage.setItem(StorageVariables.activeRole, JSON.stringify(activeRole));

    return {
      ...state,
      activeRole,
    };
  });

  readonly appName$ = this.select((state) => state.appName);
  setAppName = this.updater((state, appName: string) => {
    localStorage.setItem(StorageVariables.activeAppName, appName);

    return {
      ...state,
      appName,
    };
  });

  /**
   * Reset the store to initial state
   */
  reset() {
    for (const storageKey of Object.values(StorageVariables)) {
      localStorage.removeItem(storageKey);
    }
    this.setState(initialState);
  }

  /**
   * only store what the apps need, and the don't need a full IUserProfile, only condensed version
   */
  private condensedUser(user: IUserProfile | undefined): ICondensedUser | undefined {
    if (user) {
      return {
        id: user.id,
        username: user.username,
        email: user.email,
        roles:
          user.roles?.map((role) => ({
            ID: role.ID,
            name: role.name,
            type: roleNameToRoleType(role.name),
          })) || [],
        refresh_token: user.refresh_token,
        preferred_language_code: user.preferred_language_code,
        first_name: user.person.first_name,
        last_name: user.person.last_name,
      } as ICondensedUser;
    }
    return undefined;
  }
}
