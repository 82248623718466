import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'pes-app-pagination',
  templateUrl: './app-pagination.component.html',
  styleUrls: ['./app-pagination.component.scss'],
})
export class PesAppPaginationComponent implements OnChanges {
  @Input() public totalRecords!: number;
  @Input() public itemsPerPage = 10;
  @Input() public noDataFound = 'No data to display.';
  @Output() public pageChange = new EventEmitter<number>();

  public pagerArray = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  public showPagesUpto = 6;

  // public showFirstElipse: boolean = false;
  // public showLastElipse: boolean = false;
  public isLastElipseVisible = false;
  public isPreviousElipseVisible = false;

  public isPreviousDisabled = true;
  public isNextDisabled = false;

  public totalPages = 0;
  public pageRange: number[] = [];

  public currentPageIndex = 1;
  public startRangePageIndex = 2; // start page index will not include 1
  public endRangePageIndex = 0; // end page index will not include last page

  constructor() {}

  public ngOnChanges() {
    // if there are more than one page the create page range array
    this.totalPages = this.getTotalPages();
    if (this.totalPages > 1) {
      // this.calulatePageRangeIndex(this.startRangePageIndex);
      if (this.totalPages <= this.showPagesUpto) {
        this.endRangePageIndex = this.totalPages - 1;
        // this.showLastElipse = false; // hide last ellipse if total pages less than 10
      } else {
        this.endRangePageIndex = this.showPagesUpto;
        this.isLastElipseVisible = true;
        // this.showLastElipse = true; // show last ellipse if total pages greater than 10
      }
      // page range will not contain 1 and last page
      this.pageRange = this.getPageRangeArray(this.startRangePageIndex, this.endRangePageIndex);
      this.showLastElipse();
    }
    this.showLastElipse();
    this.showPreviousElipse();
  }

  public selectPagesPerItems(noOfItemsPerPage: number) {
    this.itemsPerPage = noOfItemsPerPage;
    this.totalPages = this.getTotalPages();
    this.startRangePageIndex = 2;
    if (this.totalPages > 1) {
      // this.calulatePageRangeIndex(this.startRangePageIndex);
      if (this.totalPages <= this.showPagesUpto) {
        this.endRangePageIndex = this.totalPages - 1;
        // this.showLastElipse = false; // hide last ellipse if total pages less than 10
      } else {
        this.endRangePageIndex = this.showPagesUpto;
        this.isLastElipseVisible = true;
        // this.showLastElipse = true; // show last ellipse if total pages greater than 10
      }
      // page range will not contain 1 and last page
      this.pageRange = this.getPageRangeArray(this.startRangePageIndex, this.endRangePageIndex);
      // this.showLastElipse();
    }
    this.currentPageIndex = 1;
    this.pageChange.emit(this.currentPageIndex);

    this.showLastElipse();
    this.showPreviousElipse();
  }

  public checkCurrentIndexIsInLastPagerWindow() {
    if (
      this.endRangePageIndex === this.getTotalPages() ||
      this.endRangePageIndex === this.getTotalPages() - 1
    ) {
      if (this.currentPageIndex >= this.startRangePageIndex) {
        return true;
      }
    }
    return false;
  }

  public checkCurrentIndexIsInFirstPagerWindow() {
    if (this.startRangePageIndex === 1 || this.startRangePageIndex === 1 + 1) {
      if (this.currentPageIndex <= this.endRangePageIndex) {
        return true;
      }
    }
    return false;
  }

  private getTotalPages() {
    return Math.ceil(this.totalRecords / this.itemsPerPage);
  }

  public get isFirstPage(): boolean {
    return 1 == this.currentPageIndex;
  }

  public get isLastPage(): boolean {
    return this.getTotalPages() == this.currentPageIndex;
  }

  public showFirstElipse() {
    return this.currentPageIndex > this.showPagesUpto; // if current index pass the total pages to show
  }

  private showPreviousElipse() {
    if (this.getTotalPages() > this.showPagesUpto + 1) {
      if (this.checkCurrentIndexIsInFirstPagerWindow()) {
        this.isPreviousElipseVisible = false;
      } else {
        this.isPreviousElipseVisible = true;
      }
    } else {
      this.isPreviousElipseVisible = false;
    }
  }

  private showLastElipse() {
    // show last ellipse only if total pages are greater than pages to show
    //and current pages index is not in last window(range of page array)
    if (this.getTotalPages() > this.showPagesUpto + 1) {
      if (this.checkCurrentIndexIsInLastPagerWindow()) {
        this.isLastElipseVisible = false;
      } else {
        this.isLastElipseVisible = true;
      }
    } else {
      this.isLastElipseVisible = false;
    }
  }

  private get currentPageIndexIsLastIndex() {
    return this.currentPageIndex == this.endRangePageIndex;
  }

  private get isCurrentPageIndexFirstIndex() {
    return this.currentPageIndex == this.startRangePageIndex;
  }

  private setMiddleOfPage(selectedPage: number) {
    const midIndex = Math.ceil(this.showPagesUpto / 2);

    this.endRangePageIndex = selectedPage + midIndex;
    this.endRangePageIndex =
      this.endRangePageIndex >= this.totalPages ? this.totalPages - 1 : this.endRangePageIndex;
    this.startRangePageIndex = selectedPage - midIndex;
    this.startRangePageIndex = this.startRangePageIndex <= 1 ? 2 : this.startRangePageIndex;
    this.pageRange = this.getPageRangeArray(this.startRangePageIndex, this.endRangePageIndex);
  }

  /**
   *
   * @param pageNumber pageNumber to select
   *
   * this method is called on each click on page number
   */

  public selectPage(selectedPage: number) {
    this.currentPageIndex = selectedPage;
    if (this.isLastElipseVisible && selectedPage == this.getTotalPages()) {
      this.endRangePageIndex = this.getTotalPages() - 1;
      this.startRangePageIndex = this.getTotalPages() - this.showPagesUpto;

      this.pageRange = this.getPageRangeArray(this.startRangePageIndex, this.endRangePageIndex);
    } else if (this.isPreviousElipseVisible && selectedPage == 1) {
      this.startRangePageIndex = 2;
      if (this.getTotalPages() <= this.showPagesUpto) {
        this.endRangePageIndex = this.totalPages - 1;
        // this.showLastElipse = false; // hide last ellipse if total pages less than 10
      } else {
        this.endRangePageIndex = this.showPagesUpto;
        this.isLastElipseVisible = true;
        // this.showLastElipse = true; // show last ellipse if total pages greater than 10
      }
      this.pageRange = this.getPageRangeArray(this.startRangePageIndex, this.endRangePageIndex);
    } else if (
      (this.isLastElipseVisible && selectedPage == this.endRangePageIndex) || // if selected page is the last index of pager window
      (this.isPreviousElipseVisible && selectedPage == this.startRangePageIndex) // if selected page is the first index of pager window
    ) {
      this.setMiddleOfPage(selectedPage);
    }

    this.showLastElipse();
    this.showPreviousElipse();

    this.pageChange.emit(this.currentPageIndex);
  }

  public selectPreviousPage(): void {
    // NOTE: The current index will be decrement after all the logic has been applied
    // check if ther are more pager window to show or not on left
    //and current page index is on stat index of pager window
    if (this.isPreviousElipseVisible && this.isCurrentPageIndexFirstIndex) {
      if (this.startRangePageIndex - 1 > this.showPagesUpto) {
        // check if last pager window length is greater than show pages upto
        this.endRangePageIndex = this.startRangePageIndex - 1;
        this.startRangePageIndex = this.startRangePageIndex - this.showPagesUpto; // assign start page index for next pager window to end index of previos winow + 1
      } else {
        // if last window size is equal to show pages upto
        this.startRangePageIndex = 2; // incrementing the
        this.endRangePageIndex = this.showPagesUpto; // excluding the last page
      }
      this.pageRange = this.getPageRangeArray(this.startRangePageIndex, this.endRangePageIndex);
    }

    // now incrementing the current page index after all logic above
    this.currentPageIndex = this.currentPageIndex - 1;

    // check to show last ellipse
    this.showLastElipse();
    this.showPreviousElipse();
    this.pageChange.emit(this.currentPageIndex);
  }

  public selectNextPage(): void {
    // NOTE: The current index will be increment after all the logic has been applied
    // check if there are more pager window to show or not
    //and current page index is on last pager window index
    if (this.isLastElipseVisible && this.currentPageIndexIsLastIndex) {
      if (this.getTotalPages() - this.endRangePageIndex < this.showPagesUpto) {
        // check if last pager window length is less than show pages upto
        this.startRangePageIndex = this.getTotalPages() - this.showPagesUpto;
        this.endRangePageIndex = this.getTotalPages() - 1; // excluding last page
      } else if (this.getTotalPages() - this.endRangePageIndex > this.showPagesUpto) {
        // check if last pager window length is greater than show pages upto
        this.startRangePageIndex = this.endRangePageIndex + 1; // assign start page index for next pager window to end index of previos winow + 1
        this.endRangePageIndex = this.endRangePageIndex + this.showPagesUpto;
      } else {
        // if last window size is equal to show pages upto
        this.startRangePageIndex = this.endRangePageIndex + 1; // incrementing the
        this.endRangePageIndex = this.getTotalPages() - 1; // excluding the last page
      }
      this.pageRange = this.getPageRangeArray(this.startRangePageIndex, this.endRangePageIndex);
    }
    // now incrementing the current page index after all logic above
    this.currentPageIndex = this.currentPageIndex + 1;

    // check to show previous ellipse
    this.showPreviousElipse();
    // check to show last ellipse
    this.showLastElipse();

    this.pageChange.emit(this.currentPageIndex);
  }

  private getPageRangeArray(start: number, end: number) {
    // eslint-disable-next-line prefer-spread
    return Array.apply(null, Array(end + 1))
      .map(function (_, i) {
        return i;
      })
      .filter(function (j) {
        return j >= start;
      });
  }

  public get showPagination(): boolean {
    return this.totalRecords > this.itemsPerPage;
  }
}
