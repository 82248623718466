import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, of } from 'rxjs';
import {
  IElements,
  IQuestionnaire,
  ISurveyPlayerConfig,
  ISurveyResponse,
  ISurveyResult,
  IUserQuestionnaireResponse,
} from '../../interfaces';
import { PesPatientQuestionnaireConfig } from '../../interfaces/patient-questionnaire-config.interface';
import {
  PepToastNotificationService,
  PepMilestoneService,
  PepPdfExportService,
} from '../../services';
import { PepQuestionnaireService } from '../../services/api-services/questionnaire.service';
import { pepTranslate } from '../../utilities';

@Component({
  selector: 'pes-patient-questionnaire',
  templateUrl: './patient-questionnaire.component.html',
  styleUrls: ['./patient-questionnaire.component.scss'],
})
export class PesPatientQuestionnaireComponent implements OnInit {
  public userFormData!: IElements;
  private questionnaireId = 0;

  private readonly questionnaireService = inject(PepQuestionnaireService);
  private readonly toastService = inject(PepToastNotificationService);
  private readonly translate = pepTranslate();
  private readonly pdfExportService = inject(PepPdfExportService);
  private readonly milestoneService = inject(PepMilestoneService);
  private readonly router = inject(Router);

  // configuration data provided by Router on navigation
  private readonly configData = this.router.getCurrentNavigation()?.extras
    ?.state as PesPatientQuestionnaireConfig;

  public get pageTitle() {
    return this.configData?.labels.pageTitle;
  }

  public get backButtonLabel() {
    return this.configData?.labels.backButtonLabel;
  }

  private readonly questionnaireConfig = {
    userId: this.configData?.userId,
    questionnaireId: this.configData?.questionnaireId,
    userStudyMilestoneId: this.configData?.userStudyMilestoneId,
  };

  public surveyConfig: ISurveyPlayerConfig | undefined;

  /**
   * Initialize the component
   */
  public ngOnInit(): void {
    if (!this.configData) {
      // no config data, so go to home page
      this.router.navigateByUrl('/');
      return;
    }

    this.initializeSurvey();
  }

  /**
   * Load all required data for the survey and display it
   */
  private initializeSurvey() {
    this.questionnaireService
      .getQuestionnaireById(this.questionnaireConfig.questionnaireId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(
            error,
            this.translate('site_questionnaire--error-getting')
          );
          return of(undefined);
        })
      )
      .subscribe((questionnaire) => {
        if (questionnaire) {
          this.initWithQuetionnaire(questionnaire);
        }
      });
  }

  /**
   * Initialize the survey with the passed IQuestionnaire
   *
   * @param questionnaire
   */
  private initWithQuetionnaire(questionnaire: IQuestionnaire) {
    if (questionnaire?.json_content) {
      this.questionnaireId = questionnaire.id;
      const pages = [questionnaire.json_content];

      this.surveyConfig = {
        survey: {
          pages,
        },
        prefillResponses: {} as ISurveyResponse,
        readonly: false,
      };
    }
  }

  /**
   * Custom method to implement submission of answers to backend
   */
  public onSubmitSurvey(surveyResult: ISurveyResult): void {
    const responses = surveyResult.responses;

    const request: IUserQuestionnaireResponse = {
      questionnaire_id: this.questionnaireConfig.questionnaireId,
      response: responses as { [key: string]: string },
      updated_by_id: this.questionnaireConfig.userId,
      user_id: this.questionnaireConfig.userId,
      user_study_milestone_id: this.questionnaireConfig.userStudyMilestoneId,
    };

    this.questionnaireService
      .submitQuestionnaire(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(error, this.translate('questionnaire-saving-error'));
          return of(false);
        })
      )
      .subscribe((questionnaireResponse) => {
        if (questionnaireResponse) {
          // update the survey with the response data.  Needed so we can upload the PDF
          this.surveyConfig?.survey.pages.forEach((page) => {
            page?.elements.forEach((element) => {
              const er = responses[element?.name || ''];
              if (er) {
                if (element.type === 'svgmap') {
                  (element as any).paths = er;
                } else {
                  element.defaultValue = er;
                }
              }
            });
            // Successfully saved all data about this survey
            this.uploadSurveyResultPDFs();
          });
        }
      });
  }

  /**
   * create the blob data from JSON
   */
  private uploadSurveyResultPDFs(): void {
    if (!this.surveyConfig) {
      throw new Error(`No Survey Configured`);
    }

    this.pdfExportService
      .getSurveyBlob(this.surveyConfig.survey)
      .then((blobfile: string) => this.uploadArtifact(blobfile));
  }

  /**
   * upload file/artifact into the S3 bucket
   */
  private uploadArtifact(blobfile: string): void {
    const formData = new FormData();
    formData.append('description', 'filled-form');
    formData.append(
      'file',
      blobfile,
      `filled-form-${this.questionnaireConfig.userStudyMilestoneId}-${this.questionnaireId}`
    );
    this.milestoneService
      .uploadArtifacts(formData, this.questionnaireConfig.userStudyMilestoneId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.toastService.showErrorToast(
            error.error instanceof ProgressEvent ? error.statusText : error.error
          );
          return of(undefined);
        })
      )
      .subscribe((response) => {
        if (response) {
          this.toastService.showSuccessToast(
            this.translate('questionnaire-submitted-successfully')
          );
        }
      });
  }

  /**
   * Navigate to previous page
   */
  public back() {
    this.router.navigateByUrl(this.configData?.backUrl || '/admin/participant-management');
  }
}
