import { Injectable } from '@angular/core';
import { INGXLoggerConfig, NGXLogger, NgxLoggerLevel } from 'ngx-logger';

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Configuration properties for TuiLoggingService
 */
export type PepLoggingConfig = INGXLoggerConfig;

/**
 * Available logging levels.
 *
 * From most to least informational:
 *  TRACE, DEBUG, INFO, LOG, WARN, ERROR, FATAL, OFF
 *
 * @link
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export import LoggingLevel = NgxLoggerLevel;

/**
 * Common logging service for TargetUI library and applications.
 *
 * Provides methods for logging messages at various levels.
 *
 * This service is a wrapper around the `ngx-logger` project.
 *
 * @link https://github.com/dbfannin/ngx-logger
 *
 */
@Injectable({ providedIn: 'root' })
export class PepLoggingService {
  constructor(private logger: NGXLogger) {}

  /**
   * Return the current configuration for the logger
   *
   * @returns the current TuiLoggingConfig
   */
  getConfig(): PepLoggingConfig {
    return this.logger.getConfigSnapshot();
  }

  /**
   * Replace the existing configuration with a new one.
   *
   * If you only want to modify the configuration, then use `getConfig` to fetch
   * the existing configuration, make changes to it, and pass the modified object
   * to this function.
   *
   * @example
   * ```
   *   const oldConfig = this.logger.getConfig();
   *   const newConfig = {
   *      ...oldConfig,
   *      disableConsoleLogging: true
   *   };
   *   this.logger.updateConfig(newConfig);
   *
   * ```
   *
   * See this link for information on available configuration settings
   * @link https://github.com/dbfannin/ngx-logger/blob/master/docs/config.md
   *
   * @param config the new configuration to use
   */
  updateConfig(config: PepLoggingConfig) {
    this.logger.updateConfig(config);
  }

  trace(message?: any | (() => any), ...additional: any[]): void {
    this.logger?.trace(message, ...additional);
  }

  debug(message?: any | (() => any), ...additional: any[]): void {
    this.logger?.debug(message, ...additional);
  }

  info(message?: any | (() => any), ...additional: any[]): void {
    this.logger?.info(message, ...additional);
  }

  log(message?: any | (() => any), ...additional: any[]): void {
    this.logger?.log(message, ...additional);
  }

  warn(message?: any | (() => any), ...additional: any[]): void {
    this.logger?.warn(message, ...additional);
  }

  error(message?: any | (() => any), ...additional: any[]): void {
    this.logger?.error(message, ...additional);
  }

  fatal(message?: any | (() => any), ...additional: any[]): void {
    this.logger?.fatal(message, ...additional);
  }
}
