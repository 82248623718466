/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PepSpinnerService } from '@targetrwe/pep-shared';
import { Observable, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { TrweAuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class TrweAuthInterceptor implements HttpInterceptor {
  private readonly authService = inject(TrweAuthService);
  private readonly spinner = inject(PepSpinnerService);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, body, method } = req;
    const userCall = '/user/';

    switch (true) {
      // logging in
      case url.endsWith('/login') && !body.refresh_token && method === 'POST':
        if (body.refresh === 'true' && body.refresh_token === '') {
          this.authService.logout();
          this.spinner.hide();
        }
        this.spinner.show();
        return next.handle(req).pipe(
          tap({
            error: (err) => {
              if (err instanceof HttpErrorResponse) {
                this.handleError(err);
              }
              return undefined;
            },
          }),
          finalize(() => {
            this.spinner.hide();
          })
        );

      // logging in with social login
      case url.endsWith('/auth') && method === 'GET':
        this.spinner.show();
        return next.handle(req).pipe(
          tap({
            error: (err) => {
              if (err instanceof HttpErrorResponse) {
                this.handleError(err);
              }
              return undefined;
            },
          }),
          finalize(() => {
            this.spinner.hide();
          })
        );

      /**
       * if user is logging in from portal with email, need to set the current user
       * (check for email because a user can be retrieved with id also and we do not want to update the current user if so)
       */
      case url.indexOf(userCall) > 0 &&
        url.includes('@') &&
        !url.endsWith('/confirm') &&
        method === 'GET':
        this.spinner.show();
        return next.handle(req).pipe(
          finalize(() => {
            this.spinner.hide();
          })
        );

      default:
        if (!req.reportProgress) {
          this.spinner.show();
        }
        return next.handle(req).pipe(
          finalize(() => {
            this.spinner.hide();
          }),
          tap({
            error: (err: HttpErrorResponse) => {
              if (err instanceof HttpErrorResponse) {
                this.handleError(err);
              }
              return undefined;
            },
          })
        );
    }
  }
  private handleError(err: any) {
    this.spinner.hide();
    if (err.error instanceof ProgressEvent || err.error instanceof ErrorEvent) {
      const errorToThrow = { msg: { Message_: err.message } };
      return throwError(() => errorToThrow);
    } else {
      return throwError(() => err.error);
    }
  }
}
