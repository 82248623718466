import { Component, inject, Input, OnInit } from '@angular/core';
import { NbComponentStatus, NbDialogRef } from '@nebular/theme';
import { pepTranslate } from '../../../utilities';

@Component({
  selector: 'pes-confirmation',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class PesConfirmationModalComponent implements OnInit {
  @Input() public title!: string;
  @Input() public body!: string;
  @Input() public okButtonTitle!: string;
  @Input() public cancelButtonTitle!: string;
  @Input() public showCancel = true;
  @Input() public icon!: string;
  @Input() public buttonStatus: NbComponentStatus = 'primary';
  @Input() public okButtonIcon!: string;
  public readonly dialogRef = inject(NbDialogRef<PesConfirmationModalComponent>);
  private readonly translate = pepTranslate();

  constructor() {}

  ngOnInit(): void {
    // set default vaues for those not provided
    this.title = this.title || this.translate('confirmation');
    this.okButtonTitle = this.okButtonTitle || this.translate('okButtonTitle');
    this.cancelButtonTitle = this.cancelButtonTitle || this.translate('cancel');
  }

  /**
   * Close the dialog and return the confirmation state
   * @param confirm true if confirm/ok selected, else false
   */
  public onClose(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
