import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { RoleType } from '@targetrwe/pep-shared';
import { map, withLatestFrom } from 'rxjs/operators';
import { TrweAuthStore } from './auth-store.service';
import { TrweAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TrweAuthGuard implements CanActivate {
  private readonly nbAuthService = inject(NbAuthService);
  private readonly router = inject(Router);
  private readonly authStore = inject(TrweAuthStore);
  private readonly authService = inject(TrweAuthService);

  public readonly loginUrlTree = this.router.createUrlTree(['auth/login']);
  public readonly unauthorizedUrlTree = this.router.createUrlTree(['pages/unauthorized']);

  canActivate(route: ActivatedRouteSnapshot) {
    return this.nbAuthService.isAuthenticatedOrRefresh().pipe(
      withLatestFrom(this.authStore.activeRole$),
      map(([authenticated, activeRole]) => {
        if (!authenticated) {
          this.authService.logout();
          this.router.navigate(['auth/login']);
          return false;
        } else {
          if (activeRole) {
            if (route.queryParams && route.queryParams['role'] === RoleType.StudySiteAdmin) {
              return true;
            }

            // current user is not authoriized for this link
            if (
              route.data['roles'] &&
              route.data['roles'].indexOf(activeRole.name.toLowerCase().replace(/\s/g, '')) === -1
            ) {
              this.authService.logout();
              this.router.navigate(['pages/unauthorized']);
              return false;
            } else {
              // current user is authorized for this link
              return true;
            }
          } else {
            // no current user, so force a login
            this.authService.logout();
            this.router.navigate(['auth/login']);
            return false;
          }
        }
      })
    );
  }
}
