// Environemnt for Dev
import workspacePackage from '../../../../package.json';

export const environment = {
  appVersion: workspacePackage.version + '-dev',
  logLevel: 'debug', // debug (most debugging), info, warn, error
  loggingClients: [
    {
      isActive: true,
      loggerLocation: '',
      loggerName: 'logConsole',
      maxRecords: 0, // currently not used
    },
    {
      isActive: true,
      loggerLocation: 'applogging',
      loggerName: 'logStorage',
      maxRecords: 50,
    },
    {
      isActive: false,
      loggerLocation: 'http://log.devtest.targetpharmasolutions.com:51480/target',
      loggerName: 'logWebApi',
      maxRecords: 0, // currently not used
    },
  ],
  name: 'dev',
  production: false,
  socialLogin: {
    clientId: '7ga3cg7me741osq7bbs4md98sm',
    facebookIdentity: 'Facebook',
    googleIdentity: 'Google',
    redirectUri: 'https://pep.dev.trwe.net/auth/sociallogin',
    responseType: 'CODE',
    scope: 'aws.cognito.signin.user.admin%20email%20openid%20phone%20profile',
    siteUrl: 'https://peptps.auth.us-east-2.amazoncognito.com/oauth2/authorize',
    targetRWEIdentity: 'TargetADFS',
  },
  timeStamp: '02/03/2025 06:22:09 AM EST',
  targetrweLiveLink: 'https://targetrwe.com/',
  targetApplicationLink: 'https://iep.dev.trwe.net/auth/login',
  paypalLoginConfig: {
    appid: 'AY1-d1b9pF7VZ8snh4fGset5LymC9s25RacfpwbXXPVmiEtTWFVM02sY24n2aaCuTdbv--Om7bLFS4SS',
    authend: 'sandbox',
    scopes: 'openid profile email', //Add https://uri.paypal.com/services/paypalattributes for Account verification status, PayPal ID
    containerid: 'lippButton',
    responseType: 'code',
    locale: 'en-us',
    buttonType: 'LWP',
    buttonShape: 'pill',
    buttonSize: 'lg',
    fullPage: 'false',
    returnurl: 'https%3A%2F%2Fpep.dev.trwe.net%2Fauth%2Flink-account',
    nonce: 1111111,
  },

  // Service endpoints
  authApiUrl: 'https://pep.dev.trwe.net:8080',
  consentServiceBaseUrl: 'https://pep.dev.trwe.net:8085',
  languageServiceUrl: 'https://pep.dev.trwe.net:8084',
  messageServiceUrl: 'https://pep.dev.trwe.net:8089',
  studyManagementServiceUrl: 'https://pep.dev.trwe.net:8085',
  studyTimepointsServiceUrl: 'https://pep.dev.trwe.net:8085',
  surveyServiceBaseUrl: 'https://pep.dev.trwe.net:8085',
  timepointsServiceUrl: 'https://pep.dev.trwe.net:8085',
  userPreferenceServiceUrl: 'https://pep.dev.trwe.net:8088',
  milestoneRulesServiceUrl: 'https://rules-dev.targetrwe.net:10090',
  odsGraphQLUrl: 'https://pep.dev.trwe.net:8090/query',
  studyManagementGraphQLUrl: 'https://pep.dev.trwe.net:8085/query',
  paymentServiceUrl: 'https://pep.dev.trwe.net:9090',
  paypalLoginApiScriptUrl: 'https://www.paypalobjects.com/js/external/api.js',
};
