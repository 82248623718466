import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

/**
 * Manage reusable form validators.
 */

@Injectable({ providedIn: 'root' })
export class PepFormValidatorsService {
  /**
   * validator to ensure confirm password matches password
   */
  public passwordMatchingValidation(group: AbstractControl) {
    const control = group.get('password');
    const matchingControl = group.get('confirmPassword');

    if (group?.errors && !group?.errors['mustMatch']) {
      return null;
    }

    // set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
      return { mustMatch: true };
    } else {
      return null;
    }
  }
  /**
   * Validator for unique milestone_name
   *
   * @param names Array of existing milestone names for this study
   * @param editMilestoneName if editing, the milestone_name of the milestone
   */
  public uniqueMilestoneValidator(names: string[], editMilestoneName?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      let nameExists = false;
      if (editMilestoneName) {
        nameExists = names.includes(value) && editMilestoneName !== value;
      } else {
        nameExists = names.includes(value);
      }
      return nameExists ? { notUnique: true } : null;
    };
  }

  /**
   * must contain:
   * 8-50 characters, both upper/lowercase, one digit, and special symbol.
   */
  public get passwordPattern(): string {
    return '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
  }

  /**
   * email format
   */
  public get emailPattern(): string {
    return '.+@.+..+';
  }

  /**
   * no spaces and only upper lower and numbers
   */
  public get noSpacePattern(): string {
    return '^[a-zA-Z0-9]+$';
  }

  public get emptySpacesPattern(): string {
    return '.*\\S.*[a-zA-z0-9 ]';
  }
  /**
   * no spaces and only numbers for 10 digits
   */
  public get phonePattern(): string {
    return '^[0-9]{10}$';
  }

  /**
   * numbers only pattern
   */
  public get numbersOnlyPattern(): string {
    return '^[0-9]+$';
  }

  public get decimalOnlyPattern(): string {
    return '^([0-9]*[0-9]).[0-9]*$';
  }

  /**
   * validator to ensure email matches verify email
   */
  public emailMatchingValidation(group: AbstractControl) {
    const control = group.get('email');
    const matchingControl = group.get('verifyEmail');

    if (group?.errors && !group?.errors['mustMatch']) {
      return null;
    }

    // set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
      return { mustMatch: true };
    } else {
      return null;
    }
  }

  /**
   * validator to ensure email matches verify email
   */
  public phoneMatchingValidation(group: AbstractControl) {
    const control = group.get('phone');
    const matchingControl = group.get('verifyPhone');

    if (group?.errors && !group?.errors['mustMatch']) {
      return null;
    }

    // set error on matchingControl if validation fails
    if (control?.value !== matchingControl?.value) {
      return { mustMatch: true };
    } else {
      return null;
    }
  }
}
