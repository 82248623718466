import { IUserRole } from './user-role.interface';

/**
 * Data describing a User
 */
export interface IUserInfo {
  email: string;
  first_name: string;
  last_name: string;
  title: string;
  user_id: number;
  roles?: IUserRole[];
}
