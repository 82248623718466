<ng-template #formControlDebugInfo let-control="control">
  Control: {{ getControlName() }}({{ control?.status }}): {{ control?.value | json }}
</ng-template>

<ng-template #formGroupDebugInfo let-control="control">
  Group: {{ getControlName() }}({{ control?.status }}):
  <pes-form-debug
    *ngFor="let c of getChildren(control)"
    [recursive]="recursive"
    [control]="c"
  ></pes-form-debug>
</ng-template>

<ng-template #formArrayDebugInfo let-control="control">
  Array: {{ getControlName() }}({{ control?.status }}):
  <pes-form-debug
    *ngFor="let c of getChildren(control)"
    [recursive]="recursive"
    [control]="c"
  ></pes-form-debug>
</ng-template>

<code>
  <ul [ngSwitch]="controlType">
    <li [class.valid]="control.status === 'VALID'" [class.invalid]="control.status === 'INVALID'">
      <ng-container
        *ngSwitchCase="'control'"
        [ngTemplateOutlet]="formControlDebugInfo"
        [ngTemplateOutletContext]="{control}"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'group'"
        [ngTemplateOutlet]="formGroupDebugInfo"
        [ngTemplateOutletContext]="{control}"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'array'"
        [ngTemplateOutlet]="formArrayDebugInfo"
        [ngTemplateOutletContext]="{control}"
      ></ng-container>
    </li>
  </ul>
</code>
