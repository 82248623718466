import {
  Component,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import * as widgets from 'surveyjs-widgets';
import * as Survey from 'survey-angular';
import { init as initCustomWidget } from '../survey-widgets/custom-widget';
import { svginit as initSvgWidget } from '../survey-widgets/svgmapwidget';

import { CommonModule } from '@angular/common';
import { ISurveyPlayerConfig } from '../../interfaces/survey-player-config.interface';
import { ISurveyResult } from '../../interfaces';
import { svginit as initSvgMapWidget } from '../survey-widgets/svgmapwidget';
import { surveyPlayerService } from './survey-player.service';
import { Converter } from 'showdown';

type CssClasses = {
  [key: string]: string | CssClasses;
};

const PES_SURVEY_CONTAINER_ID = 'pes-survey-container';

/**
 * Displays and managers running a SurveyJS survey
 */
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'pes-survey-player',
  template: `<div id="${PES_SURVEY_CONTAINER_ID}" class="${PES_SURVEY_CONTAINER_ID}"></div>`,
  styleUrls: ['./survey-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PesSurveyPlayerComponent implements OnInit {
  @Input() surveyConfig!: ISurveyPlayerConfig;
  @Output() public surveyCompleted = new EventEmitter<ISurveyResult>();
  private readonly surveyPlayerService = inject(surveyPlayerService);

  private readonly ngZone = inject(NgZone);

  constructor() {}

  public ngOnInit() {
    this.initSurveyPlayer();
    this.runSurvey(this.surveyConfig);
  }

  /**
   * Initializes SurveyJS and adds any custom widgets
   */
  private initSurveyPlayer() {
    widgets.icheck(Survey);
    widgets.select2(Survey);
    widgets.inputmask(Survey);
    widgets.jquerybarrating(Survey);
    widgets.jqueryuidatepicker(Survey);
    widgets.nouislider(Survey);
    widgets.select2tagbox(Survey);
    widgets.sortablejs(Survey);
    widgets.ckeditor(Survey);
    widgets.autocomplete(Survey);
    widgets.bootstrapslider(Survey);
    widgets.prettycheckbox(Survey);
    widgets.emotionsratings(Survey);
    initSvgMapWidget(Survey);

    initCustomWidget(Survey);
    initSvgWidget(Survey);

    Survey.JsonObject.metaData.addProperty('questionbase', 'popupdescription:text');
    Survey.JsonObject.metaData.addProperty('page', 'popupdescription:text');

    Survey.StylesManager.applyTheme('default'); // `default` is old style. Also availabe are `defaultV2` and `modern`
  }

  /**
   * Begins the survey
   * @params surveyJson the SurveyJS definition to run
   */
  private runSurvey(config: ISurveyPlayerConfig) {
    const survey = new Survey.Model(config.survey);
    const converter = new Converter();
    survey.onTextMarkdown.add((survey: any, options: any) => {
      let str = converter.makeHtml(options.text);
      str = str.replace('<p>', '');
      str = str.replace('</p>', '');
      options.html = str;
    });
    survey.onAfterRenderQuestion.add(this.afterRenderQuestionHandler.bind(this));
    survey.onComplete.add(this.completeHandler.bind(this));
    survey.onUpdateQuestionCssClasses.add(this.updateQuestionCssClassesHandler.bind(this));

    const customCss = this.surveyPlayerService.getCustomCss();

    Survey.SurveyNG.render(PES_SURVEY_CONTAINER_ID, { model: survey, css: customCss });

    if (config.prefillResponses) {
      survey.data = config.prefillResponses;
    }

    survey.locale = config.preferredLanguage || '';

    if (config.readonly) {
      survey.mode = 'display';
    }
  }

  /**
   * 	The event is fired right after a question is rendered in DOM. Use it to modify HTML elements.
   */
  private afterRenderQuestionHandler(
    survey: Survey.SurveyModel,
    options: { htmlElement: HTMLElement; question: Survey.Question }
  ) {
    this.ngZone.run(() => {
      // if this is a signature pad, then modify it to look like we want
      if (options.question.getType() === 'signaturepad' && !options.question.isReadOnly) {
        const canvas = document.querySelector('canvas');
        if (canvas && canvas.parentElement) {
          const signatureParentWidth = canvas.parentElement.clientWidth;
          options.question.width = `${signatureParentWidth}`;
        }
        // options.question.height = 200;
      }
    });
  }

  /**
   * The event is fired before rendering a question. Use it to override the default question CSS classes.
   */
  private updateQuestionCssClassesHandler(
    survey: Survey.SurveyModel,
    options: { question: Survey.Question; cssClasses: CssClasses }
  ) {
    this.ngZone.run(() => {
      const classes = options.cssClasses;
      const question = options.question;

      if (question.getType() === 'signaturepad') {
        classes['root'] += ' sq-root-signature';
      }
    });
  }

  /**
   * The event is fired after a user clicks the 'Complete' button and finishes a survey.
   */
  private completeHandler(result: Survey.SurveyModel) {
    this.ngZone.run(() => {
      const now = new Date();
      const date = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
      const time = now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
      const dateTime = date + ' ' + time;
      this.surveyCompleted.emit({ responses: result.data, completedAt: dateTime });
    });
  }
}
