<nb-card class="dialog-card">
  <nb-card-header>
    {{ 'consent_verification--title' | translate }}
    <div class="float-end">
      <nb-icon icon="close-outline" (click)="close()"></nb-icon>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form #form="ngForm" aria-labelledby="title">
      <div class="row mt-2">
        <div class="col-12 mb-2">
          <p
            [innerHTML]="'enterCode' | translate: { studyName: studyName, siteName: siteName }"
          ></p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <pes-form-input
            [control]="otp"
            [size]="'large'"
            label="Validation Code"
            [showErrors]="submitted"
            [errors]="{ required: translate('formError_required') }"
          >
          </pes-form-input>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <div class="col d-flex justify-content-end">
        <div class="row">
          <div class="col-12 align-right">
            <button
              class="me-2"
              nbButton
              type="button"
              status="primary"
              size="medium"
              (click)="onValidateOTP()"
            >
              {{ 'consent_verification--validate' | translate }}
            </button>
            <button
              nbButton
              type="button"
              outline
              status="basic"
              size="medium"
              (click)="onResend()"
            >
              {{ 'consent_verification--resend' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
