import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ISort } from '../interfaces/sort.interface';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'sort',
})
export class PesSortPipe implements PipeTransform {
  public transform<T>(value: T[], sortObject: ISort): T[] {
    try {
      if (value?.length === 0) {
        return [];
      }
      if (!sortObject || !sortObject.order || !sortObject.orderBy) {
        return value;
      }
      const direction = sortObject.order === 'asc' ? -1 : 1;
      value.sort((a: any, b: any) => {
        let param1 = a[sortObject.orderBy];
        let param2 = b[sortObject.orderBy];
        if (typeof param1 === 'string' || typeof param2 === 'string') {
          param1 = param1?.toLowerCase();
          param2 = param2?.toLowerCase();
        }
        return param1 > param2 ? -1 * direction : 1 * direction;
      });
      return value;
    } catch {
      return value;
    }
  }
}
