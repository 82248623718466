<nb-card class="dialog-card">
  <nb-card-body>
    <div class="row">
      <div class="col">
        <header class="text-center">
          <h3 class="fw-bolder"><nb-icon *ngIf="icon" icon="{{ icon }}"></nb-icon> {{ title }}</h3>
        </header>
      </div>
    </div>
    <div class="row m-4">
      <div class="col">
        <span [outerHTML]="body"></span>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-end">
        <button
          nbButton
          [status]="buttonStatus"
          size="large"
          (click)="onClose(true)"
          data-test="confirm"
          class="me-2"
        >
          {{ okButtonTitle }}
        </button>
        <button
          *ngIf="showCancel"
          nbButton
          outline
          status="basic"
          size="large"
          (click)="onClose(false)"
          data-test="cancel"
        >
          {{ cancelButtonTitle }}
        </button>
      </div>
    </div>
  </nb-card-body>
</nb-card>
