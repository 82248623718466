import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NbAuthService, NbAuthToken, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { TrweAuthService } from './auth.service';
import { PepSpinnerService } from '@targetrwe/pep-shared';
import { Router } from '@angular/router';

@Injectable()
export class TrweAuthJWTInterceptor implements HttpInterceptor {
  private readonly authService = inject(TrweAuthService);
  private readonly nbAuthService = inject(NbAuthService);
  private readonly router = inject(Router);
  private readonly spinner = inject(PepSpinnerService);
  protected readonly filter = inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER);

  private readonly openUrls: string[] = [
    '.arb',
    '/auth',
    '/lang-region/',
    '/password/confirm',
    '/password/forgot',
    '/password/reset',
    '/roles',
    '/study_invite/code',
    '/token',
    '/topic',
    '/user',
    '/user/confirm',
    '/user/login',
  ];
  constructor() {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {
      return this.nbAuthService.isAuthenticatedOrRefresh().pipe(
        switchMap((authenticated) => {
          if (authenticated) {
            return this.nbAuthService.getToken().pipe(
              switchMap((token: NbAuthToken) => {
                const JWT = `${token.getValue()}`;
                req = req.clone({
                  setHeaders: {
                    Authorization: JWT,
                  },
                });
                return next.handle(req);
              })
            );
          } else {
            /* if api call does not require auth: continue
                  else: clear and go to login
             */
            if (this.openUrls.some((url: string) => req.url.includes(url))) {
              // Request is sent to server without authentication so that the client code
              // receives the 401/403 error and can act as desired ('session expired', redirect to login, sso)
              return next.handle(req);
            } else {
              this.authService.logout();
              this.spinner.hide();
              this.router.navigate(['auth/login']);
              return throwError(() => new Error('Unauthorized'));
            }
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
