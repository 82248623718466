import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InMemoryCache } from '@apollo/client/core';
import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { API_CONFIG, IApiConfig } from './api-config';

// factory function for creating the apollo graphql interface. This provides the GraphQL api url to Apollo
export function createApollo(httpLink: HttpLink, apiConfig: IApiConfig): NamedOptions {
  return {
    ods: {
      link: httpLink.create({ uri: apiConfig.odsGraphQLUrl }),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: { fetchPolicy: 'no-cache' },
        query: { fetchPolicy: 'network-only' },
      },
    },
    studyManagement: {
      link: httpLink.create({ uri: apiConfig.studyManagementGraphQLUrl }),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: { fetchPolicy: 'no-cache' },
        query: { fetchPolicy: 'network-only' },
      },
    },
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, API_CONFIG],
    },
  ],
})
export class ApiServicesModule {}
