import { StudyStatusType } from '../types/app.types';
import { IMilestone, IUserStudyMilestone } from './milestone.interface';

/**
 * Contains the number of participants at a study stie
 */
export interface IParticipantCount {
  site: string;
  count: number;
}

/**
 * Represents a Study
 * returned from /api/v1/study
 */
export interface IStudy {
  id: number;
  study_name: string;
  study_description: string;
  created_by_id: number;
  status: StudyStatusType;
  study_start: string; // JSON encoded datetime
  study_end: string; // JSON encoded datetime
  admin_topic_arn: string;
  study_milestones?: Array<IMilestone>;
  sites: undefined; // TODO - this does not appear to be used!
  study_sites: IStudySite[];
  user_studies: IUserStudy[];
}

/**
 * Represents a Study Site
 * returned from /api/v1/study_site
 */
export interface IStudySite {
  id?: number;
  site_id: number;
  study_id: number;
  site: ISite; // TODO - Why is this here?  doesn't seem to have any data ever
  study: IStudy;
  user_studies?: Array<IUserStudy>;
  admin_topic_rn?: string;
  all_patients_topic_rn?: string;
  study_manager_topic_rn?: string;
  site_reference?: string;
  invite_code?: string;
  invite_link?: string;
  user_study_milestone_consents?: string;
  consent?: IStudySiteConsent;
}

export interface IStudySiteConsent {
  document_title: string;
  protocol_no: string;
  status: string;
  version_no: string;
  id: number;
  language_region_code: string;
  language: string;
}

/**
 * Minimual information needed to identify a Study Site
 */
export interface ISite {
  address: string;
  checked?: boolean;
  disabled?: boolean;
  id: number;
  name: string;
  study_sites?: string;
  study_sites_id?: number;
}

/**
 *  A Participant's progress in a Study
 */
export interface IUserStudy {
  id: number;
  user_id: number;
  study_site_id: number;
  study_start: string;
  study_end: string;
  user_study_milestones?: Array<IUserStudyMilestone>;
  patient_subject?: string;

  study_id?: number;
}

/**
 * Represents a Consent for a Study
 */
export interface StudyConsent {
  id: number;
  content: string;
  protocol_no: string;
  corrected_date: string;
  version_no: string;
  commit_branch: string;
  message: string;
  document_title: string;
  created_by_id: string;
  study_id: number;
  study_site_id: number;
  study_site_ids: Array<number>;
  status: string;
  language_region_code: string;
}
