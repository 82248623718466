import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StudyManagementApiService } from './api/study-management-api.service';
import { PatientSubject } from './api/study-management-api.types';

@Injectable({
  providedIn: 'root',
})
export class PatientSubjectService {
  constructor(private studyManagementApiService: StudyManagementApiService) {}

  /**
   * Return the Patient Subject information for the User at the given Study Site
   */
  public getPatientSubject(userID: number, siteReferenceID: string): Observable<PatientSubject> {
    return this.studyManagementApiService
      .patientSubject({
        query: {
          userID,
          siteReferenceID,
        },
      })
      .pipe(map((result) => result.data.patientSubject));
  }
}
