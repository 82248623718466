import { ActionModeType } from '../types';
import { IPEPSurvey } from './pep-survey.interface';

/**
 * Possible options when creating the SurveyCreator
 * @note This is DIFFERENT from the ISurveyCreatorOptions exposed in survey-creator!
 */
export type ISurveyCreatorOptions = Partial<{
  allowControlSurveyTitleVisibility: boolean;
  allowEditExpressionsInTextEditor: boolean;
  allowModifyPages: boolean;
  closeModalOutside: boolean;
  designerHeight: number;
  generateValidJSON: boolean;
  hideExpressionHeaderInLogicTab: boolean;
  inplaceEditForValues: boolean;
  isAutoSave: boolean;
  maxLogicItemsInCondition: number;
  pageEditingMode: boolean;
  questionTypes: string[];
  readOnly: boolean;
  showDefaultLanguageInTestSurveyTab: boolean;
  showDesignerTab: boolean;
  showDropdownPageSelector: boolean;
  showEmbeddedSurveyTab: boolean;
  showErrorOnFailedSave: boolean;
  showInvisibleElementsInTestSurveyTab: boolean;
  showJSONEditorTab: boolean;
  showLogicTab: boolean;
  showObjectTitles: boolean;
  showOptions: boolean;
  showPagesInTestSurveyTab: boolean;
  showPropertyGrid: boolean;
  showSimulatorInTestSurveyTab: boolean;
  showSurveyTitle: boolean;
  showTestSurveyTab: boolean;
  showTitlesInExpressions: boolean;
  showToolbox: boolean;
  showTranslationTab: boolean;
  useTabsInElementEditor: boolean;
  showState: boolean;
}>;

export type EditorType = 'questionbank' | 'questionnaire';

/**
 * Configuration data for initializing the SurveyCreator
 */
export interface ISurveyCreatorConfig {
  survey: IPEPSurvey | undefined; // the survey to edit

  isDev: boolean;
  updateToolConfiguration: boolean;
  mode: ActionModeType;
  allowMultipleQuestions: boolean;
  page: EditorType;
  languageList: Array<string>;

  allowTranslations?: boolean;
}
