/**
 * defines a File in a survey
 */
export interface ISurveyFile {
  content: string;
  name: string;
}

export type ISurveyResponseValue = string | number | Array<string | number | ISurveyFile>;

/**
 * Key/value type representing the results of a questionnaire
 */
export interface ISurveyResponse {
  [key: string]: ISurveyResponseValue;
}
