export interface IPaymentSummary {
  amount_paid: number | string;
  study_name: string;
  milestone_name: string;
  date: string; // UI field
  amount_earned: number | string;
  currency_code: string;
  amount?: number | string;
  user_study_milestone_id: number;
  user_study_id: number;
  study_site_id: number;
  site_name: string;
  payment_type: string;
  transaction_id: string;
  updated_at: string;
  user_study_milestone_upated_at: string;
}

export interface IPaymentClaim {
  user_id: number;
  payment_type: string;
  email?: string;
  mobile_number?: string;
}

export interface IPaymentConfig {
  appid: string;
  authend?: string;
  scopes: string;
  containerid: string;
  responseType: string;
  locale: string;
  buttonType: string;
  buttonShape: string;
  buttonSize: string;
  fullPage: string;
  returnurl: string;
  nonce?: number;
}

export interface IPaymentAccount {
  code?: string;
  processor: string;
}

export interface IAccountLinkStatus {
  success?: boolean;
  errorType?: {
    error: string;
  };
  source?: string;
}

export interface ISitePaymentMethod {
  studySiteId: number;
  studySiteIds: number[];
  studyName: string;
  siteName: string;
  amount: number;
  isForSurvey: boolean;
}
