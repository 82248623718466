import { Attribute, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'pes-form-debug',
  templateUrl: './form-debug.component.html',
  styleUrls: ['./form-debug.component.scss'],
})
export class PesFormDebugComponent {
  @Input() control!: AbstractControl;
  @Input() recursive = true;

  constructor(@Attribute('formControlName') public formControlName: string) {}

  get controlType(): string {
    if (this.control instanceof UntypedFormArray) {
      return 'array';
    } else if (this.control instanceof UntypedFormGroup) {
      return 'group';
    } else {
      return 'control';
    }
  }

  /**
   * Returns the name of the Control, or undefined if it can't be determined
   */
  public getControlName(): string | undefined {
    const controls = this.control?.parent?.controls;
    if (!controls) {
      return 'ROOT';
    }

    return Object.entries(controls).find((x) => x[1] === this.control)?.[0];
  }

  public getChildren(control: AbstractControl): Array<AbstractControl> {
    if ('controls' in control) {
      const controls = (control as UntypedFormGroup).controls;
      return Object.values(controls).map((v) => v);
    }
    return [];
  }
}
