<div class="form-group">
  <label *ngIf="label" for="{{ inputId }}" class="form-label">
    <span *ngIf="isRequired" class="required">*</span>
    <span> {{ label }}</span>
  </label>
  <i class="selected-icon primary" [class]="postIcon"></i>
  <nb-select
    fullWidth
    [placeholder]="placeholder"
    [optionsOverlayOffset]="overlayOffset"
    id="{{ inputId }}"
    [size]="size"
    [formControl]="control"
    [ngClass]="{ 'is-invalid': showErrors && control.errors }"
  >
    <nb-option *ngFor="let option of options" value="{{ option.value }}">
      {{ option.label }}
      <i class="mx-2 options-icon primary" [class]="option.postIcon" *ngIf="option.postIcon"></i>
    </nb-option>
  </nb-select>
  <pes-form-error
    *ngIf="showErrors && control.errors"
    [control]="control"
    [errors]="errors"
  ></pes-form-error>
</div>
