import { IUserRole } from './user-role.interface';

export interface IFormattedUserProfile {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  country_code: string;
  dob?: string;
  education_level?: number;
  email: string;
  first_name: string;
  income_range?: number;
  last_name: string;
  middle_name?: string;
  phone: string;
  postal_code?: string;
  preferred_language_code?: string;
  race?: number;
  roles?: Array<IUserRole>;
  sex?: string;
  specialty?: number;
  state?: string;
  title: string;
  username: string;

  password?: string;
  confirmPassword?: string;
  activeRoleId?: number;
  activeRoleName?: string;
  zipcodets?: string;
  zipcode?: string;
  token?: string;
  refresh_token?: string;
}
