import { ConsentAction } from '../types/consent-status.enum';

/**
 * Configuration state data passed in through the Router. Required to initialize
 * the Survey page
 */
export interface PesPatientConsentConfig {
  userStudyConsentId: number;
  studyConsentId: number;
  action: ConsentAction;
  userId: number;
  adminUserId: number;
  initialLanguage: string;
  backUrl: string;
  labels: {
    approvalFailed: string;
    approvedThankYou: string;
    approveTitle?: string;
    backBtn: string;
    completeTitle?: string;
    consentFailed: string;
    consentSaved: string;
    consentSubmitted: string;
    failedToLoad: string;
    genericThankYou: string;
    goToMyHomeBtn: string;
    pendingApprovalNote?: string;
    saveToPdfBtn: string;
    saving: string;
    siteName: string;
    studyName: string;
    submittedTimeLabel: string;
    verifiedTitle?: string;
    verifyConsentBtn: string;
  };
}
