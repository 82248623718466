<nb-card class="dialog-card">
  <ng-container *ngIf="isDefaultEnabled()">
    <nb-card-header>
      <span>{{ 'linkNewPaymentAccount' | translate }}</span>
      <div class="float-end">
        <nb-icon icon="close-outline" (click)="close()"></nb-icon>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12">
          <h5 class="d-flex justify-content-center">{{ 'selectAccount' | translate }}</h5>
          <p class="d-flex justify-content-center">
            <span class="required">*</span>{{ 'accountLinkingInfo' | translate }}
          </p>
          <div class="col d-flex justify-content-center">
            <a
              nbButton
              #paypal
              outline
              status="basic"
              data-test="confirm"
              (click)="onModeSelect('PAYPAL')"
              type="button"
            >
              <img class="cm-logo" src="../../../../../assets/images/PayPalLogo.svg" />
            </a>
            <span id="lippButton" #paypal style="display: none"></span>
          </div>
        </div>
        <div class="col-12 py-4">
          <div class="col d-flex justify-content-center">
            <button
              nbButton
              outline
              status="basic"
              data-test="confirm"
              type="button"
              (click)="onModeSelect('VENMO')"
            >
              <img class="cm-logo" src="../../../../../assets/images/VenmoLogo.svg" />
            </button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </ng-container>
  <ng-container *ngIf="isSuccessEnabled()">
    <nb-card-header>
      <span>{{ 'confirmed' | translate }}</span>
      <div class="float-end">
        <nb-icon icon="close-outline" (click)="close()"></nb-icon>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12">
          <p class="d-flex justify-content-center">{{ 'linkedPaypalAccount' | translate }}</p>
        </div>
        <div class="col-12 py-4">
          <div class="col d-flex justify-content-center">
            <button nbButton status="primary" type="button" (click)="onDone(true)">
              {{ 'done' | translate }}
            </button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </ng-container>
  <ng-container *ngIf="isVenmoEnabled()">
    <nb-card-header>
      <span *ngIf="!success">{{ 'linkNewPaymentAccount' | translate }}</span>
      <span *ngIf="success">{{ 'confirmed' | translate }}</span>
      <div class="float-end">
        <nb-icon icon="close-outline" (click)="close()"></nb-icon>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12" *ngIf="!success">
          <span class="d-flex"
            ><b>{{ 'venmo' | translate }}</b></span
          >
          <span class="d-flex">{{ 'venmoLinkInfoText' | translate }}</span>
          <span class="d-flex info-sub-text"
            ><span class="required">*</span>{{ 'venmoNoteText' | translate }}</span
          >
        </div>
        <div class="col-12 py-4">
          <form [formGroup]="paymentVenmoForm" *ngIf="!success" novalidate>
            <div class="mb-4 form-group">
              <label class="form-label" for="input-phone"
                ><span class="required">*</span>{{ 'phoneNo' | translate }}:</label
              >
              <div
                class="w-100 d-inline-flex dial-code"
                [ngClass]="{ 'is-invalid': submitted && paymentVenmoForm.controls.phone.errors }"
              >
                <nb-select
                  size="large"
                  class="border-0 country-code"
                  formControlName="country_code"
                >
                  <nb-option
                    class="code-opt"
                    *ngFor="let code of DialCode"
                    value="{{ code.dial_code }}"
                  >
                    {{ code.dial_code }} ({{ code.code }})
                  </nb-option>
                </nb-select>
                <input
                  nbInput
                  id="input-phone"
                  class="phone form-control border-0 w-75"
                  type="tel"
                  fullWidth
                  fieldSize="large"
                  #phone
                  formControlName="phone"
                />
              </div>
              <div
                *ngIf="submitted && paymentVenmoForm.controls.phone.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="paymentVenmoForm.controls.phone.errors?.['pattern']">
                  {{ 'invalidPhoneFormat' | translate }}
                </div>
              </div>
            </div>
            <div class="mb-4 form-group">
              <label class="form-label" for="input-phone"
                ><span class="required">*</span>{{ 'verifyPhoneNo' | translate }}:</label
              >
              <div
                class="w-100 d-inline-flex dial-code"
                [ngClass]="{
                  'is-invalid': submitted && paymentVenmoForm.controls.verifyPhone.errors
                }"
              >
                <nb-select
                  size="large"
                  class="border-0 country-code"
                  formControlName="verify_country_code"
                >
                  <nb-option
                    class="code-opt"
                    *ngFor="let code of DialCode"
                    value="{{ code.dial_code }}"
                  >
                    {{ code.dial_code }} ({{ code.code }})
                  </nb-option>
                </nb-select>
                <input
                  nbInput
                  id="input-phone"
                  (paste)="(false)"
                  class="phone form-control border-0 w-75"
                  type="tel"
                  fullWidth
                  fieldSize="large"
                  #verifyPhone
                  formControlName="verifyPhone"
                />
              </div>
              <div
                *ngIf="submitted && paymentVenmoForm.controls.phone.invalid"
                class="invalid-feedback"
              >
                <div *ngIf="paymentVenmoForm.controls.phone.errors?.['pattern']">
                  {{ 'invalidPhoneFormat' | translate }}
                </div>
              </div>
              <div
                *ngIf="paymentVenmoForm.controls.verifyPhone.value && paymentVenmoForm.errors?.['mustMatch']"
                class="invalid-feedback d-flex"
                id="confirmEmail-error"
              >
                {{ 'phoneMismatchError' | translate }}
              </div>
            </div>
            <div class="mb-4 d-flex justify-content-center">
              <button
                nbButton
                status="primary"
                (click)="onVenmoSubmit('VENMO')"
                [disabled]="!paymentVenmoForm.valid"
                type="submit"
              >
                {{ 'done' | translate }}
              </button>
            </div>
          </form>
          <p *ngIf="success" class="d-flex justify-content-center">
            {{ 'linkedVenmoAccount' | translate }}
          </p>
          <div *ngIf="success" class="mb-4 d-flex justify-content-center">
            <button nbButton status="primary" (click)="onDone(true)" type="submit">
              {{ 'done' | translate }}
            </button>
          </div>
        </div>
      </div>
    </nb-card-body>
  </ng-container>
  <nb-card-footer></nb-card-footer>
</nb-card>
