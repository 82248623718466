<div class="call-out" *ngIf="showLanguageAlert">
  <div class="callout-text" innerHTML="{{ 'preferredLanguageLoginAlert' | translate: { language: language } }}"></div>
  <div class="row">
    <div class="col-xxxl-12 col-xxl-12 col-md-12">
      <button class="me-2" nbButton status="primary" size="small" (click)="onOk()">
        {{ 'okButtonTitle' | translate }}
      </button>
      <button nbButton size="small" (click)="onCancel()">
        {{ 'cancel' | translate }}
      </button>
    </div>
  </div>
</div>
<img class="img-fluid d-block main-img" src="{{ brandlogo }}" alt="Engage - Target RWE" />
<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <ul class="alert-message-list d-flex justify-content-center">
    <li class="alert-message">Login Error: {{ error }}</li>
  </ul>
</nb-alert>
<nb-alert *ngIf="message && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Success</b></p>
  <ul class="alert-message-list">
    <li class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form [formGroup]="loginForm" (ngSubmit)="onLogin()" novalidate class="mt-5">
  <!-- Email -->
  <div class="mb-4">
    <pes-form-input [control]="loginForm.controls.username" [size]="'large'" label="{{ 'user_email' | translate }}"
      [errors]="{
        required: translate('formError_required'),
        pattern: translate('invalidEmailAddress')
      }" [showErrors]="submitted">
    </pes-form-input>
  </div>

  <!-- Password -->
  <div class="mb-4 form-group">
    <pes-form-input [control]="loginForm.controls.password" [size]="'large'" [type]="'password'"
      label="{{ 'password' | translate }}" [errors]="{
        required: translate('formError_required'),
        pattern: translate('passwordPatternError'),
        minlength: translate('passwordLengthError', { minLength: 8, maxLength: 50 }),
        maxLength: translate('passwordLengthError', { minLength: 8, maxLength: 50 })
      }" [showErrors]="submitted">
    </pes-form-input>
  </div>

  <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted">
    {{ 'signIn' | translate }}
  </button>
</form>

<section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
  <p>
    <span>{{ 'orSignInWith' | translate }}</span>
  </p>
  <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link" [routerLink]="socialLink.link" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon" [pack]="socialLink.pack || ''"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
      <a *ngIf="socialLink.url" [attr.href]="socialLink.url" [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon" [class.with-icon]="socialLink.icon">
        <nb-icon *ngIf="socialLink.icon; else title" [icon]="socialLink.icon" [pack]="socialLink.pack || ''"></nb-icon>
        <ng-template #title>{{ socialLink.title }}</ng-template>
      </a>
    </ng-container>
  </div>
</section>

<section class="sign-in-or-up text-center mb-4" aria-label="Account reset and signup">
  <p>
    {{ 'forgotPassword' | translate }}?
    <a class="text-link" routerLink="../request-password">{{ 'reset' | translate }}</a>
  </p>
  <p *ngIf="isPep">
    {{ 'dontHaveAccountQuestionLabel' | translate }}
    <a class="text-link" routerLink="../register">{{ 'signup' | translate }}</a>
  </p>
  <p>
    <i class="fa fa-question-circle" aria-hidden="true"></i><a class="text-link" routerLink="../help"> {{ 'help' |
      translate }}</a>
  </p>
</section>