import { IElements } from './element.interface';
import { IPEPSurvey } from './pep-survey.interface';

export interface IQuestionnaire {
  created_by_id?: number;
  id: number;
  json_content: IElements;
  title: string;
  version?: string;
  version_date?: string;
  status?: string;
  study_name?: string;
  study_id?: number;
  duplicate?: boolean;
  questionnaire_content?: IPEPSurvey;
  template_type?: string;
  name?: string;
  form_type?: string;
}

/**
 * API Response data from /api/v1/questionnaire/:id
 */
export interface IQuestionnaireResponse {
  id: number;
  version: string;
  name: string;
  status: string;
  version_date: Date;
  title: string;
  json_content: IElements;
  template_type: string;
}
