import { Component, Input } from '@angular/core';
import { NbComponentSize } from '@nebular/theme';
import { PesFormControlBaseComponent } from '../form-control-base/form-control-base.component';

/**
 * Styled Form Text input
 */
@Component({
  selector: 'pes-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class PesFormInputComponent extends PesFormControlBaseComponent {
  /**
   * The Nebular input size
   */
  @Input() size: NbComponentSize = 'medium';

  /**
   * The input type. Either `text` (default) or `password`
   */
  @Input() type: 'text' | 'password' | 'number' = 'text';

  /**
   * When true, input is readonly
   */
  @Input() readonly = false;

  /**
   * placeholder text
   */
  @Input() placeholder = '';
}
