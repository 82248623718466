import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IMessageNotification } from '../../interfaces';
import { API_CONFIG, IApiConfig } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepMessageService {
  private readonly endpoints = {
    messageDataById: '/api/v1/message',
    messageById: '/api/v2/message',
  };
  constructor(
    private apiService: PepApiService,
    @Inject(API_CONFIG) private apiConfig: IApiConfig
  ) {}

  /**
   * Get message data array by user id
   */
  public getMessageDataByRecipientId(recipientId: number): Observable<Array<IMessageNotification>> {
    return this.apiService.getAll(
      this.apiConfig.messageServiceUrl,
      `${this.endpoints.messageDataById}/?recipientid=${recipientId}`
    );
  }

  /**
   * Get a message id
   */
  public getMessageById(messageId: number): Observable<IMessageNotification> {
    return this.apiService.getSingle<IMessageNotification>(
      this.apiConfig.messageServiceUrl,
      this.endpoints.messageById,
      messageId
    );
  }

  /**
   * Put to update status on message
   */
  public updateStatus(
    message: Array<IMessageNotification>
  ): Observable<Array<IMessageNotification>> {
    return this.apiService.update(
      this.apiConfig.messageServiceUrl,
      this.endpoints.messageDataById,
      message
    );
  }

  public extractContent(message: string): string {
    const span = document.createElement('span');
    span.innerHTML = message;
    return span.textContent || span.innerText;
  }
}
