import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PesMaskEmailPipe } from './mask-email.pipe';
import { PesMaskTextPipe } from './mask-text.pipe';
import { PesMultiFilterPipe } from './multi-filter.pipe';
import { PesSortPipe } from './sort.pipe';

const pipes = [PesMaskEmailPipe, PesMaskTextPipe, PesMultiFilterPipe, PesSortPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [PesMaskEmailPipe, PesMaskTextPipe, PesMultiFilterPipe, PesSortPipe],
})
export class PepPipesModule {}
