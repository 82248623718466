<img class="img-fluid d-block main-img" src="{{ brandlogo }}" alt="Target RWE" />
<h3 id="title" class="title">{{ 'forgotPassword' | translate }}</h3>
<p class="sub-title">{{ 'emailConfirmationMessage' | translate }}</p>

<nb-alert *ngIf="error && !submitted" outline="danger" role="alert">
  <ul class="alert-message-list">
    <li class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="message && !submitted" outline="success" role="alert">
  <p class="alert-title">
    <b>{{ 'success' | translate }}</b>
  </p>
  <ul class="alert-message-list">
    <li class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form [formGroup]="requestForm" (ngSubmit)="onRequestPassword()" novalidate>
  <div class="mb-4">
    <pes-form-input
      [control]="requestForm.controls.email"
      [size]="'large'"
      label="{{ 'user_email' | translate }}"
      [errors]="{
        required: translate('formError_required'),
        pattern: translate('invalidEmailAddress')
      }"
      [showErrors]="submitted"
    >
    </pes-form-input>
  </div>

  <button nbButton fullWidth status="primary" size="large" [class.btn-pulse]="submitted">
    {{ 'requestPassword' | translate }}
  </button>
</form>

<section class="sign-in-or-up text-center mb-4" aria-label="Account reset and signup">
  <p>
    {{ 'haveAnAccountQuestionLabel' | translate }}
    <a class="text-link" routerLink="../login">{{ 'signIn' | translate }}</a>
  </p>
  <p>
    {{ 'dontHaveAccountQuestionLabel' | translate }}
    <a class="text-link" routerLink="../register">{{ 'signup' | translate }}</a>
  </p>
</section>
