import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IMessageNotification } from '../../../../interfaces/message-notification.interface';
import { catchError, of } from 'rxjs';
import { pepTranslate } from '../../../../utilities/translate';
import { PepMessageService } from '../../../../services/api-services/message.service';
import { IUserInfo } from '../../../../interfaces/user-info.interface';

@Component({
  selector: 'pes-messages-popout',
  templateUrl: './messages-popout.component.html',
  styleUrls: ['./messages-popout.component.scss'],
})
export class PesMessagesPopoutComponent implements OnInit {
  @Input() public loggedInUser!: IUserInfo;
  public error!: string;
  private messageData: IMessageNotification[] = [];
  public topMessages: IMessageNotification[] = [];
  public instructions = '';
  public buttonLabel = '';

  public readonly translate = pepTranslate();
  private readonly messageService = inject(PepMessageService);
  private readonly router = inject(Router);

  constructor() {}

  ngOnInit(): void {
    this.instructions = this.translate('openInboxInstruction');
    this.buttonLabel = this.translate('viewInbox');
    if (this.loggedInUser) {
      this.getMessages();
    }
  }

  private getMessages() {
    this.messageService
      .getMessageDataByRecipientId(this.loggedInUser.user_id)
      .pipe(
        catchError(() => {
          this.error = this.translate('badRequest');
          return of([]);
        })
      )
      .subscribe((messageResponse: Array<IMessageNotification>) => {
        messageResponse.forEach((item: IMessageNotification) => {
          if (item.notification_type === 'msgt-email-html') {
            item.message = this.messageService.extractContent(item.message);
            this.messageData.push(item);
          }
        });
        this.topMessages = [...this.messageData.splice(0, 3)];
      });
  }

  public onOpenInbox(): void {
    const url = '/user/messages/inbox';
    this.router.navigateByUrl(url, { state: { user: this.loggedInUser } });
  }
}
