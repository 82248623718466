import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAdminSite } from '../../interfaces/admin-site.interface';
import { ISite, IStudySite } from '../../interfaces/study.interface';
import { API_CONFIG, IApiConfig } from './api-config';
import { PepApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PepSiteService {
  private readonly endpoints = {
    sites: '/api/v1/site',
    studySites: '/api/v1/study_site',
  };

  constructor(
    private apiService: PepApiService,
    @Inject(API_CONFIG) private apiConfig: IApiConfig
  ) {}

  /**
   * Get Admin sites by user id and role id
   */
  public getAdminSites(
    userId: number,
    roleId: number,
    isSiteAdmin: boolean,
    filter?: string
  ): Observable<Array<IAdminSite>> {
    let query = `?isactive=true`;
    // If user role is site admin
    if (isSiteAdmin) {
      query += `&userid=${userId}&roleid=${roleId}`;
    }

    if (filter) {
      query += filter;
    }
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.sites}/${query}`
    );
  }

  /**
   * Get all sites
   */
  public getSites(): Observable<ISite[]> {
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.sites}/?isactive=true`
    );
  }

  /**
   * Get all study sites by studyId
   */
  public getStudySitesById(studyId: number): Observable<Array<IStudySite>> {
    return this.apiService.getAll(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studySites}/?studyid=${studyId}`
    );
  }

  /**
   * Get a specified Study Site
   */
  public getStudySite(siteId: number): Observable<IStudySite> {
    return this.apiService.getSingle(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studySites}`,
      siteId
    );
  }

  /**
   * Update Site Reference code
   */
  public updateSiteReferenceCode(sites: { id: number; site_reference: string }[]): Observable<any> {
    return this.apiService.update(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studySites}/`,
      sites
    );
  }

  /**
   * Update (should be a put) study sites
   */
  public updateStudySite(
    sites: {
      site_id: number;
      study_id: number;
    }[]
  ): Observable<IStudySite[]> {
    return this.apiService.add(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studySites}/`,
      sites
    );
  }

  /**
   * Remove all study sites by studyId
   */
  public removeStudySitesById(studySiteId: number): Observable<string> {
    return this.apiService.delete(
      this.apiConfig.studyManagementServiceUrl,
      `${this.endpoints.studySites}/${studySiteId}`
    );
  }
}
