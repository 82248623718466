import { Component, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PesFormErrorArgs, PesFormErrors } from './form-error.types';

@Component({
  selector: 'pes-form-error',
  templateUrl: './form-error.component.html',
})
export class PesFormErrorComponent {
  @HostBinding('class.invalid-feedback') private readonly _classInvalidFeedback = true;

  @Input() control: AbstractControl | undefined;

  /**
   * Object defining the error codes and what message to display for that error
   * IF the message is a function, it
   */
  @Input() errors: PesFormErrors = {};

  public get errorCodes(): Array<{ code: string; label: (err: PesFormErrorArgs) => string }> {
    return Object.entries(this.errors).map((kv) => ({
      code: kv[0],
      label: typeof kv[1] === 'function' ? kv[1] : (): string => `${kv[1]}`,
    }));
  }

  constructor() {}
}
