import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PesFormErrors } from '../form-error/form-error.types';

/**
 * Base class for Pes Form Controls (Input, Select, etc.)
 */
@Component({
  template: ` <p>form-control-base works!</p> `,
  styles: [],
})
export abstract class PesFormControlBaseComponent {
  private _inputId = `_${Date.now()}`;

  /**
   * HTML id used to associate the label with the input control
   */
  public get inputId(): string {
    return `${this.label.replace(/\s/g, '')}${this._inputId}`;
  }

  /**
   * Is true if the input is Required (ie, has Validators.required attached to it)
   */
  public get isRequired(): boolean {
    const result = this.control.hasValidator(Validators.required);
    return result;
  }

  /**
   * Is true if there are any errors in the control and showErrors is set to true
   */
  public get isInvalid(): boolean {
    return this.showErrors && !!this.control.errors;
  }

  /**
   * Text displayed above the input
   */
  @Input() label = '';

  /**
   * The Angular FormControl to assign to this input
   */
  @Input() control!: FormControl;

  /**
   * Object defining the error codes and the strings to display for that error
   * Ex:
   * ```
   * [errors]="{ 'required': 'Required Field' }"
   * ```
   */
  @Input() errors: PesFormErrors = {};

  /**
   * Set to true to show any Form errors, false to hide errors.
   */
  @Input() showErrors = true;

  /**
   * Placeholder for dropdown
   */
  @Input() placeholder = '';
}
