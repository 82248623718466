import { ISurveyResponse } from './survey-response.interface';

/**
 * Result emitted after completing a survey
 */
export interface ISurveyResult {
  responses: ISurveyResponse;
  completedAt: string; // date/time string
  location?: string; // where survey was completed (lat/long or description) if available
}
