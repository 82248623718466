/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO - FIX TYPINGS IN THIS FILE

import { Injectable } from '@angular/core';
import { Canvg } from 'canvg';
import { from } from 'rxjs';
import { IHTMLRenderType, SurveyPDF } from 'survey-pdf';
import { svgColors } from '../../constants';
import { IPEPSurvey } from '../../interfaces/pep-survey.interface';
import { ISurveyResponse } from '../../interfaces/survey-response.interface';
import { ISurveyResult } from '../../interfaces/survey-result.interface';
import { pepTranslate } from '../../utilities/translate';
import { Converter } from 'showdown';
/**
 * Manage interaction with a study Consent form
 */
@Injectable({ providedIn: 'root' })
export class PepPdfExportService {
  public htmlOptions: IHTMLRenderType = 'image';
  // common options for generating Consent PDFs
  public readonly pdfOptions = {
    htmlRenderAs: this.htmlOptions,
    commercial: true,
    fontSize: 10,
    format: [215, 279],
    margins: {
      bot: 10,
      left: 10,
      right: 10,
      top: 10,
    },
    useCustomFontInHtml: true,
  };

  public readonly translate = pepTranslate();

  /**
   * Print the Consent document as a PDF
   */
  public async printPDF(pdfJson: IPEPSurvey, useOriginalTemplate?: boolean): Promise<void> {
    try {
      const fileURL = await this.createPdfFromSurvey(pdfJson, useOriginalTemplate);
      const getMyFrame = document.getElementById('pdfPrint') as HTMLIFrameElement;
      getMyFrame.src = fileURL;
      setTimeout(() => {
        getMyFrame.focus();
        setTimeout(() => {
          getMyFrame.contentWindow?.print();
        }, 200);
      }, 100);
    } catch (err) {
      console.error(`Error printing the Consent as a PDF: ${err}`);
    }
  }

  /**
   * Create a PDF of the passed survey Json and return URL path to it
   *
   * @param pdfJson survey in JSON format
   * @returns path to the PDF file
   */
  private async createPdfFromSurvey(
    pdfJson: IPEPSurvey,
    useOriginalTemplate?: boolean
  ): Promise<string> {
    this.preparePDF(pdfJson, undefined, useOriginalTemplate);
    const surveyPDF = new SurveyPDF(pdfJson, this.pdfOptions);
    this.pdfMarkdown(surveyPDF);
    surveyPDF.haveCommercialLicense = true;
    const blobfile = await surveyPDF.raw('blob');
    const file = new Blob([blobfile], { type: 'application/pdf' });
    return URL.createObjectURL(file);
  }

  /**
   * Prepare he Survey for PDF rendering by replacing incompatible controls with compatible ones
   */
  private preparePDF(pdf: IPEPSurvey, language?: string, useOriginalTemplate?: boolean): void {
    if (pdf.pages) {
      pdf.pages.map((page, pageIndex) => {
        // used pageIndex & elIndex to calculate the question number for svg image,
        // because html type doesn't support the title property
        pageIndex++;
        if (page.elements) {
          page.elements.map((element, elIndex) => {
            if (useOriginalTemplate) {
              delete element.visibleIf;
            }
            elIndex++;
            if (element.type === 'dropdown') {
              element.type = 'radiogroup';
            } else if (element.type === 'emotionsratings') {
              element.type = 'radiogroup';
              const choices: any = element.choices?.map((c, index) => {
                // if emotions are not updated, survey js doesn't pass the default values
                const emotion = element.emotions ? element.emotions[index] : c;
                let text: any = c;
                if (typeof emotion === 'object') {
                  text = emotion.text;
                }
                return { text, value: c };
              });
              element.choices = [...choices];
              delete element.emotions;
            } else if (element.type === 'file') {
              element.visible = false;
            } else if (element.type === 'svgmap') {
              let maxRange = 10;
              if (element.rangeMax) {
                maxRange = element.rangeMax;
              }

              const logo = document.createElement('div');
              logo.id = 'svgId';
              logo.innerHTML = element.svg || '';
              document.body.appendChild(logo);
              let pathHtml = '';
              element.paths?.forEach((path: any) => {
                if (path.value > 0) {
                  $(`#svgId svg #${path.text}`).css(
                    'fill',
                    svgColors[(path.value / maxRange) * 10]
                  );
                }
                pathHtml += `<p style="margin-bottom:0.5rem !important;text-transform: capitalize;"><b>${path.text}</b> : ${path.value}</p>`;
              });
              document.body.removeChild(logo);
              element.type = 'html';
              let title: string;
              if (typeof element.title === 'string') {
                title = element.title;
              } else if (language && language in element.title) {
                title = element.title[language];
              } else {
                title = element.title['default'];
              }
              element.html = `<b style="font-size:15px;">${
                pageIndex * elIndex
              }. ${title}</b><br><br>
                              <div style="display:flex;">
                              <img style="width:500px;height:400px" src="${this.svgToPng(
                                logo.innerHTML
                              )}"><br><br>
                             <div style="margin-right:20px;width:30%"><p style="margin-bottom:0.5rem !important"><b>
                             Range: ${element.rangeMin || 0} - ${element.rangeMax || 10}
                             </b></p>${pathHtml}</div></div>`;
              delete element.svg;
              delete element.paths;
            }
          });
        }
      });
    }
  }

  /**
   * Render the SVG as a PNG and return the DataURL to it
   * @returns the data url to the svg as a PNG image
   */
  private svgToPng(svg: string): string {
    //Get svg markup as string
    if (svg) {
      svg = svg.replace(/\r?\n|\r/g, '').trim();
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      const v = Canvg.fromString(context, svg);
      // Start drawing the SVG on the canvas
      v.start();
      // Convert the Canvas to an image
      return canvas.toDataURL('img/png');
    }
    return '';
  }

  /**
   * Return Blob format of the survey JSON
   *
   * @param survey: is the survey JSON
   * @param userResponse: user saved answers
   * @param dateTime: User submitted Survey datetime
   * @param location: user's current location
   * @param language: user's preferred language to fill survey
   *
   * @deprecated Use toBlob instead!
   */
  public async getSurveyBlob(
    survey: IPEPSurvey,
    dateTime?: string,
    location?: string,
    language?: string
  ): Promise<string> {
    this.preparePDF(survey, language);
    const surveyPDF = new SurveyPDF(survey, this.pdfOptions);
    this.pdfMarkdown(surveyPDF);
    surveyPDF.haveCommercialLicense = true;
    surveyPDF.mode = 'display';
    if (language) {
      surveyPDF.locale = language;
    }

    if (dateTime) {
      surveyPDF.onRenderFooter.add((reseponse, canvas) => {
        canvas.drawText({
          text: `${this.translate('consent_submit--time-label')} ${dateTime} ${location || ''}`,
          fontSize: 8,
        });
      });
    }
    return await surveyPDF.raw('blob');
  }

  /**
   * Generate a PDF for the survey
   *
   * @param pdfJson survey in JSON format
   * @param data survey response data used to fill out the survey before rendering
   * @returns Blob data
   */
  public toBlob(pdfJson: IPEPSurvey, result?: ISurveyResult) {
    this.preparePDF(pdfJson);
    const surveyPDF = new SurveyPDF(pdfJson, this.pdfOptions);
    this.pdfMarkdown(surveyPDF);
    surveyPDF.mode = 'display';
    surveyPDF.data = result?.responses;

    if (result) {
      surveyPDF.onRenderFooter.add((_, canvas) => {
        canvas.drawText({
          text: `${this.translate('consent_submit--time-label')} ${result?.completedAt} ${
            result?.location || ''
          }`,
          fontSize: 8,
        });
      });
    }

    return from(surveyPDF.raw('blob'));
  }

  /**
   * Save the Survey as a PDF
   *
   * @param pdfJson survey in JSON format
   * @param title name of the file postfix
   * @param data survey response data used to fill out the survey before rendering
   */
  public savePDF(
    pdfJson: IPEPSurvey,
    title: string,
    data?: ISurveyResponse,
    useOriginalTemplate?: boolean
  ) {
    this.preparePDF(pdfJson, undefined, useOriginalTemplate);
    const surveyPDF = new SurveyPDF(pdfJson, this.pdfOptions);
    this.pdfMarkdown(surveyPDF);
    surveyPDF.mode = 'display';
    surveyPDF.data = data;
    surveyPDF.save(title);
  }

  /**
   * Save the Survey as a PDF
   *
   * @param pdfJson survey in JSON format
   * @param title name of the file postfix
   * @param data survey response data used to fill out the survey before rendering
   */
  public async savePDFAsync(
    pdfJson: IPEPSurvey,
    title: string,
    data?: ISurveyResponse,
    useOriginalTemplate?: boolean
  ): Promise<any> {
    this.preparePDF(pdfJson, undefined, useOriginalTemplate);
    const surveyPDF = new SurveyPDF(pdfJson, this.pdfOptions);
    this.pdfMarkdown(surveyPDF);
    surveyPDF.mode = 'display';
    surveyPDF.data = data;
    return surveyPDF.save(title);
  }
  /**
   * Open Consent document as a PDF
   */
  public async viewPDF(pdfJson: IPEPSurvey, useOriginalTemplate?: boolean): Promise<void> {
    try {
      const fileURL = await this.createPdfFromSurvey(pdfJson, useOriginalTemplate);
      window.open(fileURL);
    } catch (err) {
      console.error(`Error viewing the Consent as a PDF: ${err}`);
    }
  }
  /**
   * @param surveyPDF
   * method to convert html
   */
  public pdfMarkdown(surveyPDF: any): void {
    const converter = new Converter();
    surveyPDF.onTextMarkdown.add((survey: any, options: any) => {
      let str = converter.makeHtml(options.text);
      str = str.replace('<p>', '');
      str = str.replace('</p>', '');
      options.html = str;
    });
  }
}
