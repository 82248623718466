import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OdsApiService } from './api/ods-api.service';
import { Consent, InputCreateConsent } from './api/ods-api.types';

/**
 * Service for accessing Participant Consent data using GraphQL
 */
@Injectable({
  providedIn: 'root',
})
export class ParticipantConsentService {
  constructor(private odsApiService: OdsApiService) {}

  /**
   * Creates a new participant record
   *
   * @returns the newly created record
   */
  public create(consent: InputCreateConsent): Observable<boolean> {
    return this.odsApiService
      .createConsent({ input: consent })
      .pipe(map((result) => !!result?.data?.createConsent));
  }

  /**
   * Fetch the Consent data for the consent with database ID
   *
   * @param id database Id
   */
  public fetch(id: number): Observable<Consent> {
    return this.odsApiService.consent({ id }).pipe(map((result) => result.data.consent));
  }

  /**
   * Fetch the Consent data for the participant with matching dmSubjID
   *
   * @param dmSubjID SubjectId of the participant to fetch
   */
  public fetchWithSubjId(dmSubjID: string): Observable<Consent[]> {
    return this.odsApiService
      .consents({ queryParams: { dmSubjID } })
      .pipe(map((result) => (result?.data?.consents as Consent[]) || []));
  }

  /**
   * Fetch all the Consent data for all participants
   */
  public fetchAll(): Observable<Consent[]> {
    return this.odsApiService
      .consents({ queryParams: {} })
      .pipe(map((result) => (result.data.consents as Consent[]) || []));
  }
}
